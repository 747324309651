import Layout1 from '@/layout/Layout1'

export default [{
  path: '/settings/equipment-categories',
  component: Layout1,
  children: [{
    name: 'equipment-category-list',
    path: '',
    component: () => import('@/components/equipmentCategories/CategoryList')
  },{
    name: 'sub-equipment-category-list',
    path: ':id',
    props: true,
    component: () => import('@/components/equipmentCategories/SubCategoryList')
  }]
}]
