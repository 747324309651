<template>
  <div>
    <b-modal
      no-close-on-esc
      :no-close-on-backdrop="true"
      id="time-tracking-form"
      size="lg"
      no-enforce-focus
      :title="formTitle"
      v-model="showModal"
      @show="onShow"
      @hidden="hide"
      ref="modal"
    >
      <b-form @submit.prevent="handleSubmit">
        <b-form-row>
          <b-form-group label="Project" class="col-12">
            <multiselect
              v-model="selectedProject"
              id="ajax"
              label="name"
              track-by="id"
              placeholder="Type to search"
              open-direction="bottom"
              :options="projects"
              :multiple="false"
              :searchable="true"
              :loading="isProjectLoading"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="true"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              @search-change="asyncProjectFind"
            >
              <template slot="clear" slot-scope="props"> </template>
              <span slot="noResult">Project not found.</span>
            </multiselect>
            <div
              v-if="
                $v.tracker.project_id.$dirty && !$v.tracker.project_id.required
              "
              style="display: inline-block"
              class="invalid-feedback"
            >
              Project field is required.
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group label="Task" class="col-12">
            <b-form-select
              :disabled="isTaskLoading"
              v-model="selectedTask"
              value-field="value"
              text-field="text"
              :options="tasks"
              :class="{ 'is-invalid': $v.tracker.task_id.$error }"
            ></b-form-select>
            <div
              v-if="$v.tracker.task_id.$dirty && !$v.tracker.task_id.required"
              class="invalid-feedback"
            >
              Task is required
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group label="Start Time" class="col-6">
            <div class="input-group">
              <flat-pickr
                class="form-control"
                v-model="tracker.start_at"
                :config="dateTimePickerConfig"
                :class="{ 'is-invalid': $v.tracker.start_at.$error }"
                placeholder="Start Time "
                name="invoice_date"
              >
              </flat-pickr>
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  title="Toggle"
                  data-toggle
                >
                  <i class="fa fa-calendar">
                    <span aria-hidden="true" class="sr-only">Toggle</span>
                  </i>
                </button>
              </div>
              <div
                v-if="
                  !$v.tracker.start_at.required && $v.tracker.start_at.$dirty
                "
                style="display: inline-block"
                class="invalid-feedback"
              >
                Start Time field is required.
              </div>
            </div>
          </b-form-group>
          <b-form-group class="col-6" label="Stop Time">
            <div class="input-group">
              <flat-pickr
                class="form-control"
                v-model="tracker.stop_at"
                ref="stopAt"
                :config="dateTimePickerConfig"
                :class="{ 'is-invalid': $v.tracker.stop_at.$error }"
                placeholder="Stop Time "
                name="stop_at"
              >
              </flat-pickr>
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  title="Toggle"
                  data-toggle
                >
                  <i class="fa fa-calendar">
                    <span aria-hidden="true" class="sr-only">Toggle</span>
                  </i>
                </button>
              </div>
              <div
                v-if="!$v.tracker.stop_at.required && $v.tracker.stop_at.$dirty"
                style="display: inline-block"
                class="invalid-feedback"
              >
                Stop Time field is required.
              </div>
            </div>
          </b-form-group>
        </b-form-row>

        <b-form-row>
          <b-form-group label="Notes" class="col-12">
            <b-form-textarea rows="5" v-model="tracker.notes"></b-form-textarea>
          </b-form-group>
        </b-form-row>
      </b-form>

      <template slot="modal-footer">
        <b-button :disabled="loading" variant="primary" @click="handleSubmit()"
          >Save</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import Vuelidate from "vuelidate";
import { required } from "vuelidate/lib/validators";
Vue.use(Vuelidate);

//import and use multiselect
import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from "vue-multiselect";

import _ from "lodash";
import moment from "moment";
const initData = {
  project_id: null,
  task_id: null,
  start_at: null,
  stop_at: null,
  type: "manual",
  notes: null,
};
export default {
  name: "TimeTrackingForm",
  props: ["editId", "projectId", "type", "adjust"],
  components: {
    Multiselect,
  },
  watch: {
    selectedTask(val) {
      if (val) {
        this.tracker.task_id = val;
      }
    },
    selectedProject(val) {
      if (val) {
        console.log("project selected ", val);
        this.tracker.project_id = val.id;
      }
    },
    projects(val) {
      if (val.length > 0 && this.tracker) {
        this.reselectProject();
      }
    },
    tasks(val) {
      if (val.length > 0 && this.tracker) {
        this.reselectTask();
      }
    },
    tracker() {
      if (this.tracker && this.tasks.length > 0 && this.projects.length > 0) {
        this.reselectProject();
        this.reselectTask();
      }
    },
  },
  data: () => ({
    showModal: false,
    loading: false,
    tracker: { ...initData },
    isTaskLoading: false,
    tasks: [{ text: "Select Task", value: null }],
    selectedTask: null,
    isProjectLoading: false,
    projects: [],
    selectedProject: null,
    dateTimePickerConfig: {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: window.toFlatPickrDatetime(localStorage.getItem('php_datetime_format')),
      altInput: true,
      dateFormat: "Z",
      enableTime: true,
      static: false,
      minuteIncrement: 15,
    },
  }),
  computed: {
    formTitle() {
      if (this.editId) {
        return `Edit Tracking`;
      } else {
        return `Manual Tracking`;
      }
    },
  },
  methods: {
    onShow() {
      this.getProjects();
      this.getTrackerTasks();
      if (this.editId) {
        this.getTrackerDetail(this.editId);
      }
      if (this.projectId) {
        this.tracker.project_id = this.projectId;
      }
      if (this.type == "track") {
        this.$refs.stopAt.$el.nextSibling.focus();
      }
    },
    asyncProjectFind: _.debounce(function (query) {
      let self = this;
      self.isProjectLoading = true;
      window.axios
        .post("/api/projects/search-select", {
          search_string: query,
        })
        .then((response) => {
          self.projects = response.data;
          self.isProjectLoading = false;
        });
    }, 500),
    hide() {
      this.loading = false;
      this.selectedTask = null;
      this.selectedProject = null;
      this.tracker = { ...initData };
      this.$v.tracker.$reset();
      this.showModal = false;
      this.$emit("on-hide");
    },
    show() {
      this.showModal = true;
    },
    reselectProject() {
      this.projects.forEach((project) => {
        if (project.id == this.tracker.project_id) {
          this.selectedProject = project;
        }
      });
    },
    reselectTask() {
      var taskId = this.tracker.tracker_task_id;

      this.tasks.forEach((task) => {
        if (task.value == taskId) {
          this.selectedTask = task.value;
        }
      });
    },
    getTrackerDetail(id) {
      let self = this;
      window.axios
        .get(`/api/trackers/${id}`)
        .then((response) => {
          self.tracker = response.data.data;
          self.tracker.start_at = moment
            .utc(self.tracker.start_at)
            .tz(localStorage.getItem("timezone"))
            .toISOString();
          if (self.type == "track") {
            self.tracker.stop_at = moment().toISOString();
            self.tracker.type = "track";
          } else {
            self.tracker.stop_at = moment
              .utc(self.tracker.stop_at)
              .tz(localStorage.getItem("timezone"))
              .toISOString();
          }
          
          this.reselectTask();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTrackerTasks() {
      let self = this;
      self.isTaskLoading = true;
      window.axios
        .get(`/api/tracker-tasks?is_active=1`)
        .then((response) => {
          let tasksData = response.data.data;
          self.tasks = [{ text: "Select Task", value: null }];
          tasksData.forEach((task) => {
            self.tasks.push({
              text: task.name,
              value: task.id,
            });
          });
          self.isTaskLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProjects() {
      let self = this;
      self.isProjectLoading = true;
      window.axios
        .get(`/api/projects?all=1&stage=All Open&sort=name|asc`)
        .then((response) => {
          self.projects = response.data.data;
          self.isProjectLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async handleSubmit() {
      this.$v.tracker.$touch();
      const isValid = !this.$v.tracker.$invalid;

      if (isValid) {
        this.loading = true;

        if (this.editId) {
          try {
            const postedData = { ...this.tracker };

            const response = await window.axios.put(
              `/api/trackers/${this.editId}`,
              postedData
            );
            this.$emit("on-save", response.data.data);
            this.hide();
            this.loading = false;
          } catch (error) {
            this.loading = false;
            console.log(error);
          }
        } else {
          try {
            const postedData = { ...{ type: "manual" }, ...this.tracker };

            const response = await window.axios.post(
              `/api/trackers`,
              postedData
            );

            this.$emit("on-save", response.data.data);
            this.hide();
            this.loading = false;
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
  },
  validations: {
    tracker: {
      project_id: {
        required,
      },
      task_id: {
        required,
      },
      start_at: {
        required,
      },
      stop_at: {
        required,
      },
    },
  },
};
</script>
