import Layout1 from '@/layout/Layout1'

export default [{
  path: '/settings/pricelists',
  component: Layout1,
    // beforeEnter: (to, from, next) => {
    //     if(localStorage.getItem('website_p') == 3) {
    //         next()
    //     } else {
    //         next({ path: 'dashboard' })
    //     }
    // },
  children: [{
    name: 'pricelist-list',
    path: '',
    component: () => import('@/components/pricelists/Pricelist')
  },]
}]
