var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            "no-close-on-backdrop": true,
            id: "create-new-company",
            size: "lg",
            "no-enforce-focus": "",
            title: _vm.formTitle
          },
          on: {
            shown: _vm.focusMyElement,
            show: function($event) {
              return _vm.onShow()
            }
          },
          model: {
            value: _vm.show_modal,
            callback: function($$v) {
              _vm.show_modal = $$v
            },
            expression: "show_modal"
          }
        },
        [
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-sm-12",
                  attrs: { label: "Company Status" }
                },
                [
                  _c("label", { staticClass: "switcher switcher-primary" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.is_active,
                          expression: "data.is_active"
                        }
                      ],
                      staticClass: "switcher-input",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.data.is_active)
                          ? _vm._i(_vm.data.is_active, null) > -1
                          : _vm.data.is_active
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.data.is_active,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.data,
                                  "is_active",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.data,
                                  "is_active",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.data, "is_active", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "switcher-indicator" }, [
                      _c("span", { staticClass: "switcher-yes text-primary" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "switcher-no" })
                    ]),
                    _vm._v(" "),
                    !_vm.data.is_active
                      ? _c("span", { staticClass: "switcher-label" }, [
                          _vm._v("Inactive")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.is_active
                      ? _c("span", { staticClass: "switcher-label" }, [
                          _vm._v("Active")
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-sm-12",
                  attrs: {
                    label: "Name",
                    "invalid-feedback": _vm.getErrorLabel(_vm.$v.data.name)
                  }
                },
                [
                  _c("b-input", {
                    ref: "first_name",
                    staticClass: "mb-1",
                    attrs: {
                      state: _vm.$v.data.name.$error ? "invalid" : null
                    },
                    model: {
                      value: _vm.data.name,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "name", $$v)
                      },
                      expression: "data.name"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                { staticClass: "col-sm-12", attrs: { label: "Company Type" } },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.company_type_options },
                    model: {
                      value: _vm.data.company_type_id,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "company_type_id", $$v)
                      },
                      expression: "data.company_type_id"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-sm-6",
                  attrs: {
                    label: "Email Address",
                    "invalid-feedback": _vm.getErrorLabel(_vm.$v.data.email)
                  }
                },
                [
                  _c("b-input", {
                    staticClass: "mb-1",
                    attrs: {
                      state: _vm.$v.data.email.$error ? "invalid" : null
                    },
                    model: {
                      value: _vm.data.email,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "email", $$v)
                      },
                      expression: "data.email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { staticClass: "col-sm-6", attrs: { label: "Phone Number" } },
                [
                  _c("b-input", {
                    staticClass: "mb-1",
                    model: {
                      value: _vm.data.phone_number,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "phone_number", $$v)
                      },
                      expression: "data.phone_number"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                { staticClass: "col-lg-12", attrs: { label: "Country" } },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.countries_options },
                    model: {
                      value: _vm.data.country,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "country", $$v)
                      },
                      expression: "data.country"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-form-group",
                { staticClass: "col-lg-12", attrs: { label: "Address" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.data.street,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "street", $$v)
                      },
                      expression: "data.street"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-form-group",
                { staticClass: "col-lg-12", attrs: { label: "City" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.data.city,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "city", $$v)
                      },
                      expression: "data.city"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-form-group",
                { staticClass: "col-lg-12", attrs: { label: "State" } },
                [
                  _vm.loading_states
                    ? _c("b-spinner", {
                        staticClass: "ml-2 mt-3",
                        attrs: { small: "" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-form-select",
                    {
                      attrs: {
                        options: _vm.states_options,
                        disabled: _vm.loading_states
                      },
                      model: {
                        value: _vm.data.state,
                        callback: function($$v) {
                          _vm.$set(_vm.data, "state", $$v)
                        },
                        expression: "data.state"
                      }
                    },
                    [
                      _c("template", { slot: "first" }, [
                        _c(
                          "option",
                          {
                            attrs: { disabled: "" },
                            domProps: { value: null }
                          },
                          [_vm._v("-- Please select state --")]
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-form-group",
                { staticClass: "col-lg-6", attrs: { label: "Zip" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.data.zip_code,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "zip_code", $$v)
                      },
                      expression: "data.zip_code"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("hr", { staticClass: "border-light mt-3" }),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                { staticClass: "col-sm-12", attrs: { label: "" } },
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n          Primary Contact\n          "),
                    _c(
                      "a",
                      {
                        staticClass: "text-primary",
                        attrs: { href: "javascript:void(0)" },
                        on: { click: _vm.createNewPrimaryContact }
                      },
                      [_c("i", { staticClass: "ion ion-md-add-circle" })]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        id: "ajax",
                        label: "full_name",
                        "track-by": "id",
                        placeholder: "Type to search",
                        "open-direction": "bottom",
                        options: _vm.primary_contact_options,
                        multiple: false,
                        searchable: true,
                        loading: _vm.isPrimaryContactLoading,
                        "internal-search": false,
                        "clear-on-select": false,
                        "close-on-select": true,
                        "max-height": 600,
                        "show-no-results": false,
                        "hide-selected": true
                      },
                      on: { "search-change": _vm.asyncContactFind },
                      scopedSlots: _vm._u([
                        {
                          key: "clear",
                          fn: function(props) {
                            return undefined
                          }
                        }
                      ]),
                      model: {
                        value: _vm.data.primary_contact,
                        callback: function($$v) {
                          _vm.$set(_vm.data, "primary_contact", $$v)
                        },
                        expression: "data.primary_contact"
                      }
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [_vm._v("Contact not found.")]
                      )
                    ]
                  )
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-lg-6",
                  attrs: {
                    label: "Total Employees",
                    "invalid-feedback": _vm.getErrorLabel(
                      _vm.$v.data.total_employees
                    )
                  }
                },
                [
                  _c("b-input", {
                    attrs: {
                      state: _vm.$v.data.total_employees.$error
                        ? "invalid"
                        : null
                    },
                    model: {
                      value: _vm.data.total_employees,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "total_employees", $$v)
                      },
                      expression: "data.total_employees"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                { staticClass: "col-sm-12", attrs: { label: "" } },
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n          List of Employees\n          "),
                    _c(
                      "a",
                      {
                        staticClass: "text-primary",
                        attrs: { href: "javascript:void()" },
                        on: { click: _vm.createNewEmployeeContact }
                      },
                      [_c("i", { staticClass: "ion ion-md-add-circle" })]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        id: "ajax",
                        label: "full_name",
                        "track-by": "id",
                        placeholder: "Type to search",
                        "open-direction": "bottom",
                        options: _vm.primary_contact_options,
                        multiple: true,
                        searchable: true,
                        loading: _vm.isPrimaryContactLoading,
                        "internal-search": false,
                        "clear-on-select": false,
                        "close-on-select": false,
                        "max-height": 600,
                        "show-no-results": false,
                        "hide-selected": true
                      },
                      on: { "search-change": _vm.asyncContactFind },
                      scopedSlots: _vm._u([
                        {
                          key: "tag",
                          fn: function(ref) {
                            var option = ref.option
                            var remove = ref.remove
                            return [
                              _c(
                                "span",
                                { staticClass: "badge badge-primary mr-2" },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(option.full_name))
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "white" },
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return remove(option)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-times ml-2"
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          }
                        },
                        {
                          key: "clear",
                          fn: function(props) {
                            return [
                              _vm.data.employees.length
                                ? _c("div", {
                                    staticClass: "multiselect__clear",
                                    on: {
                                      mousedown: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.clearAll(props.search)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.data.employees,
                        callback: function($$v) {
                          _vm.$set(_vm.data, "employees", $$v)
                        },
                        expression: "data.employees"
                      }
                    },
                    [
                      _vm._v(" "),
                      _vm._v(" "),
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [_vm._v("Contact not found.")]
                      )
                    ]
                  )
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-form-group",
                { staticClass: "col-lg-6", attrs: { label: "Website" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.data.website,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "website", $$v)
                      },
                      expression: "data.website"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("hr", { staticClass: "border-light mt-3" }),
          _vm._v(" "),
          _c("h4", [_vm._v("Social Media Links")]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "table table-fixed table-borderless" },
            [
              _c("tr", [
                _c("th", { staticStyle: { width: "46%" } }, [
                  _vm._v("Social Media Site")
                ]),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "46%" } }, [
                  _vm._v("Social Media URL")
                ]),
                _vm._v(" "),
                _c(
                  "th",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "b-btn",
                      {
                        staticClass: "btn-circle btn-primary",
                        on: { click: _vm.addSocialMediaRow }
                      },
                      [_c("i", { staticClass: "fas fa-plus" })]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm._l(_vm.$v.data.social_medias.$each.$iter, function(v, index) {
                return _c("tr", { key: index }, [
                  _c(
                    "td",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "",
                            "invalid-feedback": _vm.getErrorLabel(v.site)
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              state: v.site.$error ? "invalid" : null,
                              options: _vm.social_medias
                            },
                            model: {
                              value: v.site.$model,
                              callback: function($$v) {
                                _vm.$set(v.site, "$model", $$v)
                              },
                              expression: "v.site.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "",
                            "invalid-feedback": _vm.getErrorLabel(v.url)
                          }
                        },
                        [
                          _c("b-input", {
                            attrs: { state: v.url.$error ? "invalid" : null },
                            model: {
                              value: v.url.$model,
                              callback: function($$v) {
                                _vm.$set(v.url, "$model", $$v)
                              },
                              expression: "v.url.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "b-btn",
                        {
                          staticClass: "btn-circle btn-primary ml-2",
                          on: {
                            click: function($event) {
                              return _vm.deleteSocialMediaRow(index)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-times" })]
                      )
                    ],
                    1
                  )
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("hr", { staticClass: "border-light mt-3" }),
          _vm._v(" "),
          _c("custom-field-form-template", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isCustomFieldsAvailable,
                expression: "isCustomFieldsAvailable"
              }
            ],
            attrs: { data: _vm.data, object: "Company" },
            on: {
              "update:data": function($event) {
                _vm.data = $event
              },
              "custom-fields-loaded": _vm.handleCustomFieldsLoaded
            }
          }),
          _vm._v(" "),
          _c(
            "template",
            { slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.loading, variant: "default" },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.loading, variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("contact-form", {
        ref: "primaryContactFormModal",
        attrs: { "create-source": "company-form" },
        on: { "on-save": _vm.newPrimaryContactAdded }
      }),
      _vm._v(" "),
      _c("contact-form", {
        ref: "employeeContactFormModal",
        on: { "on-save": _vm.newEmployeeContactAdded }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }