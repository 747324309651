<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      id="create-new-booking"
      size="lg"
      hide-header-close
      no-enforce-focus
      v-model="showModal"
      @hide="onClose"
      @show="onShow"
      @close="onClose"
      footer-class="p-0 ml-0 d-block"
      ref="modal"
    >
      <div slot="modal-header">
        <span class="modal-title" style="font-size: 1rem; font-weight: 500">
          <span v-if="bookingId && bookingOrderId == null"> Edit Booking </span>
          <span v-else-if="bookingId && bookingOrderId">
            Reschedule Booking Order
          </span>

          <span v-else> Create Booking </span>
        </span>

        <b-form inline style="position: absolute; right: 4rem; top: 38%">
          <span class="mr-3"> Pending </span>
          <label class="switcher switcher-success">
            <input
              :disabled="bookingOrderApproved == 0 && bookingOrderData"
              type="checkbox"
              class="switcher-input"
              v-model="booking.is_pending"
            />
            <span class="switcher-indicator">
              <span class="switcher-yes text-success"></span>
              <span class="switcher-no"></span>
            </span>
            <span class="switcher-label" v-if="!booking.is_pending">No</span>
            <span class="switcher-label" v-if="booking.is_pending">Yes</span>
          </label>
        </b-form>

        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="onClose()"
        >
          ×
        </button>
      </div>

      <b-form-row v-if="bookingOrderData">
        <b-form-group class="col-sm-12 text-right">
          <span class="mr-2"> Approval Status: </span>
          <label class="switcher switcher-success">
            <input
              :disabled="bookingOrderApproved == 1"
              type="checkbox"
              class="switcher-input"
              v-model="bookingOrderApproved"
            />
            <span class="switcher-indicator">
              <span class="switcher-yes text-success"></span>
              <span class="switcher-no text-warning"> </span>
            </span>
            <span class="switcher-label" v-if="!bookingOrderApproved"
              >Waiting</span
            >
            <span class="switcher-label" v-if="bookingOrderApproved"
              >Approved</span
            >
          </label>
        </b-form-group>
      </b-form-row>

      <div class="row" v-if="booking.invoice && ability.can('read', 'Invoice')">
        <div class="col text-right">
          <a target="_blank" class="text-primary" @click="viewInvoice(booking.invoice.id)"><i class="fa fa-info-circle"></i> Invoice : #{{ booking.invoice.invoice_number }}</a>
        </div>
      </div>

      <b-form-row>
        <b-form-group label="Title" class="col-12">
          <b-input
            ref="name"
            v-model="booking.title"
            :class="{
              'is-invalid': bookingSubmitted && $v.booking.title.$error,
            }"
          ></b-input>
          <div v-if="!$v.booking.title.required" class="invalid-feedback">
            Title field is required.
          </div>
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Association ?" class="col-sm-12">
          <label class="switcher switcher-success">
            <input
              :disabled="
                this.bookingOrderData &&
                this.bookingOrderData.status == 'waiting'
              "
              type="checkbox"
              class="switcher-input"
              v-model="isAssociate"
            />
            <span class="switcher-indicator">
              <span class="switcher-yes text-success"></span>
              <span class="switcher-no"></span>
            </span>
            <span class="switcher-label" v-if="!isAssociate">No</span>
            <span class="switcher-label" v-if="isAssociate">Yes</span>
          </label>
        </b-form-group>
      </b-form-row>

      <b-form-row v-if="isAssociate">
        <b-col>
          <b-form-row>
            <b-form-group label="Associate With Type" class="col-lg-12">
              <b-form-select
                :disabled="
                  this.bookingOrderData &&
                  this.bookingOrderData.status == 'waiting'
                "
                v-model="booking.associate_type"
                :options="associate_type_options"
              ></b-form-select>
            </b-form-group>
          </b-form-row>
        </b-col>
        <b-col>
          <b-form-row>
            <b-popover
              v-if="booking.associate_type && booking.associate_id"
              target="associate-with"
              :show.sync="showAssociateWithPopover"
              placement="top"
            >
              <template slot="title">
                Contact Information
                <span class="float-right">
                  <i
                    class="fa fa-times"
                    @click="showAssociateWithPopover = false"
                  ></i>
                </span>
              </template>

              <div
                v-if="
                  booking.associate_id && booking.associate_id.primary_contact
                "
              >
                <div>
                  Name:
                  <router-link
                    :to="{
                      name: 'contacts-view',
                      params: { id: booking.associate_id.primary_contact.id },
                    }"
                  >
                    {{ booking.associate_id.primary_contact.first_name }}
                    {{ booking.associate_id.primary_contact.last_name }}
                  </router-link>
                </div>

                <div>
                  Email: {{ booking.associate_id.primary_contact.email }}
                </div>

                <div>
                  Phone Number:
                  {{
                    booking.associate_id.primary_contact.phone_number
                      ? booking.associate_id.primary_contact.phone_number
                      : "-"
                  }}
                </div>

                <div
                  v-if="
                    booking.associate_id.primary_contact.band &&
                    booking.associate_id.primary_contact.band.length == 1
                  "
                >
                  Artist/Group:
                  {{ booking.associate_id.primary_contact.band[0].name }}
                </div>
                <div
                  v-if="
                    booking.associate_id.primary_contact.band &&
                    booking.associate_id.primary_contact.band.length > 1
                  "
                >
                  Artist/Group:
                  {{ mapBandNames(booking.associate_id.primary_contact.band) }}
                </div>
                <div
                  v-if="
                    booking.associate_id.primary_contact.band &&
                    booking.associate_id.primary_contact.band.length == 0
                  "
                >
                  Artist/Group: -
                </div>

                <div
                  v-if="
                    booking.associate_id.primary_contact.band &&
                    booking.associate_id.primary_contact.band.length == 1
                  "
                >
                  Genre:
                  {{ booking.associate_id.primary_contact.band[0].genre_name }}
                </div>
                <div
                  v-if="
                    booking.associate_id.primary_contact.band &&
                    booking.associate_id.primary_contact.band.length > 1
                  "
                >
                  Genre:
                  {{ mapGenreNames(booking.associate_id.primary_contact.band) }}
                </div>
                <div
                  v-if="
                    booking.associate_id.primary_contact.band &&
                    booking.associate_id.primary_contact.band.length == 0
                  "
                >
                  Genre: -
                </div>
              </div>

              <div v-else>
                <div>No contact record found for selected association.</div>
              </div>
            </b-popover>

            <b-form-group
              label="Associate With"
              class="col-lg-12"
              id="associate-with"
              @mouseover.native="showAssociateWithPopover = true"
            >
              <template slot="label">
                Associate With
                <a
                  v-if="
                    this.bookingOrderData == null ||
                    (this.bookingOrderData &&
                      this.bookingOrderData.status != 'waiting')
                  "
                  href="javascript:void(0)"
                  class="text-primary ml-3"
                  @click="createForm(booking.associate_type)"
                >
                  <i class="ion ion-md-add-circle"></i>
                </a>
              </template>

              <multiselect
                :disabled="
                  (this.bookingOrderData &&
                    this.bookingOrderData.status == 'waiting') ||
                  !associateOptionsFullyLoaded
                "
                v-model="booking.associate_id"
                :options="associate_id_options"
                :multiple="false"
                :class="{ 'is-invalid': $v.booking.associate_id.$error }"
                placeholder="Type to search"
                track-by="id"
                label="text"
              />
              <div
                v-if="bookingSubmitted && !$v.booking.associate_id.required"
                class="invalid-feedback"
                style="display: inline-block"
              >
                The associate with field is required.
              </div>
            </b-form-group>
          </b-form-row>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-form-group label="All Day Booking" class="col-sm-12">
          <label class="switcher switcher-success">
            <input
              type="checkbox"
              class="switcher-input"
              v-model="booking.all_day"
            />
            <span class="switcher-indicator">
              <span class="switcher-yes text-success"></span>
              <span class="switcher-no"></span>
            </span>
            <span class="switcher-label" v-if="!booking.all_day">No</span>
            <span class="switcher-label" v-if="booking.all_day">Yes</span>
          </label>
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-col>
          <b-form-group label="Booking Starts At">
            <div class="input-group">
              <flat-pickr
                @change="$v.booking.start_at.$touch()"
                v-model="booking.start_at"
                :config="datePickerConfig"
                :class="{
                  'is-invalid': bookingSubmitted && $v.booking.start_at.$error,
                }"
                placeholder="Select Booking Start At"
                name="start_date"
              ></flat-pickr>
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  title="Toggle"
                  data-toggle
                >
                  <i class="fa fa-calendar">
                    <span aria-hidden="true" class="sr-only">Toggle</span>
                  </i>
                </button>
              </div>
              <div
                v-if="!$v.booking.start_at.required"
                style="display: inline-block"
                class="invalid-feedback"
              >
                Booking Starts At field is required.
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Booking Ends At">
            <div class="input-group">
              <flat-pickr
                v-model="booking.end_at"
                :config="datePickerConfig"
                :class="{
                  'is-invalid': bookingSubmitted && $v.booking.end_at.$error,
                }"
                placeholder="Select Booking Start At"
                name="start_date"
              ></flat-pickr>
              <div class="input-group-btn">
                <button
                  class="btn btn-default"
                  type="button"
                  title="Toggle"
                  data-toggle
                >
                  <i class="fa fa-calendar">
                    <span aria-hidden="true" class="sr-only">Toggle</span>
                  </i>
                </button>
              </div>
              <div
                v-if="!$v.booking.end_at.required"
                style="display: inline-block"
                class="invalid-feedback"
              >
                Booking Ends At field is required.
              </div>

              <div
                v-if="!$v.booking.end_at.minValue"
                style="display: inline-block"
                class="invalid-feedback"
              >
                Booking Ends At field must be after Starts At field.
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Recurring Booking? " class="col-sm-12">
          <label class="switcher switcher-success">
            <input
              type="checkbox"
              class="switcher-input"
              v-model="booking.is_recurring"
            />
            <span class="switcher-indicator">
              <span class="switcher-yes text-success"></span>
              <span class="switcher-no"></span>
            </span>
            <span class="switcher-label" v-if="!booking.is_recurring">No</span>
            <span class="switcher-label" v-if="booking.is_recurring">Yes</span>
          </label>
        </b-form-group>
      </b-form-row>

      <b-form-row v-if="booking.is_recurring">
        <b-col>
          <b-form-row>
            <b-form-group :label="repeatEveryLabel" class="col-lg-12">
              <b-input
                ref="recurring_repeat_type_value"
                v-model="booking.recurring_options.repeat_type_value"
              ></b-input>
            </b-form-group>
          </b-form-row>
        </b-col>

        <b-col>
          <b-form-row>
            <b-form-group label="Repeat Interval" class="col-lg-12">
              <b-form-select
                name="recurring_repeat_type"
                v-model="booking.recurring_options.repeat_type"
                :options="recurringRepeatTypeOptions"
              ></b-form-select>
            </b-form-group>
          </b-form-row>
        </b-col>
      </b-form-row>

      <b-form-row v-if="booking.is_recurring">
        <b-col>
          <b-form-row>
            <b-form-group label="End Repeat By" class="col-lg-12">
              <b-form-select
                name="recurring_repeat_type"
                v-model="booking.recurring_options.repeat_end"
                :options="recurringEndOptions"
              ></b-form-select>
            </b-form-group>
          </b-form-row>
        </b-col>

        <b-col>
          <b-form-row>
            <b-form-group :label="repeatEndLabel" class="col-lg-12">
              <div v-if="booking.recurring_options.repeat_end == 'occurence'">
                <b-input
                  ref="recurring_repeat_end_value"
                  v-model="booking.recurring_options.repeat_end_value"
                ></b-input>
              </div>
              <div v-if="booking.recurring_options.repeat_end == 'date'">
                <div class="input-group">
                  <flat-pickr
                    v-model="booking.recurring_options.repeat_end_value"
                    :config="dateOnlyPickerConfig"
                    name="repeat_end_value"
                  ></flat-pickr>
                  <div class="input-group-btn">
                    <button
                      class="btn btn-default"
                      type="button"
                      title="Toggle"
                      data-toggle
                    >
                      <i class="fa fa-calendar">
                        <span aria-hidden="true" class="sr-only">Toggle</span>
                      </i>
                    </button>
                  </div>
                </div>
              </div>
            </b-form-group>
          </b-form-row>
        </b-col>
      </b-form-row>

      <div
        v-if="booking.is_recurring && booking.recurring_schedules.length > 0"
        class="alert alert-info"
      >
        <div>
          <i class="fa fa-info-circle"></i>
          <strong>
            The following schedules will be
            <span v-if="bookingId">adjusted</span><span v-else>created</span>:
          </strong>
        </div>

        <span
          :key="index"
          v-for="(schedule, index) in booking.recurring_schedules"
          class="mr-1"
        >
          {{ schedule.start_at.format("L LT") }}-{{
            schedule.end_at.format("LT")
          }}<span v-if="index != booking.recurring_schedules.length - 1"
            >,</span
          >
        </span>
      </div>

      <b-form-row>
        <b-form-group label="Guest(s)" class="col-12">
          <multiselect
            :loading="isContactLoading"
            v-model="booking.guests"
            :custom-label="emailWithNameLabel"
            :options="contactOptions"
            :multiple="true"
            placeholder="Type to search"
            track-by="id"
            label="name"
            :max-height="600"
            :show-no-results="true"
            :hide-selected="true"
            :taggable="true"
            :searchable="true"
            @tag="addNewGuestsEmail"
            :internal-search="false"
            @search-change="asyncContactFind"
            :show-no-options="false"
            tag-placeholder="Add this as new email"
          />
        </b-form-group>
      </b-form-row>

      <div>
        <strong> Assigned Users </strong>

        <div
          class="bg-lighter border-dashed mt-2"
          :key="index"
          v-for="(v, index) in $v.booking.booking_users.$each.$iter"
        >
          <i
            @click="deleteBookingUserRow(index)"
            v-b-tooltip.hover
            title="Delete Assigned User"
            class="fa fa-times text-danger mt-2 ml-2"
          >
          </i>

          <b-form-row class="p-2 pl-3 pb-3 pb-3">
            <b-col>
              <b-form-row>
                <b-form-group class="col-lg-12">
                  <template slot="label"> Assigned User </template>
                  <b-form-select
                    v-model="v.user_id.$model"
                    :options="assignedUserOptions"
                    :state="v.user_id.$error ? 'invalid' : null"
                  ></b-form-select>
                  <div
                    v-if="bookingSubmitted && !v.user_id.required"
                    class="invalid-feedback"
                  >
                    The assigned user field is required.
                  </div>
                </b-form-group>
              </b-form-row>
            </b-col>
            <b-col>
              <b-form-row>
                <b-form-group label="Booking Role" class="col-lg-12">
                  <b-form-select
                    v-model="v.role.$model"
                    :options="bookingRoleOptions"
                    :state="v.role.$error ? 'invalid' : null"
                  ></b-form-select>
                  <div
                    v-if="bookingSubmitted && !v.role.required"
                    class="invalid-feedback"
                  >
                    The booking role field is required.
                  </div>
                </b-form-group>
              </b-form-row>
            </b-col>
          </b-form-row>
        </div>

        <div class="mt-2">
          <a
            class="btn btn-sm btn-primary text-white"
            @click="addBookingUserRow"
          >
            <i class="ion ion-md-add-circle"> </i> Assigned User
          </a>
        </div>

        <hr />
      </div>

      <b-form-row>
        <b-col>
          <b-form-row>
            <b-form-group label="Assigned User Reminder" class="col-12">
              <b-form-select
                v-model="booking.reminder"
                :options="booking_reminder_options"
              ></b-form-select>
            </b-form-group>
          </b-form-row>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Room" class="col-12">
          <b-form-select
            @change="$v.booking.room_id.$touch()"
            v-model="booking.room_id"
            :options="roomOptions"
            :class="{
              'is-invalid': bookingSubmitted && $v.booking.room_id.$error,
            }"
          ></b-form-select>
          <div v-if="!$v.booking.room_id.required" class="invalid-feedback">
            Room field is required.
          </div>
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Event Type" class="col-12">
          <b-form-select
            v-model="booking.event_type_id"
            :options="event_type_options"
            :class="{
              'is-invalid': bookingSubmitted && $v.booking.event_type_id.$error,
            }"
          ></b-form-select>
          <div
            v-if="!$v.booking.event_type_id.required"
            class="invalid-feedback"
          >
            Event Type field is required.
          </div>
        </b-form-group>
      </b-form-row>

      <b-form-row v-if="booking.associate_type == 'Project'">
        <b-form-group class="col-lg-12">
          <template slot="label">
            Project Songs

            <a
              class="ml-1 btn btn-sm btn-primary text-white"
              @click="addNewSong"
            >
              <i class="ion ion-md-add-circle"> </i> New Project Song
            </a>
          </template>
          <multiselect
            v-model="booking.booking_songs"
            :options="songOptions"
            :multiple="true"
            placeholder="Type to search"
            track-by="id"
            label="text"
          />
        </b-form-group>
      </b-form-row>

      <b-form-row v-if="departmentEnabled">
        <b-form-group label="Content Team" class="col-12">
          <b-form-select
            v-model="booking.department_id"
            :options="departmentOptions"
            :class="{
              'is-invalid': bookingSubmitted && $v.booking.department_id.$error,
            }"
          ></b-form-select>
          <div
            v-if="!$v.booking.department_id.required"
            class="invalid-feedback"
          >
            Content Team field is required.
          </div>
        </b-form-group>
      </b-form-row>

      <div
        class="bg-lighter border-dashed mt-2 p-3"
        v-if="hasAddon('equipment-tracking')"
      >
        <b-form-row v-if="bookingId">
          <b>Equipment</b>
          <b-btn
            variant="primary"
            size="sm"
            class="ml-2"
            @click="showEquipmentForm"
            >Add/Remove Equipment</b-btn
          >
        </b-form-row>

        <table class="table mt-2" v-if="bookingId">
          <tr>
            <th>NAME</th>
            <th>SERIAL #</th>
            <th></th>
          </tr>
          <tr
            v-for="trackingEquipment in equipmentList"
            :key="trackingEquipment.id"
          >
            <td>{{ trackingEquipment.equipment.name }}</td>
            <td>{{ trackingEquipment.equipment.serial_number }}</td>
            <td v-if="trackingEquipment.action == 'Check In'">
              <b-badge variant="success">Checked In</b-badge>
            </td>
          </tr>
        </table>
      </div>

      <b-form-row class="mt-3">
        <b-form-group label="Description" class="col-12">
          <b-textarea v-model="booking.description" rows="5" />
        </b-form-group>
      </b-form-row>

      <b-form-row
        v-if="
          booking.zoom_data &&
          booking.zoom_data.start_url &&
          booking.zoom_data.join_url
        "
      >
        <b-form-group label="Zoom Links" class="col-12">
          <div>
            <a target="_blank" :href="booking.zoom_data.start_url">
              Start Zoom Meeting
            </a>
            or
            <a
              href="#"
              @click="
                copyToClipboard(booking.zoom_data.start_url, 'zoom-start-url')
              "
            >
              Copy Zoom Start URL <i class="fa fa-copy"></i>
            </a>
          </div>
          <div>
            <a target="_blank" :href="booking.zoom_data.join_url">
              Join Zoom Meeting
            </a>
            or
            <a
              href="#"
              @click="
                copyToClipboard(booking.zoom_data.join_url, 'zoom-join-url')
              "
            >
              Copy Zoom Join URL <i class="fa fa-copy"></i>
            </a>
          </div>
        </b-form-group>
      </b-form-row>

      
        


      <template slot="modal-footer">
        <div v-show="isCustomFieldsAvailable" class="ml-3 mr-3 row" v-b-toggle.collapse-2>
          <div class="pt-2 pb-2 col">
            <label class="col-form-label"> Custom Fields </label>
          </div>
          <div class="text-right col">
            <i v-if="customFieldsVisible" class="fa fa-chevron-up pt-3"></i>
            <i v-else class="fa fa-chevron-down pt-3"></i>
          </div>
        </div>

        <b-collapse
          v-show="isCustomFieldsAvailable"
          id="collapse-2"
          class="mt-2 ml-3 mr-3 row"
          v-model="customFieldsVisible"
        >
          <div class="col">
            <custom-field-form-template :data.sync="booking" object="Booking" :showHeaderTitle="false" @custom-fields-loaded="handleCustomFieldsLoaded"/>
          </div>
        </b-collapse>

        <hr v-show="isCustomFieldsAvailable" class="border-light m-0 mt-3">

        <div class="ml-3 mr-3 row" v-b-toggle.collapse-1>
          <div class="pt-2 pb-2 col">
            <label class="col-form-label"> To Dos </label>
          </div>
          <div class="text-right col">
            <i v-if="notesVisible" class="fa fa-chevron-up pt-3"></i>
            <i v-else class="fa fa-chevron-down pt-3"></i>
          </div>
        </div>

        <b-collapse
          id="collapse-1"
          class="mt-2 ml-3 mr-3"
          v-model="notesVisible"
        >
          <div class="text-right col mb-2">
            <button
              type="button"
              class="btn btn-primary text-right"
              @click="showCreateBookingTodo"
            >
              <i class="ion ion-md-add-circle"></i> Create New To Do
            </button>
          </div>
          <div class="col">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <td v-if="isMobile == false">Due Date</td>
                  <td>Title</td>
                  <td v-if="isMobile == false">Assigned User</td>
                  <td v-if="isMobile == false">Status</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <template v-if="booking.todos.length >= 1">
                  <tr :key="index" v-for="(todo, index) in booking.todos">
                    <td v-if="isMobile == false">
                      {{ todo.due_date_tz | moment(datetime_format) }}
                    </td>
                    <td>
                      {{ todo.title }}
                      <div v-if="isMobile">
                        Due Date:
                        <span class="ml-1"> {{ todo.due_date_tz | moment(datetime_format) }}  </span>
                      </div>
                      <div v-if="isMobile">
                        Assigned User:
                        <span
                          class="badge badge-info"
                          :key="user.id"
                          v-for="user in todo.assigned_user"
                        >
                          {{ user.full_name }} ({{ user.email }})
                        </span>
                      </div>

                      <div v-if="isMobile">
                        Status:
                        <span
                          v-if="todo.completed"
                          class="badge badge-success ml-1"
                        >
                          Completed
                        </span>
                        <span v-else class="badge badge-warning ml-1">
                          Incomplete
                        </span>
                      </div>
                    </td>
                    <td v-if="isMobile == false">
                      <span
                        class="badge badge-info"
                        :key="user.id"
                        v-for="user in todo.assigned_user"
                      >
                        {{ user.full_name }} ({{ user.email }})
                      </span>
                    </td>
                    <td v-if="isMobile == false">
                      <span v-if="todo.completed" class="badge badge-success">
                        Completed
                      </span>
                      <span v-else class="badge badge-warning">
                        Incomplete
                      </span>
                    </td>
                    <td>
                      <b-btn
                        variant="outline-dark btn-xs icon-btn md-btn-flat"
                        v-b-tooltip.hover
                        title="Edit"
                        @click="editBookingTodo(todo)"
                      >
                        <i class="fas fa-pencil-alt"></i>
                      </b-btn>
                      <b-btn
                        variant="danger btn-xs icon-btn md-btn-flat"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="deleteBookingTodo(todo)"
                      >
                        <i class="fas fa-ban"></i>
                      </b-btn>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="5" class="text-center">No data available.</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </b-collapse>

        <hr class="ml-0 mr-0 mt-0" />

        <div class="pl-4 pr-4">
          <div class="text-right">
            <b-form-group label="Booking Notification & Reminder">
              <label class="switcher switcher-success">
                <input
                  type="checkbox"
                  class="switcher-input"
                  v-model="booking.client_reminder"
                />
                <span class="switcher-indicator">
                  <span class="switcher-yes text-success"></span>
                  <span class="switcher-no"></span>
                </span>
                <span class="switcher-label" v-if="!booking.client_reminder"
                  >No</span
                >
                <span class="switcher-label" v-if="booking.client_reminder"
                  >Yes</span
                >
              </label>
            </b-form-group>

            <div class="form-group">

              <!-- <b-button
                :disabled="isLoading"
                v-if="robinBookingData != null"
                variant="primary"
                @click="disableSync(booking)"
                >Delete</b-button
              > -->

              <b-button
                class="float-left"
                :disabled="isLoading"
                v-if="booking.booking_id"
                variant="primary"
                @click="onDelete(booking)"
                >Delete</b-button
              >

              <b-button
                :disabled="isLoading"
                variant="default"
                @click="onClose()"
                >Cancel</b-button
              >

              <b-button
                v-if="booking.booking_id"
                :disabled="isLoading"
                variant="default"
                @click="cloneBooking"
              >
                Clone</b-button
              >

               <b-button
                v-if="!booking.invoice"
                :disabled="$v.booking.$error || isLoading"
                variant="primary"
                @click="saveBooking({ createInvoice : true })"
                >Save & Create Invoice </b-button
              >

              <b-button
                :disabled="$v.booking.$error || isLoading"
                variant="primary"
                @click="saveBooking"
                >Save</b-button
              >
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <contact-form ref="contactFormModal" @on-save="newContactAdded" />
    <company-form ref="companyFormModal" @on-save="newCompanyAdded" />
    <band-form ref="bandFormModal" @on-save="newBandAdded" />
    <lead-form ref="leadFormModal" @on-save="newLeadAdded" />
    <project-form
      ref="projectFormModal"
      @on-save="newProjectAdded"
      :redirect-to-detail="false"
    />
    <project-song-form
      ref="projectSongForm"
      @on-save="newProjectSongAdded"
      :projectId="booking.associate_id ? booking.associate_id.id : null"
    />
    <equipment-booking-management-form
      ref="equipmentBookingManagementFormModal"
      @on-save="loadEquipmentList"
      :edit-id="bookingId"
    />

    <todo-create-form
      ref="todoCreateForm"
      @on-save="callbackAddBookingTodo"
      :booking_id="bookingId"
      :associate_type="booking.associate_type ? booking.associate_type : null"
      :associate_id="booking.associate_id ? booking.associate_id.id : null"
      :scenario="'booking-todo'"
    />
    <todo-edit-form
      ref="todoEditForm"
      @on-save="callbackEditBookingTodo"
      :default_task="selectedTodo"
      :scenario="'booking-todo'"
    />
  </div>
</template>

<script>
import Vue from "vue";
import Vuelidate from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import moment from "moment";
import ContactForm from "../contacts/ContactForm";
import CompanyForm from "../companies/CompanyForm";
import BandForm from "../bands/BandForm";
import LeadForm from "../leads/LeadForm";
import ProjectForm from "../projects/ProjectForm";
import ProjectSongForm from "../projectSongs/ProjectSongForm";
import EquipmentBookingManagementForm from "../equipments/EquipmentBookingManagementForm.vue";
import _ from "lodash";
import CustomFieldFormTemplate from "../customField/CustomFieldFormTemplate.vue";

import TodoCreateForm from "../tasks/TaskCreateModal.vue";
import TodoEditForm from "../tasks/TaskEditModal.vue";

import Swal from "sweetalert2/dist/sweetalert2.js";

import { isMobile } from "mobile-device-detect";

Vue.use(Vuelidate);

import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from "vue-multiselect";

const initialBooking = {
  title: "",
  start_at: moment()
    .subtract(moment().minute() % 30, "minutes")
    .toDate(),
  end_at: moment()
    .subtract(moment().minute() % 30, "minutes")
    .add(1, "hours")
    .toDate(),
  all_day: false,
  user_id: "",
  room_id: "",
  event_type_id: "",
  reminder: "No Reminder",
  description: "",
  associate_type: "",
  associate_id: "",
  booking_songs: [],
  is_pending: false,
  booking_users: [
    {
      user_id: "",
      role: "",
    },
  ],
  guests: [],
  client_reminder: false,
  department_id: "",
  robin_booking_id: "",
  //recurring related
  is_recurring: false,
  recurring_options: {
    repeat_type: "days",
    repeat_type_value: 1,
    repeat_end: null,
    repeat_end_value: null,
  },
  recurring_schedules: [],
  todos: [],
  custom_fields: {}
};

export default {
  props: [
    "robinBookingData",
    "bookingData",
    "bookingId",
    "bookingOrderId",
    "bookingOrderData",
    "notificationId",
  ],
  name: "BookingForm",
  components: {
    CustomFieldFormTemplate,
    Multiselect,
    ContactForm,
    CompanyForm,
    BandForm,
    LeadForm,
    ProjectForm,
    ProjectSongForm,
    EquipmentBookingManagementForm,
    TodoCreateForm,
    TodoEditForm,
  },
  created() {},
  watch: {
    isAssociate(newVal) {
      if (newVal == false) {
        this.booking.associate_type = "";
        this.booking.associate_id = "";
      }
    },
    bookingOrderApproved(newVal, oldVal) {
      if (
        newVal == 1 &&
        oldVal == 0 &&
        this.bookingOrderData &&
        this.bookingOrderData.status == "waiting"
      ) {
        this.directApproveBookingOrder();
      }
    },
    //recurring bookings related
    "booking.is_recurring"(newVal) {
      if (newVal) {
        this.generateRecurringSchedules();
      }
    },

    "booking.recurring_options.repeat_end_value"(newVal) {
      if (newVal) {
        this.generateRecurringSchedules();
      }
    },

    "booking.recurring_options.repeat_end": {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          if (newVal == "occurence" && oldVal != null) {
            this.booking.recurring_options.repeat_end_value = 1;
          } else if (newVal == "date" && oldVal != null) {
            this.booking.recurring_options.repeat_end_value = this.$moment(
              this.booking.start_at
            )
              .endOf("data")
              .add(1, "days")
              .toDate();
          }
        }
      },
    },

    "booking.recurring_options.repeat_type"(newVal) {
      if (newVal) {
        this.generateRecurringSchedules();
      }
    },

    "booking.recurring_options.repeat_type_value"(newVal) {
      if (newVal) {
        this.generateRecurringSchedules();
      }
    },
    //end of recurring bookings related

    "booking.reminder"(newVal) {
      if (newVal != "No Reminder" && this.booking.booking_users.length == 0) {
        this.addBookingUserRow();
      }
    },
    "booking.all_day"(newVal) {
      if (newVal) {
        if (this.start_at) {
          let start_at = this.$moment(this.start_at)
            .startOf("day")
            .format("YYYY-MM-DD HH:mm");
          this.booking.start_at = start_at;
        } else if (this.bookingData.start_at) {
          let start_at = this.$moment(this.bookingData.start_at)
            .startOf("day")
            .format("YYYY-MM-DD HH:mm");
          this.booking.start_at = start_at;
        } else {
          let start_at = this.$moment()
            .startOf("day")
            .format("YYYY-MM-DD HH:mm");
          this.booking.start_at = start_at;
        }

        if (this.end_at) {
          let end_at = this.$moment(this.end_at)
            .endOf("day")
            .format("YYYY-MM-DD HH:mm");
          this.booking.end_at = end_at;
        } else if (this.bookingData.end_at) {
          let end_at = this.$moment(this.bookingData.end_at)
            .endOf("day")
            .format("YYYY-MM-DD HH:mm");
          this.booking.end_at = end_at;
        } else {
          let end_at = this.$moment().endOf("day").format("YYYY-MM-DD HH:mm");
          this.booking.end_at = end_at;
        }
      }
    },
    "booking.associate_type": {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          console.log("associate type changed");
          console.log(oldVal);
          console.log(newVal);
          console.log(this.booking.associate);
          this.reloadAssociateIdOption(newVal, oldVal, this.booking.associate);
        }
      },
    },
    "booking.associate_id": {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.primary_contact && this.bookingId == null) {
          const prefilledContact = {
            ...newVal.primary_contact,
            ...{ name: newVal.primary_contact.full_name },
          };
          this.booking.guests = [prefilledContact];
        }

        if (newVal && newVal.id && this.booking.associate_type == "Project") {
          this.loadProjectSongs(newVal.id);
        }
      },
    },
    //make the booking end_at date are same as booking start_at date
    "booking.start_at": {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && oldVal) {
          let startDt = moment(newVal).get("date");
          let endDt = moment(this.booking.end_at).get("date");
          if (startDt != endDt && !this.booking.booking_id) {
            this.booking.end_at = this.$moment(this.booking.end_at)
              .set("date", startDt)
              .format("YYYY-MM-DD HH:mm");
          }

          if (this.booking.is_recurring) {
            if (this.mainBooking.start_at != null) {
              this.mainBooking.start_at_changed += 1;

              //only trigger main booking change when the datetime field changed in booking form
              if (this.mainBooking.start_at_changed > 1) {
                const diffInMinutes = this.$moment(newVal).diff(
                  this.$moment(oldVal),
                  "minutes"
                );
                this.mainBooking.start_at.add(diffInMinutes, "minutes");
              }
            }

            this.generateRecurringSchedules();
          } else {
            this.mainBooking.start_at = null;
            this.mainBooking.end_at = null;
          }
        }
      },
    },
    "booking.end_at": {
      immediate: false,
      handler(newVal, oldVal) {
        if (newVal && oldVal) {
          if (this.booking.is_recurring) {
            if (this.mainBooking.end_at != null) {
              this.mainBooking.end_at_changed += 1;

              //only trigger main booking change when the datetime field changed in booking form
              if (this.mainBooking.end_at_changed > 1) {
                const diffInMinutes = this.$moment(newVal).diff(
                  this.$moment(oldVal),
                  "minutes"
                );
                this.mainBooking.end_at.add(diffInMinutes, "minutes");
              }
            }

            this.generateRecurringSchedules();
          } else {
            this.mainBooking.start_at = null;
            this.mainBooking.end_at = null;
          }
        }
      },
    },
  },

  methods: {
    asyncContactFind: _.debounce(async function (query) {
      this.isContactLoading = true;
      try {
        const response = await window.axios.post(
          "/api/contacts/search-select",
          {
            search_string: query,
          }
        );

        this.contactOptions = response.data;
        this.isContactLoading = false;
      } catch (error) {
        this.isContactLoading = false;
        console.log(error);
      }
    }, 500),
    mapBandNames(bands) {
      const bandNames = bands
        .map((band) => {
          return band.name;
        })
        .join(", ");
      return bandNames;
    },
    mapGenreNames(bands) {
      const genreNames = bands
        .map((band) => {
          return band.genre_name;
        })
        .join(", ");
      return genreNames;
    },
    async loadBookingRoleOptions() {
      const bookingRoles = await window.axios.get(
        "/api/picklists/Booking Roles"
      );

      if (
        bookingRoles.data.data.options &&
        bookingRoles.data.data.options.length > 0
      ) {
        this.bookingRoleOptions = bookingRoles.data.data.options.map(
          (option) => {
            return {
              value: option.id,
              text: option.name,
            };
          }
        );
        this.bookingRoleOptions.unshift({
          value: "",
          text: "Choose Role",
        });
      }
    },

    editBookingTodo(todo) {
      this.selectedTodo = todo;
      this.$refs.todoEditForm.show();
    },

    async deleteBookingTodo(todo) {
      const result = await this.$swal({
        title: `Are you sure you want to delete this To Do`,
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Delete",
      });
      if (result.value && todo.id) {
        try {
          await window.axios.delete(`/api/tasks/${todo.id}`);

          this.booking.todos = this.booking.todos.filter((item) => {
            return (
              item.title != todo.title ||
              item.due_date != todo.due_date ||
              item.reminder != todo.reminder ||
              item.details != todo.details ||
              item.assigned_user != todo.assigned_user
            );
          });
        } catch (error) {
          console.log(error);
        }
      } else if (result.value && todo.id == undefined) {
        this.booking.todos = this.booking.todos.filter((item) => {
          return (
            item.title != todo.title ||
            item.due_date != todo.due_date ||
            item.reminder != todo.reminder ||
            item.details != todo.details ||
            item.assigned_user != todo.assigned_user
          );
        });
      }
    },

    showCreateBookingTodo() {
      this.$refs.todoCreateForm.show();
    },

    callbackEditBookingTodo(todo) {
      this.booking.todos = this.booking.todos.map((exTodo) => {
        if (
          exTodo.title == this.selectedTodo.title &&
          exTodo.due_date == this.selectedTodo.due_date &&
          exTodo.reminder == this.selectedTodo.reminder &&
          exTodo.details == this.selectedTodo.details
        ) {
          todo.due_date_tz = this.$moment
            .utc(todo.due_date)
            .tz(this.timezone)
            .format(this.datetime_format);
          exTodo = todo;
        }

        return exTodo;
      });
    },

    callbackAddBookingTodo(todo) {
      todo.due_date_tz = this.$moment
        .utc(todo.due_date)
        .tz(this.timezone)
        .format(this.datetime_format);
      this.booking.todos.push(todo);
    },

    loadEquipmentList() {
      window.axios
        .get(`/api/equipments/booking-equipments/${this.bookingId}`)
        .then((response) => {
          this.equipmentList = response.data;
        });
    },
    cloneBooking() {
      this.booking.id = null;
      this.booking.booking_id = null;
      this.booking.booking_order_id = null;

      this.$toasted.show("Booking data cloned!", {
        position: "top-center",
        duration: 3000,
        type: "success",
        theme: "bubble",
      });
    },

    emailWithNameLabel(option) {
      if (option.first_name && option.last_name) {
        return `${option.first_name} ${option.last_name} (${option.email})`;
      } else {
        return `${option.email}`;
      }
    },

    async directApproveBookingOrder() {
      const result = await this.$swal({
        title: `Booking Order Approval Confirmation`,
        text: "Would you like to send a rejection notice to all other bookings during this time block and remove them from your calendar view?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#46B1C9",
        confirmButtonText: "Yes",
        showDenyButton: true,
        denyButtonText: "No",
        denyButtonColor: "#d33",
      });
      if (result.isConfirmed || result.isDenied) {
        var acceptUrl = `/api/booking-service-orders/${this.bookingOrderData.id}/accept`;
        if (result.isConfirmed) {
          acceptUrl += `?cancel_others=1`;
        }

        this.$swal({
          title: "Processing ...",
          html: "<div class='spinner-grow text-primary mb-2'></div> <div>Please wait while we approve the booking. </div> <div> Please don't close your browser/tab. </div>",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });

        try {
          const response = await window.axios.post(acceptUrl);
          if (response.data.status == "success") {
            this.$swal.close();
            this.$snotify.success(
              `Booking ${response.data.data.booking.title} accepted.`,
              `Success`
            );
            this.bookingOrderApproved = 1;
          } else if (response.data.status == "already_accepted") {
            this.$swal.close();
            this.$snotify.success(
              `Your booking has already been accepted.`,
              `Success`
            );
            this.bookingOrderApproved = 0;
          } else if (response.data.status == "already_rejected") {
            this.$swal.close();
            this.$snotify.success(
              `Your booking has already rejected.`,
              `Success`
            );
            this.bookingOrderApproved = 0;
          }
          this.hide();
          this.$parent.getCalendarBookingData();
        } catch (error) {
          console.log(error);
          this.$swal.close();
          this.$snotify.error(
            `There was a problem when accepting the booking.`,
            "Error"
          );
          this.bookingOrderApproved = 0;
        }
      } else {
        this.bookingOrderApproved = 0;
      }
    },

    addNewGuestsEmail(email) {
      let data = {
        id: Math.random(),
        email: email,
        name: email,
        non_contact: true,
      };

      if (this.validateEmail(email)) {
        this.booking.guests.push(data);
      }
    },
    validateEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    generateRecurringSchedules() {
      let startAt = this.$moment(this.booking.start_at).startOf("minute");
      if (this.mainBooking.start_at && this.booking.is_recurring) {
        startAt = this.mainBooking.start_at;
      }

      let endAt = this.$moment(this.booking.end_at).startOf("minute");
      if (this.mainBooking.end_at && this.booking.is_recurring) {
        endAt = this.mainBooking.end_at;
      }

      let schedules = [
        {
          start_at: startAt,
          end_at: endAt,
        },
      ];

      const repeatType = this.booking.recurring_options.repeat_type;
      const repeatTypeValue = this.booking.recurring_options.repeat_type_value;
      const repeatEnd = this.booking.recurring_options.repeat_end;
      const repeatEndValue = this.booking.recurring_options.repeat_end_value;

      if (repeatEnd == "occurence" && repeatEndValue) {
        for (let i = 1; i < repeatEndValue; i++) {
          const nextStartAt = this.$moment(startAt)
            .add(repeatTypeValue * i, repeatType)
            .startOf("minute");
          const nextEndAt = this.$moment(endAt)
            .add(repeatTypeValue * i, repeatType)
            .startOf("minute");
          schedules.push({
            start_at: nextStartAt,
            end_at: nextEndAt,
          });
        }
      } else if (repeatEnd == "date" && repeatEndValue) {
        const repeatEndDate = this.$moment(repeatEndValue).endOf("day");
        const diffInRepeatType = repeatEndDate.diff(startAt, repeatType);

        for (let i = 1; i <= diffInRepeatType; i++) {
          const nextStartAt = this.$moment(startAt)
            .add(repeatTypeValue * i, repeatType)
            .startOf("minute");
          const nextEndAt = this.$moment(endAt)
            .add(repeatTypeValue * i, repeatType)
            .startOf("minute");

          schedules.push({
            start_at: nextStartAt,
            end_at: nextEndAt,
          });
        }
      }

      this.booking.recurring_schedules = schedules;
    },
    async loadDetailBooking() {
      const bookingDetailResponse = await window.axios.get(
        `/api/bookings/${this.bookingId}`
      );
      const bookingDetail = bookingDetailResponse.data;

      let recurringOptions = initialBooking.recurring_options;

      if (bookingDetail.is_recurring) {
        const mainRecurring = bookingDetail.recurring_bookings.find(
          (booking) => {
            return booking.recurring_id == null;
          }
        );
        if (mainRecurring) {
          this.mainBooking.start_at = this.$moment
            .utc(mainRecurring.start_at)
            .tz(this.timezone)
            .startOf("minute");
          this.mainBooking.end_at = this.$moment
            .utc(mainRecurring.end_at)
            .tz(this.timezone)
            .startOf("minute");
          //reset the counter
          this.mainBooking.start_at_changed = 0;
          this.mainBooking.end_at_changed = 0;
        }

        recurringOptions = JSON.parse(bookingDetail.recurring_options);
        if (recurringOptions.repeat_end == "date") {
          recurringOptions.repeat_end_value = this.$moment
            .utc(recurringOptions.repeat_end_value)
            .startOf("minute")
            .tz(this.timezone)
            .toDate();
        }
      }

      const startAt = this.$moment
        .utc(bookingDetail.start_at)
        .tz(this.timezone)
        .startOf("minute")
        .format("YYYY-MM-DD HH:mm");
      const endAt = this.$moment
        .utc(bookingDetail.end_at)
        .tz(this.timezone)
        .startOf("minute")
        .format("YYYY-MM-DD HH:mm");
      console.log(`start-at - end-at ${startAt} - ${endAt}`);

      const todos = bookingDetail.todos.map((todo) => {
        todo.due_date_tz = this.$moment
          .utc(todo.due_date)
          .tz(this.timezone)
          .format("MM/DD/YYYY h:mma");
        todo.assigned_user = todo.members.map((member) => {
          return member.user;
        });
        return todo;
      });

      var parsedGuests = [];
      if (bookingDetail.google_calendar_event_id == null) {
        parsedGuests = JSON.parse(bookingDetail.guests);
      }

      var isPending = bookingDetail.is_pending;
      //when the form is called from reschedule booking order, set the pending to false
      if (this.bookingOrderId) {
        isPending = 0;
      }

      const bookingUsers = bookingDetail.booking_users.map((bookingUser) => {
        bookingUser.role = bookingUser.role_id;
        return bookingUser;
      });

      if (
        bookingDetail.description &&
        bookingDetail.description.includes("Session Collaborators") == false &&
        bookingDetail.band_collaborators
      ) {
        bookingDetail.description += `\r\nSession Collaborators: ${bookingDetail.band_collaborators}\r\n`;
      }

      this.booking = {
        id: bookingDetail.id,
        booking_id: bookingDetail.id,
        start_at: startAt,
        end_at: endAt,
        reminder: bookingDetail.reminder
          ? bookingDetail.reminder.reminder
          : "No Reminder",
        title: bookingDetail.title,
        all_day: bookingDetail.all_day,
        is_pending: isPending,
        type: bookingDetail.type,
        description: bookingDetail.description,
        room_id: bookingDetail.room_id,
        event_type_id: bookingDetail.event_type_id,
        user_id: bookingDetail.user_id,
        associate: bookingDetail.associate,
        associate_id: bookingDetail.associate_id,
        associate_type: bookingDetail.associated_with_type,
        booking_songs: bookingDetail.booking_songs,
        booking_users: bookingUsers,
        department_id: bookingDetail.departmentable
          ? bookingDetail.departmentable.id
          : null,
        is_recurring: bookingDetail.is_recurring,
        recurring_options: recurringOptions,
        zoom_data: JSON.parse(bookingDetail.zoom_data),
        guests: parsedGuests,
        client_reminder: this.clientReminder,
        todos: todos,
        custom_fields: bookingDetail.custom_fields || {},
        invoice: bookingDetail.invoice || null
      };

      if (this.bookingOrderId) {
        this.booking.booking_order_id = parseInt(this.bookingOrderId);
      }

      if (this.bookingOrderData && this.bookingOrderData.status == "waiting") {
        this.bookingOrderApproved = 0;
      } else if (
        (this.bookingOrderData && this.bookingOrderData.status == "approved") ||
        (this.bookingOrderData && this.bookingOrderData.status == "completed")
      ) {
        this.bookingOrderApproved = 1;
      }

      if (this.notificationId) {
        this.booking.notification_id = this.notificationId;
      }

      if (bookingDetail.google_calendar_event_id != null) {
        var guestsInfo = [];
        if (bookingDetail.google_calendar_booked_by != null) {
          var guests = JSON.parse(bookingDetail.google_calendar_guests);
          guestsInfo = guests.map((email) => {
            return {
              id: Math.random(),
              email: email,
              name: email,
              non_contact: true,
            };
          });
        } else {
          guestsInfo = JSON.parse(bookingDetail.guests);
          console.log("the guests");
          console.log(guests);
        }

        //we need to do this, to force sync between vue data & external data
        this.booking.guests = Object.assign([], guestsInfo);
      }

      console.log("associate e");
      console.log(bookingDetail.associate);

      if (bookingDetail.associate_type) {
        this.isAssociate = true;
        if (
          this.booking.associate_type == "Project" &&
          bookingDetail.booking_songs.length > 0
        ) {
          this.booking.booking_songs = bookingDetail.booking_songs.map(
            (song) => {
              return {
                text: song.project_song.name,
                id: song.project_song.id,
              };
            }
          );
        }
      } else {
        this.isAssociate = false;
      }
    },

    async copyToClipboard(link, type) {
      try {
        await this.$copyText(link);
        if (type == "zoom-start-url") {
          this.$toasted.show("Zoom Start URL clipboard.", {
            position: "bottom-center",
            duration: 3000,
            type: "success",
            theme: "bubble",
          });
        } else if (type == "zoom-join-url") {
          this.$toasted.show("Zoom Join URL copied to clipboard.", {
            position: "bottom-center",
            duration: 3000,
            type: "success",
            theme: "bubble",
          });
        }
      } catch (error) {
        console.log(error);
        if (type == "zoom-start-url") {
          this.$toasted.show("Failed to copy Zoom Start URL.", {
            position: "bottom-center",
            duration: 3000,
            type: "error",
            theme: "bubble",
          });
        } else if (type == "zoom-join-url") {
          this.$toasted.show("Failed to copy Zoom Join URL .", {
            position: "bottom-center",
            duration: 3000,
            type: "error",
            theme: "bubble",
          });
        }
      }
    },
    //   const response = await window.axios.get(
    //     "/api/picklists/Event Types?order_by=id|asc"
    //   );
    //   const eventTypeOptions = response.data.data.options.map((option) => {
    //     return {
    //       value: option.id,
    //       text: option.name,
    //     };
    //   });
    //   this.event_type_options = [
    //     { value: "", text: "Choose Event Type" },
    //     ...eventTypeOptions,
    //   ];

    // },
    async disableSync(booking) {
      const result = await this.$swal({
        title: `Are you sure you want to delete this booking?`,
        text: "Once booking is removed, it cannot be re-synced!",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Delete",
      });
      if (result.value) {
        try {
          await window.axios.get(
            `/api/robin-booking-queues/${booking.robin_event_id}/disable-sync`
          );
          this.$snotify.success("Booking Deleted", "Success");
          this.hide();
          this.$emit("on-delete", booking.robin_event_id);
        } catch (error) {
          console.log(error);
          this.$snotify.error("Failed to delete Booking", "Error");
          this.hide();
          this.$emit("on-delete", booking.robin_event_id);
        }
      }
    },
    getErrorLabel(validation) {
      return Object.keys(validation)
        .filter((key) => !key.startsWith("$"))
        .reduce((prev, curr) => {
          if (!validation[curr]) {
            prev = this.errorLabels[curr];
          }
          return prev;
        }, "");
    },
    addBookingUserRow() {
      this.booking.booking_users.push({
        user_id: "",
        role: "",
      });
      if (this.bookingSubmitted) {
        this.$v.booking.booking_users.$touch();
      }
    },
    deleteBookingUserRow(index) {
      this.booking.booking_users.splice(index, 1);
    },

    async reloadAssociateIdOption(val, oldVal, initAssociation = null) {
      if (val === "Contact") {
        //before doing request, prefill the load
        this.associate_id_options = [];
        if (initAssociation) {
          this.associate_id_options.push({
            id: initAssociation.id,
            text: `${initAssociation.first_name} ${initAssociation.last_name}`,
            primary_contact: {
              id: initAssociation.id,
              first_name: initAssociation.first_name,
              last_name: initAssociation.last_name,
              name: initAssociation.full_name,
              full_name: initAssociation.full_name,
              email: initAssociation.email,
              phone_number: initAssociation.phone_number,
              band: initAssociation.band,
            },
          });

          if (this.booking.associate_id && this.booking.associate_type) {
            const associateId = this.booking.associate_id.value
              ? this.booking.associate_id.value
              : this.booking.associate_id;

            const notNullExistingType = this.associate_type_options.find(
              (type) => {
                return type.value == oldVal;
              }
            );

            if (notNullExistingType !== undefined && val != oldVal) {
              this.booking.associate_id = "";
            } else {
              const associate = this.associate_id_options.find((item) => {
                return item.id == associateId;
              });
              this.booking.associate_id = { ...associate };
            }
          }
        }

        const queryParams = {
          'fields' : 'id,first_name,last_name,email,phone_number'
        }

        var queryString = Object.keys(queryParams).map(function(key) {
            return key + '=' + queryParams[key]
        }).join('&');

        const response = await window.axios.get(`/api/v2/contacts/options?${queryString}`);
        const options = response.data;
        
        const associateIdOptions = options.map((contact) => {
          return {
            id: contact.id,
            text: `${contact.first_name} ${contact.last_name}`,
            primary_contact: {
              id: contact.id,
              first_name: contact.first_name,
              last_name: contact.last_name,
              name: contact.full_name,
              full_name: contact.full_name,
              email: contact.email,
              phone_number: contact.phone_number,
              band: contact.band,
            },
          };
        });

        this.associate_id_options = [
          ...this.associate_id_options,
          ...associateIdOptions,
        ];
        this.associateOptionsFullyLoaded = true;

        if (
          this.booking.associate_id &&
          this.booking.associate_type &&
          initAssociation == null
        ) {
          const associateId = this.booking.associate_id.value
            ? this.booking.associate_id.value
            : this.booking.associate_id;

          const notNullExistingType = this.associate_type_options.find(
            (type) => {
              return type.value == oldVal;
            }
          );

          if (notNullExistingType !== undefined && val != oldVal) {
            this.booking.associate_id = "";
          } else {
            const associate = this.associate_id_options.find((item) => {
              return item.id == associateId;
            });
            this.booking.associate_id = { ...associate };
          }
        }
      }

      if (val === "Company") {
        const response = await window.axios.get(`/api/companies/option-list`);
        this.associate_id_options = [];
        const options = response.data;

        this.associateOptionsFullyLoaded = true;
        options.forEach((company) => {
          this.associate_id_options.push({
            id: company.id,
            text: company.name,
            primary_contact: company.primary_contact,
          });
        });

        if (this.booking.associate_id && this.booking.associate_type) {
          const associateId = this.booking.associate_id.value
            ? this.booking.associate_id.value
            : this.booking.associate_id;

          const notNullExistingType = this.associate_type_options.find(
            (type) => {
              return type.value == oldVal;
            }
          );

          if (notNullExistingType !== undefined && val != oldVal) {
            this.booking.associate_id = "";
          } else {
            const associate = this.associate_id_options.find((item) => {
              return item.id == associateId;
            });

            console.log("reassociate company");

            if (this.bookingId) {
              console.log("edit booking");
            } else {
              this.booking.guests = [
                {
                  id: associate.primary_contact.id,
                  name: associate.primary_contact.full_name,
                },
              ];
            }

            this.booking.associate_id = { ...associate };
          }
        }
      }

      if (val === "Band/Group" || val === "Artist/Group") {

        const queryParams = {
          'fields' : 'id,name,contacts.id,contacts.email,contacts.first_name,contacts.last_name,contacts.phone_number',
          'includes' : 'contacts'
        }

        const queryString = Object.keys(queryParams).map(function(key) {
            return key + '=' + queryParams[key]
        }).join('&');


        const response = await window.axios.get(`/api/v2/bands/options?${queryString}`);
        this.associate_id_options = [];

        const options = response.data;

        this.associateOptionsFullyLoaded = true;
        options.forEach((band) => {
          var bandInfo = {
            id: band.id,
            text: band.name,
          };
          if (band["contacts.id"]) {
            bandInfo.primary_contact = {
              id : band["contacts.id"],
              email : band["contacts.email"],
              name: band["contacts.first_name"] + " " + band["contacts.last_name"],
              first_name : band["contacts.first_name"],
              last_name : band["contacts.last_name"],
              phone_number: band["contacts.phone_number"]
            }
          }

          this.associate_id_options.push(bandInfo);
        });

        if (this.booking.associate_id && this.booking.associate_type) {
          const associateId = this.booking.associate_id.value
            ? this.booking.associate_id.value
            : this.booking.associate_id;

          const notNullExistingType = this.associate_type_options.find(
            (type) => {
              return type.value == oldVal;
            }
          );

          if (notNullExistingType !== undefined && val != oldVal) {
            this.booking.associate_id = "";
          } else {
            const associate = this.associate_id_options.find((item) => {
              return item.id == associateId;
            });
            this.booking.associate_id = { ...associate };
          }
        }
      }

      if (val === "Lead") {
        const response = await window.axios.get(`/api/leads/option-list`);
        this.associate_id_options = [];
        let options = response.data;

        this.associateOptionsFullyLoaded = true;
        options.forEach((lead) => {
          this.associate_id_options.push({
            id: lead.id,
            text: `${lead.primary_contact.first_name} ${lead.primary_contact.last_name}`,
            primary_contact: lead.primary_contact,
          });
        });

        if (this.booking.associate_id && this.booking.associate_type) {
          const associateId = this.booking.associate_id.value
            ? this.booking.associate_id.value
            : this.booking.associate_id;

          const notNullExistingType = this.associate_type_options.find(
            (type) => {
              return type.value == oldVal;
            }
          );

          if (notNullExistingType !== undefined && val != oldVal) {
            this.booking.associate_id = "";
          } else {
            const associate = this.associate_id_options.find((item) => {
              return item.id == associateId;
            });
            this.booking.associate_id = { ...associate };
          }
        }
      }

      if (val === "Project") {
        var projectUrl = `/api/projects/options?is_active=1`;
        if (this.bookingId) {
          projectUrl += `&exclude_booking_id=${this.bookingId}`;
        }
        const response = await window.axios.get(projectUrl);

        this.associate_id_options = [];
        this.associateOptionsFullyLoaded = true;

        const projects = response.data;

        projects.forEach((project) => {
          let primaryContact = project.primary_contact;
          primaryContact.band = [project.band ? project.band : []];

          this.associate_id_options.push({
            id: project.id,
            text: project.name,
            primary_contact: primaryContact,
          });
        });

        if (this.booking.associate_id && this.booking.associate_type) {
          const associateId = this.booking.associate_id.value
            ? this.booking.associate_id.value
            : this.booking.associate_id;

          const notNullExistingType = this.associate_type_options.find(
            (type) => {
              return type.value == oldVal;
            }
          );

          if (notNullExistingType !== undefined && val != oldVal) {
            this.booking.associate_id = "";
          } else {
            const associate = this.associate_id_options.find((item) => {
              return item.id == associateId;
            });
            this.booking.associate_id = { ...associate };
          }
        }
      }
    },

    createForm(type) {
      if (type == "Contact") {
        this.$refs.contactFormModal.show();
      }

      if (type == "Company") {
        this.$refs.companyFormModal.show();
      }

      if (type == "Band/Group" || type == "Artist/Group") {
        this.$refs.bandFormModal.show();
      }

      if (type == "Lead") {
        this.$refs.leadFormModal.show();
      }

      if (type == "Project") {
        this.$refs.projectFormModal.show();
      }
    },

    newContactAdded(value) {
      const newContact = {
        value: value.id,
        text: `${value.first_name} ${value.last_name}`,
      };

      this.associate_id_options.push(newContact);
      this.booking.associate_id = newContact;

      this.reloadAssociateIdOption("Contact", "Contact");
    },
    newCompanyAdded(value) {
      let newCompany = {
        value: value.id,
        text: value.name,
      };

      this.associate_id_options.push(newCompany);
      this.booking.associate_id = newCompany;
      this.reloadAssociateIdOption("Company", "Company");
    },
    newBandAdded(value) {
      let newBand = {
        value: value.id,
        text: value.name,
      };

      this.associate_id_options.push(newBand);
      this.booking.associate_id = newBand;
      this.reloadAssociateIdOption("Band", "Band");
    },
    newLeadAdded(value) {
      let newLead = {
        value: value.id,
        text: `${value.primary_contact.first_name} ${value.primary_contact.last_name}`,
      };

      this.associate_id_options.push(newLead);
      this.booking.associate_id = newLead;

      this.reloadAssociateIdOption("Lead", "Lead");
    },
    newProjectAdded(value) {
      let newProject = {
        value: value.id,
        text: value.name,
      };

      this.associate_id_options.push(newProject);
      this.booking.associate_id = newProject;

      this.reloadAssociateIdOption("Project", "Project");
    },

    addNewSong() {
      this.$refs.projectSongForm.displayModal();
    },

    newProjectSongAdded(projectSong) {
      this.songOptions.push({
        id: projectSong.data.data.id,
        text: projectSong.data.data.name,
      });
      this.booking.booking_songs.push({
        id: projectSong.data.data.id,
        text: projectSong.data.data.name,
      });
    },

    onClose() {
      this.hide();
    },

    async onDelete(booking) {
      if (this.bookingOrderData && this.bookingOrderData.status == "waiting") {
        const result = await this.$swal({
          title: `This event has not been accepted. Would you like to send a rejection notification to the booking contact?`,
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#46B1C9",
          confirmButtonText: "Yes",
          showDenyButton: true,
          denyButtonText: "No",
          denyButtonColor: "#d33",
        });
        if (result.isConfirmed || result.isDenied) {
          var deleteUrl = `/api/bookings/${booking.booking_id}`;
          if (result.isDenied) {
            deleteUrl += "?disable_order_cancel_email=1";
          }

          try {
            await window.axios.delete(deleteUrl);
            this.$snotify.success("Booking Deleted", "Success");
            this.isLoading = false;
            this.$emit("on-delete", booking.booking_id);
            this.hide();
          } catch (error) {
            this.isLoading = false;
            console.log(error);
            this.$snotify.error("Failed to delete Booking", "Error");
            this.$emit("on-delete", booking.booking_id);
            this.hide();
          }
        } else {
          return false;
        }
      } else {
        const result = await this.$swal({
          title: `Are you sure you want to delete this booking`,
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "Delete",
        });
        if (result.value) {
          if (booking.is_recurring) {
            const deleteType = await Swal.fire({
              customClass: {
                denyButton: "swal2-confirm swal2-styled",
              },
              title: `Recurring booking detected.`,
              text: "Click Delete to delete only this booking, click Delete All to delete all associated bookings.",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#d33",
              confirmButtonText: "Delete",
              showDenyButton: true,
              denyButtonText: "Delete All",
              denyButtonColor: "#46B1C9",
              focusConfirm: true,
            });

            if (deleteType.isConfirmed || deleteType.isDenied) {
              let deleteAll = false;
              if (deleteType.isDenied) {
                deleteAll = true;
              }

              var deleteUrl = `/api/bookings/${booking.booking_id}`;
              if (deleteAll) {
                deleteUrl += "?include_recurring=1";
              }

              try {
                await window.axios.delete(deleteUrl);
                this.$snotify.success("Booking Deleted", "Success");
                this.isLoading = false;
                this.$emit("on-delete", booking.booking_id);
                this.hide();
              } catch (error) {
                this.isLoading = false;
                console.log(error);
                this.$snotify.error("Failed to delete Booking", "Error");
                this.$emit("on-delete", booking.booking_id);
                this.hide();
              }
            }
            //canceled
            else {
              this.isLoading = false;
              return false;
            }
          } else {
            this.isLoading = true;
            try {
              await window.axios.delete(`/api/bookings/${booking.booking_id}`);
              this.$snotify.success("Booking Deleted", "Success");
              this.isLoading = false;
              this.$emit("on-delete", booking.booking_id);
              this.hide();
            } catch (error) {
              this.isLoading = false;
              console.log(error);
              this.$snotify.error("Failed to delete Booking", "Error");
              this.$emit("on-delete", booking.booking_id);
              this.hide();
            }
          }
        }
      }
    },

    async loadProjectSongs(associateId) {
      const songResponse = await window.axios.get(
        `/api/projects/${associateId}/songs`
      );
      this.songOptions = songResponse.data.map((song) => {
        return {
          id: song.id,
          text: song.name,
        };
      });
    },

    async saveBooking({ createInvoice = false} = {}) {
      this.bookingSubmitted = true;
      this.$v.booking.$touch();

      // if its still pending or an error is returned do not submit
      if (this.$v.booking.$pending || this.$v.booking.$error) return;

      let isValid = !this.$v.booking.$invalid;
      if (isValid) {
        this.isLoading = true;

        let postedData = {
          ...this.booking,
          ...{
            start_at: this.$moment(this.booking.start_at)
              .utc()
              .format(window.apiDateTimeFormat),
            end_at: this.$moment(this.booking.end_at)
              .utc()
              .format(window.apiDateTimeFormat),
            associate_id: this.isAssociate
              ? this.booking.associate_id.id
              : this.booking.associate_id,
          },
        };

        if (postedData.is_recurring) {
          postedData.recurring_schedules = postedData.recurring_schedules.map(
            (schedule) => {
              return {
                start_at: this.$moment(schedule.start_at)
                  .utc()
                  .format(window.apiDateTimeFormat),
                end_at: this.$moment(schedule.end_at)
                  .utc()
                  .format(window.apiDateTimeFormat),
              };
            }
          );
          postedData.recurring_options = JSON.stringify(
            postedData.recurring_options
          );
        } else {
          postedData.recurring_options = JSON.stringify(
            postedData.recurring_options
          );
        }

        //handle reschedule booking service order
        if (this.booking.booking_id && this.bookingOrderId) {
          const rescheduleUrl = `/api/booking-service-orders/${this.bookingOrderId}/reschedule`;
          try {
            const rescheduleResponse = await window.axios.put(
              rescheduleUrl,
              postedData
            );
            this.hide();
            this.isLoading = false;
            this.$snotify.success("Reschedule Booking Success.", "Success!");
            this.$emit("on-update", rescheduleResponse.data, isValid);
          } catch (error) {
            console.log(error);
            if (
              error.response.status == 422 &&
              error.response.data.status &&
              error.response.data.status == "booked"
            ) {
              const confirmResult = await Swal.fire({
                customClass: {
                  denyButton: "swal2-confirm swal2-styled",
                },
                title: `The room is already booked`,
                text: "The room is already booked during the time you have selected.",
                confirmButtonColor: "#d33",
                confirmButtonText: "Confirm",
                focusConfirm: true,
              });
              //confirmed or pending
              if (confirmResult.isConfirmed || confirmResult.isDenied) {
                this.isLoading = false;
                return false;
              }
              //canceled
              else {
                this.isLoading = false;
                return false;
              }
            }
          }
        }

        //handle update booking only
        else if (this.booking.booking_id && this.bookingOrderId == null) {
          let includeRecurring = false;
          if (this.booking.is_recurring) {
            const updateType = await Swal.fire({
              customClass: {
                denyButton: "swal2-confirm swal2-styled",
              },
              title: `Recurring booking detected.`,
              text: "Click Update to update only this booking, click Update All to all all associated bookings.",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#d33",
              confirmButtonText: "Update",
              showDenyButton: true,
              denyButtonText: "Update All",
              denyButtonColor: "#46B1C9",
              focusConfirm: true,
            });

            console.log("choose update type");

            if (updateType.isDenied) {
              includeRecurring = 1;
            }

            if (updateType.isDismissed) {
              this.isLoading = false;
              return false;
            }
          }

          var updateUrl = `/api/bookings/${this.booking.booking_id}`;
          if (includeRecurring) {
            updateUrl += "?include_recurring=1";
          }

          try {
            const updateBookingResponse = await window.axios.put(
              updateUrl,
              postedData
            );
            this.hide();
            this.isLoading = false;
            this.$snotify.success("Booking Data Updated", "Success!");
            this.$emit("on-update", updateBookingResponse.data, isValid);

            if(createInvoice){
              console.log('redirect after update')
              this.$router.push({
                name: "invoice-create",
                params: {
                  booking_id: updateBookingResponse.data.data.id
                },
              });
            }
          } catch (error) {
            if (
              error.response.status == 422 &&
              error.response.data.status &&
              error.response.data.status == "booked"
            ) {
              const confirmResult = await Swal.fire({
                customClass: {
                  denyButton: "swal2-confirm swal2-styled",
                },
                title: `The room is already booked`,
                text: "The room is already booked during the time you have selected. Would you like to schedule the new booking anyway?",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showDenyButton: true,
                denyButtonText: "Set as Pending",
                denyButtonColor: "#46B1C9",
                focusConfirm: true,
              });
              //confirmed or pending
              if (confirmResult.isConfirmed || confirmResult.isDenied) {
                //if pending, set to 1, call save again
                if (confirmResult.isDenied) {
                  this.booking.is_pending = 1;
                } else if (confirmResult.isConfirmed) {
                  this.booking.double_booking = 1;
                }
                //try to save again
                this.saveBooking();
              }
              //canceled
              else {
                this.isLoading = false;
                return false;
              }
            } else if(
              error.response.status == 422 &&
              error.response.data.status &&
              error.response.data.status == "assigned"
            ) {

              const confirmResult = await Swal.fire({
                customClass: {
                  denyButton: "swal2-confirm swal2-styled",
                },
                title: `The selected users are already assigned`,
                text: "The selected users is already assigned during the time you have selected. Would you like to schedule the new booking anyway?",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showDenyButton: true,
                denyButtonText: "Set as Pending",
                denyButtonColor: "#46B1C9",
                focusConfirm: true,
              });

              if (confirmResult.isConfirmed || confirmResult.isDenied) {
                //if pending, set to 1, call save again
                if (confirmResult.isDenied) {
                  this.booking.is_pending = 1;
                } else if (confirmResult.isConfirmed) {
                  this.booking.double_assignment = 1;
                }
                //try to save again
                this.saveBooking();
              }
              //canceled
              else {
                this.isLoading = false;
                return false;
              }

            }
              else if (
              error.response.status == 422 &&
              error.response.data.status &&
              error.response.data.status == "exceed_limit"
            ) {
              await Swal.fire({
                customClass: {
                  denyButton: "swal2-confirm swal2-styled",
                },
                title: `The daily booking limit reached.`,
                text: "The daily booking limit for the time you have selected has been reached. Please select a different day or time",
                showConfirmButton: true,
                confirmButtonColor: "#d33",
                confirmButtonText: "Confirm",
                focusConfirm: true,
              });
              this.isLoading = false;
              return false;
            } else if (
              error.response.status == 422 &&
              error.response.data.status &&
              ( error.response.data.status == 401 || error.response.data.status == 403 )
            ) {
              await Swal.fire({
                customClass: {
                  denyButton: "swal2-confirm swal2-styled",
                },
                title: `Write Access to Google Calendar Required`,
                text: `Your user appears to be missing "Write" access to the selected room within the Google Calendar. Please contact your IT team to request "Write" access to continue.`,
                showConfirmButton: true,
                confirmButtonColor: "#d33",
                confirmButtonText: "Confirm",
                focusConfirm: true,
              });

              this.isLoading = false;
              return false;
            }
          }
        }
        // handle create booking
        else {
          try {
            const createBookingResponse = await window.axios.post(
              "/api/bookings",
              postedData
            );
            this.hide();
            this.isLoading = false;
            if (postedData.is_pending == 1) {
              this.$snotify.success("New Pending Booking Created", "Success!");
            } else {
              this.$snotify.success("New Booking Created", "Success!");
            }
            this.$emit("on-save", createBookingResponse.data, isValid);

            if(createInvoice){
              this.$router.push({
                name: "invoice-create",
                params: {
                  booking_id: createBookingResponse.data.data.id
                },
              });
            }

          } catch (error) {
            console.log(error.response);
            if (
              error.response.status == 422 &&
              error.response.data.status &&
              error.response.data.status == "booked"
            ) {
              const confirmResult = await Swal.fire({
                customClass: {
                  denyButton: "swal2-confirm swal2-styled",
                },
                title: `The room is already booked`,
                text: "The room is already booked during the time you have selected. Would you like to schedule the new booking anyway?",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showDenyButton: true,
                denyButtonText: "Set as Pending",
                denyButtonColor: "#46B1C9",
                focusConfirm: true,
              });
              //confirmed or pending
              if (confirmResult.isConfirmed || confirmResult.isDenied) {
                //if pending, set to 1, call save again
                if (confirmResult.isDenied) {
                  this.booking.is_pending = 1;
                } else if (confirmResult.isConfirmed) {
                  this.booking.double_booking = 1;
                }
                //try to save again
                this.saveBooking();
              }
              //canceled
              else {
                this.isLoading = false;
                return false;
              }
            } else if(
              error.response.status == 422 &&
              error.response.data.status &&
              error.response.data.status == "assigned"
            ){

              const confirmResult = await Swal.fire({
                customClass: {
                  denyButton: "swal2-confirm swal2-styled",
                },
                title: `The selected users are already assigned`,
                text: "The selected users is already assigned during the time you have selected. Would you like to schedule the new booking anyway?",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                confirmButtonText: "Confirm",
                showDenyButton: true,
                denyButtonText: "Set as Pending",
                denyButtonColor: "#46B1C9",
                focusConfirm: true,
              });

              if (confirmResult.isConfirmed || confirmResult.isDenied) {
                //if pending, set to 1, call save again
                if (confirmResult.isDenied) {
                  this.booking.is_pending = 1;
                } else if (confirmResult.isConfirmed) {
                  this.booking.double_assignment = 1;
                }
                //try to save again
                this.saveBooking();
              }
              //canceled
              else {
                this.isLoading = false;
                return false;
              }
            }else if (
              error.response.status == 422 &&
              error.response.data.status &&
              error.response.data.status == "exceed_limit"
            ) {
              await Swal.fire({
                customClass: {
                  denyButton: "swal2-confirm swal2-styled",
                },
                title: `The daily booking limit reached.`,
                text: "The daily booking limit for the time you have selected has been reached. Please select a different day or time",
                showConfirmButton: true,
                confirmButtonColor: "#d33",
                confirmButtonText: "Confirm",
                focusConfirm: true,
              });
              this.isLoading = false;
              return false;
            } else if (
              error.response.status == 422 &&
              error.response.data.status &&
              ( error.response.data.status == 401 || error.response.data.status == 403 )
            ) {
              await Swal.fire({
                customClass: {
                  denyButton: "swal2-confirm swal2-styled",
                },
                title: `Write Access to Google Calendar Required.`,
                text: `Your user appears to be missing "Write" access to the selected room within the Google Calendar. Please contact your IT team to request "Write" access to continue.`,
                showConfirmButton: true,
                confirmButtonColor: "#d33",
                confirmButtonText: "Confirm",
                focusConfirm: true,
              });

              this.isLoading = false;
              return false;
            } else if (error.response.status == 500) {
              this.$snotify.error(
                `There was a problem when create the booking.`,
                "Error"
              );
              this.isLoading = false;
              return false;
            }
          }
        }
      }
    },

    async loadRoomOptions() {
      const roomsReponse = await window.axios.get("/api/rooms/options");
      this.roomOptions = [
        {
          text: "Choose Room",
          value: "",
        },
      ];
      roomsReponse.data.forEach((room) => {
        this.roomOptions.push({
          text: room.name,
          value: room.id,
        });
      });
    },

    async loadUserOptions() {
      const usersReponse = await window.axios.get(
        "/api/users/get-all?sort_by=first_name|asc"
      );
      this.assignedUserOptions = [
        {
          text: "Choose Assigned User",
          value: "",
        },
      ];
      Object.keys(usersReponse.data).forEach((key) => {
        this.assignedUserOptions.push({
          text: `${usersReponse.data[key].first_name} ${usersReponse.data[key].last_name}`,
          value: usersReponse.data[key].id,
        });
      });
    },

    async bootNetWorkCall() {
      //speeding up network call, by calling multiple endpoint in one call, in parallel
      const [
        eventTypeResponse,
        companySettingResponse,
        departmentPicklistResponse,
        roomOptionResponse,
        userOptionResponse,
        bookingRoleOptionResponse,
        // contactOptionResponse,
      ] = await Promise.allSettled([
        window.axios.get("/api/picklists/Event Types?order_by=id|asc"),
        window.axios.get("/api/settings"),
        window.axios.get("/api/picklists/Department"),
        window.axios.get("/api/rooms/options"),
        window.axios.get("/api/users/get-all?sort_by=first_name|asc"),
        window.axios.get("/api/picklists/Booking Roles"),
        // window.axios.get("/api/contacts/option-list"),
      ]);

      //handle list of event types
      if (eventTypeResponse.status == "fulfilled") {
        const eventTypeOptions = eventTypeResponse.value.data.data.options.map(
          (option) => {
            return {
              value: option.id,
              text: option.name,
            };
          }
        );

        this.event_type_options = [
          { value: "", text: "Choose Event Type" },
          ...eventTypeOptions,
        ];
      }

      //handle company settings
      if (companySettingResponse.status == "fulfilled") {
        const bookingDepartmentSetting = companySettingResponse.value.data.find(
          (setting) => {
            return setting.name == "company_booking_department";
          }
        );
        if (bookingDepartmentSetting && bookingDepartmentSetting.value == "1") {
          this.departmentEnabled = true;
        }

        const bookingGuestNotification = companySettingResponse.value.data.find(
          (setting) => {
            return setting.name == "company_booking_guest_notification";
          }
        );

        if (bookingGuestNotification && bookingGuestNotification.value == "1") {
          this.booking.client_reminder = true;
          this.clientReminder = true;
        }
      }

      //handle department list
      if (departmentPicklistResponse.status == "fulfilled") {
        if (
          departmentPicklistResponse.value.data.data.options &&
          departmentPicklistResponse.value.data.data.options.length > 0
        ) {
          this.departmentOptions =
            departmentPicklistResponse.value.data.data.options.map((option) => {
              return {
                value: option.id,
                text: option.name,
              };
            });
          this.departmentOptions.unshift({
            value: "",
            text: "Choose Content Team",
          });
        }
      }

      //handle room response
      if (roomOptionResponse.status == "fulfilled") {
        this.roomOptions = [
          {
            text: "Choose Room",
            value: "",
          },
        ];

        roomOptionResponse.value.data.forEach((room) => {
          this.roomOptions.push({
            text: room.name,
            value: room.id,
          });
        });
      }

      //handle user list
      if (userOptionResponse.status == "fulfilled") {
        this.assignedUserOptions = [
          {
            text: "Choose Assigned User",
            value: "",
          },
        ];
        Object.keys(userOptionResponse.value.data).forEach((key) => {
          this.assignedUserOptions.push({
            text: `${userOptionResponse.value.data[key].first_name} ${userOptionResponse.value.data[key].last_name}`,
            value: userOptionResponse.value.data[key].id,
          });
        });
      }

      //handle booking role list
      if (bookingRoleOptionResponse.status == "fulfilled") {
        if (
          bookingRoleOptionResponse.value.data.data.options &&
          bookingRoleOptionResponse.value.data.data.options.length > 0
        ) {
          this.bookingRoleOptions =
            bookingRoleOptionResponse.value.data.data.options.map((option) => {
              return {
                value: option.id,
                text: option.name,
              };
            });
          this.bookingRoleOptions.unshift({
            value: "",
            text: "Choose Role",
          });
        }
      }

      //handle contact response
      // if (contactOptionResponse.status == "fulfilled") {
      //   const contactOptions = contactOptionResponse.value.data.map(
      //     (contact) => {
      //       return {
      //         ...contact,
      //         ...{ name: contact.full_name },
      //       };
      //     }
      //   );

      //   this.contactOptions = contactOptions;
      // }
    },

    async onShow() {
      this.bootNetWorkCall();
      this.associateOptionsFullyLoaded = false;

      this.loadEquipmentList();
      // this.loadBookingRoleOptions();

      if (this.bookingId) {
        this.loadDetailBooking();
      } else {
        this.booking = { ...initialBooking };
        if (
          this.bookingData &&
          this.bookingData.start_at &&
          this.bookingData.end_at
        ) {
          this.booking.start_at = this.bookingData.start_at;
          this.booking.end_at = this.bookingData.end_at;
        }
        this.booking.guests = [];
        this.booking.todos = [];
        (this.booking.booking_users = [
          {
            user_id: "",
            role: "",
          },
        ]),
          (this.booking.recurring_options.repeat_end = "occurence");
        this.booking.recurring_options.repeat_end_value = 1;
      }
    },
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
      this.booking.booking_songs = [];
      this.booking.todos = [];
      this.booking = { ...initialBooking };
      this.bookingSubmitted = false;
      this.$v.booking.$reset();
      this.$emit("on-close");
    },
    showEquipmentForm() {
      this.$refs.equipmentBookingManagementFormModal.show();
    },
    async refreshEquipment() {
      const equipmentResponse = await window.axios.get(
        `/api/equipments/by-booking-id/${this.bookingId}`
      );
      this.equipments = equipmentResponse.data;
    },
    handleCustomFieldsLoaded(data){
      this.isCustomFieldsAvailable = data.length > 0 ? true : false;
    },
    viewInvoice(id) {
      this.$router.push({ name: "invoice-view", params: { id: id } });
    },
  },
  data: () => ({
    isContactLoading: false,
    bookingRoleOptions: [
      {
        text: "Choose Booking Role",
        value: "",
      },
    ],

    isMobile: isMobile,
    selectedTodo: null,
    todos: [],
    notesVisible: false,
    customFieldsVisible: false,

    equipmentList: [],
    equipments: [],
    showAssociateWithPopover: false,

    clientReminder: false,
    timezone: localStorage.getItem("timezone"),

    bookingOrderApproved: 0,

    mainBooking: {
      //required, this is to detect the number times mainBooking.start_at changes
      start_at_changed: 0,
      //required, this is to detect the number times mainBooking.end_at changes
      end_at_changed: 0,
      start_at: null,
      end_at: null,
    },

    recurringEndChangeCount: 0,
    //recurring related options
    recurringRepeatTypeOptions: [
      { value: "days", text: "Days" },
      { value: "months", text: "Months" },
      { value: "weeks", text: "Weeks" },
      { value: "years", text: "Years" },
    ],

    recurringEndOptions: [
      { value: "occurence", text: "Occurrences" },
      { value: "date", text: "Date" },
    ],

    //department related data
    event_type_options: [
      {
        text: "Choose Event Type",
        value: "",
      },
    ],
    departmentEnabled: false,
    departmentOptions: [],

    contactOptions: [],
    songOptions: [],
    isAssociate: false,
    newAssociateId: "",
    bookingSubmitted: false,
    showModal: false,
    isCheckBooking: false, // to indicate whether we currently call check booking api or not
    isBooked: false,
    booking: { ...initialBooking },
    isLoading: false,
    roomOptions: [
      {
        text: "Choose Room",
        value: "",
      },
    ],
    datePickerConfig: {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: window.toFlatPickrDatetime(localStorage.getItem('php_datetime_format')),
      altInput: true,
      //dateFormat: 'Z',
      enableTime: true,
      static: false,
      minuteIncrement: 15,
    },
    dateOnlyPickerConfig: {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: window.toFlatPickrDatetime(localStorage.getItem('php_date_format')),
      altInput: true,
      //dateFormat: 'Z',
      enableTime: false,
      static: false,
      minuteIncrement: 15,
    },
    assignedUserOptions: [{ text: "Choose Assigned User", value: "" }],
    associate_type_options: [
      { text: "Contact", value: "Contact" },
      { text: "Company", value: "Company" },
      { text: "Artist/Group", value: "Artist/Group" },
      { text: "Lead", value: "Lead" },
      { text: "Project", value: "Project" },
    ],

    associate_id_options: [],
    guestNotificationReminder: false,

    associateOptionsFullyLoaded: false,

    isCustomFieldsAvailable: null,
  }),
  validations() {
    return {
      booking: {
        title: { required },
        start_at: {
          required,
        },
        end_at: {
          required,
          minValue: (value, vm) => {
            return this.$moment(value).isAfter(this.$moment(vm.start_at));
          },
        },
        booking_users: {
          required: requiredIf(function (booking) {
            if (booking.reminder === "No Reminder") {
              return false;
            } else {
              return true;
            }
          }),
          $each: {
            user_id: {
              required,
            },
            role: {
              required,
            },
          },
        },
        room_id: {
          required,
        },
        event_type_id: { required },
        associate_type: {
          required: requiredIf(function () {
            if (this.isAssociate) {
              return true;
            } else {
              return false;
            }
          }),
        },
        associate_id: {
          required: requiredIf(function () {
            if (this.isAssociate) {
              return true;
            } else {
              return false;
            }
          }),
        },
        department_id: {
          required: requiredIf(function () {
            if (this.departmentEnabled) {
              return true;
            } else {
              return false;
            }
          }),
        },
      },
    };
  },
  computed: {
    datetime_format(){
      return localStorage.getItem('datetime_format');
    },
    repeatEndLabel() {
      const repeatEnd = this.booking.recurring_options.repeat_end;
      if (repeatEnd == "date") {
        return "End Date";
      } else {
        return "Number of Occurrences";
      }
    },
    repeatEveryLabel() {
      const repeatType = this.booking.recurring_options.repeat_type;
      const repeatTypeValue = this.booking.recurring_options.repeat_type_value;

      let label = "";
      if (repeatType == "days") {
        label = `Repeat Every ${repeatTypeValue} Days`;
      } else if (repeatType == "weeks") {
        label = `Repeat Every ${repeatTypeValue} Weeks`;
      } else if (repeatType == "months") {
        label = `Repeat Every ${repeatTypeValue} Months`;
      } else if (repeatType == "years") {
        label = `Repeat Every ${repeatTypeValue} Years`;
      }

      return label;
    },
    settings() {
      return JSON.parse(localStorage.getItem('settings'));
    },
    showProjectId(){
      var result = false;
      if(this.settings && this.settings.company_show_project_id == "1"){
        result = true;
      }
      return result;
    },
    ability() {
      return window.ability;
    },
  },
};
</script>
<style scoped>
.btn-sm,
.btn-group-sm > .btn {
  line-height: 1px;
}
.border-dashed {
  border: 1px dashed #f5f5f5;
  border-radius: 7px;
}
.bg-lighter {
  background: #f5f5f5;
}
</style>