<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      id="create-new-task"
      centered
      title="Create New To Do"
      v-model="show_modal"
      :no-enforce-focus="true"
      @show="loadData"
    >
      <div class="row">
        <b-form-group
          label="Title"
          class="col-lg-12"
          :invalid-feedback="getErrorLabel($v.task.title)"
        >
          <b-input v-model="task.title" :state="$v.task.title.$error ? 'invalid' : null"></b-input>
        </b-form-group>
      </div>

      <b-form-row>
        <b-form-group label class="col-sm-12">
          <template slot="label">Assigned Users</template>
          <multiselect
            v-model="task.assigned_user"
            id="assigned_user_ajax"
            :custom-label="userLabel"
            track-by="id"
            placeholder="Type to search"
            open-direction="bottom"
            :options="assignedUserOptions"
            :multiple="true"
            :searchable="true"
            :loading="assignedUserLoading"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="true"
            @select="selectAssignedUser"
            @search-change="findAssignedUser"
          >
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="badge badge-primary mr-2">
                {{ option.first_name }} {{ option.last_name }} ({{ option.email }})
                <a
                  style="color: white;"
                  href="javascript:void(0)"
                  @click="remove(option)"
                >
                  <i class="fa fa-times ml-2"></i>
                </a>
              </span>
            </template>
            <template slot="clear" slot-scope="props">
              <div
                class="multiselect__clear"
                v-if="task.assigned_user.length"
                @mousedown.prevent.stop="clearAll(props.search)"
              ></div>
            </template>
            <span slot="noResult">User not found.</span>
          </multiselect>
        </b-form-group>
      </b-form-row>

      <div class="row">
        <b-form-group
          label="Due Date"
          class="col-lg-6"
          :invalid-feedback="getErrorLabel($v.task.due_date)"
        >
          <div class="input-group">
            <flat-pickr
              v-model="task.due_date"
              :config="config"
              class="form-control"
              :class="{ 'is-invalid' : $v.task.due_date.$error }"
              placeholder="Select date"
              name="due_date"
            ></flat-pickr>
            <div class="input-group-btn">
              <button class="btn btn-default" type="button" title="Toggle" data-toggle>
                <i class="fa fa-calendar">
                  <span aria-hidden="true" class="sr-only">Toggle</span>
                </i>
              </button>
            </div>
          </div>
        </b-form-group>
        <b-form-group label="Reminder" class="col-lg-6">
          <b-form-select v-model="task.reminder" :options="reminder_options"></b-form-select>
        </b-form-group>
      </div>
      <hr class="border-light mt-3" />

      <div class="row">
        <b-form-group label="Associate Type" class="col-lg-12">
          <b-form-select
            v-model="task.selected_associate_type"
            :options="associate_type_options"
            :disabled="this.associate_type !== null && this.scenario !== 'booking-todo'"
          ></b-form-select>
        </b-form-group>
      </div>

      <div class="row" v-if="task.selected_associate_type != 'General'">
        <b-form-group
          label
          class="col-lg-12"
          :invalid-feedback="getErrorLabel($v.task.selected_associate_id)"
        >
          <multiselect
            v-model="task.selected_associate_id"
            :options="associate_id_options"
            :multiple="false"
            :disabled="this.associate_id !== null && this.scenario !== 'booking-todo'"
            :class="{ 'is-invalid' : $v.task.selected_associate_id.$error }"
            placeholder="Type to search"
            track-by="id"
            label="text"
          />
          <div
            class="text-danger"
            v-if="$v.task.selected_associate_id.$error"
          >{{ getErrorLabel($v.task.selected_associate_id) }}</div>
        </b-form-group>
      </div>

      <hr class="border-light mt-3" />

      <div class="row">
        <b-form-group label="Status" class="col-lg-12">
          <label class="switcher switcher-primary">
            <input type="checkbox" class="switcher-input" v-model="task.completed" />
            <span class="switcher-indicator">
              <span class="switcher-yes text-primary"></span>
              <span class="switcher-no"></span>
            </span>
            <span class="switcher-label" v-if="!task.completed">Incomplete</span>
            <span class="switcher-label" v-if="task.completed">Completed</span>
          </label>
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group label="Details" class="col-lg-12">
          <b-form-textarea v-model="task.details" placeholder="Enter details..." rows="3" />
        </b-form-group>
      </div>
      <template slot="modal-footer">
        <b-button :disabled="loading" variant="default" @click="cancel()">Cancel</b-button>
        <b-button :disabled="loading" variant="primary" @click="save()">Save</b-button>
      </template>
    </b-modal>
  </div>
</template>


<script>
import Vue from "vue";
import Vuelidate from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import moment from "moment";

Vue.use(Vuelidate);

export default {
  props: {
    associate_type: {
      Type: String,
      default: null,
    },
    associate_id: {
      Type: Number,
      default: null,
    },
    scenario: {
      Type: String,
      default: null
    },
    booking_id: {
      Type: Number,
      default: null
    }
  },
  components: {
    Multiselect,
  },
  watch: {
    "task.selected_associate_type"(new_val, old_val) {
      let self = this;
      if (new_val == "Lead" && this.show_modal) {
        window.axios.get(`/api/leads/option-list`).then((response) => {
          self.associate_id_options = [];
          Object.keys(response.data).forEach((key) => {
            let label = '';
            if(response.data[key].type == 'music' && response.data[key].band){
              label = response.data[key].band.name;
            }
            else if(response.data[key].type != 'music' && response.data[key].company) {
              label = response.data[key].company.name;
            }

            self.associate_id_options.push({
              id: response.data[key].id,
              text: `${response.data[key].primary_contact.first_name} ${response.data[key].primary_contact.last_name} (${label})`,
            });
          });

          //Preselect associate_id if associate_id props detected

          if (self.associate_id !== null) {
            self.task.selected_associate_id = self.associate_id_options.filter(
              (item) => item.id == self.associate_id
            )[0];
          }
        });
      }

      if (new_val == "Contact" && this.show_modal) {

        const queryParams = {
          'fields' : 'id,first_name,last_name',
        }

        const queryString = Object.keys(queryParams).map(function(key) {
          return key + '=' + queryParams[key]
        }).join('&');

        window.axios.get(`/api/v2/contacts/options?${queryString}`).then((response) => {
          self.associate_id_options = [];
          
          Object.keys(response.data).forEach((key) => {
            self.associate_id_options.push({
              id: response.data[key].id,
              text: `${response.data[key].first_name} ${response.data[key].last_name}`,
            });
          });

          //Preselect associate_id if associate_id props detected

          if (self.associate_id !== null) {
            self.task.selected_associate_id = self.associate_id_options.filter(
              (item) => item.id == self.associate_id
            )[0];
          }
        });
      }

      if (new_val == "Company" && this.show_modal) {
        window.axios.get(`/api/companies/option-list`).then((response) => {
          self.associate_id_options = [];
          Object.keys(response.data).forEach((key) => {
            self.associate_id_options.push({
              id: response.data[key].id,
              text: response.data[key].name,
            });
          });

          //Preselect associate_id if associate_id props detected

          if (self.associate_id !== null) {
            self.task.selected_associate_id = self.associate_id_options.filter(
              (item) => item.id == self.associate_id
            )[0];
          }
        });
      }

      if (new_val == "Band/Group" && this.show_modal) {

        const queryParams = {
          'fields' : 'id,name',
        }
        
        const queryString = Object.keys(queryParams).map(function(key) {
          return key + '=' + queryParams[key]
        }).join('&');

        window.axios.get(`/api/v2/bands/options?${queryString}`).then((response) => {
          self.associate_id_options = [];

          Object.keys(response.data).forEach((key) => {
            self.associate_id_options.push({
              id: response.data[key].id,
              text: response.data[key].name,
            });
          });

          //preselect associate_id if associate_id props detected
          if (self.associate_id !== null) {
            self.task.selected_associate_id = self.associate_id_options.filter(
              (item) => item.id == self.associate_id
            )[0];
          }
        });
      }

      if (new_val == "Project" && this.show_modal) {
        window.axios.get(`/api/projects/options`).then((response) => {
          self.associate_id_options = [];
          Object.keys(response.data).forEach((key) => {
            self.associate_id_options.push({
              id: response.data[key].id,
              text: response.data[key].name,
            });
          });

          //preselect associate_id if associate_id props detected
          if (self.associate_id !== null) {
            self.task.selected_associate_id = self.associate_id_options.filter(
              (item) => item.id == self.associate_id
            )[0];
          }
        });
      }
    },
  },
  data() {
    return {
      assignedUserOptions: [],
      assignedUserLoading: false,
      show_modal: false,
      task: {
        title: null,
        assigned_user: [],
        due_date: moment()
          .add(30 - (moment().minute() % 30), "minutes")
          .toDate(),
        reminder: "No Reminder",
        selected_associate_type: "General",
        selected_associate_id: null,
        completed: false,
        details: null,
      },
      errorLabels: {
        required: "This field is required.",
      },
      assigned_user_options: [],
      reminder_options: [
        { text: "No Reminder", value: "No Reminder" },
        { text: "At Time of To Do", value: "At Time of To Do" },
        { text: "5 Minutes Before", value: "5 Minutes Before" },
        { text: "10 Minutes Before", value: "10 Minutes Before" },
        { text: "15 Minutes Before", value: "15 Minutes Before" },
        { text: "30 Minutes Before", value: "30 Minutes Before" },
        { text: "1 Hour Before", value: "1 Hour Before" },
        { text: "2 Hours Before", value: "2 Hours Before" },
        { text: "1 Day Before", value: "1 Day Before" },
      ],
      associate_type_options: [
        { text: "General", value: "General" },
        { text: "Lead", value: "Lead" },
        { text: "Contact", value: "Contact" },
        { text: "Company", value: "Company" },
        { text: "Artist/Group", value: "Band/Group" },
        { text: "Project", value: "Project" },
      ],
      associate_id_options: [],
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: window.toFlatPickrDatetime(localStorage.getItem('php_datetime_format')),
        altInput: true,
        dateFormat: window.dateTimePickerSaveFormat,
        enableTime: true,
        static: false,
        minuteIncrement: 15,
        //locale: Hindi, // locale for this instance only
      },
      loading: false,
    };
  },
  created() {
    
  },
  validations: {
    task: {
      title: {
        required,
      },
      due_date: {
        required,
      },
      selected_associate_id: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.selected_associate_type != "General";
        }),
      },
    },
    form: ["task"],
  },
  methods: {
    userLabel(data) {
      return `${data.first_name} ${data.last_name} (${data.email})`;
    },
    limitText(count) {
      return `and ${count} other users`;
    },
    selectAssignedUser() {},
    findAssignedUser: _.debounce(function (query) {
      let self = this;
      self.assignedUserLoading = true;
      window.axios
        .get("/api/users/get-all", {
          search_string: query,
        })
        .then((response) => {
          self.assignedUserOptions = response.data;
          self.assignedUserLoading = false;
        });
    }, 500),
    loadData() {
      if (this.associate_type !== null) {
        this.task.selected_associate_type = this.associate_type;
      }
    },
    getErrorLabel(validation) {
      return Object.keys(validation)
        .filter((key) => !key.startsWith("$"))
        .reduce((prev, curr) => {
          if (!validation[curr]) {
            prev = this.errorLabels[curr];
          }

          return prev;
        }, "");
    },

    async save(){

      this.$v.form.$touch();
      const isValid = !this.$v.form.$invalid;
      if(isValid){

        let postedTask = { ...this.task };

        if (postedTask.selected_associate_id) {
          postedTask.selected_associate_id = this.task.selected_associate_id.id;
        }

        postedTask.due_date = this.$moment(postedTask.due_date)
          .utc()
          .format(window.apiDateTimeFormat);

        //todo form called from create booking 
        if(this.scenario == 'booking-todo' && this.booking_id == null){

          this.$emit('on-save',  postedTask);
          this.hide();
          this.task = {
            title: null,
            assigned_user: [],
            due_date: Date.now(),
            reminder: "No Reminder",
            selected_associate_type: "General",
            selected_associate_id: null,
            completed: false,
            details: null,
            todos: []
          };
          this.$v.$reset();

        }

        //todo form called from edit booking, create directly
        else if(this.scenario == 'booking-todo' && this.booking_id) {

          try {

            this.loading = true;

            postedTask.booking_id = this.booking_id;
            
            const response = await window.axios.post("/api/tasks", postedTask);

            this.loading = false;
            this.hide();

            this.task = {
              title: null,
              assigned_user: [],
              due_date: Date.now(),
              reminder: "No Reminder",
              selected_associate_type: "General",
              selected_associate_id: null,
              completed: false,
              details: null,
              todos: []
            };
            this.$v.$reset();

            let todo = response.data;
            console.log('todo response e');
            console.log(response.data);

            todo.assigned_user = todo.members.map((member) => {
              return member.user;
            });

            console.log('added todo');
            console.log(todo);

            this.$emit('on-save',  todo);

            
          } catch (error) {
            console.log(error); 
          }

        }

        //normal create task
        else {

          this.loading = true;

          try {

            await window.axios.post("/api/tasks", postedTask);

            this.loading = false;
            this.task = {
              title: null,
              assigned_user: [],
              due_date: Date.now(),
              reminder: "No Reminder",
              selected_associate_type: "General",
              selected_associate_id: null,
              completed: false,
              details: null,
              booking_id: null,
              todos: []
            };

            this.$v.$reset();

            this.$emit("on-validate", this.$data, isValid);
            this.hide();
            this.$snotify.success("New To Do Created", "Success!");
            
          } catch (error) {
            console.log(error);
          }

        }

      }
    },

    show() {
      let self = this;
      window.axios.get("/api/users/get-all").then((response) => {
        self.assignedUserOptions = response.data;
      });
      if (this.associate_type !== null) {
        this.task.selected_associate_type = this.associate_type;
      }

      this.show_modal = true;
    },
    hide() {
      this.show_modal = false;
    },
    cancel() {
      this.hide();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />
