import Layout1 from '@/layout/Layout1'

export default [{
  path: '/reports/',
  component: Layout1,
  children: [{
    name: 'revenue',
    path: 'revenue',
    component: () => import('@/components/reports/RevenueReport')
  },{
    name: 'revenue-monthly',
    path: 'revenue-monthly',
    component: () => import('@/components/reports/RevenueMonthlyReport')
  },{
    name: 'open-invoices',
    path: 'open-invoices',
    component: () => import('@/components/reports/OpenInvoicesReport')
  },{
    name: 'past-due-invoices',
    path: 'past-due-invoices',
    component: () => import('@/components/reports/PastDueInvoicesReport')
  },{
    name: 'booking',
    path: 'booking',
    component: () => import('@/components/reports/BookingReport')
  },
  {
    name: 'session-report',
    path: 'session-report',
    component: () => import('@/components/reports/SessionReport')
  },
  {
    name: 'room-rates-and-labor',
    path: 'room-rates-and-labor',
    component: () => import('@/components/reports/RoomRatesLaborReport')
  },
  {
    name: 'equipment-tracking',
    path: 'equipment-tracking',
    component: () => import('@/components/reports/EquipmentTrackingReport')
  },
  {
    name: 'referrals',
    path: 'referrals',
    component: () => import('@/components/reports/ReferralReport')
  },{
    name: 'closed-lost-leads',
    path: 'closed-lost-leads',
    component: () => import('@/components/reports/ClosedLostReport')
  },{
    name: 'active-leads',
    path: 'active-leads',
    component: () => import('@/components/reports/ActiveLeadPipelineReport')
  },{
    name: 'converted-leads',
    path: 'converted-leads',
    component: () => import('@/components/reports/ConvertedLeadsReport')
  },{
    name: 'event-roi',
    path: 'event-roi',
    component: () => import('@/components/reports/EventRoiReport')
  },{
    name: 'time-tracking',
    path: 'time-tracking',
    component: () => import('@/components/reports/TrackerReport')
  },{
    name: 'room-utilization',
    path: 'room-utilization',
    component: () => import('@/components/reports/RoomUtilizationReport')
  },{
    name: 'daily-room-utilization',
    path: 'daily-room-utilization',
    component: () => import('@/components/reports/DailyRoomUtilizationReport')
  }]
}]
