import Layout1 from '@/layout/Layout1'

export default [{
  path: '/settings/picklists',
  component: Layout1,
  children: [{
    name: 'picklists-list',
    path: '',
    component: () => import('@/components/picklists/PicklistList')
  }]
}]
