<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      id="manage-booking-equipment"
      size="lg"
      no-enforce-focus
      :title="formTitle"
      v-model="show_modal"
      @shown="focusMyElement"
      @show="onShow()"
      ref="modal"
    >

      <b-form-row>
        
      </b-form-row>

      <qrcode-stream @decode="onDecode" v-if="showQrScanner"></qrcode-stream>
      
      <div class="text-center">
        <b-btn id="button-addon2" variant="primary" @click="openQrScanner()"> <i class="fas fa-camera"></i> Scan Equipment</b-btn>
      </div>

      <hr class="border-light mt-3 mb-3">

      <div class="d-flex flex-row justify-content-center mt-3">
        <div class="">
          <b-form-select
            v-model="selected_category_id"
            :options="category_options"
            @change="onCategoryChange()"
          >
            <template slot="first">
              <option :value="null">All</option>
            </template>
          </b-form-select>
        </div>
        <div class="ml-2">
          <b-form-select
            v-model="selected_sub_category_id"
            :options="sub_category_options"
            :disabled="(selected_category_id == null) ? true : false"
            @change="onSubcategoryChange()"
          >
          <template slot="first">
              <option :value="null">All</option>
            </template>
          </b-form-select>
        </div>
      </div>

      <div>
        <multiselect
            v-model="equipment"
            :options="equipmentOptions"
            :multiple="false"
            placeholder="Type to search"
            track-by="id"
            label="name"
            :max-height="600"
            :show-no-results="true"
            :hide-selected="true"
            :taggable="false"
            tag-placeholder="Check in this equipment"
            class="mt-2"
            @input="addEquipment"
            :custom-label="customLabel"
            noResult="No result found"
          >
          <template slot="option" slot-scope="props">
            <span :class="props.option.status != 'Available' ? 'text-muted' : ''">{{ props.option.name }}</span>
          </template>
          </multiselect>
      </div>


      <table class="table">
        <tr>
          <th></th>
          <th>NAME</th>
          <th>SERIAL #</th>
        </tr>
        <tr v-for="trackingEquipment in equipmentList" :key="trackingEquipment.id">
          <td v-if="trackingEquipment.action == 'Check Out'">
            <b-btn size="xs" variant="danger" @click="deleteEquipment(trackingEquipment.id)"><i class="fa fa-trash" v-b-tooltip.hover title="Delete Equipment from Session" /></b-btn>
            <b-btn size="xs" variant="success" @click="checkoutEquipment(trackingEquipment.id)"><i class="fa fa-check" v-b-tooltip.hover title="Check In Equipment" /></b-btn>
          </td>
          <td v-if="trackingEquipment.action == 'Check In'">
            <b-badge variant="success">Checked In</b-badge>      
          </td>
          <td>{{ trackingEquipment.equipment.name }}</td>
          <td>{{ trackingEquipment.equipment.serial_number }}</td>
        </tr>
      </table>

          
      <template slot="modal-footer">
        <b-button variant="default" @click="cancel()"> Close </b-button>
      </template>
    </b-modal>
  </div>
</template>


<!-- Page -->
<style src="@/vendor/styles/pages/users.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-form-wizard/vue-form-wizard.scss" lang="scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Vue from "vue";
import Toasted from "vue-toasted";
import Vuelidate from "vuelidate";
import { required, helpers } from "vuelidate/lib/validators";
import VueNumerals from "vue-numerals";
import Swatches from "vue-swatches";

import "vue-swatches/dist/vue-swatches.min.css";
//import and use multiselect
import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from "vue-multiselect";
import { QrcodeStream } from 'vue-qrcode-reader'

Vue.use(VueNumerals);
Vue.use(Vuelidate);
Vue.use(Toasted);

export default {
  props: {
    editId: {
      type: Number,
      default: null,
    },
  },
  components: {
    Swatches,
    Multiselect,
    QrcodeStream
  },
  computed: {
    formTitle() {
      return "Add/Remove Equipment";
    },
  },
  created() {
    console.log(this.editId)
    let self = this
    window.axios.get('/api/equipment-categories/category-select-options').then(response => {
      this.category_options = response.data
    })

    this.loadEquipments();
  },
  data: () => ({
    equipment: null,
    equipmentList: [],
    equipmentOptions: [],
    loading_subcategory: false,
    selected_category_id: null,
    selected_sub_category_id: null,
    category_options: [],
    sub_category_options: [],
    showQrScanner: false,
    qrcode: null,
    loading: false,
    show_modal: false,
    errors: [],
    errorLabels: {
      required: "This field is required.",
      decimal: "Only numeric are allowed",
      url: "Invalid url format",
    },
  }),
  methods: {   
    customLabel (equipment) {
      return `${equipment.name}`
    },
    deleteEquipment(trackingId){
      window.axios.delete(`/api/equipments/booking-equipments/${trackingId}`).then(response => {
        this.loadEquipmentList()
        this.$snotify.success("Equipment Deleted", "Success");
        this.$emit('on-save')
      })
    },
    
    loadEquipments(){
      window.axios.get('/api/equipments/options', {
        params: {
          category_id: this.selected_category_id,
          sub_category_id: this.selected_sub_category_id
        }
      }).then(response => {
        this.equipmentOptions = response.data
      })
    },
    onCategoryChange(){
      let self = this;        
        //if (this.editId == null && this.duplicateId == null) {
          this.selected_sub_category_id = null
        //}
          this.loading_subcategory = true;
          window.axios
            .get(`/api/equipment-categories/subcategory-select-options/${this.selected_category_id}`)
            .then((response) => {
              self.sub_category_options = response.data;   
              self.loading_subcategory = false;
            });
            this.loadEquipments();
    },
    onSubcategoryChange(){
      this.loadEquipments();
    },
    openQrScanner(){
      this.showQrScanner = true
    },
    onDecode(decodedString) {
      this.showQrScanner = false

      let equipment = this.equipmentOptions.filter( equipment => { return equipment.qrcode === decodedString })


      if(equipment[0].status == 'In Use'){
          this.$swal({
          // title: `Are you sure you wan to override checkout for equipment ${equipment[0].name}?`, 
          title: 'This equipment is already checked out to another session or location. Are you sure you want to check this equipment into the new session or location (it will automatically be closed from the previous session or location)?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          //confirmButtonColor: '#3085d6',
          //cancelButtonColor: '#d33',
          confirmButtonText: 'Continue'
        }).then((result) => {
          if (result.value) {
            window.axios.post(`/api/equipments/checked-in-by-id`, {
              booking_id: this.editId,
              equipment_id: equipment[0].id,
              override: true
            }).then((response) =>{
              this.loadEquipmentList()
              this.clearForm()
              this.$snotify.success("Equipment Check Out Overrided", "Success");
              this.$emit('on-save')
            }).error((error) => {
              this.$snotify.error('There was a problem overriding equipment check out.','Error!')
            })
          }          
        })

        return
      }

      window.axios.post('/api/equipments/checked-in-by-qrcode', {
        booking_id: this.editId,
        equipment_qrcode: decodedString
      }).then(response => {
        this.loadEquipmentList()
        this.clearForm()
        this.$snotify.success("Equipment Checked Out", "Success");
        this.$emit('on-save')
      })
    },
    getErrorLabel(validation) {
      return Object.keys(validation)
        .filter((key) => !key.startsWith("$"))
        .reduce((prev, curr) => {
          if (!validation[curr]) {
            prev = this.errorLabels[curr];
          }

          return prev;
        }, "");
    },
    addEquipment(value, id) {
      if(value.status == 'In Use'){
          this.$swal({
          //title: `Are you sure you wan to override check out for equipment ${value.name}?`,
          title: 'This equipment is already checked out to another session or location. Are you sure you want to check this equipment into the new session or location (it will automatically be closed from the previous session or location)?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          //confirmButtonColor: '#3085d6',
          //cancelButtonColor: '#d33',
          confirmButtonText: 'Continue'
        }).then((result) => {
          if (result.value) {
            window.axios.post(`/api/equipments/checked-in-by-id`, {
              booking_id: this.editId,
              equipment_id: value.id,
              override: true
            }).then((response) =>{
              this.loadEquipmentList()
              this.$snotify.success("Equipment Check Out Overrided", "Success");
              this.clearForm()
              this.$emit('on-save')
            }).error((error) => {
              self.$snotify.error('There was a problem overriding equipment check out.','Error!')
            })
          }          
        })

        return
      }

      window.axios.post('/api/equipments/checked-in-by-id', {
        booking_id: this.editId,
        equipment_id: value.id
      }).then(response => {
        this.loadEquipmentList()
        this.clearForm()
        this.$snotify.success("Equipment Checked Out", "Success");
        this.$emit('on-save')
      })
    },
    checkoutEquipment(trackingId) {
      window.axios.post('/api/equipments/checked-out-by-tracking-id', {
        tracking_id: trackingId,
      }).then(response => {
        this.loadEquipmentList()
        this.$snotify.success("Equipment Checked In", "Success");
        this.$emit('on-save')
      })
    },
    clearForm() {
      this.equipment = null
      this.selected_category_id = null
      this.selected_sub_category_id = null
      this.$v.$reset();
    },
    show() {
      this.show_modal = true;
    },
    hide() {
      this.show_modal = false;
    },
    cancel() {
      this.hide();
    },
    focusMyElement(e) {
      //this.$refs.name.focus();
    },
    loadEquipmentList(){
      window.axios.get(`/api/equipments/booking-equipments/${this.editId}`).then(response => {
          this.equipmentList = response.data
        })   
    },
    async onShow() {
      this.loadEquipmentList()
     
    },
  },
};
</script>
