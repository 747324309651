<template>
  <div>
    <contact-form
      ref="contactFormModal"
      :create-source="'lead-form'"
      @on-save="contactAdded"
    />
    <band-form ref="bandGroupModal" @on-save="bandGroupAdded" :contact-id="data.contact_id ? data.contact_id : null" />
    <company-form ref="companyFormModal" @on-save="companyAdded" :contact-id="data.contact_id ? data.contact_id : null" />

    <b-modal
      :no-close-on-backdrop="true"
      id="create-new-lead"
      size="lg"
      no-enforce-focus
      :title="formTitle"
      v-model="showModal"
      @shown="focusMyElement"
      @show="onShow()"
      @hide="cancel"
      ref="modal"
      hide-footer
    >
      <!-- <h4 class="text-muted pt-0 hide">
        <span v-if="editId == null">Create New</span>
        <span v-else>Edit</span>
        Lead
      </h4> -->

      <form-wizard v-if="showModal" @on-complete="save()" ref="formWizard">
        <!-- Allow html in tab title (this template required for the proper styling) -->
        <template slot="step" slot-scope="props">
          <wizard-step
            :tab="props.tab"
            @click.native="props.navigateToTab(props.index)"
            @keyup.enter.native="props.navigateToTab(props.index)"
            :transition="props.transition"
            :index="props.index"
          >
            <span
              slot="title"
              :class="{ 'text-danger': props.tab.validationError }"
              v-html="props.tab.title"
            ></span>
          </wizard-step>
        </template>
        <!-- / -->

        <tab-content
          title="Lead Details"
          :before-change="() => validateStep('step-one')"
        >
          <b-card class="mb-3">
            <lead-form-one
              :edit_id="editId"
              ref="step-one"
              @on-validate="mergePartialModels"
              :lead_data="data"
              @show-contact-form="showContactForm"
            />
          </b-card>
        </tab-content>

        <tab-content
          title="Project Details"
          :before-change="() => validateStep('step-two')"
        >
          <b-card class="mb-3">
            <lead-form-two
              :edit_id="editId"
              ref="step-two"
              @on-validate="mergePartialModels"
              @show-company-form="showCompanyForm"
              @show-band-group-form="showBandGroupForm"
              :lead_data="data"
            />
          </b-card>
        </tab-content>

        <!-- Buttons -->

        <b-btn :disabled="loading" variant="primary" slot="prev">Back</b-btn>
        <b-btn :disabled="loading" variant="primary" slot="next">Next</b-btn>
        <b-btn :disabled="loading" variant="primary" slot="finish">Save</b-btn>
        <template slot="custom-buttons-left" slot-scope="props">
          <b-btn :disabled="loading" @click="cancel" class="green-btn"
            >Cancel</b-btn
          >
        </template>
      </form-wizard>
    </b-modal>
  </div>
</template>

<style src="@/vendor/libs/vue-quill-editor/typography.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-quill-editor/editor.scss" lang="scss"></style>

<!-- Page -->
<style src="@/vendor/styles/pages/users.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-form-wizard/vue-form-wizard.scss" lang="scss"></style>

<script>
import { FormWizard, TabContent, WizardStep } from "vue-form-wizard";

import ContactForm from "@/components/contacts/ContactForm";
import BandForm from "@/components/bands/BandForm";
import CompanyForm from "@/components/companies/CompanyForm";

import LeadFormOne from "@/components/leads/LeadFormOne";
import LeadFormTwo from "@/components/leads/LeadFormTwo";

const init_data = {
  contact_id: "",
  email: "",
  first_name: "",
  last_name: "",
  contact_type: null,
  referral_source: null,
  gender: "Male",
  phone_number: "",
  primary_instruments: "",
  lead_stage: "New",
  stage_id: "",
  studio_experience: "None",
  project_revenue_estimate: "",
  contact_notes: "",
  project_notes: "",
  band_id: null,
  type: "music",
  company_id: null,
  band_genre: null,
  band_members: [],
  social_medias: [],
  music_medias: [],
  event: null,
  custom_fields: {},
  copy_contact_info: false
};
export default {
  props: {
    editId: {
      type: Number,
      default: null,
    },
  },
  components: {
    FormWizard,
    TabContent,
    WizardStep,
    LeadFormOne,
    LeadFormTwo,
    ContactForm,
    CompanyForm,
    BandForm,
  },
  computed: {
    formTitle() {
      if (this.editId !== null) {
        return `Edit Lead ${this.data.contact_id.full_name}`;
      } else {
        return "Create New Lead";
      }
    },
  },
  data: () => ({
    breadcrumbItems: [
      {
        text: "Leads",
        to: { name: "leads-list" },
      },
      {
        text: "Create New",
        active: true,
      },
    ],
    step: 0,
    loading: false,
    showModal: false,
    errors: [],
    band_member_error: false,
    social_media_error: false,
    social_medias: [],
    music_media_error: false,
    music_medias: [],
    genre_options: [],
    data: _.cloneDeep(init_data),
  }),
  methods: {
    focusMyElement(e) {
      //this.$refs.first_name.focus()
    },

    showContactForm() {
      this.$refs["contactFormModal"].show();
    },
    contactAdded(contact) {
      this.$refs["step-one"].contactAdded(contact);
    },

    showCompanyForm() {
      this.$refs["companyFormModal"].show();
    },
    companyAdded(company) {
      this.$refs["step-two"].companyAdded(company);
    },

    showBandGroupForm() {
      console.log('contact when trigger lead band-group form');
      console.log(this.data.contact_id);
      this.$refs["bandGroupModal"].show();
    },
    bandGroupAdded(company) {
      this.$refs["step-two"].bandGroupAdded(company);
    },

    async loadGenreOptions() {
      const genreOptionResponse = await window.axios.get(
        "/api/picklists/Genres"
      );
      this.genre_options = genreOptionResponse.data.data.options.map(
        (option) => {
          return {
            text: option.name,
            value: option.id,
          };
        }
      );
      this.data.band_genre = this.genre_options[0].value;
    },

    async onShow() {
      this.loadGenreOptions();

      if (this.editId !== null) {
        try {
          const response = await window.axios.get(`/api/leads/${this.editId}`);
          const lead = response.data;
          console.log("lead data");
          console.log(lead);

          this.data = Object.assign(
            {},
            {
              custom_fields: lead.custom_fields,
              contact_id: lead.primary_contact,
              referral_source: lead.referral_source_id,
              event: lead.event,
              gender: lead.primary_contact.gender,
              lead_stage: lead.stage,
              stage_id: lead.stage_id,
              studio_experience: lead.band ? lead.band.studio_experience : null,
              project_revenue_estimate: lead.project_revenue_estimate,
              contact_notes: lead.primary_contact.notes,
              project_notes: lead.project_notes,
              target_completion_date: lead.target_completion_date,
              target_start_date: lead.target_start_date,
              number_of_songs: lead.number_of_songs,
              band_id: lead.band,
              band_group_name: lead.band ? lead.band.name : null,
              band_genre: lead.band ? lead.band.genre_id : null,
              band_members: lead.band
                ? lead.band.members.map((item) => {
                    let member = item;
                    member.instruments = item.primary_instruments;
                    return member;
                  })
                : null,
              band_group_website: lead.band ? lead.band.website : null,
              music_influences: lead.band ? lead.band.music_influences : null,
              music_sounds_like: lead.band ? lead.band.music_sounds_like : null,
              favorite_snacks_drinks: lead.band
                ? lead.band.favorite_foods_and_drinks
                : null,
              social_medias: lead.band
                ? lead.band.social_medias.map((item) => {
                    return {
                      site: item.id,
                      url: item.pivot.url,
                    };
                  })
                : null,

              company_id: lead.company,
              number_of_episodes: lead.number_of_episodes
                ? lead.number_of_episodes
                : null,
              genre_id: lead.genre_id,
            }
          );

          console.log(this.data);
        } catch (error) {
          console.log(error);
        }
      } else {
        this.data = _.cloneDeep(init_data);
        this.data.country = "United States";
      }
    },

    validateStep(name) {
      var refToValidate = this.$refs[name];
      return refToValidate.validate();
    },

    mergePartialModels(model, isValid) {
      if (isValid) {
        // merging each step model into the final model
        this.data = Object.assign({}, this.data, model.data);
      }
    },

    async save(event, createAnother = false) {
      this.loading = true;
      // let self = this;

      if (this.editId === null) {
        try {

          const postedData = {...this.data};

          if (postedData.event !== null) {
            postedData.event = postedData.event.id;
          }

          if (postedData.contact_id !== null) {
            postedData.contact_id = postedData.contact_id.id;
          }

          if(postedData.band_id !== null){
            postedData.band_id = postedData.band_id.id;
          }

          if(postedData.company_id !== null){
            postedData.company_id = postedData.company_id.id;
          }

          const response = await window.axios.post("/api/leads", postedData);
          this.loading = false;
          this.clearForm();
          this.hide();
          this.$snotify.success("New Lead Created.", "Success!");
          this.$emit("on-save", response.data, true);
        } catch (error) {
          this.loading = false;
          this.errors = error.response.data.errors;
          this.$snotify.error("There was a problem creating lead.", "Error!");

          console.log(error);
        }
      } else {
        try {
          const postedData = {...this.data}

          if (postedData.event !== null) {
            postedData.event = postedData.event.id;
          }

          if (postedData.contact_id !== null) {
            postedData.contact_id = postedData.contact_id.id;
          }

           if(postedData.band_id !== null){
            postedData.band_id = postedData.band_id.id;
          }

          if(postedData.company_id !== null){
            postedData.company_id = postedData.company_id.id;
          }

          const response = await window.axios.put(
            `/api/leads/${this.editId}`,
            postedData
          );
          this.loading = false;
          this.clearForm();
          this.hide();
          this.$snotify.success("Lead Successfully Edited.", "Success!");
          this.$emit("on-save", response.data);
        } catch (error) {
          this.loading = false;
          this.errors = error.response.data.errors;
          this.$snotify.error("There was a problem saving lead.", "Error!");
          console.log(error);
        }
      }
    },
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    cancel() {
      this.$refs.formWizard.reset();
      this.hide();
    },
    clearForm() {
      this.data = Object.assign({}, init_data);
      this.step = 0;
      this.errors = [];
    },
  },
};
</script>
