import Layout1 from '@/layout/Layout1'


export default [{
    path: '/tools/imports/',
    component: Layout1,
    children: [{
        name: 'import',
        path: '',
        component: () => import('@/components/imports/DataImport')
    }]
}]