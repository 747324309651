<template>
    <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">
        <!-- Brand -->
        <b-navbar-brand to="/"><img :src="logo" height="40"></b-navbar-brand>

        <!-- Sidenav toggle -->
        <b-navbar-nav class="align-items-lg-center mr-auto mr-lg-4" v-if="sidenavToggle">
            <a class="nav-item nav-link px-0 ml-2 ml-lg-0" href="javascript:void(0)" @click="toggleSidenav">
                <i class="ion ion-md-menu text-large align-middle"/>
            </a>
        </b-navbar-nav>

        <!-- Navbar toggle -->
        <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

        <b-collapse is-nav id="app-layout-navbar">
            <!-- Divider -->
            <hr class="d-lg-none w-100 my-2">

            <!--<b-navbar-nav class="align-items-lg-center">
                &lt;!&ndash; Search &ndash;&gt;
                <label class="nav-item navbar-text navbar-search-box p-0 active">
                    <i class="ion ion-ios-search navbar-icon align-middle"></i>
                    <span class="navbar-search-input pl-2">
            <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="width:200px">
          </span>
                </label>
            </b-navbar-nav>-->

        </b-collapse>

        <!-- Notification Pop Up -->
        <b-modal
            centered
            id="notification-modal"
            ref="notification-modal"
            header-text-variant="dark"
            body-text-variant="dark"
            :title="notif.title"
            hide-footer
            >
            <div v-html="notif.description" class="text-dark"></div>
        </b-modal>
    </b-navbar>
</template>

<script>

    import moment from 'moment';

    export default {
        name: 'app-layout-navbar',

        props: {
            sidenavToggle: {
                type: Boolean,
                default: true
            }
        },

        data: function () {
            return {
                notifications: [],
                notif: {
                    id: null,
                    title: null,
                    description: null,
                    createdAt: null,
                },
                unreadNotification: 0
            }
        },

        created() {
        },

        computed: {
            logo: function () {
                let logo = localStorage.getItem('rp_logo_path')
                if(logo.includes('http')){
                    return logo;
                }
                else {
                    return '/media/'+logo
                }
            }
        },


        filters: {
            moment: function (mydate) {
                var date = new Date(mydate + ' UTC');
                return moment(date.toString()).fromNow();
            }
        },
        methods: {
            secondsToHms(d) {
                d = Number(d);
                var h = Math.floor(d / 3600);
                var m = Math.floor(d % 3600 / 60);
                var s = Math.floor(d % 3600 % 60);

                var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
                var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
                var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
                return hDisplay + mDisplay + sDisplay;
            },
            moment: function (mydate) {
                return moment(mydate);
            },

            toggleSidenav() {
                this.layoutHelpers.toggleCollapsed()
            },

            getLayoutNavbarBg() {
                return this.layoutNavbarBg
            },


            showMessage(n){
                let self = this

                this.notif.id = n.id;
                this.notif.title = n.data.title;
                this.notif.description = n.data.description;
                this.notif.createdAt = n.created_at;
                this.$refs['notification-modal'].show();

                axios.get('/api/notifications/' + this.notif.id).
                then(response => {
                    self.unreadNotification -= 1

                    let i = self.notifications.map(notification => notification.id).indexOf(n.id)

                    this.$delete(self.notifications, i)
                }).
                catch(error => {
                    console.log(error)
                });
            },

            getLatestNotification() {
                let self = this
                axios.get('/api/notifications/take/5').then(response => {
                    self.notifications = response.data
                }).catch(error => {
                    console.log(error)
                });
            },

            markAllRead() {
                this.unreadNotification = 0;
                this.notifications = [];
                this.$router.push({name: 'notifications'});
            }
        }
    }
</script>

<style>
.item {
    position:relative;
    text-align:center;
    vertical-align:middle;
}
.notify-badge{
    position: absolute;
    right:-20%;
    top:-1%;
    background:red;
    text-align: center;
    border-radius: 50%;
    color:white;
    padding:0px 6px;
    border:1px solid;
    font-size: 12px;
}
</style>
