import { render, staticRenderFns } from "./ProjectSongForm.vue?vue&type=template&id=47797142&scoped=true&"
import script from "./ProjectSongForm.vue?vue&type=script&lang=js&"
export * from "./ProjectSongForm.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&lang=css&"
import style1 from "./ProjectSongForm.vue?vue&type=style&index=1&id=47797142&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47797142",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/code/nathan/Sonido_SaaS/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47797142')) {
      api.createRecord('47797142', component.options)
    } else {
      api.reload('47797142', component.options)
    }
    module.hot.accept("./ProjectSongForm.vue?vue&type=template&id=47797142&scoped=true&", function () {
      api.rerender('47797142', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/src/components/projectSongs/ProjectSongForm.vue"
export default component.exports