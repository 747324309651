import Layout1 from "@/layout/Layout1";

export default [{
  path: '/settings/',
  component: Layout1,
  children: [{
    name: 'white-label',
    path: 'white-label',
    component: () => import('@/components/settings/WhiteLabel')
  },{
    name: 'company',
    path: 'company',
    component: () => import('@/components/settings/CompanySetting')
  },{
    name: 'failed-import-log',
    path: 'failed-import-log',
    component: () => import('@/components/settings/FailedImportLog')
  },{
    name: 'integrations',
    path: 'integrations',
    component: () => import('@/components/settings/IntegrationPlatform')
  },,{
    name: 'oauth-management',
    path: 'oauth-management',
    component: () => import('@/components/settings/OauthManagement')
  },
  {
    name: 'forms',
    path: 'forms',
    component: () => import('@/components/settings/FormBuilder')
  },
  {
    name: 'stripe',
    path: 'stripe',
    component: () => import('@/components/settings/StripeSetting'),
      beforeEnter: (to, from, next) => {
          if(localStorage.getItem('website_p') == 1) {
              next()
          } else {
            next({ path: "dashboard" });
          }
        }
      }
    ]
  }
];
