var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "layout-footer footer", class: _vm.getLayoutFooterBg() },
    [
      _c(
        "div",
        { staticClass: "container-fluid container-p-x pb-3" },
        [
          _c("b-row", [
            _c(
              "div",
              {
                staticClass: "col-12 col-md-7 pt-3 text-center text-md-left",
                staticStyle: { "font-size": "12px" }
              },
              [
                _vm._v(
                  "\n              ⓒ 2022 Hoggard Technology | Sonido Software "
                ),
                _c("span", { staticClass: "ml-3" }, [
                  _vm._v("All rights reserved.")
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-12 col-md-5 text-center text-md-right",
                staticStyle: { "font-size": "12px" }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "footer-link pt-3",
                    attrs: {
                      href: "https://www.mysonido.com/user-license-agreement/",
                      target: "_blank"
                    }
                  },
                  [_vm._v("User License Agreement")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "footer-link pt-3 ml-4",
                    attrs: {
                      href: "https://www.mysonido.com/privacy-policy/",
                      target: "_blank"
                    }
                  },
                  [_vm._v("Privacy Policy")]
                )
              ]
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }