var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            "no-close-on-backdrop": true,
            id: "create-new-task",
            centered: "",
            title: "Create New To Do",
            "no-enforce-focus": true
          },
          on: { show: _vm.loadData },
          model: {
            value: _vm.show_modal,
            callback: function($$v) {
              _vm.show_modal = $$v
            },
            expression: "show_modal"
          }
        },
        [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-lg-12",
                  attrs: {
                    label: "Title",
                    "invalid-feedback": _vm.getErrorLabel(_vm.$v.task.title)
                  }
                },
                [
                  _c("b-input", {
                    attrs: {
                      state: _vm.$v.task.title.$error ? "invalid" : null
                    },
                    model: {
                      value: _vm.task.title,
                      callback: function($$v) {
                        _vm.$set(_vm.task, "title", $$v)
                      },
                      expression: "task.title"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                { staticClass: "col-sm-12", attrs: { label: "" } },
                [
                  _c("template", { slot: "label" }, [_vm._v("Assigned Users")]),
                  _vm._v(" "),
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        id: "assigned_user_ajax",
                        "custom-label": _vm.userLabel,
                        "track-by": "id",
                        placeholder: "Type to search",
                        "open-direction": "bottom",
                        options: _vm.assignedUserOptions,
                        multiple: true,
                        searchable: true,
                        loading: _vm.assignedUserLoading,
                        "internal-search": false,
                        "clear-on-select": false,
                        "close-on-select": true,
                        "max-height": 600,
                        "show-no-results": false,
                        "hide-selected": true
                      },
                      on: {
                        select: _vm.selectAssignedUser,
                        "search-change": _vm.findAssignedUser
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "tag",
                          fn: function(ref) {
                            var option = ref.option
                            var remove = ref.remove
                            return [
                              _c(
                                "span",
                                { staticClass: "badge badge-primary mr-2" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(option.first_name) +
                                      " " +
                                      _vm._s(option.last_name) +
                                      " (" +
                                      _vm._s(option.email) +
                                      ")\n              "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { color: "white" },
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return remove(option)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-times ml-2"
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          }
                        },
                        {
                          key: "clear",
                          fn: function(props) {
                            return [
                              _vm.task.assigned_user.length
                                ? _c("div", {
                                    staticClass: "multiselect__clear",
                                    on: {
                                      mousedown: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.clearAll(props.search)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.task.assigned_user,
                        callback: function($$v) {
                          _vm.$set(_vm.task, "assigned_user", $$v)
                        },
                        expression: "task.assigned_user"
                      }
                    },
                    [
                      _vm._v(" "),
                      _vm._v(" "),
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [_vm._v("User not found.")]
                      )
                    ]
                  )
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-lg-6",
                  attrs: {
                    label: "Due Date",
                    "invalid-feedback": _vm.getErrorLabel(_vm.$v.task.due_date)
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "input-group" },
                    [
                      _c("flat-pickr", {
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.$v.task.due_date.$error },
                        attrs: {
                          config: _vm.config,
                          placeholder: "Select date",
                          name: "due_date"
                        },
                        model: {
                          value: _vm.task.due_date,
                          callback: function($$v) {
                            _vm.$set(_vm.task, "due_date", $$v)
                          },
                          expression: "task.due_date"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group-btn" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-default",
                            attrs: {
                              type: "button",
                              title: "Toggle",
                              "data-toggle": ""
                            }
                          },
                          [
                            _c("i", { staticClass: "fa fa-calendar" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "sr-only",
                                  attrs: { "aria-hidden": "true" }
                                },
                                [_vm._v("Toggle")]
                              )
                            ])
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { staticClass: "col-lg-6", attrs: { label: "Reminder" } },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.reminder_options },
                    model: {
                      value: _vm.task.reminder,
                      callback: function($$v) {
                        _vm.$set(_vm.task, "reminder", $$v)
                      },
                      expression: "task.reminder"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("hr", { staticClass: "border-light mt-3" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-lg-12",
                  attrs: { label: "Associate Type" }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.associate_type_options,
                      disabled:
                        this.associate_type !== null &&
                        this.scenario !== "booking-todo"
                    },
                    model: {
                      value: _vm.task.selected_associate_type,
                      callback: function($$v) {
                        _vm.$set(_vm.task, "selected_associate_type", $$v)
                      },
                      expression: "task.selected_associate_type"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.task.selected_associate_type != "General"
            ? _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-lg-12",
                      attrs: {
                        label: "",
                        "invalid-feedback": _vm.getErrorLabel(
                          _vm.$v.task.selected_associate_id
                        )
                      }
                    },
                    [
                      _c("multiselect", {
                        class: {
                          "is-invalid": _vm.$v.task.selected_associate_id.$error
                        },
                        attrs: {
                          options: _vm.associate_id_options,
                          multiple: false,
                          disabled:
                            this.associate_id !== null &&
                            this.scenario !== "booking-todo",
                          placeholder: "Type to search",
                          "track-by": "id",
                          label: "text"
                        },
                        model: {
                          value: _vm.task.selected_associate_id,
                          callback: function($$v) {
                            _vm.$set(_vm.task, "selected_associate_id", $$v)
                          },
                          expression: "task.selected_associate_id"
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.task.selected_associate_id.$error
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              _vm._s(
                                _vm.getErrorLabel(
                                  _vm.$v.task.selected_associate_id
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("hr", { staticClass: "border-light mt-3" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-form-group",
                { staticClass: "col-lg-12", attrs: { label: "Status" } },
                [
                  _c("label", { staticClass: "switcher switcher-primary" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.task.completed,
                          expression: "task.completed"
                        }
                      ],
                      staticClass: "switcher-input",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.task.completed)
                          ? _vm._i(_vm.task.completed, null) > -1
                          : _vm.task.completed
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.task.completed,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.task,
                                  "completed",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.task,
                                  "completed",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.task, "completed", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "switcher-indicator" }, [
                      _c("span", { staticClass: "switcher-yes text-primary" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "switcher-no" })
                    ]),
                    _vm._v(" "),
                    !_vm.task.completed
                      ? _c("span", { staticClass: "switcher-label" }, [
                          _vm._v("Incomplete")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.task.completed
                      ? _c("span", { staticClass: "switcher-label" }, [
                          _vm._v("Completed")
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-form-group",
                { staticClass: "col-lg-12", attrs: { label: "Details" } },
                [
                  _c("b-form-textarea", {
                    attrs: { placeholder: "Enter details...", rows: "3" },
                    model: {
                      value: _vm.task.details,
                      callback: function($$v) {
                        _vm.$set(_vm.task, "details", $$v)
                      },
                      expression: "task.details"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.loading, variant: "default" },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.loading, variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }