var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "layout-navbar align-items-lg-center container-p-x",
      attrs: { toggleable: "lg", variant: _vm.getLayoutNavbarBg() }
    },
    [
      _c("b-navbar-brand", { attrs: { to: "/" } }, [
        _c("img", { attrs: { src: _vm.logo, height: "40" } })
      ]),
      _vm._v(" "),
      _vm.sidenavToggle
        ? _c(
            "b-navbar-nav",
            { staticClass: "align-items-lg-center mr-auto mr-lg-4" },
            [
              _c(
                "a",
                {
                  staticClass: "nav-item nav-link px-0 ml-2 ml-lg-0",
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.toggleSidenav }
                },
                [
                  _c("i", {
                    staticClass: "ion ion-md-menu text-large align-middle"
                  })
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-navbar-toggle", { attrs: { target: "app-layout-navbar" } }),
      _vm._v(" "),
      _c("b-collapse", { attrs: { "is-nav": "", id: "app-layout-navbar" } }, [
        _c("hr", { staticClass: "d-lg-none w-100 my-2" })
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "notification-modal",
          attrs: {
            centered: "",
            id: "notification-modal",
            "header-text-variant": "dark",
            "body-text-variant": "dark",
            title: _vm.notif.title,
            "hide-footer": ""
          }
        },
        [
          _c("div", {
            staticClass: "text-dark",
            domProps: { innerHTML: _vm._s(_vm.notif.description) }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }