import Layout1 from '@/layout/Layout1'

export default [{
  path: '/settings/room/',
  component: Layout1,
    beforeEnter: (to, from, next) => {
        if(localStorage.getItem('website_p') == 1) {
            next()
        } else {
            next({ path: 'dashboard' })
        }
    },
  children: [{
    name: 'rooms-list',
    path: '',
    component: () => import('@/components/rooms/RoomList')
  }, {
    name: 'room-view',
    path: ':id',
    props: true,
    component: () => import('@/components/rooms/RoomView')
  }]
}]
