import LayoutWithoutSideNav from '@/components/reviewer/LayoutWithoutSidenav'

export default [{
  path: '/review-portal',
  component: LayoutWithoutSideNav,
  children: [{
    name: 'review-portal',
    path: '',
    component: () => import('@/components/reviewer/ReviewPortal')
  }]
}]
