var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            "no-close-on-backdrop": true,
            id: "manage-booking-equipment",
            size: "lg",
            "no-enforce-focus": "",
            title: _vm.formTitle
          },
          on: {
            shown: _vm.focusMyElement,
            show: function($event) {
              return _vm.onShow()
            }
          },
          model: {
            value: _vm.show_modal,
            callback: function($$v) {
              _vm.show_modal = $$v
            },
            expression: "show_modal"
          }
        },
        [
          _c("b-form-row"),
          _vm._v(" "),
          _vm.showQrScanner
            ? _c("qrcode-stream", { on: { decode: _vm.onDecode } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "b-btn",
                {
                  attrs: { id: "button-addon2", variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.openQrScanner()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-camera" }),
                  _vm._v(" Scan Equipment")
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("hr", { staticClass: "border-light mt-3 mb-3" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-row justify-content-center mt-3" },
            [
              _c(
                "div",
                {},
                [
                  _c(
                    "b-form-select",
                    {
                      attrs: { options: _vm.category_options },
                      on: {
                        change: function($event) {
                          return _vm.onCategoryChange()
                        }
                      },
                      model: {
                        value: _vm.selected_category_id,
                        callback: function($$v) {
                          _vm.selected_category_id = $$v
                        },
                        expression: "selected_category_id"
                      }
                    },
                    [
                      _c("template", { slot: "first" }, [
                        _c("option", { domProps: { value: null } }, [
                          _vm._v("All")
                        ])
                      ])
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ml-2" },
                [
                  _c(
                    "b-form-select",
                    {
                      attrs: {
                        options: _vm.sub_category_options,
                        disabled:
                          _vm.selected_category_id == null ? true : false
                      },
                      on: {
                        change: function($event) {
                          return _vm.onSubcategoryChange()
                        }
                      },
                      model: {
                        value: _vm.selected_sub_category_id,
                        callback: function($$v) {
                          _vm.selected_sub_category_id = $$v
                        },
                        expression: "selected_sub_category_id"
                      }
                    },
                    [
                      _c("template", { slot: "first" }, [
                        _c("option", { domProps: { value: null } }, [
                          _vm._v("All")
                        ])
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("multiselect", {
                staticClass: "mt-2",
                attrs: {
                  options: _vm.equipmentOptions,
                  multiple: false,
                  placeholder: "Type to search",
                  "track-by": "id",
                  label: "name",
                  "max-height": 600,
                  "show-no-results": true,
                  "hide-selected": true,
                  taggable: false,
                  "tag-placeholder": "Check in this equipment",
                  "custom-label": _vm.customLabel,
                  noResult: "No result found"
                },
                on: { input: _vm.addEquipment },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function(props) {
                      return [
                        _c(
                          "span",
                          {
                            class:
                              props.option.status != "Available"
                                ? "text-muted"
                                : ""
                          },
                          [_vm._v(_vm._s(props.option.name))]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.equipment,
                  callback: function($$v) {
                    _vm.equipment = $$v
                  },
                  expression: "equipment"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "table" },
            [
              _c("tr", [
                _c("th"),
                _vm._v(" "),
                _c("th", [_vm._v("NAME")]),
                _vm._v(" "),
                _c("th", [_vm._v("SERIAL #")])
              ]),
              _vm._v(" "),
              _vm._l(_vm.equipmentList, function(trackingEquipment) {
                return _c("tr", { key: trackingEquipment.id }, [
                  trackingEquipment.action == "Check Out"
                    ? _c(
                        "td",
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: { size: "xs", variant: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteEquipment(
                                    trackingEquipment.id
                                  )
                                }
                              }
                            },
                            [
                              _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true }
                                  }
                                ],
                                staticClass: "fa fa-trash",
                                attrs: {
                                  title: "Delete Equipment from Session"
                                }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-btn",
                            {
                              attrs: { size: "xs", variant: "success" },
                              on: {
                                click: function($event) {
                                  return _vm.checkoutEquipment(
                                    trackingEquipment.id
                                  )
                                }
                              }
                            },
                            [
                              _c("i", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover",
                                    modifiers: { hover: true }
                                  }
                                ],
                                staticClass: "fa fa-check",
                                attrs: { title: "Check In Equipment" }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  trackingEquipment.action == "Check In"
                    ? _c(
                        "td",
                        [
                          _c("b-badge", { attrs: { variant: "success" } }, [
                            _vm._v("Checked In")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(trackingEquipment.equipment.name))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(trackingEquipment.equipment.serial_number))
                  ])
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "default" },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v(" Close ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }