var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form-row",
        [
          _vm.projectTypeOptions.length > 1
            ? _c(
                "b-form-group",
                { staticClass: "col-12", attrs: { label: "Project Type" } },
                [
                  _c(
                    "b-form-radio-group",
                    {
                      staticClass: "project-type-radio mt-md-1",
                      attrs: {
                        buttons: "",
                        "button-variant": "outline-primary"
                      },
                      model: {
                        value: _vm.data.type,
                        callback: function($$v) {
                          _vm.$set(_vm.data, "type", $$v)
                        },
                        expression: "data.type"
                      }
                    },
                    [
                      _vm._l(_vm.projectTypeOptions, function(option) {
                        return [
                          _c(
                            "b-form-radio",
                            {
                              key: option.text,
                              staticClass: "project-type-radio-item",
                              attrs: { value: option.value }
                            },
                            [
                              _c("div", [
                                option.value == "music"
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-music project-type-radio-icon"
                                    })
                                  : option.value == "podcast"
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-microphone project-type-radio-icon"
                                    })
                                  : option.value == "audio-book"
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-book project-type-radio-icon"
                                    })
                                  : option.value == "post-production"
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-sliders-h project-type-radio-icon"
                                    })
                                  : option.value == "voiceover"
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-headset project-type-radio-icon"
                                    })
                                  : option.value == "other"
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-file-audio project-type-radio-icon"
                                    })
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "project-type-radio-label" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(option.text) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.data.type == "music"
            ? _c(
                "b-form-group",
                { staticClass: "col-12" },
                [
                  _c(
                    "template",
                    { slot: "label" },
                    [
                      _vm._v("\n        Artist / Group\n        "),
                      _c(
                        "b-link",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          class: {
                            "ml-1": 1,
                            "text-primary": !_vm.data.copy_contact_info,
                            "text-disabled": _vm.data.copy_contact_info
                          },
                          attrs: {
                            disabled: _vm.data.copy_contact_info,
                            title: "Create New Artist/Group"
                          },
                          on: { click: _vm.createNewBandGroup }
                        },
                        [_c("i", { staticClass: "ion ion-md-add-circle" })]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "multiselect",
                    {
                      class: {
                        "is-invalid":
                          _vm.$v.data.band_id.$error &&
                          _vm.$v.data.band_id.$dirty
                      },
                      attrs: {
                        disabled: _vm.data.copy_contact_info,
                        id: "band_id",
                        label: "name",
                        "track-by": "id",
                        placeholder: "Type to search",
                        "open-direction": "bottom",
                        options: _vm.bandOptions,
                        multiple: false,
                        searchable: true,
                        loading: _vm.isBandLoading,
                        "internal-search": false,
                        "clear-on-select": false,
                        "close-on-select": true,
                        "options-limit": 300,
                        "max-height": 600,
                        "show-no-results": true,
                        "hide-selected": true
                      },
                      on: { "search-change": _vm.asyncBandFind },
                      model: {
                        value: _vm.data.band_id,
                        callback: function($$v) {
                          _vm.$set(_vm.data, "band_id", $$v)
                        },
                        expression: "data.band_id"
                      }
                    },
                    [
                      _c("template", { slot: "clear" }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [_vm._v("Band not found.")]
                      )
                    ],
                    2
                  ),
                  _vm._v(" "),
                  !_vm.$v.data.band_id.required && _vm.$v.data.band_id.$dirty
                    ? _c("div", { staticClass: "invalid-feedback d-block" }, [
                        _vm._v("\n        Band field is required.\n      ")
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.data.type == "music" && this.edit_id == null
            ? _c(
                "b-form-group",
                { staticClass: "col-12" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: {
                        id: "copy_contact_info",
                        name: "copy_contact_info",
                        value: true,
                        "unchecked-value": false
                      },
                      model: {
                        value: _vm.data.copy_contact_info,
                        callback: function($$v) {
                          _vm.$set(_vm.data, "copy_contact_info", $$v)
                        },
                        expression: "data.copy_contact_info"
                      }
                    },
                    [
                      _vm._v(
                        "\n        Copy contact info as Artist / Group Name\n      "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.data.type != "music"
            ? _c(
                "b-form-group",
                { staticClass: "col-12" },
                [
                  _c("template", { slot: "label" }, [
                    _vm._v("\n        Company\n        "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        staticClass: "text-primary",
                        attrs: { href: "#", title: "Create New Company" },
                        on: { click: _vm.createNewCompany }
                      },
                      [_c("i", { staticClass: "ion ion-md-add-circle" })]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "multiselect",
                    {
                      class: {
                        "is-invalid":
                          _vm.$v.data.company_id.$error &&
                          _vm.$v.data.company_id.$dirty
                      },
                      attrs: {
                        id: "band_id",
                        label: "name",
                        "track-by": "id",
                        placeholder: "Type to search",
                        "open-direction": "bottom",
                        options: _vm.companyOptions,
                        multiple: false,
                        searchable: true,
                        loading: _vm.isCompanyLoading,
                        "internal-search": false,
                        "clear-on-select": false,
                        "close-on-select": true,
                        "options-limit": 300,
                        "max-height": 600,
                        "show-no-results": true,
                        "hide-selected": true
                      },
                      on: { "search-change": _vm.asyncCompanyFind },
                      model: {
                        value: _vm.data.company_id,
                        callback: function($$v) {
                          _vm.$set(_vm.data, "company_id", $$v)
                        },
                        expression: "data.company_id"
                      }
                    },
                    [
                      _c("template", { slot: "clear" }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [_vm._v("Company not found.")]
                      )
                    ],
                    2
                  ),
                  _vm._v(" "),
                  !_vm.$v.data.company_id.required &&
                  _vm.$v.data.company_id.$dirty
                    ? _c("div", { staticClass: "invalid-feedback d-block" }, [
                        _vm._v("\n        Company field is required.\n      ")
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-form-group",
            { staticClass: "col-6", attrs: { label: "Lead Stage" } },
            [
              _vm.data.lead_stage == "Booked Studio Time (Converted)" ||
              _vm.data.lead_stage == "Closed Lost"
                ? _c("b-form-input", {
                    attrs: { plaintext: true },
                    model: {
                      value: _vm.data.lead_stage,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "lead_stage", $$v)
                      },
                      expression: "data.lead_stage"
                    }
                  })
                : _c("b-form-select", {
                    attrs: { options: _vm.leadStageOptions },
                    model: {
                      value: _vm.data.stage_id,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "stage_id", $$v)
                      },
                      expression: "data.stage_id"
                    }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              staticClass: "col-6",
              attrs: {
                label: "Project Revenue Estimate",
                "invalid-feedback": _vm.getErrorLabel(
                  _vm.$v.data.project_revenue_estimate
                )
              }
            },
            [
              _c("b-input", {
                staticClass: "mb-1",
                attrs: {
                  state: _vm.$v.data.project_revenue_estimate.$error
                    ? "invalid"
                    : null
                },
                model: {
                  value: _vm.data.project_revenue_estimate,
                  callback: function($$v) {
                    _vm.$set(_vm.data, "project_revenue_estimate", $$v)
                  },
                  expression: "data.project_revenue_estimate"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.data.type == "music"
        ? _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-12",
                  attrs: {
                    label: "Number of Songs",
                    "invalid-feedback": _vm.getErrorLabel(
                      _vm.$v.data.number_of_songs
                    )
                  }
                },
                [
                  _c("b-input", {
                    attrs: {
                      state: _vm.$v.data.number_of_songs.$error
                        ? "invalid"
                        : null
                    },
                    model: {
                      value: _vm.data.number_of_songs,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "number_of_songs", $$v)
                      },
                      expression: "data.number_of_songs"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type !== "music"
        ? _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-12",
                  attrs: {
                    label: "Number of Episodes",
                    "invalid-feedback": _vm.getErrorLabel(
                      _vm.$v.data.number_of_episodes
                    )
                  }
                },
                [
                  _c("b-input", {
                    attrs: {
                      state: _vm.$v.data.number_of_episodes.$error
                        ? "invalid"
                        : null
                    },
                    model: {
                      value: _vm.data.number_of_episodes,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "number_of_episodes", $$v)
                      },
                      expression: "data.number_of_episodes"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type !== "music"
        ? _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-12",
                  attrs: {
                    label: "Genre",
                    "invalid-feedback": _vm.getErrorLabel(_vm.$v.data.genre_id)
                  }
                },
                [
                  _c("b-form-select", {
                    class: {
                      "is-invalid": _vm.$v.data.genre_id.$error
                    },
                    attrs: { options: _vm.genreOptions },
                    model: {
                      value: _vm.data.genre_id,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "genre_id", $$v)
                      },
                      expression: "data.genre_id"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form-row",
        [
          _c(
            "b-form-group",
            { staticClass: "col-md-6", attrs: { label: "Target Start Date" } },
            [
              _c(
                "b-input-group",
                [
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: {
                      config: _vm.config,
                      placeholder: "Select date",
                      name: "date"
                    },
                    model: {
                      value: _vm.data.target_start_date,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "target_start_date", $$v)
                      },
                      expression: "data.target_start_date"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group-btn" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: {
                          type: "button",
                          title: "Toggle",
                          "data-toggle": ""
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-calendar" }, [
                          _c(
                            "span",
                            {
                              staticClass: "sr-only",
                              attrs: { "aria-hidden": "true" }
                            },
                            [_vm._v("Toggle")]
                          )
                        ])
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              staticClass: "col-md-6",
              attrs: { label: "Target Completion Date" }
            },
            [
              _c(
                "b-input-group",
                [
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: {
                      config: _vm.config,
                      placeholder: "Select date",
                      name: "date"
                    },
                    model: {
                      value: _vm.data.target_completion_date,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "target_completion_date", $$v)
                      },
                      expression: "data.target_completion_date"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group-btn" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: {
                          type: "button",
                          title: "Toggle",
                          "data-toggle": ""
                        }
                      },
                      [
                        _c("i", { staticClass: "fa fa-calendar" }, [
                          _c(
                            "span",
                            {
                              staticClass: "sr-only",
                              attrs: { "aria-hidden": "true" }
                            },
                            [_vm._v("Toggle")]
                          )
                        ])
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.edit_id
        ? _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                { staticClass: "col-sm-12", attrs: { label: "Lead Notes" } },
                [
                  _c("b-textarea", {
                    attrs: { rows: "5" },
                    model: {
                      value: _vm.data.project_notes,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "project_notes", $$v)
                      },
                      expression: "data.project_notes"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }