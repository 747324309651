<template>
  <div>
    <b-form-row>
      <b-form-group label="Referral Source" class="col-12">
        <b-form-select
          v-model="data.referral_source"
          :options="referralSourceOptions"
        ></b-form-select>
      </b-form-group>
    </b-form-row>

    <b-form-row v-if="data.referral_source == eventOptionId">
      <b-form-group label="Event" class="col-12">
        <multiselect
          v-model="data.event"
          id="event"
          label="title"
          track-by="id"
          placeholder="Type to search"
          open-direction="bottom"
          :options="eventOptions"
          :multiple="false"
          :searchable="true"
          :loading="isEventLoading"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="true"
          :options-limit="300"
          :max-height="600"
          :show-no-results="true"
          :hide-selected="true"
          @search-change="asyncEventFind"
        >
          <template slot="clear"></template>
          <span slot="noResult">Event not found.</span>
        </multiselect>
      </b-form-group>
    </b-form-row>

    <b-form-row>
      <b-form-group class="col-12">
        <template slot="label">
          Primary Contact
          <a
            href="#"
            class="text-primary"
            @click="createNewContact"
            v-b-tooltip.hover
            title="Create New Contact"
          >
            <i class="ion ion-md-add-circle"></i>
          </a>
        </template>
        <multiselect
          v-model="data.contact_id"
          id="contact_id"
          label="full_name"
          track-by="id"
          placeholder="Type to search"
          open-direction="bottom"
          :options="contactOptions"
          :multiple="false"
          :searchable="true"
          :loading="isContactLoading"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="true"
          :options-limit="300"
          :max-height="600"
          :show-no-results="true"
          :hide-selected="true"
          :allow-empty="true"
          @search-change="asyncContactFind"
          :class="{
            'is-invalid': $v.data.contact_id.$error,
          }"
        >
          <template slot="clear"></template>
          <span slot="noResult">Contact not found.</span>
        </multiselect>

        <div v-if="$v.data.contact_id.$error" class="invalid-feedback d-block">
          Contact field is required.
        </div>
      </b-form-group>
    </b-form-row>

    <custom-field-form-template :data.sync="data" object="Lead" />

  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import Vuelidate from "vuelidate";
import { required } from "vuelidate/lib/validators";
import _ from "lodash";
import CustomFieldFormTemplate from "../customField/CustomFieldFormTemplate.vue";

Vue.use(Vuelidate);
export default {
  name: "LeadFormOne",
  props: ["lead_data", "edit_id"],
  components: {
    CustomFieldFormTemplate,
    Multiselect,
  },
  async created() {
    let self = this;
    self.isEventLoading = true;

    this.loadContactOptions();

    window.axios.post("/api/events/search-select").then((response) => {
      self.eventOptions = response.data;
      self.isEventLoading = false;
    });

    await window.axios
      .get("/api/picklists/Referral Sources")
      .then(function (response) {
        _.each(response.data.data.options, function (o) {
          self.referralSourceOptions.push(_.pick(o, "name", "id"));
          if (o.name == "Event") {
            self.eventOptionId = o.id;
          }
        });
        self.referralSourceOptions = self.referralSourceOptions.map(
          ({ id: value, name: text }) => ({
            value,
            text,
          })
        );
        if (self.data.referral_source === null) {
          self.data.referral_source = self.referralSourceOptions[0].value;
        }
      });
  },
  watch: {
    "data.referral_source": {
      immediate: true,
      handler(newVal) {
        if (newVal !== null && newVal === this.eventOptionId) {
          let self = this;
          this.isEventLoading = true;
          window.axios
            .post("/api/events/search-select", {
              search_string: "",
            })
            .then((response) => {
              self.eventOptions = response.data;
              self.isEventLoading = false;
            });
        }
      },
    },
    lead_data: function () {
      this.data = {
        contact_type: this.lead_data.contact_type,
        referral_source: this.lead_data.referral_source,
        first_name: this.lead_data.first_name,
        last_name: this.lead_data.last_name,
        email: this.lead_data.email,
        gender: this.lead_data.gender,
        phone_number: this.lead_data.phone_number,
        primary_instruments: this.lead_data.primary_instruments,
        event: this.lead_data.event ? this.lead_data.event.id : null,
        contact_notes: this.lead_data.contact_notes,
        contact_id: this.lead_data.contact_id,
        custom_fields: this.lead_data.custom_fields
      };
      console.log(this.data);
    },
    "lead_data.event": {
      immediate: true,
      handler(newVal) {
        if (newVal !== null && newVal.id) {
          this.data.event = {
            id: newVal.id,
            title: newVal.title,
          };
        }
      },
    },
  },
  data: () => ({
    eventOptionId: null,
    referralSourceOptions: [],
    editorOptions: {
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }, { size: [] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ direction: "rtl" }, { align: [] }],
          ["link", "image", "video"],
          ["clean"],
        ],
      },
    },
    isEventLoading: false,
    data: {
      contact_id: null,
      contact_type: null,
      referral_source: null,
      event_id: null,
      first_name: "",
      last_name: "",
      email: "",
      gender: "Male",
      phone_number: "",
      primary_instruments: "",
      contact_notes: "",
      event: null,
      custom_fields: {}
    },
    eventOptions: [],
    errorLabels: {
      required: "This field is required.",
      url: "Invalid url format",
      email: "Invalid email format",
      phone_number: "Invalid phone number format",
    },
    isContactLoading: false,
    contactOptions: [],
  }),
  validations: {
    data: {
      contact_id: {
        required,
      },
    },
    form: ["data.contact_id"],
  },

  methods: {
    createNewContact() {
      this.$emit("show-contact-form");
    },

    contactAdded(contact) {
      this.loadContactOptions();

      this.data.contact_id = contact;
      // this.$v.data.$reset();
    },

    validate() {
      this.$v.data.$touch();
      var isValid = !this.$v.data.$invalid;
      this.$emit("on-validate", this.$data, isValid);
      return isValid;
    },

    getErrorLabel(validation) {
      return Object.keys(validation)
        .filter((key) => !key.startsWith("$"))
        .reduce((prev, curr) => {
          if (!validation[curr]) {
            prev = this.errorLabels[curr];
          }

          return prev;
        }, "");
    },

    limitText(count) {
      return `and ${count} other contacts`;
    },

    async loadContactOptions() {
      try {
        const response = await window.axios.post(
          "/api/contacts/search-select",
          {
            search_string: "",
          }
        );

        this.contactOptions = response.data;
        this.isContactLoading = false;
      } catch (error) {
        this.isContactLoading = false;
        console.log(error);
      }
    },

    asyncContactFind: _.debounce(async function (query) {
      this.isContactLoading = true;
      try {
        const response = await window.axios.post(
          "/api/contacts/search-select",
          {
            search_string: query,
          }
        );

        this.contactOptions = response.data;
        this.isContactLoading = false;
      } catch (error) {
        this.isContactLoading = false;
        console.log(error);
      }
    }, 500),

    asyncEventFind: _.debounce(async function (query) {
      try {
        this.isEventLoading = true;
        const response = await window.axios.post("/api/events/search-select", {
          search_string: query,
        });

        this.eventOptions = response.data;
        this.isEventLoading = false;
      } catch (error) {
        console.log(error);
      }
    }, 500),

    cancel() {
      this.hide();
    },
  },
};
</script>

<style>
</style>
