import Layout1 from '@/layout/Layout1'

export default [{
  path: '/crm/company/',
  component: Layout1,
  children: [{
    name: 'companies-list',
    path: '',
    component: () => import('@/components/companies/CompanyList')
  }, {
    name: 'company-view',
    path: ':id',
    props: true,
    component: () => import('@/components/companies/CompanyView')
  }]
}]
