import Layout1 from '@/layout/Layout1'

export default [{
  path: '/settings/report-builder/',
  component: Layout1,    
  children: [{
    name: 'report-list',
    path: '',
    component: () => import('@/components/reportBuilder/ReportList'),
  }, {
    name: 'report-load-contact',
    path: 'load',
    component: () => import('@/components/reportBuilder/ReportLoadContact')
  }, {
    name: 'report-create-contact',
    path: 'create/contact',
    props: true,
    component: () => import('@/components/reportBuilder/ReportCreateContact')
  }, {
    name: 'report-edit-contact',
    path: 'edit/contact/:id',
    props: true,
    component: () => import('@/components/reportBuilder/ReportEditContact')
  }, {
    name: 'report-load-project',
    path: 'load',
    component: () => import('@/components/reportBuilder/ReportLoadProject')
  }, {
    name: 'report-create-project',
    path: 'create/project',
    props: true,
    component: () => import('@/components/reportBuilder/ReportCreateProject')
  }, {
    name: 'report-edit-project',
    path: 'edit/project/:id',
    props: true,
    component: () => import('@/components/reportBuilder/ReportEditProject')
  }, {
    name: 'report-load-booking',
    path: 'load',
    component: () => import('@/components/reportBuilder/ReportLoadBooking')
  }, {
    name: 'report-create-booking',
    path: 'create/booking',
    props: true,
    component: () => import('@/components/reportBuilder/ReportCreateBooking')
  }, {
    name: 'report-edit-booking',
    path: 'edit/booking/:id',
    props: true,
    component: () => import('@/components/reportBuilder/ReportEditBooking')
  }, {
    name: 'report-load-lead',
    path: 'load',
    component: () => import('@/components/reportBuilder/ReportLoadLead')
  }, {
    name: 'report-create-lead',
    path: 'create/lead',
    props: true,
    component: () => import('@/components/reportBuilder/ReportCreateLead')
  }, {
    name: 'report-edit-lead',
    path: 'edit/lead/:id',
    props: true,
    component: () => import('@/components/reportBuilder/ReportEditLead')
  }]
}]
