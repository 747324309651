import Layout1 from '@/layout/Layout1'

const ProjectList = () => import('@/components/projects/ProjectList');
const ProjectListCreate = () => import('@/components/projects/ProjectListCreate');

export default [{
    path: '/projects/',
    component: Layout1,
    children: [{
        name: 'projects-list',
        path: '/',
        component: ProjectList,
        props: { default: true, createModal: false }

    },{
        name: 'projects-create',
        path: '/projects/create',
        component: ProjectListCreate,
        props: { default: true, createModal: true }

    },{
        name: 'projects-view',
        path: '/projects/:id',
        props: true,
        component: () => import('@/components/projects/ProjectView')
    },{
        name: 'file-review',
        path: '/projects/:project_id/file-review/:id',
        props: true,
        component: () => import('@/components/projects/FileReview')
    },{
        name : 'project-file-preview',
        path : '/projects/:project_id/file-preview/:id',
        props : true,
        component: () => import('@/components/projects/ProjectFilePreview')
    }]
}]
