<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      id="create-new-band"
      size="lg"
      no-enforce-focus
      :title="formTitle"
      v-model="showModal"
      @shown="focusMyElement"
      @show="onShow()"
      ref="modal"
    >
      <b-form-group id="input-group-name" label="Name" label-for="name">
        <b-form-input
          ref="name"
          id="name"
          name="name"
          v-model="$v.data.name.$model"
          :state="$v.data.name.$dirty ? !$v.data.name.$error : null"
          aria-describedby="name-live-feedback"
        ></b-form-input>

        <b-form-invalid-feedback id="name-live-feedback"
          >The name field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-row>
        <b-form-group label class="col-sm-12">
          <template slot="label">
            Primary Contact
            <a href="#" class="text-primary" @click="createNewPrimaryContact">
              <i class="ion ion-md-add-circle"></i>
            </a>
          </template>
          <multiselect
            v-model="data.primary_contact"
            id="primary_contact_ajax"
            label="full_name"
            track-by="id"
            placeholder="Type to search"
            open-direction="bottom"
            :options="primary_contact_options"
            :multiple="false"
            :searchable="true"
            :loading="isPrimaryContactLoading"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="true"
            @search-change="asyncContactFind"
          >
            <template slot="clear" slot-scope="props"></template>
            <span slot="noResult">Contact not found.</span>
          </multiselect>
        </b-form-group>
      </b-form-row>

      <div class="row">
        <b-form-group
          label="Total Members"
          class="col-lg-6"
          :invalid-feedback="getErrorLabel($v.data.total_members)"
        >
          <b-input
            v-model="data.total_members"
            :state="$v.data.total_members.$error ? 'invalid' : null"
          ></b-input>
        </b-form-group>
      </div>

      <b-form-row>
        <b-form-group label class="col-sm-12">
          <template slot="label">
            List of Members
            <a
              href="javascript:void(0)"
              class="text-primary"
              @click="createNewMemberContact"
            >
              <i class="ion ion-md-add-circle"></i>
            </a>
          </template>
          <multiselect
            v-model="data.members"
            id="ajax"
            label="full_name"
            track-by="id"
            placeholder="Type to search"
            open-direction="bottom"
            :options="primary_contact_options"
            :multiple="true"
            :searchable="true"
            :loading="isPrimaryContactLoading"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="false"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="true"
            @search-change="asyncContactFind"
          >
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="badge badge-primary mr-2">
                <span>{{ option.full_name }}</span>
                <a
                  style="color: white"
                  href="javascript:void(0)"
                  @click="remove(option)"
                >
                  <i class="fa fa-times ml-2"></i>
                </a>
              </span>
            </template>
            <template slot="clear" slot-scope="props">
              <div
                class="multiselect__clear"
                v-if="data.members.length"
                @mousedown.prevent.stop="clearAll(props.search)"
              ></div>
            </template>
            <span slot="noResult">Contact not found.</span>
          </multiselect>
        </b-form-group>
      </b-form-row>

      <hr class="border-light mt-3" />

      <b-form-row>
        <b-form-group label="Genre" class="col-sm-12">
          <b-form-select
            v-model="data.genre_id"
            :options="genre_options"
          ></b-form-select>
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Musical Influences" class="col-sm-12">
          <b-input
            ref="music_influences"
            v-model="data.music_influences"
            class="mb-1"
          />
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Music Sounds Like" class="col-sm-12">
          <b-input
            ref="music_sounds_like"
            v-model="data.music_sounds_like"
            class="mb-1"
          />
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Studio Experience" class="col-lg-12">
          <b-form-select
            v-model="data.studio_experience"
            :options="studio_experience_options"
          ></b-form-select>
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Favorite Snacks/Drink" class="col-lg-12">
          <b-input
            ref="favorite_foods_and_drinks"
            v-model="data.favorite_foods_and_drinks"
            class="mb-1"
          />
        </b-form-group>
      </b-form-row>

      <div class="row">
        <b-form-group label="Website" class="col-sm-12">
          <b-input v-model="data.website"></b-input>
        </b-form-group>
      </div>

      <b-form-row>
        <b-form-group label="Manager" class="col-lg-12">
          <b-input
            ref="manager"
            v-model="data.manager"
            class="mb-1"
          />
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Music Publisher" class="col-lg-12">
          <b-input
            ref="music_publisher"
            v-model="data.music_publisher"
            class="mb-1"
          />
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Record Label" class="col-lg-12">
          <b-input
            ref="record_label"
            v-model="data.record_label"
            class="mb-1"
          />
        </b-form-group>
      </b-form-row>

       <b-form-row>
        <b-form-group label="Session Collaborators" class="col-lg-12">
          <b-input
            ref="collaborators"
            v-model="data.collaborators"
            class="mb-1"
          />
        </b-form-group>
      </b-form-row>

       <b-form-row>
        <b-form-group label="PRO / Collection Society" class="col-lg-12">
          <b-input
            ref="pro_collection_society"
            v-model="data.pro_collection_society"
            class="mb-1"
          />
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Bio" class="col-lg-12">
          <b-textarea v-model="data.bio" rows="5" />
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Country" class="col-lg-12">
          <b-form-select
            :disabled="loadingStates"
            v-model="data.country"
            :options="countries_options"
          ></b-form-select>
        </b-form-group>
      </b-form-row>

      <div class="row">
        <b-form-group label="Address" class="col-lg-12">
          <b-input v-model="data.street"></b-input>
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group label="City" class="col-lg-12">
          <b-input v-model="data.city"></b-input>
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group label="State" class="col-lg-12">
          <b-spinner small class="ml-2 mt-3" v-if="loadingStates" />
          <b-form-select
            v-model="data.state"
            :options="states_options"
            :disabled="loadingStates"
          >
            <template slot="first">
              <option :value="null" disabled>-- Please select state --</option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group label="Zip" class="col-6">
          <b-input v-model="data.zip_code"></b-input>
        </b-form-group>
      </div>

      <hr class="border-light mt-3" />
      <strong>Social Media Links</strong>
      <table class="table table-fixed table-borderless">
        <tr>
          <th style="width: 46%">Social Media Site</th>
          <th style="width: 46%">Social Media URL</th>
          <th class="text-right">
            <b-btn @click="addSocialMediaRow" class="btn-circle btn-primary">
              <i class="fas fa-plus" />
            </b-btn>
          </th>
        </tr>

        <tr
          v-bind:key="index"
          v-for="(v, index) in $v.data.social_medias.$each.$iter"
        >
          <td>
            <b-form-group label :invalid-feedback="getErrorLabel(v.site)">
              <b-form-select
                v-model="v.site.$model"
                :state="v.site.$error ? 'invalid' : null"
                :options="social_medias_options"
              ></b-form-select>
            </b-form-group>
          </td>
          <td>
            <b-form-group label :invalid-feedback="getErrorLabel(v.url)">
              <b-input
                v-model="v.url.$model"
                :state="v.url.$error ? 'invalid' : null"
              />
            </b-form-group>
          </td>
          <td>
            <b-btn
              @click="deleteSocialMediaRow(index)"
              class="btn-circle btn-primary ml-2"
            >
              <i class="fas fa-times" />
            </b-btn>
          </td>
        </tr>
      </table>

      <hr class="border-light mt-3" />
      <strong>Music Media Links</strong>
      <table class="table table-fixed table-borderless">
        <tr>
          <th style="width: 46%">Music Media Site</th>
          <th style="width: 46%">Music Media URL</th>
          <th class="text-right">
            <b-btn @click="addMusicMediaRow" class="btn-circle btn-primary">
              <i class="fas fa-plus" />
            </b-btn>
          </th>
        </tr>

        <tr
          v-bind:key="index"
          v-for="(v, index) in $v.data.music_medias.$each.$iter"
        >
          <td>
            <b-form-group label :invalid-feedback="getErrorLabel(v.site)">
              <b-form-select
                v-model="v.site.$model"
                :state="v.site.$error ? 'invalid' : null"
                :options="music_medias_options"
              ></b-form-select>
            </b-form-group>
          </td>
          <td>
            <b-form-group label :invalid-feedback="getErrorLabel(v.url)">
              <b-input
                v-model="v.url.$model"
                :state="v.url.$error ? 'invalid' : null"
              />
            </b-form-group>
          </td>
          <td>
            <b-btn
              @click="deleteMusicMediaRow(index)"
              class="btn-circle btn-primary ml-2"
            >
              <i class="fas fa-times" />
            </b-btn>
          </td>
        </tr>
      </table>

      <hr class="border-light mt-3" />
      <!-- <b-form-row>
                <b-form-group label="Notes" class="col-sm-12">
                    <quill-editor v-model="data.notes" :options="editorOptions" />
                    <b-textarea v-model="data.notes" rows="5" />
                </b-form-group>
      </b-form-row>-->

      <custom-field-form-template v-show="isCustomFieldsAvailable" :data.sync="data" object="Band" @custom-fields-loaded="handleCustomFieldsLoaded" />

      <template slot="modal-footer">
        <b-button :disabled="loading" variant="default" @click="cancel()"
          >Cancel</b-button
        >
        <b-button :disabled="loading" variant="primary" @click="save()"
          >Save</b-button
        >
      </template>
    </b-modal>
    <contact-form
      ref="primaryContactFormModal"
      @on-save="newPrimaryContactAdded"
    />
    <contact-form
      ref="memberContactFormModal"
      @on-save="newMemberContactAdded"
    />
  </div>
</template>

<style src="@/vendor/libs/vue-quill-editor/typography.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-quill-editor/editor.scss" lang="scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Vue from "vue";
import Vuelidate from "vuelidate";
import Toasted from "vue-toasted";
import { required, email, url, numeric } from "vuelidate/lib/validators";
import ContactForm from "../contacts/ContactForm";
import Multiselect from "vue-multiselect";
import CustomFieldFormTemplate from "../customField/CustomFieldFormTemplate.vue";

Vue.use(Vuelidate);
Vue.use(Toasted);

const init_data = {
  is_active: true,
  name: "",
  street: "",
  city: "",
  state: null,
  zip_code: "",
  country: null,
  genre: "A'Capella",
  music_influences: "",
  music_sounds_like: "",
  manager: "",
  music_publisher: "",
  record_label: "",
  collaborators: "",
  pro_collection_society: "",
  bio: "",
  studio_experience: "None",
  primary_contact: null,
  total_members: null,
  members: [],
  website: "",
  social_medias: [],
  music_medias: [],
  notes: "",
  custom_fields: {}
};

export default {
  name: "BandForm",
  props: {
    editId: {
      type: Number,
      default: null,
    },
    contactId: {
      type: Object,
      default: null,
    },
  },

  components: {
    CustomFieldFormTemplate,
    ContactForm,
    Multiselect,
    quillEditor: () =>
      import("vue-quill-editor/dist/vue-quill-editor")
        .then((m) => m.quillEditor)
        .catch(() => {}),
  },

  watch: {
    "data.country": {
      immediate: true,
      handler(newVal, oldVal) {
        let self = this;
        if (newVal && this.showModal) {
          this.loadingStates = true;
          window.axios
            .get(`/api/countries/get-states/${newVal}`)
            .then((response) => {
              self.states_options = [];
              Object.keys(response.data).forEach((key) => {
                self.states_options.push({
                  text: response.data[key],
                  value: response.data[key],
                });
              });
              self.loadingStates = false;
            });
        }
      },
    },
  },

  created() {},

  validations: {
    data: {
      name: {
        required,
      },
      total_members: {
        numeric,
      },
      social_medias: {
        $each: {
          site: {
            required,
          },
          url: {
            required,
            url,
          },
        },
      },
      music_medias: {
        $each: {
          site: {
            required,
          },
          url: {
            required,
            url,
          },
        },
      },
    },
    form: ["data"],
  },

  data: () => ({
    genre_options: [],
    editorOptions: {
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }, { size: [] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ direction: "rtl" }, { align: [] }],
          ["link", "image", "video"],
          ["clean"],
        ],
      },
    },
    isActive: false,
    showModal: false,
    loadingStates: false,
    isPrimaryContactLoading: false,
    primary_contact_options: [],
    states_options: [],
    countries_options: [],
    social_medias_options: [],
    music_medias_options: [],
    errorLabels: {
      required: "This field is required.",
      email: "Invalid email format",
      numeric: "Only numeric are allowed",
      url: "Invalid url format",
    },
    data: init_data,
    loading: false,
    isCustomFieldsAvailable: false
  }),

  computed: {
    formTitle() {
      if (this.editId !== null) {
        return `Edit Artist / Group ${this.data.name}`;
      } else {
        return "Create New Artist / Group";
      }
    },
  },

  methods: {
    addSocialMediaRow() {
      this.data.social_medias.push({
        site: "",
        url: "",
      });
    },
    deleteSocialMediaRow(index) {
      this.data.social_medias.splice(index, 1);
    },
    addMusicMediaRow() {
      this.data.music_medias.push({
        site: "",
        url: "",
      });
    },
    deleteMusicMediaRow(index) {
      this.data.music_medias.splice(index, 1);
    },
    show() {
      this.loadContacts();
      this.loadCountries();
      this.loadSocialMedias();
      this.loadMusicMedias();

      this.showModal = true;
    },

    async loadContacts() {
      try {
        this.isPrimaryContactLoading = true;
        const response = await window.axios.post("/api/contacts/search-select");
        this.primary_contact_options = response.data;
        this.isPrimaryContactLoading = false;

        if(this.contactId){
                    console.log('the contact id');
          console.log(this.contactId);

          this.data.primary_contact = this.primary_contact_options.find(contact => {
            return contact.id == this.contactId.id;
          });
        }

      } catch (error) {
        console.log(error);
      }
    },

    async loadCountries() {
      try {
        const response = await window.axios.get("/api/countries/get-all-name");
        Object.keys(response.data).forEach((key) => {
          this.countries_options.push({
            text: response.data[key],
            value: response.data[key],
          });
        });
      } catch (error) {
        console.log(error);
      }
    },

    async loadSocialMedias() {
      try {
        const response = await window.axios.get(
          "/api/social-medias/all?pluck=true"
        );
        this.social_medias_options = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    async loadMusicMedias() {
      try {
        const response = await window.axios.get(
          "/api/music-medias/all?pluck=true"
        );
        this.music_medias_options = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    clearForm() {
      this.data = Object.assign({}, init_data);
      this.data.social_medias = [];
      this.data.music_medias = [];
      this.data.country = "United States";
      this.step = 0;
      this.errors = [];
      this.$v.$reset();
    },

    limitText(count) {
      return `and ${count} other contacts`;
    },

    hide() {
      this.showModal = false;
    },

    focusMyElement() {
      this.$refs.name.focus();
    },

    createNewBand() {
      this.editId = null;
    },

    createNewPrimaryContact() {
      this.editId = null;
      this.$refs.primaryContactFormModal.show();
    },

    createNewMemberContact() {
      this.editId = null;
      this.$refs.memberContactFormModal.show();
    },

    newPrimaryContactAdded() {
      var value = this.$store.state.primaryContact;
      this.data.primary_contact = {
        id: value.id,
        first_name: value.first_name,
        last_name: value.last_name,
        full_name: value.full_name,
      };
    },
    newMemberContactAdded(value) {
      this.data.members.push({
        id: value.id,
        first_name: value.first_name,
        last_name: value.last_name,
        full_name: value.full_name,
      });
    },

    async save(event, createAnother = false) {
      this.$v.form.$touch();
      let isValid = !this.$v.form.$invalid;
      if (isValid) {
        this.loading = true;

        const postedData = { ...this.data };

        console.log(postedData)
        if (this.editId === null) {
          const response = await window.axios.post("/api/bands", postedData);
          try {
            this.loading = false;
            this.clearForm();
            this.hide();
            this.$snotify.success("New Artist/Group Created", "Success");
            this.$emit("on-save", response.data, isValid);
          } catch (error) {
            this.loading = false;
            this.errors = error.response.data.errors;
            this.$snotify.error(
              "There was a problem creating Artist/Group.",
              "Error"
            );
          }
        } else {
          const response = await axios.put(
            `/api/bands/${this.editId}`,
            postedData
          );
          try {
            this.loading = false;
            this.clearForm();
            this.hide();
            this.$emit("on-save", response.data, isValid);
            this.$snotify.success("Artist/Group Updated", "Success");
          } catch (error) {
            this.loading = false;
            this.errors = error.response.data.errors;
            this.$snotify.error(
              "There was a problem updating Artist/Group.",
              "Error"
            );
          }
        }
      }
    },

    getErrorLabel(validation) {
      return Object.keys(validation)
        .filter((key) => !key.startsWith("$"))
        .reduce((prev, curr) => {
          if (!validation[curr]) {
            prev = this.errorLabels[curr];
          }

          return prev;
        }, "");
    },

    asyncContactFind: _.debounce(function (query) {
      let self = this;
      this.isPrimaryContactLoading = true;
      window.axios
        .post("/api/contacts/search-select", {
          search_string: query,
        })
        .then((response) => {
          self.primary_contact_options = response.data;
          self.isPrimaryContactLoading = false;
        });
    }, 500),

    cancel() {
      this.hide();
    },

    async loadGenreOptions() {
      const genreOptionsResponse = await window.axios.get(
        "/api/picklists/Genres"
      );
      this.genre_options = genreOptionsResponse.data.data.options.map(
        (option) => {
          return {
            text: option.name,
            value: option.id,
          };
        }
      );

      this.data.genre_id = this.genre_options[0].value;
    },

    async onShow() {
      this.loadGenreOptions();
      if (this.editId !== null) {
        await window.axios.get(`/api/bands/${this.editId}`).then((response) => {
          this.data = response.data;
          if (this.data.primary_contact.length) {
            this.data.primary_contact = this.data.primary_contact[0];
          }
          this.data.social_medias = response.data.social_medias.map((item) => {
            return {
              site: item.id,
              url: item.pivot.url,
            };
          });
          this.data.music_medias = response.data.music_medias.map((item) => {
            return {
              site: item.id,
              url: item.pivot.url,
            };
          });
        });
      } else {
        this.clearForm();
      }
    },

    handleCustomFieldsLoaded(data){
      this.isCustomFieldsAvailable = data.length > 0 ? true : false;
    }
  },
};
</script>
