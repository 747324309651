import Layout1 from '@/layout/Layout1'

export default [{
    path: '/crm/band-groups/',
    component: Layout1,
    children: [{
        name: 'band-groups-list',
        path: '/',
        component: () => import('@/components/bands/BandList')
    },{
        name: 'band-groups-view',
        path: ':id',
        props: true,
        component: () => import('@/components/bands/BandView')
    }]
}]
