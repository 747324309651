var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sidenav",
    { class: _vm.curClasses, attrs: { orientation: _vm.orientation } },
    [
      _c(
        "div",
        {
          staticClass: "sidenav-inner",
          class: { "py-1": _vm.orientation !== "horizontal" }
        },
        [
          _vm.ability.can("read", "Dashboard")
            ? _c(
                "sidenav-router-link",
                { attrs: { icon: "fas fa-th", to: "/", exact: true } },
                [_vm._v("Dashboard")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ability.can("read", "Lead") || _vm.ability.can("create", "Lead")
            ? _c(
                "sidenav-menu",
                {
                  attrs: {
                    icon: "ion ion-md-radio-button-on",
                    active: _vm.isMenuActive("/leads"),
                    open: _vm.isMenuOpen("/leads")
                  }
                },
                [
                  _c("template", { slot: "link-text" }, [
                    _vm._v("Leads\n        "),
                    _c(
                      "label",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$store.state.newLeads,
                            expression: "$store.state.newLeads"
                          }
                        ],
                        staticClass:
                          "bg-primary text-white font-weight-bold align-text-top ml-2",
                        staticStyle: {
                          padding: "0px 6px",
                          "text-align": "center",
                          width: "auto",
                          "border-radius": "1rem"
                        }
                      },
                      [_vm._v(_vm._s(_vm.$store.state.newLeads))]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.ability.can("read", "Lead")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: { to: "/leads", exact: true },
                          on: {
                            click: function($event) {
                              return _vm.refreshNewLeads()
                            }
                          }
                        },
                        [_vm._v("View Leads")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ability.can("create", "Lead")
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/leads/create", exact: true } },
                        [_vm._v("Add Lead")]
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ability.can("read", "Contact") ||
          _vm.ability.can("read", "Band") ||
          _vm.ability.can("read", "Company")
            ? _c(
                "sidenav-menu",
                {
                  attrs: {
                    icon: "ion ion-ios-contacts",
                    active: _vm.isMenuActive("/crm"),
                    open: _vm.isMenuOpen("/crm")
                  }
                },
                [
                  _c("template", { slot: "link-text" }, [
                    _vm._v("Contact Manager")
                  ]),
                  _vm._v(" "),
                  _vm.ability.can("read", "Contact")
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/crm/contacts", exact: true } },
                        [_vm._v("Contact")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ability.can("read", "Band")
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/crm/band-groups", exact: true } },
                        [_vm._v("Artist / Group")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ability.can("read", "Company")
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/crm/company", exact: true } },
                        [_vm._v("Company")]
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ability.can("read", "Project") ||
          _vm.ability.can("create", "Project") ||
          _vm.ability.can("create", "Project-file-delivery")
            ? _c(
                "sidenav-menu",
                {
                  attrs: {
                    icon: "ion ion-md-options",
                    active: _vm.isMenuActive("/projects"),
                    open: _vm.isMenuOpen("/projects")
                  }
                },
                [
                  _c("template", { slot: "link-text" }, [_vm._v("Projects")]),
                  _vm._v(" "),
                  _vm.ability.can("read", "Project") ||
                  _vm.ability.can("create", "Project-file-delivery")
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/projects", exact: true } },
                        [_vm._v("View Projects")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ability.can("create", "Project")
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/projects/create", exact: true } },
                        [_vm._v("Add Project")]
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ability.can("read", "Task")
            ? _c(
                "sidenav-router-link",
                { attrs: { icon: "fas fa-tasks", to: "/tasks", exact: true } },
                [_vm._v("To Dos")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ability.can("read", "Event") ||
          _vm.ability.can("read", "Booking") ||
          _vm.ability.can("read", "Quote") ||
          _vm.ability.can("read", "Invoice")
            ? _c(
                "sidenav-menu",
                {
                  attrs: {
                    icon: "ion ion-ios-megaphone",
                    active: _vm.isMenuActive("/marketing"),
                    open: _vm.isMenuOpen("/marketing")
                  }
                },
                [
                  _c("template", { slot: "link-text" }, [_vm._v("Marketing")]),
                  _vm._v(" "),
                  _vm.ability.can("read", "Event")
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/marketing/events", exact: true } },
                        [_vm._v("Events")]
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ability.can("read", "Booking")
            ? _c(
                "sidenav-menu",
                {
                  attrs: {
                    icon: "ion ion-ios-calendar",
                    active: _vm.isMenuActive("/bookings"),
                    open: _vm.isMenuOpen("/bookings")
                  }
                },
                [
                  _c("template", { slot: "link-text" }, [_vm._v("Calendar")]),
                  _vm._v(" "),
                  _vm.ability.can("read", "Booking")
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/bookings", exact: true } },
                        [_vm._v("Bookings")]
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ability.can("read", "Quote") ||
          _vm.ability.can("create", "Quote") ||
          _vm.ability.can("read", "Invoice") ||
          _vm.ability.can("create", "Invoice")
            ? _c(
                "sidenav-menu",
                {
                  attrs: {
                    icon: "fas fa-money-bill-wave",
                    active:
                      _vm.isMenuActive("/quotes") ||
                      _vm.isMenuActive("/invoices"),
                    open:
                      _vm.isMenuOpen("/quotes") || _vm.isMenuOpen("/invoices")
                  }
                },
                [
                  _c("template", { slot: "link-text" }, [_vm._v("Financials")]),
                  _vm._v(" "),
                  _vm.ability.can("read", "Quote")
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/quotes", exact: true } },
                        [_vm._v("View Quotes")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ability.can("create", "Quote")
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/quotes/create", exact: true } },
                        [_vm._v("Add Quotes")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ability.can("read", "Invoice")
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/invoices", exact: true } },
                        [_vm._v("View Invoices")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ability.can("create", "Invoice")
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/invoices/create", exact: true } },
                        [_vm._v("Add Invoices")]
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ability.can("read", "Equipment")
            ? _c(
                "sidenav-router-link",
                {
                  attrs: {
                    icon: "fas fa-microphone-alt",
                    to: "/equipment",
                    exact: true
                  }
                },
                [_vm._v("Equipment")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ability.can("read", "Report")
            ? _c(
                "sidenav-menu",
                {
                  attrs: {
                    icon: "ion ion-md-podium",
                    active:
                      _vm.isMenuActive("/reports") ||
                      _vm.isMenuActive("/settings/report-builder"),
                    open:
                      _vm.isMenuOpen("/reports") ||
                      _vm.isMenuOpen("/settings/report-builder")
                  }
                },
                [
                  _c("template", { slot: "link-text" }, [_vm._v("Reports")]),
                  _vm._v(" "),
                  !_vm.isSpotifySite
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/reports/active-leads", exact: true } },
                        [_vm._v("Active Lead Pipeline\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isSpotifySite
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: { to: "/reports/converted-leads", exact: true }
                        },
                        [_vm._v("Converted Leads\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isSpotifySite
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: "/reports/closed-lost-leads",
                            exact: true
                          }
                        },
                        [_vm._v("Closed Lost Leads")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isSpotifySite
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/reports/revenue", exact: true } },
                        [_vm._v("Revenue")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isSpotifySite
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: { to: "/reports/revenue-monthly", exact: true }
                        },
                        [_vm._v("Monthly Revenue")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isSpotifySite
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: { to: "/reports/open-invoices", exact: true }
                        },
                        [_vm._v("Open Invoices")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isSpotifySite
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            to: "/reports/past-due-invoices",
                            exact: true
                          }
                        },
                        [_vm._v("Past Due Invoices")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "sidenav-router-link",
                    { attrs: { to: "/reports/booking", exact: true } },
                    [_vm._v("Bookings")]
                  ),
                  _vm._v(" "),
                  _c(
                    "sidenav-router-link",
                    { attrs: { to: "/reports/session-report", exact: true } },
                    [_vm._v("Sessions")]
                  ),
                  _vm._v(" "),
                  !_vm.isSpotifySite
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/reports/referrals", exact: true } },
                        [_vm._v("Referrals")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isSpotifySite
                    ? _c(
                        "sidenav-router-link",
                        { attrs: { to: "/reports/event-roi", exact: true } },
                        [_vm._v("Event ROI")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isSpotifySite
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: { to: "/reports/time-tracking", exact: true }
                        },
                        [_vm._v("Time Tracking")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "sidenav-router-link",
                    { attrs: { to: "/reports/room-utilization", exact: true } },
                    [_vm._v("\n        Room Utilization (Hours)\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "sidenav-router-link",
                    {
                      attrs: {
                        to: "/reports/daily-room-utilization",
                        exact: true
                      }
                    },
                    [_vm._v("\n        Room Utilization (Days)\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "sidenav-router-link",
                    { attrs: { to: "/reports/room-rates-and-labor" } },
                    [_vm._v("\n        Room Rates & Labor\n      ")]
                  ),
                  _vm._v(" "),
                  _vm.ability.can("read", "Setting-company") ||
                  _vm.ability.can("update", "Setting-company")
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: { to: "/settings/report-builder", exact: true }
                        },
                        [_vm._v("Report Builder")]
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("sidenav-divider", { staticClass: "mb-1" }),
          _vm._v(" "),
          _vm.roles.includes("Admin") ||
          _vm.ability.can("read", "Setting-company") ||
          _vm.ability.can("update", "Setting-company") ||
          _vm.ability.can("read", "Setting-whitelabel") ||
          _vm.ability.can("update", "Setting-whitelabel") ||
          _vm.ability.can("read", "Room") ||
          _vm.ability.can("update", "Room") ||
          _vm.ability.can("read", "Export") ||
          _vm.ability.can("read", "Import") ||
          _vm.ability.can("read", "User") ||
          _vm.ability.can("update", "User") ||
          _vm.ability.can("create", "User") ||
          _vm.ability.can("delete", "User") ||
          _vm.ability.can("read", "Setting-picklist") ||
          _vm.ability.can("update", "Setting-picklist") ||
          _vm.ability.can("read", "Setting-equipmentCategory") ||
          _vm.ability.can("update", "Setting-equipmentCategory")
            ? _c(
                "div",
                { staticClass: "sidenav-inner" },
                [
                  _c(
                    "sidenav-header",
                    { staticClass: "small font-weight-semibold" },
                    [_vm._v("Administration")]
                  ),
                  _vm._v(" "),
                  _vm.p == 1 &&
                  (_vm.ability.can("read", "User") ||
                    _vm.ability.can("update", "User"))
                    ? _c(
                        "sidenav-menu",
                        {
                          attrs: {
                            icon: "ion ion-ios-people",
                            active: _vm.isMenuActive("/user"),
                            open: _vm.isMenuOpen("/user")
                          }
                        },
                        [
                          _c("template", { slot: "link-text" }, [
                            _vm._v("Users")
                          ]),
                          _vm._v(" "),
                          _vm.ability.can("read", "User")
                            ? _c(
                                "sidenav-router-link",
                                { attrs: { to: "/user/list", exact: true } },
                                [_vm._v("View Users")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.ability.can("create", "User")
                            ? _c(
                                "sidenav-router-link",
                                { attrs: { to: "/user/create", exact: true } },
                                [_vm._v("Add User")]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ability.can("read", "Setting-company") ||
                  _vm.ability.can("update", "Setting-company") ||
                  _vm.ability.can("read", "Setting-whitelabel") ||
                  _vm.ability.can("update", "Setting-whitelabel") ||
                  _vm.ability.can("read", "Setting-picklist") ||
                  _vm.ability.can("update", "Setting-picklist") ||
                  _vm.ability.can("read", "Setting-equipmentCategory") ||
                  _vm.ability.can("update", "Setting-equipmentCategory") ||
                  _vm.ability.can("read", "Setting-CustomField") ||
                  _vm.ability.can("update", "Setting-CustomField") ||
                  _vm.ability.can("read", "Setting-Coupon") ||
                  _vm.ability.can("update", "Setting-Coupon") ||
                  _vm.ability.can("read", "Room") ||
                  _vm.ability.can("update", "Room")
                    ? _c(
                        "sidenav-menu",
                        {
                          attrs: {
                            icon: "ion ion-md-cog",
                            active:
                              _vm.isMenuActive("/settings") ||
                              _vm.isMenuActive("/tools") ||
                              _vm.isMenuActive("/support-access"),
                            open:
                              _vm.isMenuOpen("/settings") ||
                              _vm.isMenuOpen("/tools") ||
                              _vm.isMenuOpen("/support-access")
                          }
                        },
                        [
                          _c("template", { slot: "link-text" }, [
                            _vm._v("Settings")
                          ]),
                          _vm._v(" "),
                          _vm.ability.can("read", "Setting-whitelabel")
                            ? _c(
                                "sidenav-router-link",
                                {
                                  attrs: {
                                    to: "/settings/white-label",
                                    exact: true
                                  }
                                },
                                [_vm._v("White Label")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.ability.can("read", "Room") && _vm.p == 1
                            ? _c(
                                "sidenav-router-link",
                                {
                                  attrs: { to: "/settings/room", exact: true }
                                },
                                [_vm._v("Room Management")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.ability.can("read", "Setting-company") ||
                          _vm.ability.can("update", "Setting-company")
                            ? _c(
                                "sidenav-router-link",
                                {
                                  attrs: {
                                    to: "/settings/company",
                                    exact: true
                                  }
                                },
                                [_vm._v("Company")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "sidenav-menu",
                            {
                              attrs: {
                                icon: "",
                                active:
                                  _vm.isMenuActive("/settings/picklists") ||
                                  _vm.isMenuActive("/settings/custom-field") ||
                                  _vm.isMenuActive(
                                    "/settings/equipment-categories"
                                  ),
                                open:
                                  _vm.isMenuOpen("/settings/picklists") ||
                                  _vm.isMenuOpen("/settings/custom-field") ||
                                  _vm.isMenuOpen(
                                    "/settings/equipment-categories"
                                  )
                              }
                            },
                            [
                              _c("template", { slot: "link-text" }, [
                                _vm._v("Custom Fields")
                              ]),
                              _vm._v(" "),
                              _vm.ability.can("read", "Setting-picklist") ||
                              _vm.ability.can("update", "Setting-picklist")
                                ? _c(
                                    "sidenav-router-link",
                                    {
                                      attrs: {
                                        to: "/settings/picklists",
                                        exact: true
                                      }
                                    },
                                    [_vm._v("Picklists")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.p == 1 &&
                              (_vm.ability.can("read", "Setting-CustomField") ||
                                _vm.ability.can(
                                  "update",
                                  "Setting-CustomField"
                                ))
                                ? _c(
                                    "sidenav-router-link",
                                    {
                                      attrs: {
                                        to: "/settings/custom-field",
                                        exact: true
                                      }
                                    },
                                    [_vm._v("Custom Fields")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.ability.can(
                                "read",
                                "Setting-equipmentCategory"
                              ) ||
                              _vm.ability.can(
                                "update",
                                "Setting-equipmentCategory"
                              )
                                ? _c(
                                    "sidenav-router-link",
                                    {
                                      attrs: {
                                        to: "/settings/equipment-categories",
                                        exact: true
                                      }
                                    },
                                    [_vm._v("Equipment Categories")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "sidenav-router-link",
                                { attrs: { to: "/settings/pricelists" } },
                                [_vm._v("\n            Price List\n          ")]
                              )
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.ability.can("read", "Setting-Coupon") ||
                          _vm.ability.can("update", "Setting-Coupon")
                            ? _c(
                                "sidenav-router-link",
                                {
                                  attrs: {
                                    to: "/settings/coupons",
                                    exact: true
                                  }
                                },
                                [_vm._v("Coupons")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.ability.can("read", "Setting-company") ||
                          _vm.ability.can("update", "Setting-company")
                            ? _c(
                                "sidenav-router-link",
                                {
                                  attrs: {
                                    to: "/settings/integrations",
                                    exact: true
                                  }
                                },
                                [_vm._v("Integrations")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.ability.can("read", "Setting-Form") ||
                          _vm.ability.can("update", "Setting-Form")
                            ? _c(
                                "sidenav-router-link",
                                {
                                  attrs: { to: "/settings/forms", exact: true }
                                },
                                [_vm._v("Forms")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.ability.can("read", "Import")
                            ? _c(
                                "sidenav-router-link",
                                {
                                  attrs: { to: "/tools/imports", exact: true }
                                },
                                [_vm._v("Data Import")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.ability.can("read", "Export")
                            ? _c(
                                "sidenav-router-link",
                                {
                                  attrs: { to: "/tools/exports", exact: true }
                                },
                                [_vm._v("Data Export")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.roles.includes("Admin")
                            ? _c(
                                "sidenav-router-link",
                                {
                                  attrs: { to: "/support-access", exact: true }
                                },
                                [_vm._v("Support Access")]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ability.can("read", "Account-and-billing") &&
                  _vm.showAccountBilling()
                    ? _c(
                        "sidenav-router-link",
                        {
                          attrs: {
                            icon: "ion ion-md-card",
                            to: "/account/billing",
                            exact: true
                          }
                        },
                        [_vm._v("Account & Billing")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }