import Layout1 from '@/layout/Layout1'

export default [{
  path: '/tasks/',
  component: Layout1,
  children: [{
    name: 'tasks-list',
    path: '',
    component: () => import('@/components/tasks/TasksList')
  }
  ]
}]
