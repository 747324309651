import Layout1 from '@/layout/Layout1'

export default [{
  path: '/crm/contacts/',
  component: Layout1,
  children: [{
    name: 'contacts-list',
    path: '',
    component: () => import('@/components/contacts/ContactsList')
  }, {
    name: 'contacts-view',
    path: ':id',
    props: true,
    component: () => import('@/components/contacts/ContactView')
  }]
}]
