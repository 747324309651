import Layout1 from '@/layout/Layout1'

export default [{
  path: '/invoices',
  component: Layout1,
  children: [{
    name: 'invoice-list',
    path: '/',
    component: () => import('@/components/invoices/InvoiceList')
  },{
    name: 'invoice-create',
    path: 'create',
    component: () => import('@/components/invoices/InvoiceCreate'),
    props : true
  },{
    name: 'invoice-edit',
    path: 'edit/:id',
    props : true,
    component: () => import('@/components/invoices/InvoiceEdit')
  },{
    name: 'invoice-view',
    path: ':id',
    props : true,
    component: () => import('@/components/invoices/InvoiceDetail')
  },{
    name: 'invoice-payment',
    path: ':id/payment',
    props : true,
    component: () => import('@/components/invoices/InvoicePayment')
  }]
}]
