var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            "no-close-on-backdrop": true,
            id: "create-new-booking",
            size: "lg",
            "hide-header-close": "",
            "no-enforce-focus": "",
            "footer-class": "p-0 ml-0 d-block"
          },
          on: { hide: _vm.onClose, show: _vm.onShow, close: _vm.onClose },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "modal-header" }, slot: "modal-header" },
            [
              _c(
                "span",
                {
                  staticClass: "modal-title",
                  staticStyle: { "font-size": "1rem", "font-weight": "500" }
                },
                [
                  _vm.bookingId && _vm.bookingOrderId == null
                    ? _c("span", [_vm._v(" Edit Booking ")])
                    : _vm.bookingId && _vm.bookingOrderId
                    ? _c("span", [
                        _vm._v("\n          Reschedule Booking Order\n        ")
                      ])
                    : _c("span", [_vm._v(" Create Booking ")])
                ]
              ),
              _vm._v(" "),
              _c(
                "b-form",
                {
                  staticStyle: {
                    position: "absolute",
                    right: "4rem",
                    top: "38%"
                  },
                  attrs: { inline: "" }
                },
                [
                  _c("span", { staticClass: "mr-3" }, [_vm._v(" Pending ")]),
                  _vm._v(" "),
                  _c("label", { staticClass: "switcher switcher-success" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.booking.is_pending,
                          expression: "booking.is_pending"
                        }
                      ],
                      staticClass: "switcher-input",
                      attrs: {
                        disabled:
                          _vm.bookingOrderApproved == 0 && _vm.bookingOrderData,
                        type: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.booking.is_pending)
                          ? _vm._i(_vm.booking.is_pending, null) > -1
                          : _vm.booking.is_pending
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.booking.is_pending,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.booking,
                                  "is_pending",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.booking,
                                  "is_pending",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.booking, "is_pending", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "switcher-indicator" }, [
                      _c("span", { staticClass: "switcher-yes text-success" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "switcher-no" })
                    ]),
                    _vm._v(" "),
                    !_vm.booking.is_pending
                      ? _c("span", { staticClass: "switcher-label" }, [
                          _vm._v("No")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.booking.is_pending
                      ? _c("span", { staticClass: "switcher-label" }, [
                          _vm._v("Yes")
                        ])
                      : _vm._e()
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "aria-label": "Close" },
                  on: {
                    click: function($event) {
                      return _vm.onClose()
                    }
                  }
                },
                [_vm._v("\n        ×\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.bookingOrderData
            ? _c(
                "b-form-row",
                [
                  _c("b-form-group", { staticClass: "col-sm-12 text-right" }, [
                    _c("span", { staticClass: "mr-2" }, [
                      _vm._v(" Approval Status: ")
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "switcher switcher-success" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.bookingOrderApproved,
                            expression: "bookingOrderApproved"
                          }
                        ],
                        staticClass: "switcher-input",
                        attrs: {
                          disabled: _vm.bookingOrderApproved == 1,
                          type: "checkbox"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.bookingOrderApproved)
                            ? _vm._i(_vm.bookingOrderApproved, null) > -1
                            : _vm.bookingOrderApproved
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.bookingOrderApproved,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.bookingOrderApproved = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.bookingOrderApproved = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.bookingOrderApproved = $$c
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "switcher-indicator" }, [
                        _c("span", {
                          staticClass: "switcher-yes text-success"
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "switcher-no text-warning" })
                      ]),
                      _vm._v(" "),
                      !_vm.bookingOrderApproved
                        ? _c("span", { staticClass: "switcher-label" }, [
                            _vm._v("Waiting")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bookingOrderApproved
                        ? _c("span", { staticClass: "switcher-label" }, [
                            _vm._v("Approved")
                          ])
                        : _vm._e()
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.booking.invoice
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col text-right" }, [
                  _c(
                    "a",
                    {
                      staticClass: "text-primary",
                      attrs: { target: "_blank" },
                      on: {
                        click: function($event) {
                          return _vm.viewInvoice(_vm.booking.invoice.id)
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-info-circle" }),
                      _vm._v(
                        " Invoice : #" +
                          _vm._s(_vm.booking.invoice.invoice_number)
                      )
                    ]
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                { staticClass: "col-12", attrs: { label: "Title" } },
                [
                  _c("b-input", {
                    ref: "name",
                    class: {
                      "is-invalid":
                        _vm.bookingSubmitted && _vm.$v.booking.title.$error
                    },
                    model: {
                      value: _vm.booking.title,
                      callback: function($$v) {
                        _vm.$set(_vm.booking, "title", $$v)
                      },
                      expression: "booking.title"
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.booking.title.required
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v("\n          Title field is required.\n        ")
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                { staticClass: "col-sm-12", attrs: { label: "Association ?" } },
                [
                  _c("label", { staticClass: "switcher switcher-success" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.isAssociate,
                          expression: "isAssociate"
                        }
                      ],
                      staticClass: "switcher-input",
                      attrs: {
                        disabled:
                          this.bookingOrderData &&
                          this.bookingOrderData.status == "waiting",
                        type: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.isAssociate)
                          ? _vm._i(_vm.isAssociate, null) > -1
                          : _vm.isAssociate
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.isAssociate,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.isAssociate = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.isAssociate = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.isAssociate = $$c
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "switcher-indicator" }, [
                      _c("span", { staticClass: "switcher-yes text-success" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "switcher-no" })
                    ]),
                    _vm._v(" "),
                    !_vm.isAssociate
                      ? _c("span", { staticClass: "switcher-label" }, [
                          _vm._v("No")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAssociate
                      ? _c("span", { staticClass: "switcher-label" }, [
                          _vm._v("Yes")
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.isAssociate
            ? _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-lg-12",
                              attrs: { label: "Associate With Type" }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  disabled:
                                    this.bookingOrderData &&
                                    this.bookingOrderData.status == "waiting",
                                  options: _vm.associate_type_options
                                },
                                model: {
                                  value: _vm.booking.associate_type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.booking, "associate_type", $$v)
                                  },
                                  expression: "booking.associate_type"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-row",
                        [
                          _vm.booking.associate_type && _vm.booking.associate_id
                            ? _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: "associate-with",
                                    show: _vm.showAssociateWithPopover,
                                    placement: "top"
                                  },
                                  on: {
                                    "update:show": function($event) {
                                      _vm.showAssociateWithPopover = $event
                                    }
                                  }
                                },
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      "\n              Contact Information\n              "
                                    ),
                                    _c("span", { staticClass: "float-right" }, [
                                      _c("i", {
                                        staticClass: "fa fa-times",
                                        on: {
                                          click: function($event) {
                                            _vm.showAssociateWithPopover = false
                                          }
                                        }
                                      })
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _vm.booking.associate_id &&
                                  _vm.booking.associate_id.primary_contact
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          [
                                            _vm._v(
                                              "\n                Name:\n                "
                                            ),
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "contacts-view",
                                                    params: {
                                                      id:
                                                        _vm.booking.associate_id
                                                          .primary_contact.id
                                                    }
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.booking.associate_id
                                                        .primary_contact
                                                        .first_name
                                                    ) +
                                                    "\n                  " +
                                                    _vm._s(
                                                      _vm.booking.associate_id
                                                        .primary_contact
                                                        .last_name
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(
                                            "\n                Email: " +
                                              _vm._s(
                                                _vm.booking.associate_id
                                                  .primary_contact.email
                                              ) +
                                              "\n              "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(
                                            "\n                Phone Number:\n                " +
                                              _vm._s(
                                                _vm.booking.associate_id
                                                  .primary_contact.phone_number
                                                  ? _vm.booking.associate_id
                                                      .primary_contact
                                                      .phone_number
                                                  : "-"
                                              ) +
                                              "\n              "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _vm.booking.associate_id.primary_contact
                                          .band &&
                                        _vm.booking.associate_id.primary_contact
                                          .band.length == 1
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                Artist/Group:\n                " +
                                                  _vm._s(
                                                    _vm.booking.associate_id
                                                      .primary_contact.band[0]
                                                      .name
                                                  ) +
                                                  "\n              "
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.booking.associate_id.primary_contact
                                          .band &&
                                        _vm.booking.associate_id.primary_contact
                                          .band.length > 1
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                Artist/Group:\n                " +
                                                  _vm._s(
                                                    _vm.mapBandNames(
                                                      _vm.booking.associate_id
                                                        .primary_contact.band
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.booking.associate_id.primary_contact
                                          .band &&
                                        _vm.booking.associate_id.primary_contact
                                          .band.length == 0
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                Artist/Group: -\n              "
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.booking.associate_id.primary_contact
                                          .band &&
                                        _vm.booking.associate_id.primary_contact
                                          .band.length == 1
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                Genre:\n                " +
                                                  _vm._s(
                                                    _vm.booking.associate_id
                                                      .primary_contact.band[0]
                                                      .genre_name
                                                  ) +
                                                  "\n              "
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.booking.associate_id.primary_contact
                                          .band &&
                                        _vm.booking.associate_id.primary_contact
                                          .band.length > 1
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                Genre:\n                " +
                                                  _vm._s(
                                                    _vm.mapGenreNames(
                                                      _vm.booking.associate_id
                                                        .primary_contact.band
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.booking.associate_id.primary_contact
                                          .band &&
                                        _vm.booking.associate_id.primary_contact
                                          .band.length == 0
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                Genre: -\n              "
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    : _c("div", [
                                        _c("div", [
                                          _vm._v(
                                            "No contact record found for selected association."
                                          )
                                        ])
                                      ])
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-lg-12",
                              attrs: {
                                label: "Associate With",
                                id: "associate-with"
                              },
                              nativeOn: {
                                mouseover: function($event) {
                                  _vm.showAssociateWithPopover = true
                                }
                              }
                            },
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(
                                  "\n              Associate With\n              "
                                ),
                                this.bookingOrderData == null ||
                                (this.bookingOrderData &&
                                  this.bookingOrderData.status != "waiting")
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "text-primary ml-3",
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            return _vm.createForm(
                                              _vm.booking.associate_type
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "ion ion-md-add-circle"
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              _c("multiselect", {
                                class: {
                                  "is-invalid":
                                    _vm.$v.booking.associate_id.$error
                                },
                                attrs: {
                                  disabled:
                                    (this.bookingOrderData &&
                                      this.bookingOrderData.status ==
                                        "waiting") ||
                                    !_vm.associateOptionsFullyLoaded,
                                  options: _vm.associate_id_options,
                                  multiple: false,
                                  placeholder: "Type to search",
                                  "track-by": "id",
                                  label: "text"
                                },
                                model: {
                                  value: _vm.booking.associate_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.booking, "associate_id", $$v)
                                  },
                                  expression: "booking.associate_id"
                                }
                              }),
                              _vm._v(" "),
                              _vm.bookingSubmitted &&
                              !_vm.$v.booking.associate_id.required
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "invalid-feedback",
                                      staticStyle: { display: "inline-block" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              The associate with field is required.\n            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-sm-12",
                  attrs: { label: "All Day Booking" }
                },
                [
                  _c("label", { staticClass: "switcher switcher-success" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.booking.all_day,
                          expression: "booking.all_day"
                        }
                      ],
                      staticClass: "switcher-input",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.booking.all_day)
                          ? _vm._i(_vm.booking.all_day, null) > -1
                          : _vm.booking.all_day
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.booking.all_day,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.booking,
                                  "all_day",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.booking,
                                  "all_day",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.booking, "all_day", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "switcher-indicator" }, [
                      _c("span", { staticClass: "switcher-yes text-success" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "switcher-no" })
                    ]),
                    _vm._v(" "),
                    !_vm.booking.all_day
                      ? _c("span", { staticClass: "switcher-label" }, [
                          _vm._v("No")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.booking.all_day
                      ? _c("span", { staticClass: "switcher-label" }, [
                          _vm._v("Yes")
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "Booking Starts At" } },
                    [
                      _c(
                        "div",
                        { staticClass: "input-group" },
                        [
                          _c("flat-pickr", {
                            class: {
                              "is-invalid":
                                _vm.bookingSubmitted &&
                                _vm.$v.booking.start_at.$error
                            },
                            attrs: {
                              config: _vm.datePickerConfig,
                              placeholder: "Select Booking Start At",
                              name: "start_date"
                            },
                            on: {
                              change: function($event) {
                                return _vm.$v.booking.start_at.$touch()
                              }
                            },
                            model: {
                              value: _vm.booking.start_at,
                              callback: function($$v) {
                                _vm.$set(_vm.booking, "start_at", $$v)
                              },
                              expression: "booking.start_at"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "input-group-btn" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-default",
                                attrs: {
                                  type: "button",
                                  title: "Toggle",
                                  "data-toggle": ""
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-calendar" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "sr-only",
                                      attrs: { "aria-hidden": "true" }
                                    },
                                    [_vm._v("Toggle")]
                                  )
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          !_vm.$v.booking.start_at.required
                            ? _c(
                                "div",
                                {
                                  staticClass: "invalid-feedback",
                                  staticStyle: { display: "inline-block" }
                                },
                                [
                                  _vm._v(
                                    "\n              Booking Starts At field is required.\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                [
                  _c("b-form-group", { attrs: { label: "Booking Ends At" } }, [
                    _c(
                      "div",
                      { staticClass: "input-group" },
                      [
                        _c("flat-pickr", {
                          class: {
                            "is-invalid":
                              _vm.bookingSubmitted &&
                              _vm.$v.booking.end_at.$error
                          },
                          attrs: {
                            config: _vm.datePickerConfig,
                            placeholder: "Select Booking Start At",
                            name: "start_date"
                          },
                          model: {
                            value: _vm.booking.end_at,
                            callback: function($$v) {
                              _vm.$set(_vm.booking, "end_at", $$v)
                            },
                            expression: "booking.end_at"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group-btn" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-default",
                              attrs: {
                                type: "button",
                                title: "Toggle",
                                "data-toggle": ""
                              }
                            },
                            [
                              _c("i", { staticClass: "fa fa-calendar" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "sr-only",
                                    attrs: { "aria-hidden": "true" }
                                  },
                                  [_vm._v("Toggle")]
                                )
                              ])
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        !_vm.$v.booking.end_at.required
                          ? _c(
                              "div",
                              {
                                staticClass: "invalid-feedback",
                                staticStyle: { display: "inline-block" }
                              },
                              [
                                _vm._v(
                                  "\n              Booking Ends At field is required.\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.$v.booking.end_at.minValue
                          ? _c(
                              "div",
                              {
                                staticClass: "invalid-feedback",
                                staticStyle: { display: "inline-block" }
                              },
                              [
                                _vm._v(
                                  "\n              Booking Ends At field must be after Starts At field.\n            "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-sm-12",
                  attrs: { label: "Recurring Booking? " }
                },
                [
                  _c("label", { staticClass: "switcher switcher-success" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.booking.is_recurring,
                          expression: "booking.is_recurring"
                        }
                      ],
                      staticClass: "switcher-input",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.booking.is_recurring)
                          ? _vm._i(_vm.booking.is_recurring, null) > -1
                          : _vm.booking.is_recurring
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.booking.is_recurring,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.booking,
                                  "is_recurring",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.booking,
                                  "is_recurring",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.booking, "is_recurring", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "switcher-indicator" }, [
                      _c("span", { staticClass: "switcher-yes text-success" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "switcher-no" })
                    ]),
                    _vm._v(" "),
                    !_vm.booking.is_recurring
                      ? _c("span", { staticClass: "switcher-label" }, [
                          _vm._v("No")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.booking.is_recurring
                      ? _c("span", { staticClass: "switcher-label" }, [
                          _vm._v("Yes")
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.booking.is_recurring
            ? _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-lg-12",
                              attrs: { label: _vm.repeatEveryLabel }
                            },
                            [
                              _c("b-input", {
                                ref: "recurring_repeat_type_value",
                                model: {
                                  value:
                                    _vm.booking.recurring_options
                                      .repeat_type_value,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.booking.recurring_options,
                                      "repeat_type_value",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "booking.recurring_options.repeat_type_value"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-lg-12",
                              attrs: { label: "Repeat Interval" }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  name: "recurring_repeat_type",
                                  options: _vm.recurringRepeatTypeOptions
                                },
                                model: {
                                  value:
                                    _vm.booking.recurring_options.repeat_type,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.booking.recurring_options,
                                      "repeat_type",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "booking.recurring_options.repeat_type"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.booking.is_recurring
            ? _c(
                "b-form-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-lg-12",
                              attrs: { label: "End Repeat By" }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  name: "recurring_repeat_type",
                                  options: _vm.recurringEndOptions
                                },
                                model: {
                                  value:
                                    _vm.booking.recurring_options.repeat_end,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.booking.recurring_options,
                                      "repeat_end",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "booking.recurring_options.repeat_end"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-lg-12",
                              attrs: { label: _vm.repeatEndLabel }
                            },
                            [
                              _vm.booking.recurring_options.repeat_end ==
                              "occurence"
                                ? _c(
                                    "div",
                                    [
                                      _c("b-input", {
                                        ref: "recurring_repeat_end_value",
                                        model: {
                                          value:
                                            _vm.booking.recurring_options
                                              .repeat_end_value,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.booking.recurring_options,
                                              "repeat_end_value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "booking.recurring_options.repeat_end_value"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.booking.recurring_options.repeat_end == "date"
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "input-group" },
                                      [
                                        _c("flat-pickr", {
                                          attrs: {
                                            config: _vm.dateOnlyPickerConfig,
                                            name: "repeat_end_value"
                                          },
                                          model: {
                                            value:
                                              _vm.booking.recurring_options
                                                .repeat_end_value,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.booking.recurring_options,
                                                "repeat_end_value",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "booking.recurring_options.repeat_end_value"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "input-group-btn" },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-default",
                                                attrs: {
                                                  type: "button",
                                                  title: "Toggle",
                                                  "data-toggle": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass:
                                                      "fa fa-calendar"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "sr-only",
                                                        attrs: {
                                                          "aria-hidden": "true"
                                                        }
                                                      },
                                                      [_vm._v("Toggle")]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.booking.is_recurring && _vm.booking.recurring_schedules.length > 0
            ? _c(
                "div",
                { staticClass: "alert alert-info" },
                [
                  _c("div", [
                    _c("i", { staticClass: "fa fa-info-circle" }),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(
                        "\n          The following schedules will be\n          "
                      ),
                      _vm.bookingId
                        ? _c("span", [_vm._v("adjusted")])
                        : _c("span", [_vm._v("created")]),
                      _vm._v(":\n        ")
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.booking.recurring_schedules, function(
                    schedule,
                    index
                  ) {
                    return _c("span", { key: index, staticClass: "mr-1" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(schedule.start_at.format("L LT")) +
                          "-" +
                          _vm._s(schedule.end_at.format("LT"))
                      ),
                      index != _vm.booking.recurring_schedules.length - 1
                        ? _c("span", [_vm._v(",")])
                        : _vm._e()
                    ])
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                { staticClass: "col-12", attrs: { label: "Guest(s)" } },
                [
                  _c("multiselect", {
                    attrs: {
                      loading: _vm.isContactLoading,
                      "custom-label": _vm.emailWithNameLabel,
                      options: _vm.contactOptions,
                      multiple: true,
                      placeholder: "Type to search",
                      "track-by": "id",
                      label: "name",
                      "max-height": 600,
                      "show-no-results": true,
                      "hide-selected": true,
                      taggable: true,
                      searchable: true,
                      "internal-search": false,
                      "show-no-options": false,
                      "tag-placeholder": "Add this as new email"
                    },
                    on: {
                      tag: _vm.addNewGuestsEmail,
                      "search-change": _vm.asyncContactFind
                    },
                    model: {
                      value: _vm.booking.guests,
                      callback: function($$v) {
                        _vm.$set(_vm.booking, "guests", $$v)
                      },
                      expression: "booking.guests"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("strong", [_vm._v(" Assigned Users ")]),
              _vm._v(" "),
              _vm._l(_vm.$v.booking.booking_users.$each.$iter, function(
                v,
                index
              ) {
                return _c(
                  "div",
                  { key: index, staticClass: "bg-lighter border-dashed mt-2" },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "fa fa-times text-danger mt-2 ml-2",
                      attrs: { title: "Delete Assigned User" },
                      on: {
                        click: function($event) {
                          return _vm.deleteBookingUserRow(index)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "b-form-row",
                      { staticClass: "p-2 pl-3 pb-3 pb-3" },
                      [
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-row",
                              [
                                _c(
                                  "b-form-group",
                                  { staticClass: "col-lg-12" },
                                  [
                                    _c("template", { slot: "label" }, [
                                      _vm._v(" Assigned User ")
                                    ]),
                                    _vm._v(" "),
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.assignedUserOptions,
                                        state: v.user_id.$error
                                          ? "invalid"
                                          : null
                                      },
                                      model: {
                                        value: v.user_id.$model,
                                        callback: function($$v) {
                                          _vm.$set(v.user_id, "$model", $$v)
                                        },
                                        expression: "v.user_id.$model"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm.bookingSubmitted && !v.user_id.required
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "\n                  The assigned user field is required.\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          [
                            _c(
                              "b-form-row",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "col-lg-12",
                                    attrs: { label: "Booking Role" }
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.bookingRoleOptions,
                                        state: v.role.$error ? "invalid" : null
                                      },
                                      model: {
                                        value: v.role.$model,
                                        callback: function($$v) {
                                          _vm.$set(v.role, "$model", $$v)
                                        },
                                        expression: "v.role.$model"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm.bookingSubmitted && !v.role.required
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              "\n                  The booking role field is required.\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "mt-2" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-sm btn-primary text-white",
                    on: { click: _vm.addBookingUserRow }
                  },
                  [
                    _c("i", { staticClass: "ion ion-md-add-circle" }),
                    _vm._v(" Assigned User\n        ")
                  ]
                )
              ]),
              _vm._v(" "),
              _c("hr")
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-12",
                          attrs: { label: "Assigned User Reminder" }
                        },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.booking_reminder_options },
                            model: {
                              value: _vm.booking.reminder,
                              callback: function($$v) {
                                _vm.$set(_vm.booking, "reminder", $$v)
                              },
                              expression: "booking.reminder"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                { staticClass: "col-12", attrs: { label: "Room" } },
                [
                  _c("b-form-select", {
                    class: {
                      "is-invalid":
                        _vm.bookingSubmitted && _vm.$v.booking.room_id.$error
                    },
                    attrs: { options: _vm.roomOptions },
                    on: {
                      change: function($event) {
                        return _vm.$v.booking.room_id.$touch()
                      }
                    },
                    model: {
                      value: _vm.booking.room_id,
                      callback: function($$v) {
                        _vm.$set(_vm.booking, "room_id", $$v)
                      },
                      expression: "booking.room_id"
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.booking.room_id.required
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v("\n          Room field is required.\n        ")
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                { staticClass: "col-12", attrs: { label: "Event Type" } },
                [
                  _c("b-form-select", {
                    class: {
                      "is-invalid":
                        _vm.bookingSubmitted &&
                        _vm.$v.booking.event_type_id.$error
                    },
                    attrs: { options: _vm.event_type_options },
                    model: {
                      value: _vm.booking.event_type_id,
                      callback: function($$v) {
                        _vm.$set(_vm.booking, "event_type_id", $$v)
                      },
                      expression: "booking.event_type_id"
                    }
                  }),
                  _vm._v(" "),
                  !_vm.$v.booking.event_type_id.required
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n          Event Type field is required.\n        "
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.booking.associate_type == "Project"
            ? _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-lg-12" },
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n          Project Songs\n\n          "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "ml-1 btn btn-sm btn-primary text-white",
                            on: { click: _vm.addNewSong }
                          },
                          [
                            _c("i", { staticClass: "ion ion-md-add-circle" }),
                            _vm._v(" New Project Song\n          ")
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("multiselect", {
                        attrs: {
                          options: _vm.songOptions,
                          multiple: true,
                          placeholder: "Type to search",
                          "track-by": "id",
                          label: "text"
                        },
                        model: {
                          value: _vm.booking.booking_songs,
                          callback: function($$v) {
                            _vm.$set(_vm.booking, "booking_songs", $$v)
                          },
                          expression: "booking.booking_songs"
                        }
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.departmentEnabled
            ? _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-12", attrs: { label: "Content Team" } },
                    [
                      _c("b-form-select", {
                        class: {
                          "is-invalid":
                            _vm.bookingSubmitted &&
                            _vm.$v.booking.department_id.$error
                        },
                        attrs: { options: _vm.departmentOptions },
                        model: {
                          value: _vm.booking.department_id,
                          callback: function($$v) {
                            _vm.$set(_vm.booking, "department_id", $$v)
                          },
                          expression: "booking.department_id"
                        }
                      }),
                      _vm._v(" "),
                      !_vm.$v.booking.department_id.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n          Content Team field is required.\n        "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasAddon("equipment-tracking")
            ? _c(
                "div",
                { staticClass: "bg-lighter border-dashed mt-2 p-3" },
                [
                  _vm.bookingId
                    ? _c(
                        "b-form-row",
                        [
                          _c("b", [_vm._v("Equipment")]),
                          _vm._v(" "),
                          _c(
                            "b-btn",
                            {
                              staticClass: "ml-2",
                              attrs: { variant: "primary", size: "sm" },
                              on: { click: _vm.showEquipmentForm }
                            },
                            [_vm._v("Add/Remove Equipment")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.bookingId
                    ? _c(
                        "table",
                        { staticClass: "table mt-2" },
                        [
                          _c("tr", [
                            _c("th", [_vm._v("NAME")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("SERIAL #")]),
                            _vm._v(" "),
                            _c("th")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.equipmentList, function(
                            trackingEquipment
                          ) {
                            return _c("tr", { key: trackingEquipment.id }, [
                              _c("td", [
                                _vm._v(_vm._s(trackingEquipment.equipment.name))
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    trackingEquipment.equipment.serial_number
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              trackingEquipment.action == "Check In"
                                ? _c(
                                    "td",
                                    [
                                      _c(
                                        "b-badge",
                                        { attrs: { variant: "success" } },
                                        [_vm._v("Checked In")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          })
                        ],
                        2
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-form-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-form-group",
                { staticClass: "col-12", attrs: { label: "Description" } },
                [
                  _c("b-textarea", {
                    attrs: { rows: "5" },
                    model: {
                      value: _vm.booking.description,
                      callback: function($$v) {
                        _vm.$set(_vm.booking, "description", $$v)
                      },
                      expression: "booking.description"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.booking.zoom_data &&
          _vm.booking.zoom_data.start_url &&
          _vm.booking.zoom_data.join_url
            ? _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-12", attrs: { label: "Zoom Links" } },
                    [
                      _c("div", [
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: _vm.booking.zoom_data.start_url
                            }
                          },
                          [
                            _vm._v(
                              "\n            Start Zoom Meeting\n          "
                            )
                          ]
                        ),
                        _vm._v("\n          or\n          "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.copyToClipboard(
                                  _vm.booking.zoom_data.start_url,
                                  "zoom-start-url"
                                )
                              }
                            }
                          },
                          [
                            _vm._v("\n            Copy Zoom Start URL "),
                            _c("i", { staticClass: "fa fa-copy" })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: _vm.booking.zoom_data.join_url
                            }
                          },
                          [
                            _vm._v(
                              "\n            Join Zoom Meeting\n          "
                            )
                          ]
                        ),
                        _vm._v("\n          or\n          "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                return _vm.copyToClipboard(
                                  _vm.booking.zoom_data.join_url,
                                  "zoom-join-url"
                                )
                              }
                            }
                          },
                          [
                            _vm._v("\n            Copy Zoom Join URL "),
                            _c("i", { staticClass: "fa fa-copy" })
                          ]
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "template",
            { slot: "modal-footer" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isCustomFieldsAvailable,
                      expression: "isCustomFieldsAvailable"
                    },
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.collapse-2",
                      modifiers: { "collapse-2": true }
                    }
                  ],
                  staticClass: "ml-3 mr-3 row"
                },
                [
                  _c("div", { staticClass: "pt-2 pb-2 col" }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v(" Custom Fields ")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right col" }, [
                    _vm.customFieldsVisible
                      ? _c("i", { staticClass: "fa fa-chevron-up pt-3" })
                      : _c("i", { staticClass: "fa fa-chevron-down pt-3" })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isCustomFieldsAvailable,
                      expression: "isCustomFieldsAvailable"
                    }
                  ],
                  staticClass: "mt-2 ml-3 mr-3 row",
                  attrs: { id: "collapse-2" },
                  model: {
                    value: _vm.customFieldsVisible,
                    callback: function($$v) {
                      _vm.customFieldsVisible = $$v
                    },
                    expression: "customFieldsVisible"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c("custom-field-form-template", {
                        attrs: {
                          data: _vm.booking,
                          object: "Booking",
                          showHeaderTitle: false
                        },
                        on: {
                          "update:data": function($event) {
                            _vm.booking = $event
                          },
                          "custom-fields-loaded": _vm.handleCustomFieldsLoaded
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c("hr", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCustomFieldsAvailable,
                    expression: "isCustomFieldsAvailable"
                  }
                ],
                staticClass: "border-light m-0 mt-3"
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle.collapse-1",
                      modifiers: { "collapse-1": true }
                    }
                  ],
                  staticClass: "ml-3 mr-3 row"
                },
                [
                  _c("div", { staticClass: "pt-2 pb-2 col" }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v(" To Dos ")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-right col" }, [
                    _vm.notesVisible
                      ? _c("i", { staticClass: "fa fa-chevron-up pt-3" })
                      : _c("i", { staticClass: "fa fa-chevron-down pt-3" })
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                {
                  staticClass: "mt-2 ml-3 mr-3",
                  attrs: { id: "collapse-1" },
                  model: {
                    value: _vm.notesVisible,
                    callback: function($$v) {
                      _vm.notesVisible = $$v
                    },
                    expression: "notesVisible"
                  }
                },
                [
                  _c("div", { staticClass: "text-right col mb-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary text-right",
                        attrs: { type: "button" },
                        on: { click: _vm.showCreateBookingTodo }
                      },
                      [
                        _c("i", { staticClass: "ion ion-md-add-circle" }),
                        _vm._v(" Create New To Do\n          ")
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c("table", { staticClass: "table table-bordered" }, [
                      _c("thead", [
                        _c("tr", [
                          _vm.isMobile == false
                            ? _c("td", [_vm._v("Due Date")])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("td", [_vm._v("Title")]),
                          _vm._v(" "),
                          _vm.isMobile == false
                            ? _c("td", [_vm._v("Assigned User")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isMobile == false
                            ? _c("td", [_vm._v("Status")])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("td")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        [
                          _vm.booking.todos.length >= 1
                            ? _vm._l(_vm.booking.todos, function(todo, index) {
                                return _c("tr", { key: index }, [
                                  _vm.isMobile == false
                                    ? _c("td", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm._f("moment")(
                                                todo.due_date_tz,
                                                _vm.datetime_format
                                              )
                                            ) +
                                            "\n                  "
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(todo.title) +
                                        "\n                    "
                                    ),
                                    _vm.isMobile
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                      Due Date:\n                      "
                                          ),
                                          _c("span", { staticClass: "ml-1" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    todo.due_date_tz,
                                                    _vm.datetime_format
                                                  )
                                                ) +
                                                "  "
                                            )
                                          ])
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isMobile
                                      ? _c(
                                          "div",
                                          [
                                            _vm._v(
                                              "\n                      Assigned User:\n                      "
                                            ),
                                            _vm._l(todo.assigned_user, function(
                                              user
                                            ) {
                                              return _c(
                                                "span",
                                                {
                                                  key: user.id,
                                                  staticClass:
                                                    "badge badge-info"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(user.full_name) +
                                                      " (" +
                                                      _vm._s(user.email) +
                                                      ")\n                      "
                                                  )
                                                ]
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isMobile
                                      ? _c("div", [
                                          _vm._v(
                                            "\n                      Status:\n                      "
                                          ),
                                          todo.completed
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge badge-success ml-1"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Completed\n                      "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge badge-warning ml-1"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Incomplete\n                      "
                                                  )
                                                ]
                                              )
                                        ])
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  _vm.isMobile == false
                                    ? _c(
                                        "td",
                                        _vm._l(todo.assigned_user, function(
                                          user
                                        ) {
                                          return _c(
                                            "span",
                                            {
                                              key: user.id,
                                              staticClass: "badge badge-info"
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(user.full_name) +
                                                  " (" +
                                                  _vm._s(user.email) +
                                                  ")\n                    "
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isMobile == false
                                    ? _c("td", [
                                        todo.completed
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge badge-success"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      Completed\n                    "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "badge badge-warning"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      Incomplete\n                    "
                                                )
                                              ]
                                            )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "b-btn",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true }
                                            }
                                          ],
                                          attrs: {
                                            variant:
                                              "outline-dark btn-xs icon-btn md-btn-flat",
                                            title: "Edit"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.editBookingTodo(todo)
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-pencil-alt"
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-btn",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true }
                                            }
                                          ],
                                          attrs: {
                                            variant:
                                              "danger btn-xs icon-btn md-btn-flat",
                                            title: "Delete"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteBookingTodo(todo)
                                            }
                                          }
                                        },
                                        [_c("i", { staticClass: "fas fa-ban" })]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              })
                            : [
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-center",
                                      attrs: { colspan: "5" }
                                    },
                                    [_vm._v("No data available.")]
                                  )
                                ])
                              ]
                        ],
                        2
                      )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "ml-0 mr-0 mt-0" }),
              _vm._v(" "),
              _c("div", { staticClass: "pl-4 pr-4" }, [
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Booking Notification & Reminder" } },
                      [
                        _c(
                          "label",
                          { staticClass: "switcher switcher-success" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.booking.client_reminder,
                                  expression: "booking.client_reminder"
                                }
                              ],
                              staticClass: "switcher-input",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.booking.client_reminder
                                )
                                  ? _vm._i(_vm.booking.client_reminder, null) >
                                    -1
                                  : _vm.booking.client_reminder
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.booking.client_reminder,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.booking,
                                          "client_reminder",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.booking,
                                          "client_reminder",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.booking,
                                      "client_reminder",
                                      $$c
                                    )
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "switcher-indicator" }, [
                              _c("span", {
                                staticClass: "switcher-yes text-success"
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "switcher-no" })
                            ]),
                            _vm._v(" "),
                            !_vm.booking.client_reminder
                              ? _c("span", { staticClass: "switcher-label" }, [
                                  _vm._v("No")
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.booking.client_reminder
                              ? _c("span", { staticClass: "switcher-label" }, [
                                  _vm._v("Yes")
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _vm.booking.booking_id
                          ? _c(
                              "b-button",
                              {
                                staticClass: "float-left",
                                attrs: {
                                  disabled: _vm.isLoading,
                                  variant: "primary"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.onDelete(_vm.booking)
                                  }
                                }
                              },
                              [_vm._v("Delete")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.isLoading,
                              variant: "default"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onClose()
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        ),
                        _vm._v(" "),
                        _vm.booking.booking_id
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  disabled: _vm.isLoading,
                                  variant: "default"
                                },
                                on: { click: _vm.cloneBooking }
                              },
                              [_vm._v("\n              Clone")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.$v.booking.$error || _vm.isLoading,
                              variant: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.saveBooking({ createInvoice: true })
                              }
                            }
                          },
                          [_vm._v("Save & Create Invoice ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: _vm.$v.booking.$error || _vm.isLoading,
                              variant: "primary"
                            },
                            on: { click: _vm.saveBooking }
                          },
                          [_vm._v("Save")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("contact-form", {
        ref: "contactFormModal",
        on: { "on-save": _vm.newContactAdded }
      }),
      _vm._v(" "),
      _c("company-form", {
        ref: "companyFormModal",
        on: { "on-save": _vm.newCompanyAdded }
      }),
      _vm._v(" "),
      _c("band-form", {
        ref: "bandFormModal",
        on: { "on-save": _vm.newBandAdded }
      }),
      _vm._v(" "),
      _c("lead-form", {
        ref: "leadFormModal",
        on: { "on-save": _vm.newLeadAdded }
      }),
      _vm._v(" "),
      _c("project-form", {
        ref: "projectFormModal",
        attrs: { "redirect-to-detail": false },
        on: { "on-save": _vm.newProjectAdded }
      }),
      _vm._v(" "),
      _c("project-song-form", {
        ref: "projectSongForm",
        attrs: {
          projectId: _vm.booking.associate_id
            ? _vm.booking.associate_id.id
            : null
        },
        on: { "on-save": _vm.newProjectSongAdded }
      }),
      _vm._v(" "),
      _c("equipment-booking-management-form", {
        ref: "equipmentBookingManagementFormModal",
        attrs: { "edit-id": _vm.bookingId },
        on: { "on-save": _vm.loadEquipmentList }
      }),
      _vm._v(" "),
      _c("todo-create-form", {
        ref: "todoCreateForm",
        attrs: {
          booking_id: _vm.bookingId,
          associate_type: _vm.booking.associate_type
            ? _vm.booking.associate_type
            : null,
          associate_id: _vm.booking.associate_id
            ? _vm.booking.associate_id.id
            : null,
          scenario: "booking-todo"
        },
        on: { "on-save": _vm.callbackAddBookingTodo }
      }),
      _vm._v(" "),
      _c("todo-edit-form", {
        ref: "todoEditForm",
        attrs: { default_task: _vm.selectedTodo, scenario: "booking-todo" },
        on: { "on-save": _vm.callbackEditBookingTodo }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }