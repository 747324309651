var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-wrapper layout-1 layout-without-sidenav" },
    [
      _c(
        "div",
        { staticClass: "layout-inner" },
        [
          _c("app-layout-navbar", { attrs: { sidenavToggle: false } }),
          _vm._v(" "),
          _c("div", { staticClass: "layout-container" }, [
            _c(
              "div",
              { staticClass: "layout-content" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "router-transitions container-fluid flex-grow-1"
                  },
                  [_c("router-view")],
                  1
                ),
                _vm._v(" "),
                _c("app-layout-footer")
              ],
              1
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }