<template>
  <div>
    <b-form-row>
      <b-form-group
        class="col-12"
        label="Project Type"
        v-if="projectTypeOptions.length > 1"
      >
        <b-form-radio-group
          v-model="data.type"
          buttons
          button-variant="outline-primary"
          class="project-type-radio mt-md-1"
        >
          <template v-for="option in projectTypeOptions">
            <b-form-radio
              :value="option.value"
              :key="option.text"
              class="project-type-radio-item"
            >
              <div>
                <i
                  v-if="option.value == 'music'"
                  class="fas fa-music project-type-radio-icon"
                ></i>
                <i
                  v-else-if="option.value == 'podcast'"
                  class="fas fa-microphone project-type-radio-icon"
                ></i>
                <i
                  v-else-if="option.value == 'audio-book'"
                  class="fas fa-book project-type-radio-icon"
                ></i>
                <i
                  v-else-if="option.value == 'post-production'"
                  class="fas fa-sliders-h project-type-radio-icon"
                ></i>
                <i
                  v-else-if="option.value == 'voiceover'"
                  class="fas fa-headset project-type-radio-icon"
                ></i>
                <i
                  v-else-if="option.value == 'other'"
                  class="fas fa-file-audio project-type-radio-icon"
                ></i>
              </div>
              <div class="project-type-radio-label">
                {{ option.text }}
              </div>
            </b-form-radio>
          </template>
        </b-form-radio-group>
      </b-form-group>

      <b-form-group v-if="data.type == 'music'" class="col-12">
        <template slot="label">
          Artist / Group
          <b-link
            :class="{
              'ml-1': 1,
              'text-primary': !data.copy_contact_info,
              'text-disabled': data.copy_contact_info
            }"
            :disabled="data.copy_contact_info"
            @click="createNewBandGroup"
            v-b-tooltip.hover
            title="Create New Artist/Group"
          >
            <i class="ion ion-md-add-circle"></i>
          </b-link>
        </template>

        <multiselect
         :disabled="data.copy_contact_info"
          v-model="data.band_id"
          id="band_id"
          label="name"
          track-by="id"
          placeholder="Type to search"
          open-direction="bottom"
          :options="bandOptions"
          :multiple="false"
          :searchable="true"
          :loading="isBandLoading"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="true"
          :options-limit="300"
          :max-height="600"
          :show-no-results="true"
          :hide-selected="true"
          @search-change="asyncBandFind"
          :class="{
            'is-invalid': $v.data.band_id.$error && $v.data.band_id.$dirty,
          }"
        >
          <template slot="clear"></template>
          <span slot="noResult">Band not found.</span>
        </multiselect>
        <!-- <b-form-select
          v-model="data.band_id"
          :options="bandOptions"
          :class="{
            'is-invalid': $v.data.band_id.$error,
          }"
        ></b-form-select> -->
        <div
          v-if="!$v.data.band_id.required && $v.data.band_id.$dirty"
          class="invalid-feedback d-block"
        >
          Band field is required.
        </div>
      </b-form-group>

      <b-form-group v-if="data.type == 'music' && this.edit_id == null" class="col-12">
        <b-form-checkbox
          id="copy_contact_info"
          v-model="data.copy_contact_info"
          name="copy_contact_info"
          :value="true"
          :unchecked-value="false"
        >
          Copy contact info as Artist / Group Name
        </b-form-checkbox>

      </b-form-group>

      <b-form-group v-if="data.type != 'music'" class="col-12">
        <template slot="label">
          Company
          <a
            href="#"
            class="text-primary"
            @click="createNewCompany"
            v-b-tooltip.hover
            title="Create New Company"
          >
            <i class="ion ion-md-add-circle"></i>
          </a>
        </template>

        <multiselect
          v-model="data.company_id"
          id="band_id"
          label="name"
          track-by="id"
          placeholder="Type to search"
          open-direction="bottom"
          :options="companyOptions"
          :multiple="false"
          :searchable="true"
          :loading="isCompanyLoading"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="true"
          :options-limit="300"
          :max-height="600"
          :show-no-results="true"
          :hide-selected="true"
          @search-change="asyncCompanyFind"
          :class="{
            'is-invalid':
              $v.data.company_id.$error && $v.data.company_id.$dirty,
          }"
        >
          <template slot="clear"></template>
          <span slot="noResult">Company not found.</span>
        </multiselect>
        <div
          v-if="!$v.data.company_id.required && $v.data.company_id.$dirty"
          class="invalid-feedback d-block"
        >
          Company field is required.
        </div>
      </b-form-group>

      <b-form-group label="Lead Stage" class="col-6">
        <b-form-input
          :plaintext="true"
          v-model="data.lead_stage"
          v-if="
            data.lead_stage == 'Booked Studio Time (Converted)' ||
            data.lead_stage == 'Closed Lost'
          "
        />
        <b-form-select
          v-model="data.stage_id"
          v-else
          :options="leadStageOptions"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        label="Project Revenue Estimate"
        class="col-6"
        :invalid-feedback="getErrorLabel($v.data.project_revenue_estimate)"
      >
        <b-input
          :state="$v.data.project_revenue_estimate.$error ? 'invalid' : null"
          v-model="data.project_revenue_estimate"
          class="mb-1"
        />
      </b-form-group>
    </b-form-row>

    <b-form-row v-if="data.type == 'music'">
      <b-form-group
        label="Number of Songs"
        class="col-12"
        :invalid-feedback="getErrorLabel($v.data.number_of_songs)"
      >
        <b-input
          :state="$v.data.number_of_songs.$error ? 'invalid' : null"
          v-model="data.number_of_songs"
        />
      </b-form-group>
    </b-form-row>

    <b-form-row v-if="data.type !== 'music'">
      <b-form-group
        label="Number of Episodes"
        class="col-12"
        :invalid-feedback="getErrorLabel($v.data.number_of_episodes)"
      >
        <b-input
          :state="$v.data.number_of_episodes.$error ? 'invalid' : null"
          v-model="data.number_of_episodes"
        />
      </b-form-group>
    </b-form-row>

    <b-form-row v-if="data.type !== 'music'">
      <b-form-group
        label="Genre"
        class="col-12"
        :invalid-feedback="getErrorLabel($v.data.genre_id)"
      >
        <b-form-select
          v-model="data.genre_id"
          :options="genreOptions"
          :class="{
            'is-invalid': $v.data.genre_id.$error,
          }"
        ></b-form-select>
      </b-form-group>
    </b-form-row>

    <b-form-row>
      <b-form-group label="Target Start Date" class="col-md-6">
        <b-input-group>
          <flat-pickr
            v-model="data.target_start_date"
            :config="config"
            class="form-control"
            placeholder="Select date"
            name="date"
          ></flat-pickr>
          <div class="input-group-btn">
            <button
              class="btn btn-default"
              type="button"
              title="Toggle"
              data-toggle
            >
              <i class="fa fa-calendar">
                <span aria-hidden="true" class="sr-only">Toggle</span>
              </i>
            </button>
          </div>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Target Completion Date" class="col-md-6">
        <b-input-group>
          <flat-pickr
            v-model="data.target_completion_date"
            :config="config"
            class="form-control"
            placeholder="Select date"
            name="date"
          ></flat-pickr>
          <div class="input-group-btn">
            <button
              class="btn btn-default"
              type="button"
              title="Toggle"
              data-toggle
            >
              <i class="fa fa-calendar">
                <span aria-hidden="true" class="sr-only">Toggle</span>
              </i>
            </button>
          </div>
        </b-input-group>
      </b-form-group>
    </b-form-row>

    <b-form-row v-if="!edit_id">
      <b-form-group label="Lead Notes" class="col-sm-12">
        <!-- <quill-editor v-model="data.project_notes" :options="editorOptions" /> -->
        <b-textarea v-model="data.project_notes" rows="5">
        </b-textarea>
      </b-form-group>
    </b-form-row>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Vue from "vue";
import Vuelidate from "vuelidate";
import {
  required,
  email,
  url,
  helpers,
  decimal,
  requiredIf,
} from "vuelidate/lib/validators";
const mustBeNumber = (value) => !helpers.req(value) || /^\d+$/.test(value);
const mustBeNumberDecimal = (value) =>
  !helpers.req(value) || /^\d+(\.\d{1,2})?$/.test(value);

Vue.use(Vuelidate);
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  name: "LeadFormTwo",
  props: ["lead_data", "edit_id"],
  components: {
    quillEditor: () =>
      import("vue-quill-editor/dist/vue-quill-editor")
        .then((m) => m.quillEditor)
        .catch(() => {}),
    Multiselect,
  },

  created() {
    // this.loadCompanySettings();
  },

  watch: {
    lead_data: function () {
      this.data = {
        copy_contact_info: false,
        lead_stage: this.lead_data.lead_stage || "",
        stage_id: this.lead_data.stage_id || "",
        project_revenue_estimate: this.lead_data.project_revenue_estimate,
        number_of_songs: this.lead_data.number_of_songs,
        number_of_episodes: this.lead_data.number_of_episodes,
        target_completion_date: this.lead_data.target_completion_date,
        target_start_date: this.lead_data.target_start_date,
        project_notes: this.lead_data.project_notes,
        type: this.lead_data.type || "music",
        company_id: this.lead_data.company_id || "",
        band_id: this.lead_data.band_id || "",
        genre_id: this.lead_data.genre_id || "",

      };
      this.loadCompanySettings();
      this.loadBandOptions();
      this.loadCompanyOptions();
      this.loadGenreOptions();
      this.loadLeadStageOptions();
    },
  },
  data: () => ({
    isValidated: false,
    leadStageOptions: [{
      value: 'Active', text: 'Active Stage'
    }, {
      value: 'All', text: 'All'
    }],
    genreOptions: [],
    bandOptions: [],
    isBandLoading: false,
    companyOptions: [{ text: "Choose Company", value: "" }],
    isCompanyLoading: false,
    config: {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: window.toFlatPickrDatetime(localStorage.getItem('php_date_format')),
      altInput: true,
      dateFormat: window.datePickerSaveFormat,
      enableTime: false,
    },
    data: {
      copy_contact_info: false,
      lead_stage: "New",
      stage_id: "",
      project_revenue_estimate: "",
      number_of_songs: null,
      number_of_episodes: null,
      target_start_date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
      target_completion_date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
      notes: "",
      type: "music",
      band_id: "",
      company_id: "",
      genre_id: "",
    },
    errorLabels: {
      required: "This field is required.",
      mustBeNumber: "Only number is allowed.",
      mustBeNumberDecimal: "Only decimal number is allowed.",
    },
    projectTypeOptions: [],
  }),
  validations: {
    data: {
      project_revenue_estimate: {
        mustBeNumberDecimal,
      },
      number_of_songs: {
        mustBeNumber,
      },
      number_of_episodes: {
        mustBeNumber,
      },
      band_id: {
        required: requiredIf(function () {
          if (this.data.type == "music" && this.data.copy_contact_info == false && this.isValidated == false) {
            return true;
          } else {
            return false;
          }
        }),
      },
      company_id: {
        required: requiredIf(function () {
          if (this.data.type !== "music") {
            return true;
          } else {
            return false;
          }
        }),
      },
      genre_id: {
        required: requiredIf(function () {
          if (this.data.type !== "music") {
            return true;
          } else {
            return false;
          }
        }),
      },
    },
  },
  methods: {
    async loadGenreOptions() {
      const genreResponse = await window.axios.get("/api/picklists/Genres");
      const genreOptions = genreResponse.data.data.options.map((genre) => {
        return {
          value: genre.id,
          text: genre.name,
        };
      });
      this.genreOptions = [
        ...[{ value: "", text: "Choose Genre" }],
        ...genreOptions,
      ];
    },

    async loadLeadStageOptions() {
      try {
        const stageOptionResponse = await window.axios.get(
          "/api/picklists/Lead Stages"
        );
        console.log(stageOptionResponse.data.data.options);
        if (
          stageOptionResponse.data &&
          stageOptionResponse.data.data &&
          stageOptionResponse.data.data.options
        ) {
          const options = stageOptionResponse.data.data.options.map(
            (option) => {
              return {
                text: option.name,
                value: option.id,
              };
            }
          );
          this.leadStageOptions = options
          if(this.data.stage_id == "" && this.leadStageOptions[0] && this.leadStageOptions[0].value){
            this.data.stage_id = this.leadStageOptions[0].value;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    asyncBandFind: _.debounce(async function (query) {
      try {
        this.isBandLoading = true;
        const response = await window.axios.post("/api/bands/search-select", {
          search_string: query,
        });

        this.bandOptions = response.data;
        this.isBandLoading = false;
      } catch (error) {
        this.isBandLoading = false;
        console.log(error);
      }
    }, 500),

    createNewCompany() {
      this.$emit("show-company-form");
    },
    companyAdded(company) {
      this.loadCompanyOptions();
      this.data.company_id = company;
      console.log("company data");
      console.log(this.data.company_id);
    },
    createNewBandGroup() {
      this.$emit("show-band-group-form");
    },
    bandGroupAdded(band) {
      this.loadBandOptions();
      this.data.band_id = band;
      console.log("band data");
      console.log(this.data.band_id);
    },
    async loadBandOptions() {
      const queryParams = {
        'fields' : 'id,name',
      }
      const queryString = Object.keys(queryParams).map(function(key) {
        return key + '=' + queryParams[key]
      }).join('&');

      const bandResponse = await window.axios.get(`/api/v2/bands/options?${queryString}`);
      this.bandOptions = bandResponse.data;
    },
    async loadCompanyOptions() {
      const companyResponse = await window.axios.get(
        `/api/companies/option-list`
      );
      if (companyResponse.data.length > 0) {
        this.companyOptions = companyResponse.data;
      }
    },

    asyncCompanyFind: _.debounce(async function (query) {
      try {
        this.isCompanyLoading = true;
        const response = await window.axios.post(
          "/api/companies/search-select",
          {
            search_string: query,
          }
        );
        this.companyOptions = response.data;
        this.isCompanyLoading = false;
      } catch (error) {
        this.isCompanyLoading = false;
        console.log(error);
      }
    }),

    async loadCompanySettings() {
      const settingResponse = await window.axios.get("/api/settings");

      const projectTypeSetting = settingResponse.data.find((setting) => {
        return setting.name == "company_project_types";
      });

      if (projectTypeSetting) {
        this.projectTypeConfig = JSON.parse(projectTypeSetting.value);

        const musicOption = this.projectTypeOptions.find((option) => {
          return option.value == "music";
        });
        if (this.projectTypeConfig.music && musicOption === undefined) {
          this.projectTypeOptions.push({ value: "music", text: "Music" });
        }

        const podcastOption = this.projectTypeOptions.find((option) => {
          return option.value == "podcast";
        });
        if (this.projectTypeConfig.podcast && podcastOption === undefined) {
          this.projectTypeOptions.push({ value: "podcast", text: "Podcast" });
        }

        const audiobookOption = this.projectTypeOptions.find((option) => {
          return option.value == "audio-book";
        });
        if (this.projectTypeConfig.audioBook && audiobookOption === undefined) {
          this.projectTypeOptions.push({
            value: "audio-book",
            text: "Audiobook",
          });
        }

        const voiceOverOption = this.projectTypeOptions.find((option) => {
          return option.value == "voiceover";
        });
        if (this.projectTypeConfig.voiceOver && voiceOverOption === undefined) {
          this.projectTypeOptions.push({
            value: "voiceover",
            text: "Voiceover",
          });
        }

        const postProductionOption = this.projectTypeOptions.find((option) => {
          return option.value == "post-production";
        });
        if (
          this.projectTypeConfig.postProduction &&
          postProductionOption === undefined
        ) {
          this.projectTypeOptions.push({
            value: "post-production",
            text: "Post Production",
          });
        }

        const otherOption = this.projectTypeOptions.find((option) => {
          return option.value == "other";
        });
        if (this.projectTypeConfig.other && otherOption === undefined) {
          this.projectTypeOptions.push({ value: "other", text: "Other" });
        }

        if (this.projectTypeOptions.length == 1) {
          this.data.type = this.projectTypeOptions[0].value;
        }
      }
    },
    validate() {
      this.isValidated = false;
      this.$v.data.$touch();
      var isValid = !this.$v.data.$invalid;
      console.log("is this valid? " + isValid);
      if (isValid) {
        this.isValidated = true;
        let data = this.$data;
        this.$emit("on-validate", data, true);
        return true;
      }
      return;
    },
    getErrorLabel(validation) {
      return Object.keys(validation)
        .filter((key) => !key.startsWith("$"))
        .reduce((prev, curr) => {
          if (!validation[curr]) {
            prev = this.errorLabels[curr];
          }

          return prev;
        }, "");
    },
  },
};
</script>

<style scoped>
.text-disabled {
  color: grey !important;
}
.project-type-radio-label {
  font-size: 80%;
}
.project-type-radio-icon {
  font-size: 1.5rem;
}

.project-type-radio {
  display: flex;
  flex-direction: column;
  border-radius: 0;
}

.project-type-radio-item {
  margin-top: 5px !important;
  border-radius: 5px !important;
  background: #daeff4;
  border-color: #daeff4;
  color: #4e5155;
}
.project-type-radio-item.active {
  background: #46b1c9;
  color: white;
}

@media (min-width: 768px) {
  .project-type-radio {
    display: flex;
    flex-direction: row;
  }

  .project-type-radio {
    margin: -8px;
  }

  .project-type-radio > * {
    margin: 8px;
  }

  .project-type-radio-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 100px;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100px;
    border-radius: 12px !important;
  }
}
</style>