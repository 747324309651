import Layout1 from '@/layout/Layout1'

export default [{
  path: '/notifications/',
  component: Layout1,
  children: [{
    name: 'notifications',
    path: '',
    component: () => import('@/components/notifications/NotificationPage')
  }]
}]
