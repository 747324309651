var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            "no-close-on-backdrop": true,
            id: "create-new-contact",
            size: "lg",
            "no-enforce-focus": "",
            title: _vm.formTitle
          },
          on: {
            shown: _vm.focusMyElement,
            show: function($event) {
              return _vm.onShow()
            }
          },
          model: {
            value: _vm.show_modal,
            callback: function($$v) {
              _vm.show_modal = $$v
            },
            expression: "show_modal"
          }
        },
        [
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-sm-12",
                  attrs: { label: "Contact Status" }
                },
                [
                  _c("label", { staticClass: "switcher switcher-primary" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.is_active,
                          expression: "data.is_active"
                        }
                      ],
                      staticClass: "switcher-input",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.data.is_active)
                          ? _vm._i(_vm.data.is_active, null) > -1
                          : _vm.data.is_active
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.data.is_active,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.data,
                                  "is_active",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.data,
                                  "is_active",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.data, "is_active", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "switcher-indicator" }, [
                      _c("span", { staticClass: "switcher-yes text-primary" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "switcher-no" })
                    ]),
                    _vm._v(" "),
                    !_vm.data.is_active
                      ? _c("span", { staticClass: "switcher-label" }, [
                          _vm._v("Inactive")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.data.is_active
                      ? _c("span", { staticClass: "switcher-label" }, [
                          _vm._v("Active")
                        ])
                      : _vm._e()
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.displayCompanyField()
            ? _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-12", attrs: { label: "Company" } },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            id: "company_id",
                            label: "name",
                            "track-by": "id",
                            placeholder: "Type to search",
                            "open-direction": "bottom",
                            options: _vm.companyOptions,
                            multiple: false,
                            searchable: true,
                            loading: _vm.isCompanyLoading,
                            "internal-search": false,
                            "clear-on-select": false,
                            "close-on-select": true,
                            "max-height": 600,
                            "show-no-results": false,
                            "hide-selected": true
                          },
                          on: { "search-change": _vm.asyncCompanyFind },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "clear",
                                fn: function(props) {
                                  return undefined
                                }
                              }
                            ],
                            null,
                            false,
                            1632182706
                          ),
                          model: {
                            value: _vm.data.company_id,
                            callback: function($$v) {
                              _vm.$set(_vm.data, "company_id", $$v)
                            },
                            expression: "data.company_id"
                          }
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Company not found.")]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                { staticClass: "col-sm-12", attrs: { label: "Contact Type" } },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.contact_type_options },
                    model: {
                      value: _vm.data.contact_type_id,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "contact_type_id", $$v)
                      },
                      expression: "data.contact_type_id"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.isSpotifySite
            ? _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-sm-12",
                      attrs: { label: "Department" }
                    },
                    [
                      _c("b-form-select", {
                        attrs: { options: _vm.departmentOptions },
                        model: {
                          value: _vm.data.department_id,
                          callback: function($$v) {
                            _vm.$set(_vm.data, "department_id", $$v)
                          },
                          expression: "data.department_id"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-sm-6",
                  attrs: {
                    label: "First Name",
                    "invalid-feedback": _vm.getErrorLabel(
                      _vm.$v.data.first_name
                    )
                  }
                },
                [
                  _c("b-input", {
                    ref: "first_name",
                    staticClass: "mb-1",
                    attrs: {
                      state: _vm.$v.data.first_name.$error ? "invalid" : null
                    },
                    model: {
                      value: _vm.data.first_name,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "first_name", $$v)
                      },
                      expression: "data.first_name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "col-sm-6",
                  attrs: {
                    label: "Last Name",
                    "invalid-feedback": _vm.getErrorLabel(_vm.$v.data.last_name)
                  }
                },
                [
                  _c("b-input", {
                    staticClass: "mb-1",
                    attrs: {
                      state: _vm.$v.data.last_name.$error ? "invalid" : null
                    },
                    model: {
                      value: _vm.data.last_name,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "last_name", $$v)
                      },
                      expression: "data.last_name"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-sm-12",
                  attrs: {
                    label: "Email Address",
                    "invalid-feedback": _vm.getErrorLabel(_vm.$v.data.email)
                  }
                },
                [
                  _c("b-input", {
                    staticClass: "mb-1",
                    attrs: {
                      state: _vm.$v.data.email.$error ? "invalid" : null
                    },
                    model: {
                      value: _vm.data.email,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "email", $$v)
                      },
                      expression: "data.email"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                {
                  staticClass: "col-sm-6",
                  attrs: {
                    label: "Phone Number",
                    "invalid-feedback": _vm.getErrorLabel(
                      _vm.$v.data.phone_number
                    )
                  }
                },
                [
                  _c("b-input", {
                    staticClass: "mb-1",
                    attrs: {
                      state: _vm.$v.data.phone_number.$error ? "invalid" : null
                    },
                    model: {
                      value: _vm.data.phone_number,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "phone_number", $$v)
                      },
                      expression: "data.phone_number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "col-sm-6",
                  attrs: { label: "Primary Instrument(s)" }
                },
                [
                  _c("b-input", {
                    staticClass: "mb-1",
                    model: {
                      value: _vm.data.primary_instruments,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "primary_instruments", $$v)
                      },
                      expression: "data.primary_instruments"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-row",
            [
              _c(
                "b-form-group",
                { staticClass: "col-lg-12", attrs: { label: "Country" } },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.countries_options },
                    model: {
                      value: _vm.data.country,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "country", $$v)
                      },
                      expression: "data.country"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-form-group",
                { staticClass: "col-lg-12", attrs: { label: "Address" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.data.street,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "street", $$v)
                      },
                      expression: "data.street"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-form-group",
                { staticClass: "col-lg-12", attrs: { label: "City" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.data.city,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "city", $$v)
                      },
                      expression: "data.city"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-form-group",
                { staticClass: "col-lg-12", attrs: { label: "State" } },
                [
                  _vm.loading_states
                    ? _c("b-spinner", {
                        staticClass: "ml-2 mt-3",
                        attrs: { small: "" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-form-select",
                    {
                      attrs: {
                        options: _vm.states_options,
                        disabled: _vm.loading_states
                      },
                      model: {
                        value: _vm.data.state,
                        callback: function($$v) {
                          _vm.$set(_vm.data, "state", $$v)
                        },
                        expression: "data.state"
                      }
                    },
                    [
                      _c("template", { slot: "first" }, [
                        _c(
                          "option",
                          {
                            attrs: { disabled: "" },
                            domProps: { value: null }
                          },
                          [_vm._v("-- Please select state --")]
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "b-form-group",
                { staticClass: "col-lg-6", attrs: { label: "Zip" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.data.zip_code,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "zip_code", $$v)
                      },
                      expression: "data.zip_code"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("custom-field-form-template", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isCustomFieldsAvailable,
                expression: "isCustomFieldsAvailable"
              }
            ],
            attrs: { data: _vm.data, object: "Contact" },
            on: {
              "update:data": function($event) {
                _vm.data = $event
              },
              "custom-fields-loaded": _vm.handleCustomFieldsLoaded
            }
          }),
          _vm._v(" "),
          _c(
            "template",
            { slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "default" },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.loading, variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }