<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      id="editTask"
      centered
      title="Editing To Do"
      v-model="show_modal"
      :no-enforce-focus="true"
      @show="loadData"
    >
      <div class="row">
        <b-form-group
          label="Title"
          class="col-lg-12"
          :invalid-feedback="getErrorLabel($v.task.title)"
        >
          <b-input
            v-model="task.title"
            :state="$v.task.title.$error ? 'invalid' : null"
          ></b-input>
        </b-form-group>
      </div>

      <b-form-row>
        <b-form-group label class="col-sm-12">
          <template slot="label">Assigned Users</template>
          <multiselect
            v-model="task.assigned_user"
            id="assigned_user_ajax"
            :custom-label="userLabel"
            track-by="id"
            placeholder="Type to search"
            open-direction="bottom"
            :options="assignedUserOptions"
            :multiple="true"
            :searchable="true"
            :loading="assignedUserLoading"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="true"
            @search-change="findAssignedUser"
          >
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="badge badge-primary mr-2">
                {{ option.first_name }} {{ option.last_name }} ({{
                  option.email
                }})
                <a
                  style="color: white"
                  href="javascript:void(0)"
                  @click="remove(option)"
                >
                  <i class="fa fa-times ml-2"></i>
                </a>
              </span>
            </template>
            <template slot="clear" slot-scope="props">
              <div
                class="multiselect__clear"
                v-if="task.assigned_user.length"
                @mousedown.prevent.stop="clearAll(props.search)"
              ></div>
            </template>
            <span slot="noResult">User not found.</span>
          </multiselect>
        </b-form-group>
      </b-form-row>

      <div class="row">
        <b-form-group
          label="Due Date"
          class="col-lg-6"
          :invalid-feedback="getErrorLabel($v.task.due_date)"
        >
          <div class="input-group">
            <flat-pickr
              v-model="task.due_date"
              :config="config"
              class="form-control"
              :class="{ 'is-invalid': $v.task.due_date.$error }"
              placeholder="Select date"
              name="due_date"
            ></flat-pickr>
            <div class="input-group-btn">
              <button
                class="btn btn-default"
                type="button"
                title="Toggle"
                data-toggle
              >
                <i class="fa fa-calendar">
                  <span aria-hidden="true" class="sr-only">Toggle</span>
                </i>
              </button>
            </div>
          </div>
        </b-form-group>
        <b-form-group label="Reminder" class="col-lg-6">
          <b-form-select
            v-model="task.reminder"
            :options="reminder_options"
          ></b-form-select>
        </b-form-group>
      </div>

      <hr class="border-light mt-3" />
      <div class="row">
        <b-form-group label="Associate Type" class="col-lg-12">
          <b-form-select
            v-model="task.selected_associate_type"
            :options="associate_type_options"
            :disabled="
              this.associate_type !== null && this.scenario !== 'booking-todo'
            "
          ></b-form-select>
        </b-form-group>
      </div>

      <div class="row" v-if="task.selected_associate_type != 'General'">
        <b-form-group label class="col-lg-12">
          <multiselect
            v-model="task.selected_associate_id"
            :options="associate_id_options"
            :multiple="false"
            :disabled="
              this.associate_id !== null && this.scenario !== 'booking-todo'
            "
            placeholder="Type to search"
            track-by="id"
            label="text"
          />
        </b-form-group>
      </div>

      <div v-if="task.booking_id">
        <a @click="openCalendarBooking(task.booking_id)" href="#">
          <i class="fa fa-calendar-alt mr-1"></i> View Linked Calendar Booking
        </a>
      </div>

      <hr class="border-light mt-3" />

      <div class="row">
        <b-form-group label="Status" class="col-lg-12">
          <label class="switcher switcher-primary">
            <input
              type="checkbox"
              class="switcher-input"
              v-model="task.completed"
            />
            <span class="switcher-indicator">
              <span class="switcher-yes text-primary"></span>
              <span class="switcher-no"></span>
            </span>
            <span class="switcher-label" v-if="!task.completed"
              >Incomplete</span
            >
            <span class="switcher-label" v-if="task.completed">Completed</span>
          </label>
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group label="Details" class="col-lg-12">
          <b-form-textarea
            v-model="task.details"
            placeholder="Enter details..."
            rows="3"
          />
        </b-form-group>
      </div>
      <template slot="modal-footer">
        <b-button :disabled="loading" variant="default" @click="cancel()"
          >Cancel</b-button
        >
        <b-button
          v-if="ability.can('update', 'Task')"
          :disabled="loading"
          variant="primary"
          @click="save()"
          >Save</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import Vuelidate from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

Vue.use(Vuelidate);

export default {
  props: {
    id: {
      Type: Number,
    },
    associate_type: {
      Type: String,
      default: null,
    },
    associate_id: {
      Type: Number,
      default: null,
    },
    validate_only: {
      Type: Boolean,
      default: false,
    },
    default_task: {
      Type: Object,
      default: null,
    },
    scenario: {
      Type: String,
      default: null,
    },
  },
  components: {
    Multiselect,
  },
  computed: {
    ability() {
      return window.ability;
    },
    timezone() {
      return localStorage.getItem("timezone");
    },
  },
  watch: {
    "task.selected_associate_type"(new_val, old_val) {
      let self = this;
      if (new_val == "Lead") {
        window.axios.get(`api/leads/option-list`).then((response) => {
          self.associate_id_options = [];
          Object.keys(response.data).forEach((key) => {
            var label = "";
            if (response.data[key].type == "music" && response.data[key].band) {
              label = response.data[key].band.name;
            } else if (
              response.data[key].type != "music" &&
              response.data[key].company
            ) {
              label = response.data[key].company.name;
            }

            self.associate_id_options.push({
              id: response.data[key].id,
              text: `${response.data[key].primary_contact.first_name} ${response.data[key].primary_contact.last_name} (${label})`,
            });

            if (self.default_task.associate_id != null) {
              self.default_task.selected_associate_id =
                self.default_task.associate_id;
            }

            //preselect associate_id if associate_id props detected
            if (
              self.default_task &&
              self.default_task.selected_associate_id !== null
            ) {
              console.log("looping dulu");
              console.log(self.associate_id_options);
              self.task.selected_associate_id =
                self.associate_id_options.filter(
                  (item) => item.id == self.default_task.selected_associate_id
                )[0];
              console.log("looping dulu");
              console.log(self.task.selected_associate_id);
            }
          });
        });
      }

      if (new_val == "Contact") {

        const queryParams = {
          'fields' : 'id,first_name,last_name',
        }

        const queryString = Object.keys(queryParams).map(function(key) {
          return key + '=' + queryParams[key]
        }).join('&');


        window.axios.get(`/api/v2/contacts/options?${queryString}`).then((response) => {
          self.associate_id_options = [];
          Object.keys(response.data).forEach((key) => {
            self.associate_id_options.push({
              id: response.data[key].id,
              text: `${response.data[key].first_name} ${response.data[key].last_name}`,
            });
          });

          //Preselect associate_id if associate_id props detected

          if (self.associate_id !== null) {
            self.task.selected_associate_id = self.associate_id_options.filter(
              (item) => item.id == self.associate_id
            )[0];
          }

          if (self.default_task.associate_id != null) {
            self.default_task.selected_associate_id =
              self.default_task.associate_id;
          }

          //preselect associate_id if associate_id props detected
          if (
            self.default_task &&
            self.default_task.selected_associate_id !== null
          ) {
            console.log("default task associate id found");
            self.task.selected_associate_id = self.associate_id_options.filter(
              (item) => item.id == self.default_task.selected_associate_id
            )[0];
          }
        });
      }

      if (new_val == "Company") {
        window.axios.get(`/api/companies/option-list`).then((response) => {
          self.associate_id_options = [];
          Object.keys(response.data).forEach((key) => {
            self.associate_id_options.push({
              id: response.data[key].id,
              text: response.data[key].name,
            });
          });

          //Preselect associate_id if associate_id props detected

          if (self.associate_id !== null) {
            self.task.selected_associate_id = self.associate_id_options.filter(
              (item) => item.id == self.associate_id
            )[0];
          }

          if (self.default_task.associate_id != null) {
            self.default_task.selected_associate_id =
              self.default_task.associate_id;
          }

          //preselect associate_id if associate_id props detected
          if (
            self.default_task &&
            self.default_task.selected_associate_id !== null
          ) {
            self.task.selected_associate_id = self.associate_id_options.filter(
              (item) => item.id == self.default_task.selected_associate_id
            )[0];
          }
        });
      }

      if (new_val == "Project") {
        window.axios.get(`/api/projects/options`).then((response) => {
          self.associate_id_options = [];
          Object.keys(response.data).forEach((key) => {
            self.associate_id_options.push({
              id: response.data[key].id,
              text: response.data[key].name,
            });
          });

          //preselect associate_id if associate_id props detected
          if (self.associate_id !== null) {
            self.task.selected_associate_id = self.associate_id_options.filter(
              (item) => item.id == self.associate_id
            )[0];
          }

          if (self.default_task.associate_id != null) {
            self.default_task.selected_associate_id =
              self.default_task.associate_id;
          }

          //preselect associate_id if associate_id props detected
          if (
            self.default_task &&
            self.default_task.selected_associate_id !== null
          ) {
            self.task.selected_associate_id = self.associate_id_options.filter(
              (item) => item.id == self.default_task.selected_associate_id
            )[0];
          }
        });
      }

      if (new_val == "Band/Group") {

        const queryParams = {
          'fields' : 'id,name',
        }
        
        const queryString = Object.keys(queryParams).map(function(key) {
          return key + '=' + queryParams[key]
        }).join('&');

        window.axios.get(`/api/v2/bands/options?${queryString}`).then((response) => {
          self.associate_id_options = [];
          Object.keys(response.data).forEach((key) => {
            self.associate_id_options.push({
              id: response.data[key].id,
              text: response.data[key].name,
            });
          });

          //preselect associate_id if associate_id props detected
          if (self.associate_id !== null) {
            self.task.selected_associate_id = self.associate_id_options.filter(
              (item) => item.id == self.associate_id
            )[0];
          }

          if (self.default_task.associate_id != null) {
            self.default_task.selected_associate_id =
              self.default_task.associate_id;
          }

          //preselect associate_id if associate_id props detected
          if (
            self.default_task &&
            self.default_task.selected_associate_id !== null
          ) {
            self.task.selected_associate_id = self.associate_id_options.filter(
              (item) => item.id == self.default_task.selected_associate_id
            )[0];
          }
        });
      }
    },
  },
  data() {
    return {
      assignedUserOptions: [],
      assignedUserLoading: false,
      show_modal: false,
      task: {
        title: null,
        assigned_user: [],
        due_date: this.$moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
        reminder: "No Reminder",
        selected_associate_type: "General",
        selected_associate_id: null,
        completed: false,
        details: null,
      },
      errorLabels: {
        required: "This field is required.",
      },
      reminder_options: [
        { text: "No Reminder", value: "No Reminder" },
        { text: "At Time of To Do", value: "At Time of To Do" },
        { text: "5 Minutes Before", value: "5 Minutes Before" },
        { text: "10 Minutes Before", value: "10 Minutes Before" },
        { text: "15 Minutes Before", value: "15 Minutes Before" },
        { text: "30 Minutes Before", value: "30 Minutes Before" },
        { text: "1 Hour Before", value: "1 Hour Before" },
        { text: "2 Hours Before", value: "2 Hours Before" },
        { text: "1 Day Before", value: "1 Day Before" },
      ],
      associate_type_options: [
        { text: "General", value: "General" },
        { text: "Lead", value: "Lead" },
        { text: "Contact", value: "Contact" },
        { text: "Company", value: "Company" },
        { text: "Artist/Group", value: "Band/Group" },
        { text: "Project", value: "Project" },
      ],
      associate_id_options: [],
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: window.toFlatPickrDatetime(localStorage.getItem('php_datetime_format')),
        altInput: true,
        dateFormat: window.dateTimePickerSaveFormat,
        enableTime: true,
        static: false,
        minuteIncrement: 15,
        //locale: Hindi, // locale for this instance only
      },
      loading: false,
    };
  },
  validations: {
    task: {
      title: {
        required,
      },
      due_date: {
        required,
      },
      selected_associate_id: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.selected_associate_type != "General";
        }),
      },
    },
    form: ["task"],
  },
  methods: {
    openCalendarBooking(bookingId) {
      this.$router.push({
        name: "bookings",
        query: {
          booking_id: bookingId,
        },
      });
    },
    userLabel(data) {
      return `${data.first_name} ${data.last_name} (${data.email})`;
    },
    limitText(count) {
      return `and ${count} other users`;
    },
    selectAssignedUser() {},
    findAssignedUser: _.debounce(function (query) {
      let self = this;
      self.assignedUserLoading = true;
      window.axios
        .get("/api/users/get-all", {
          search_string: query,
        })
        .then((response) => {
          self.assignedUserOptions = response.data;
          self.assignedUserLoading = false;
        });
    }, 500),
    async loadData() {
      let self = this;

      if (this.id || (this.default_task && this.default_task.id != undefined)) {
        //triggered from edit task page, or user click edit task from edit booking form
        let todoId = this.id;
        if (this.default_task && this.default_task.id) {
          todoId = this.default_task.id;
        }

        try {
          const response = await window.axios.get(`/api/tasks/${todoId}`);

          let associate_type = "General";
          let associate_id = null;
          if (response.data.associate_type == "App\\Models\\Lead") {
            associate_type = "Lead";
            let associatedLabel = "";
            if (
              response.data.associate.type == "music" &&
              response.data.associate.band
            ) {
              associatedLabel = response.data.associate.band.name;
            } else if (
              response.data.associate.type != "music" &&
              response.data.associate.company
            ) {
              associatedLabel = response.data.associate.company.name;
            }

            associate_id = {
              id: response.data.associate_id,
              text: `${response.data.associate.primary_contact.first_name} ${response.data.associate.primary_contact.last_name} (${associatedLabel})`,
            };
          }

          if (response.data.associate_type == "App\\Models\\Project") {
            associate_type = "Project";
            associate_id = {
              id: response.data.associate_id,
              text: `${response.data.associate.name}`,
            };
          }

          if (response.data.associate_type == "App\\Models\\Contact") {
            associate_type = "Contact";
            associate_id = {
              id: response.data.associate_id,
              text: `${response.data.associate.first_name} ${response.data.associate.last_name}`,
            };
          }

          if (response.data.associate_type == "App\\Models\\Company") {
            associate_type = "Company";
            associate_id = {
              id: response.data.associate_id,
              text: response.data.associate.name,
            };
          }

          if (response.data.associate_type == "App\\Models\\Band") {
            associate_type = "Band/Group";
            associate_id = {
              id: response.data.associate_id,
              text: response.data.associate.name,
            };
          }

          let reminder = "No Reminder";
          if (response.data.reminders.length > 0) {
            reminder = response.data.reminders[0].reminder;
          }

          this.task = {
            title: response.data.title,
            assigned_user: [],
            due_date: self.$moment
              .utc(response.data.due_date)
              .tz(self.timezone)
              .format(window.apiDateTimeFormat),
            reminder: reminder,
            selected_associate_type: associate_type,
            selected_associate_id: associate_id,
            completed: response.data.completed,
            details: response.data.details,
            booking_id: response.data.booking_id,
          };

          response.data.members.forEach((data) => {
            self.task.assigned_user.push(data.user);
          });
        } catch (error) {
          console.log(error);
        }
      } else if (this.default_task && this.default_task.id == undefined) {
        //happened when open edit task from create booking
        console.log("default task e, edit from create booking");
        console.log(this.default_task);
        this.task = {
          title: this.default_task.title,
          assigned_user: this.default_task.assigned_user,
          due_date: this.default_task.due_date,
          reminder: this.default_task.reminder,
          selected_associate_type: this.default_task.selected_associate_type,
          selected_associate_id: this.default_task.selected_associate_id,
          completed: this.default_task.completed,
          details: this.default_task.details,
          booking_id: this.default_task.booking_id,
        };
      }
    },

    getErrorLabel(validation) {
      return Object.keys(validation)
        .filter((key) => !key.startsWith("$"))
        .reduce((prev, curr) => {
          if (!validation[curr]) {
            prev = this.errorLabels[curr];
          }

          return prev;
        }, "");
    },
    save() {
      let self = this;
      this.$v.form.$touch();
      var isValid = !this.$v.form.$invalid;

      this.$emit("on-validate", this.$data, isValid);
      if (isValid) {
        let task = { ...this.task };
        if (task.selected_associate_id) {
          task.selected_associate_id = this.task.selected_associate_id.id;
        }

        task.due_date = this.$moment(task.due_date)
          .utc()
          .format(window.apiDateTimeFormat);

        if (this.validate_only) {
          self.$emit("on-save", task);
          self.hide();
          self.task = {
            title: null,
            assigned_user: [],
            due_date: Date.now(),
            reminder: "No Reminder",
            selected_associate_type: "General",
            selected_associate_id: null,
            completed: false,
            details: null,
            todos: [],
          };
          self.$v.$reset();
        } else {
          this.loading = true;

          var taskId = this.id;
          if (taskId == undefined && this.default_task) {
            taskId = this.default_task.id;
          }

          window.axios.put(`/api/tasks/${taskId}`, task).then((response) => {
            self.loading = false;
            (self.task = {
              title: null,
              assigned_user: localStorage.getItem("user_id"),
              due_date: Date.now(),
              reminder: "No Reminder",
              selected_associate_type: "General",
              selected_associate_id: null,
              completed: false,
              details: null,
            }),
              self.$v.$reset();

            if (self.id) {
              self.$emit("on-validate", this.$data, isValid);
            } else if (this.default_task.id) {
              let updatedTask = response.data;
              updatedTask.assigned_user = updatedTask.members.map((member) => {
                return member.user;
              });

              self.$emit("on-save", updatedTask);
            }

            self.hide();
            self.$snotify.success("To Do Updated.", "Success!");
          });
        }
      }
      return isValid;
    },
    show() {
      let self = this;
      window.axios.get("/api/users/get-all").then((response) => {
        self.assignedUserOptions = response.data;
      });
      this.show_modal = true;
    },
    hide() {
      this.show_modal = false;
    },
    cancel() {
      this.hide();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />
<style src="@/vendor/libs/vue-multiselect/vue-multiselect.scss" lang="scss"></style>
