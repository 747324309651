import Layout1 from '@/layout/Layout1'

export default [{
  path: '/leads/',
  component: Layout1,
  children: [{
    name: 'leads-list',
    path: '',
    component: () => import('@/components/leads/LeadsList'),
    props: { default: true, createModal: false }
  }, {
    name: 'leads-list-create',
    path: 'create',
    component: () => import('@/components/leads/LeadListCreate'),
    props: { default: true, createModal: true }
  }, {
    name: 'leads-edit',
    path: 'edit/:id',
    props: true,
    component: () => import('@/components/leads/LeadEdit')
  }, {
    name: 'leads-activity-edit',
    path: 'activity/edit/:id',
    props: true,
    component: () => import('@/components/leads/LeadActivityEdit')
  }, {
    name: 'leads-view',
    path: ':id',
    props: true,
    component: () => import('@/components/leads/LeadView')
  }]
}]
