import Layout1 from '@/layout/Layout1'

export default [{
  // Blank layout
  path: '/account',
  component: Layout1,
  children: [{
    path: 'plan-upgrade-success',
    component: () => import('@/components/shared/PlanUpgradeSuccess'),
    props: true
  }, {
    path: 'plan-upgrade-cancel',
    component: () => import('@/components/shared/PlanUpgradeCancel')
  },{
    path: 'buy-additional-project-success',
    component: () => import('@/components/shared/BuyAdditionalProjectSuccess'),
    props: true
  }, {
    path: 'buy-additional-project-failed',
    component: () => import('@/components/shared/BuyAdditionalProjectFailed')
  },{
    path: 'package-pricing',
    component: () => import('@/components/account/PackagePricing')
  },{
    path: 'billing',
    component: () => import('@/components/account/Billing')
  },{
    path: 'payment-history',
    component: () => import('@/components/account/PaymentHistory')
  },{
    path: 'cancel-subscription',
    component: () => import('@/components/account/CancelSubscription')
  }]
}]
