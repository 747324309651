<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      id="create-new-company"
      size="lg"
      no-enforce-focus
      :title="formTitle"
      v-model="show_modal"
      @shown="focusMyElement"
      @show="onShow()"
      ref="modal"
    >
      <b-form-row>
        <b-form-group label="Company Status" class="col-sm-12">
          <label class="switcher switcher-primary">
            <input type="checkbox" class="switcher-input" v-model="data.is_active" />
            <span class="switcher-indicator">
              <span class="switcher-yes text-primary"></span>
              <span class="switcher-no"></span>
            </span>
            <span class="switcher-label" v-if="!data.is_active">Inactive</span>
            <span class="switcher-label" v-if="data.is_active">Active</span>
          </label>
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group
          label="Name"
          :invalid-feedback="getErrorLabel($v.data.name)"
          class="col-sm-12"
        >
          <b-input
            ref="first_name"
            v-model="data.name"
            class="mb-1"
            :state="$v.data.name.$error ? 'invalid' : null"
          />
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Company Type" class="col-sm-12">
          <b-form-select v-model="data.company_type_id" :options="company_type_options"></b-form-select>
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group
          label="Email Address"
          :invalid-feedback="getErrorLabel($v.data.email)"
          class="col-sm-6"
        >
          <b-input
            v-model="data.email"
            :state="$v.data.email.$error ? 'invalid' : null"
            class="mb-1"
          />
        </b-form-group>
        <b-form-group label="Phone Number" class="col-sm-6">
          <b-input v-model="data.phone_number" class="mb-1" />
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Country" class="col-lg-12">
          <b-form-select v-model="data.country" :options="countries_options"></b-form-select>
        </b-form-group>
      </b-form-row>

      <div class="row">
        <b-form-group label="Address" class="col-lg-12">
          <b-input v-model="data.street"></b-input>
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group label="City" class="col-lg-12">
          <b-input v-model="data.city"></b-input>
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group label="State" class="col-lg-12">
          <b-spinner small class="ml-2 mt-3" v-if="loading_states" />
          <b-form-select v-model="data.state" :options="states_options" :disabled="loading_states">
            <template slot="first">
              <option :value="null" disabled>-- Please select state --</option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group label="Zip" class="col-lg-6">
          <b-input v-model="data.zip_code"></b-input>
        </b-form-group>
      </div>

      <hr class="border-light mt-3" />

      <b-form-row>
        <b-form-group label class="col-sm-12">
          <template slot="label">
            Primary Contact
            <a
              href="javascript:void(0)"
              class="text-primary"
              @click="createNewPrimaryContact"
            >
              <i class="ion ion-md-add-circle"></i>
            </a>
          </template>
          <multiselect
            v-model="data.primary_contact"
            id="ajax"
            label="full_name"
            track-by="id"
            placeholder="Type to search"
            open-direction="bottom"
            :options="primary_contact_options"
            :multiple="false"
            :searchable="true"
            :loading="isPrimaryContactLoading"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="true"
            @search-change="asyncContactFind"
          >
            <template slot="clear" slot-scope="props"></template>
            <span slot="noResult">Contact not found.</span>
          </multiselect>
        </b-form-group>
      </b-form-row>

      <div class="row">
        <b-form-group
          label="Total Employees"
          class="col-lg-6"
          :invalid-feedback="getErrorLabel($v.data.total_employees)"
        >
          <b-input
            v-model="data.total_employees"
            :state="$v.data.total_employees.$error ? 'invalid' : null"
          ></b-input>
        </b-form-group>
      </div>

      <b-form-row>
        <b-form-group label class="col-sm-12">
          <template slot="label">
            List of Employees
            <a
              href="javascript:void()"
              class="text-primary"
              @click="createNewEmployeeContact"
            >
              <i class="ion ion-md-add-circle"></i>
            </a>
          </template>
          <multiselect
            v-model="data.employees"
            id="ajax"
            label="full_name"
            track-by="id"
            placeholder="Type to search"
            open-direction="bottom"
            :options="primary_contact_options"
            :multiple="true"
            :searchable="true"
            :loading="isPrimaryContactLoading"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="false"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="true"
            @search-change="asyncContactFind"
          >
            <template slot="tag" slot-scope="{ option, remove }">
              <span class="badge badge-primary mr-2">
                <span>{{ option.full_name }}</span>
                <a href="javascript:void(0)" style="color: white;" @click="remove(option)">
                  <i class="fa fa-times ml-2" />
                </a>
              </span>
            </template>
            <template slot="clear" slot-scope="props">
              <div
                class="multiselect__clear"
                v-if="data.employees.length"
                @mousedown.prevent.stop="clearAll(props.search)"
              ></div>
            </template>
            <span slot="noResult">Contact not found.</span>
          </multiselect>
        </b-form-group>
      </b-form-row>

      <div class="row">
        <b-form-group label="Website" class="col-lg-6">
          <b-input v-model="data.website"></b-input>
        </b-form-group>
      </div>

      <hr class="border-light mt-3" />
      <h4>Social Media Links</h4>
      <table class="table table-fixed table-borderless">
        <tr>
          <th style="width: 46%">Social Media Site</th>
          <th style="width: 46%">Social Media URL</th>
          <th class="text-right">
            <b-btn @click="addSocialMediaRow" class="btn-circle btn-primary">
              <i class="fas fa-plus" />
            </b-btn>
          </th>
        </tr>

        <tr :key="index" v-for="(v, index) in $v.data.social_medias.$each.$iter">
          <td>
            <b-form-group label :invalid-feedback="getErrorLabel(v.site)">
              <b-form-select
                v-model="v.site.$model"
                :state="v.site.$error ? 'invalid' : null"
                :options="social_medias"
              ></b-form-select>
            </b-form-group>
          </td>
          <td>
            <b-form-group label :invalid-feedback="getErrorLabel(v.url)">
              <b-input v-model="v.url.$model" :state="v.url.$error ? 'invalid' : null" />
            </b-form-group>
          </td>
          <td>
            <b-btn @click="deleteSocialMediaRow(index)" class="btn-circle btn-primary ml-2">
              <i class="fas fa-times" />
            </b-btn>
          </td>
        </tr>
      </table>

      <hr class="border-light mt-3" />

      <custom-field-form-template v-show="isCustomFieldsAvailable" :data.sync="data" object="Company"  @custom-fields-loaded="handleCustomFieldsLoaded"/>

      <template slot="modal-footer">
        <b-button :disabled="loading" variant="default" @click="cancel()">Cancel</b-button>
        <b-button :disabled="loading" variant="primary" @click="save()">Save</b-button>
      </template>
    </b-modal>
    <contact-form ref="primaryContactFormModal" @on-save="newPrimaryContactAdded" :create-source="'company-form'" />
    <contact-form ref="employeeContactFormModal" @on-save="newEmployeeContactAdded" />
  </div>
</template>

<style src="@/vendor/libs/vue-quill-editor/typography.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-quill-editor/editor.scss" lang="scss"></style>

<!-- Page -->
<style src="@/vendor/styles/pages/users.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-form-wizard/vue-form-wizard.scss" lang="scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Vue from "vue";
import Toasted from "vue-toasted";
import LaddaBtn from "@/vendor/libs/ladda/Ladda";
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import { required, email, url, numeric } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import ContactForm from "../contacts/ContactForm";
import CustomFieldFormTemplate from "../customField/CustomFieldFormTemplate.vue";

Vue.use(Vuelidate);
Vue.use(Toasted);
Vue.use(VueMask);

const init_data = {
  is_active: true,
  company_type_id: null,
  name: "",
  email: "",
  phone_number: "",
  street: "",
  city: "",
  state: null,
  zip_code: "",
  country: "",
  primary_contact: null,
  total_employees: null,
  employees: [],
  website: "",
  social_medias: [],
  notes: "",
  custom_fields: {}
};
export default {
  props: {
    editId: {
      type: Number,
      default: null,
    },
    contactId: {
      type: Object,
      default: null
    }
  },
  components: {
    CustomFieldFormTemplate,
    LaddaBtn,
    Multiselect,
    ContactForm,
    quillEditor: () =>
      import("vue-quill-editor/dist/vue-quill-editor")
        .then((m) => m.quillEditor)
        .catch(() => {}),
  },
  created() {},
  computed: {
    formTitle() {
      if (this.editId !== null) {
        return `Edit Company ${this.data.name}`;
      } else {
        return "Create New Company";
      }
    },
  },
  watch: {
    "data.country"(newVal, oldVal) {
      let self = this;
      this.loading_states = true;
      if (newVal) {
        window.axios
          .get(`/api/countries/get-states/${newVal}`)
          .then((response) => {
            self.states_options = [];
            Object.keys(response.data).forEach((key) => {
              self.states_options.push({
                text: response.data[key],
                value: response.data[key],
              });
            });
            self.loading_states = false;
          });
      }
    },
  },
  validations: {
    data: {
      email: {
        email,
      },
      name: {
        required,
      },
      total_employees: {
        numeric,
      },
      social_medias: {
        $each: {
          site: {
            required,
          },
          url: {
            required,
            url,
          },
        },
      },
    },
    form: ["data"],
  },
  data: () => ({
    company_type_options: [],
    editorOptions: {
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }, { size: [] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ direction: "rtl" }, { align: [] }],
          ["link", "image", "video"],
          ["clean"],
        ],
      },
    },
    breadcrumbItems: [
      {
        text: "Companys",
        to: { name: "companys-list" },
      },
      {
        text: "Create New Company",
        active: true,
      },
    ],
    social_medias: {},
    step: 0,
    loading: false,
    isPrimaryContactLoading: false,
    loading_states: false,
    show_modal: false,
    errors: [],
    band_member_error: false,
    social_media_error: false,
    social_medias: [],
    music_media_error: false,
    music_medias: [],
    data: _.cloneDeep(init_data),
    states_options: [],
    countries_options: [],
    primary_contact_options: [],
    errorLabels: {
      required: "This field is required.",
      email: "Invalid email format",
      numeric: "Only numeric are allowed",
      url: "Invalid url format",
    },
    isCustomFieldsAvailable: false,
  }),
  methods: {
    async loadContacts(){
      try {
        this.isPrimaryContactLoading = true;
        const response = await window.axios.post("/api/contacts/search-select");
        this.primary_contact_options = response.data;
        this.isPrimaryContactLoading = false;

        if(this.contactId){
          console.log('the contact id');
          console.log(this.contactId);
          this.data.primary_contact = this.primary_contact_options.find(contact => {
            return contact.id == this.contactId.id;
          });
        }

      } catch (error) {
        this.isPrimaryContactLoading = false;
        console.log(error);
      }
    },
    addSocialMediaRow() {
      this.data.social_medias.push({
        site: "",
        url: "",
      });
    },
    deleteSocialMediaRow(index) {
      this.data.social_medias.splice(index, 1);
    },
    createNewPrimaryContact() {
      this.editId = null;
      this.$refs.primaryContactFormModal.show();
    },
    createNewEmployeeContact() {
      this.editId = null;
      this.$refs.employeeContactFormModal.show();
    },
    newPrimaryContactAdded(value) {
      this.data.primary_contact = {
        id: value.id,
        first_name: value.first_name,
        last_name: value.last_name,
        full_name: value.full_name,
      };
    },
    newEmployeeContactAdded(value) {
      this.data.employees.push({
        id: value.id,
        first_name: value.first_name,
        last_name: value.last_name,
        full_name: value.full_name,
      });
    },
    limitText(count) {
      return `and ${count} other contacts`;
    },
    clearAll() {
      this.data.primary_contact = [];
    },
    asyncContactFind: _.debounce(function (query) {
      let self = this;
      this.isPrimaryContactLoading = true;
      window.axios
        .post("/api/contacts/search-select", {
          search_string: query,
        })
        .then((response) => {
          self.primary_contact_options = response.data;
          self.isPrimaryContactLoading = false;
        });
    }, 500),
    getErrorLabel(validation) {
      return Object.keys(validation)
        .filter((key) => !key.startsWith("$"))
        .reduce((prev, curr) => {
          if (!validation[curr]) {
            prev = this.errorLabels[curr];
          }

          return prev;
        }, "");
    },
    save(event, createAnother = false) {
      let self = this;

      this.$v.form.$touch();
      var isValid = !this.$v.form.$invalid;

      if (isValid) {
        this.loading = true;
        if (this.editId === null) {
          window.axios
            .post("/api/companies", this.data)
            .then((response) => {
              self.loading = false;
              self.clearForm();
              self.hide();
              this.$emit("on-save", response.data, isValid);
              self.$snotify.success("New Company Created", "Success");
            })
            .catch((error) => {
              self.loading = false;
              self.errors = error.response.data.errors;
              self.$snotify.error(
                "There was a problem creating Company",
                "Error!"
              );
            });
        } else {
          window.axios
            .put(`/api/companies/${this.editId}`, this.data)
            .then((response) => {
              self.loading = false;
              self.clearForm();
              self.hide();
              self.$snotify.success("Company Updated", "Success");
              self.$emit("on-save", response.data);
            })
            .catch((error) => {
              self.loading = false;
              self.errors = error.response.data.errors;
              self.$snotify.error(
                "There was a problem updating Company",
                "Error!"
              );
            });
        }
      }
    },
    clearForm() {
      this.data = Object.assign({}, init_data);
      this.step = 0;
      this.errors = [];
      this.$v.$reset();
    },
    show() {
      let self = this;

      this.loadContacts();

      window.axios.get("/api/countries/get-all-name").then((response) => {
        Object.keys(response.data).forEach((key) => {
          self.countries_options.push({
            text: response.data[key],
            value: response.data[key],
          });
        });
      });

      window.axios.get("/api/social-medias/all?pluck=true").then((response) => {
        self.social_medias = response.data;
      });
      this.show_modal = true;
    },
    hide() {
      this.show_modal = false;
    },
    cancel() {
      this.hide();
    },
    focusMyElement(e) {
      this.$refs.first_name.focus();
    },
    reloadCompanyData(id) {
      let self = this;
      window.axios.get(`/api/companies/${id}`).then((response) => {
        self.data = response.data;
        self.data.social_medias = response.data.social_medias.map((item) => {
          return {
            site: item.id,
            url: item.pivot.url,
          };
        });
      });
    },
    async loadCompanyTypeOptions(){
      const companyTypeResponse = await window.axios.get('/api/picklists/Company Types');
      this.company_type_options = companyTypeResponse.data.data.options.map(option => {
        return {
          text: option.name,
          value: option.id
        }
      });
      if(this.company_type_options[0]){
        this.data.company_type_id = this.company_type_options[0].value;
      }
    },
    async onShow() {
      this.loadCompanyTypeOptions();
      if (this.editId !== null) {
        this.reloadCompanyData(this.editId);
      } else {
        this.data = _.cloneDeep(init_data);
        // this.data.company_type_id = this.company_type_options[0].value;
        this.data.country = "United States";
      }
    },
    handleCustomFieldsLoaded(data) {
      this.isCustomFieldsAvailable = data.length > 0 ? true : false;
    }
  },
};
</script>
