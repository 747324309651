import Layout1 from '@/layout/Layout1'

export default [{
    path: '/quotes/',
    component: Layout1,
    children: [{
        name: 'quote-list',
        path: '/',
        component: () => import('@/components/quotes/QuoteList'),

    },{
        name: 'quote-create',
        path: 'create',
        component: () => import('@/components/quotes/QuoteCreate'),
    },{
        name : 'quote-view',
        path : ':id',
        props: true,
        component: () => import('@/components/quotes/QuoteDetail')
    },{
        name : 'quote-edit',
        path : ':id/edit',
        props: true,
        component: () => import('@/components/quotes/QuoteEdit')
    }]
}]