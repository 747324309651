import Layout1 from '@/layout/Layout1'

export default [{
  path: '/marketing/events/',
  component: Layout1,
  children: [{
    name: 'marketing-events-list',
    path: '',
    component: () => import('@/components/events/EventList')
  }
  ]
}]
