var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            "no-close-on-esc": "",
            "no-close-on-backdrop": true,
            id: "time-tracking-form",
            size: "lg",
            "no-enforce-focus": "",
            title: _vm.formTitle
          },
          on: { show: _vm.onShow, hidden: _vm.hide },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-12", attrs: { label: "Project" } },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            id: "ajax",
                            label: "name",
                            "track-by": "id",
                            placeholder: "Type to search",
                            "open-direction": "bottom",
                            options: _vm.projects,
                            multiple: false,
                            searchable: true,
                            loading: _vm.isProjectLoading,
                            "internal-search": false,
                            "clear-on-select": false,
                            "close-on-select": true,
                            "max-height": 600,
                            "show-no-results": false,
                            "hide-selected": true
                          },
                          on: { "search-change": _vm.asyncProjectFind },
                          scopedSlots: _vm._u([
                            {
                              key: "clear",
                              fn: function(props) {
                                return undefined
                              }
                            }
                          ]),
                          model: {
                            value: _vm.selectedProject,
                            callback: function($$v) {
                              _vm.selectedProject = $$v
                            },
                            expression: "selectedProject"
                          }
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Project not found.")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.$v.tracker.project_id.$dirty &&
                      !_vm.$v.tracker.project_id.required
                        ? _c(
                            "div",
                            {
                              staticClass: "invalid-feedback",
                              staticStyle: { display: "inline-block" }
                            },
                            [
                              _vm._v(
                                "\n            Project field is required.\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-12", attrs: { label: "Task" } },
                    [
                      _c("b-form-select", {
                        class: { "is-invalid": _vm.$v.tracker.task_id.$error },
                        attrs: {
                          disabled: _vm.isTaskLoading,
                          "value-field": "value",
                          "text-field": "text",
                          options: _vm.tasks
                        },
                        model: {
                          value: _vm.selectedTask,
                          callback: function($$v) {
                            _vm.selectedTask = $$v
                          },
                          expression: "selectedTask"
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.tracker.task_id.$dirty &&
                      !_vm.$v.tracker.task_id.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v("\n            Task is required\n          ")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-6", attrs: { label: "Start Time" } },
                    [
                      _c(
                        "div",
                        { staticClass: "input-group" },
                        [
                          _c("flat-pickr", {
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.$v.tracker.start_at.$error
                            },
                            attrs: {
                              config: _vm.dateTimePickerConfig,
                              placeholder: "Start Time ",
                              name: "invoice_date"
                            },
                            model: {
                              value: _vm.tracker.start_at,
                              callback: function($$v) {
                                _vm.$set(_vm.tracker, "start_at", $$v)
                              },
                              expression: "tracker.start_at"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "input-group-btn" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-default",
                                attrs: {
                                  type: "button",
                                  title: "Toggle",
                                  "data-toggle": ""
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-calendar" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "sr-only",
                                      attrs: { "aria-hidden": "true" }
                                    },
                                    [_vm._v("Toggle")]
                                  )
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          !_vm.$v.tracker.start_at.required &&
                          _vm.$v.tracker.start_at.$dirty
                            ? _c(
                                "div",
                                {
                                  staticClass: "invalid-feedback",
                                  staticStyle: { display: "inline-block" }
                                },
                                [
                                  _vm._v(
                                    "\n              Start Time field is required.\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { staticClass: "col-6", attrs: { label: "Stop Time" } },
                    [
                      _c(
                        "div",
                        { staticClass: "input-group" },
                        [
                          _c("flat-pickr", {
                            ref: "stopAt",
                            staticClass: "form-control",
                            class: {
                              "is-invalid": _vm.$v.tracker.stop_at.$error
                            },
                            attrs: {
                              config: _vm.dateTimePickerConfig,
                              placeholder: "Stop Time ",
                              name: "stop_at"
                            },
                            model: {
                              value: _vm.tracker.stop_at,
                              callback: function($$v) {
                                _vm.$set(_vm.tracker, "stop_at", $$v)
                              },
                              expression: "tracker.stop_at"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "input-group-btn" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-default",
                                attrs: {
                                  type: "button",
                                  title: "Toggle",
                                  "data-toggle": ""
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-calendar" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "sr-only",
                                      attrs: { "aria-hidden": "true" }
                                    },
                                    [_vm._v("Toggle")]
                                  )
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          !_vm.$v.tracker.stop_at.required &&
                          _vm.$v.tracker.stop_at.$dirty
                            ? _c(
                                "div",
                                {
                                  staticClass: "invalid-feedback",
                                  staticStyle: { display: "inline-block" }
                                },
                                [
                                  _vm._v(
                                    "\n              Stop Time field is required.\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-12", attrs: { label: "Notes" } },
                    [
                      _c("b-form-textarea", {
                        attrs: { rows: "5" },
                        model: {
                          value: _vm.tracker.notes,
                          callback: function($$v) {
                            _vm.$set(_vm.tracker, "notes", $$v)
                          },
                          expression: "tracker.notes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.loading, variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSubmit()
                    }
                  }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }