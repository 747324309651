import layoutHelpers from '@/layout/helpers.js'

export default function () {
    return {
        // Public url
        publicUrl: '/',

        // Layout helpers
        layoutHelpers,
        packageDetails: [
            {
            id: 1,
            features: [                
                'Lead Management',
                'Scheduling (Multiple Rooms)',
                'Custom Web Forms (Leads)',
                'Contact Management',
                'Project Management',
                'Audio File Review Portal',
                'File Delivery (Large File Transfer)',
                'Time Tracking',            
                'Quotes, Invoices and Client Payments',
                'Coupon Code Management',
                'Equipment Management',
                'Equipment Tracking',
                'Dashboard & Reports',
                'Online Booking Portal (client booking)',
                'Custom Fields',
            ],
            msg1: 'This package also includes <b>5 users</b>, <b>Unlimited Projects monthly</b>, and <b>2 TB of file storage</b>',
            

            },
            {
            id: 2,
            features: [
                'Lead Management',
                'Scheduling (Single Room)',
                'Custom Web Forms (Leads)',
                'Contact Management',
                'Project Management',
                'Audio File Review Portal',
                'File Delivery (Large File Transfer)',
                'Time Tracking',            
                'Quotes, Invoices and Client Payments',
                'Coupon Code Management',                
                'Equipment Management',
                'Dashboard & Reports'
            ],
            msg1: 'This package also includes <b>1 user</b>, up to <b>Unlimited Projects monthly</b>, and up to <b>1 TB of Project Storage</b>',
            msg2: '<i>Looking for more users, storage space or unlimited Projects?</i>'

            },
            {
            id: 3,
            features: [
                'Lead Management',
                'Scheduling (Single Room)',
                'Custom Web Forms (Leads)',
                'Contact Management',
                'Project Management',
                'Audio File Review Portal',
                'File Delivery (Large File Transfer)',
                'Time Tracking',            
                'Quotes, Invoices and Client Payments',
                'Coupon Code Management',
                'Equipment Management',
                'Dashboard & Reports'
            ],
            msg1: 'This package also includes <b>1 user</b>, up to <b>3 Projects monthly</b>, and up to <b>500 GB of Project Storage</b>',
            msg2: '<i>Looking for more storage space or unlimited Projects?</i>'
            }
        ],
        chartColor: {
            'blue': '#00a5ee',
            'green': '#7cd276',
            'yellow': '#dcd400',
            'orange': '#d59100',
            'red': '#cb4547',
            'grey': '#7f7f7f',
            'black': '#2d2d2d'
        },

        genderOption: [
            {value: 'Male', text: 'Male'},
            {value: 'Female', text: 'Female'},
        ],
        company_type_options: [
            {value: 'Education', text: 'Education'},
            {value: 'Film Producer', text: 'Film Producer'},
            {value: 'Label', text: 'Label'},
            {value: 'Mastering Studio', text: 'Mastering Studio'},
            {value: 'Music Distribution', text: 'Music Distribution'},
            {value: 'Music Instrument Manufacturer', text: 'Music Instrument Manufacturer'},
            {value: 'Recording Studio', text: 'Recording Studio'},
            {value: 'Performing Rights Organization', text: 'Performing Rights Organization'},
            {value: 'Production Company', text: 'Production Company'},
            {value: 'Radio', text: 'Radio'},
            {value: 'Television', text: 'Television'},
            {value: 'Venue', text: 'Venue'},
            {value: 'Videographer', text: 'Videographer'},
            {value: 'Other', text: 'Other'}
        ],
        contact_type_options: [
            {value: 'Artist/Band Manager', text: 'Artist/Band Manager'},
            {value: 'Artist & Repertoire (A&R)', text: 'Artist & Repertoire (A&R)'},
            {value: 'Booking Agent', text: 'Booking Agent'},
            {value: 'Business Development', text: 'Business Development'},
            {value: 'CEO/President', text: 'CEO/President'},
            {value: 'Composer', text: 'Composer'},
            {value: 'Engineer', text: 'Engineer'},
            {value: 'Film Producer', text: 'Film Producer'},
            {value: 'Legal', text: 'Legal'},
            {value: 'Marketing', text: 'Marketing'},
            {value: 'Musician', text: 'Musician'},
            {value: 'Office Manager', text: 'Office Manager'},
            {value: 'Producer', text: 'Producer'},
            {value: 'Publicist', text: 'Publicist'},
            {value: 'Public Relations', text: 'Public Relations'},
            {value: 'Reception', text: 'Reception'},
            {value: 'Studio Assistant', text: 'Studio Assistant'},
            {value: 'Videographer', text: 'Videographer'},
            {value: 'Other', text: 'Other'},
        ],
        referral_source_options: [
            {value: 'Event', text: 'Event'},
            {value: 'Past Customer', text: 'Past Customer'},
            {value: 'Social Media', text: 'Social Media'},
            {value: 'Web: Organic Search', text: 'Web: Organic Search'},
            {value: 'Web: Online Advertising', text: 'Web: Online Advertising'},
            {value: 'Word of Mouth', text: 'Word of Mouth'},
            {value: 'Other', text: 'Other'},
        ],
        studio_experience_options: [
            {value: 'None', text: 'None'},
            {value: 'Novice', text: 'Novice'},
            {value: 'Intermediate', text: 'Intermediate'},
            {value: 'Advanced', text: 'Advanced'},
            {value: 'Expert', text: 'Expert'},
        ],
        lead_stage_options: [
            {value: 'New', text: 'New'},
            {value: 'In Communication', text: 'In Communication'},
            {value: 'Closed Lost', text: 'Closed Lost'},
        ],
        lead_list_stage_options: [
            {value: 'New', text: 'New'},
            {value: 'In Communication', text: 'In Communication'},
            {value: 'Booked Studio Time (Converted)', text: 'Booked Studio Time (Converted)'},
            {value: 'Closed Lost', text: 'Closed Lost'},
        ],
        project_stage_options: [
            {value: 'All Open', text : 'All Open'},
            {value: '', text : 'All'},
            {value: 'Post Production', text : 'Post Production'},
            {value: 'Pre Production', text: 'Pre Production'},
            {value: 'Recording', text: 'Recording'},
            {value: 'Editing', text: 'Editing'},
            {value: 'Mixing', text: 'Mixing'},
            {value: 'Mastering', text: 'Mastering'},
            {value: 'File-Review', text: 'File-Review'},
            {value: 'Revision', text: 'Revision'},
            {value: 'Delivered', text: 'Completed (Delivered)'},
        ],
        project_stage_select: [
            {value: '', text : 'Choose Project Stage'},
            {value: 'Pre Production', text: 'Pre Production'},
            {value: 'Recording', text: 'Recording'},
            {value: 'Editing', text: 'Editing'},
            {value: 'Mixing', text: 'Mixing'},
            {value: 'Mastering', text: 'Mastering'},
            {value: 'File-Review', text: 'File-Review'},
            {value: 'Revision', text: 'Revision'},
            {value: 'Delivered', text: 'Completed (Delivered)'},
        ],
        genre_options: [
            {value: '', text : 'All'},
            {value: 'A\'Capella', text: 'A\'Capella'},
            {value: 'Audio Restoration', text: 'Audio Restoration'},
            {value: 'Automated Dialog Replacement (ADR)', text: 'Automated Dialog Replacement (ADR)'},
            {value: 'Blues', text: 'Blues'},
            {value: 'Christian', text: 'Christian'},
            {value: 'Country', text: 'Country'},
            {value: 'Electronic', text: 'Electronic'},
            {value: 'Folk', text: 'Folk'},
            {value: 'Funk/Soul', text: 'Funk/Soul'},
            {value: 'Hip Hop', text: 'Hip Hop'},
            {value: 'Jazz', text: 'Jazz'},
            {value: 'Latin', text: 'Latin'},
            {value: 'Metal', text: 'Metal'},
            {value: 'Podcast', text: 'Podcast'},
            {value: 'Pop', text: 'Pop'},
            {value: 'Post Production', text: 'Post Production'},
            {value: 'Punk', text: 'Punk'},
            {value: 'R&B', text: 'R&B'},
            {value: 'Rap', text: 'Rap'},
            {value: 'Reggae', text: 'Reggae'},
            {value: 'Rock', text: 'Rock'},
            {value: 'Voiceover/Audiobook', text: 'Voiceover/Audiobook'},
            {value: 'Other', text: 'Other'}
            
        ],

        genre_select: [
            {value: '', text : 'Choose Genre'},
            {value: 'A\'Capella', text: 'A\'Capella'},
            {value: 'Audio Restoration', text: 'Audio Restoration'},
            {value: 'Automated Dialog Replacement (ADR)', text: 'Automated Dialog Replacement (ADR)'},
            {value: 'Blues', text: 'Blues'},
            {value: 'Christian', text: 'Christian'},
            {value: 'Country', text: 'Country'},
            {value: 'Electronic', text: 'Electronic'},
            {value: 'Folk', text: 'Folk'},
            {value: 'Funk/Soul', text: 'Funk/Soul'},
            {value: 'Hip Hop', text: 'Hip Hop'},
            {value: 'Jazz', text: 'Jazz'},
            {value: 'Latin', text: 'Latin'},
            {value: 'Metal', text: 'Metal'},
            {value: 'Podcast', text: 'Podcast'},
            {value: 'Pop', text: 'Pop'},
            {value: 'Post Production', text: 'Post Production'},
            {value: 'Punk', text: 'Punk'},
            {value: 'R&B', text: 'R&B'},
            {value: 'Rap', text: 'Rap'},
            {value: 'Reggae', text: 'Reggae'},
            {value: 'Rock', text: 'Rock'},
            {value: 'Voiceover/Audiobook', text: 'Voiceover/Audiobook'},
            {value: 'Other', text: 'Other'}
        ],

        genre: [
            {value: 'A\'Capella', text: 'A\'Capella'},
            {value: 'Audio Restoration', text: 'Audio Restoration'},
            {value: 'Automated Dialog Replacement (ADR)', text: 'Automated Dialog Replacement (ADR)'},
            {value: 'Blues', text: 'Blues'},
            {value: 'Christian', text: 'Christian'},
            {value: 'Country', text: 'Country'},
            {value: 'Electronic', text: 'Electronic'},
            {value: 'Folk', text: 'Folk'},
            {value: 'Funk/Soul', text: 'Funk/Soul'},
            {value: 'Hip Hop', text: 'Hip Hop'},
            {value: 'Jazz', text: 'Jazz'},
            {value: 'Latin', text: 'Latin'},
            {value: 'Metal', text: 'Metal'},
            {value: 'Podcast', text: 'Podcast'},
            {value: 'Pop', text: 'Pop'},
            {value: 'Post Production', text: 'Post Production'},
            {value: 'Punk', text: 'Punk'},
            {value: 'R&B', text: 'R&B'},
            {value: 'Rap', text: 'Rap'},
            {value: 'Reggae', text: 'Reggae'},
            {value: 'Rock', text: 'Rock'},
            {value: 'Voiceover/Audiobook', text: 'Voiceover/Audiobook'},
            {value: 'Other', text: 'Other'}
        ],
        activity_type_options: [
            {value: 'Call', text: 'Call'},
            {value: 'Email', text: 'Email'},
            {value: 'In-Person', text: 'In-Person'},
            {value: 'Event', text: 'Event'},
        ],
        snooze_options : [
            { text: 'Snooze for 5 Minutes', value: '5 Minutes After'},
            { text: 'Snooze for 10 Minutes', value: '10 Minutes After'},
            { text: 'Snooze for 15 Minutes', value: '15 Minutes After'},
            { text: 'Snooze for 30 Minutes', value: '30 Minutes After'},
            { text: 'Snooze for 1 Hour', value: '1 Hour After'},
            { text: 'Snooze for 2 Hours', value: '2 Hours After'},
            { text: 'Snooze for 1 Day', value: '1 Day After'},
        ],

        booking_type_options : [
            {value: '', text : 'Choose Event Type'},
            {value: 'Pre Production', text: 'Pre Production'},
            {value: 'Recording', text: 'Recording'},
            {value: 'Editing', text: 'Editing'},
            {value: 'Mixing', text: 'Mixing'},
            {value: 'Mastering', text: 'Mastering'},
            {value: 'File-Review', text: 'File-Review'},
            {value: 'Revision', text: 'Revision'},
            {value: 'Other', text: 'Other'}
        ],

        booking_reminder_options : [
            { text: 'No Reminder', value: 'No Reminder'},
            { text: 'At Time of Event', value: 'At Time of Schedule'},
            { text: '5 Minutes Before', value: '5 Minutes Before'},
            { text: '10 Minutes Before', value: '10 Minutes Before'},
            { text: '15 Minutes Before', value: '15 Minutes Before'},
            { text: '30 Minutes Before', value: '30 Minutes Before'},
            { text: '1 Hour Before', value: '1 Hour Before'},
            { text: '2 Hours Before', value: '2 Hours Before'},
            { text: '1 Day Before', value: '1 Day Before'},
        ],

        quote_status_options : [
            { text : 'All', value : 'All'},
            { text : 'Sent', value : 'sent'},
            { text : 'Accepted', value : 'accepted'},
            { text : 'Declined', value : 'declined'}
        ],


        review_status_options: [
            { text : 'New', value : 'New'},
            { text : 'Complete', value : 'Complete'},
            { text : 'Rejected', value : 'Rejected'},
        ],

        get isSpotify() {
            const baseUrl = window.location.host.toString();
            //if (baseUrl.includes("spotify") || baseUrl.includes("warrence")) {
            if (baseUrl.includes("spotify")) {
              return true;
            }
          },

        hasAddon(slug) {
            const addons = localStorage.getItem('website_a').split(',')
            return addons.includes(slug)
        },

        showAccountBilling(slug) {
            const accountType = localStorage.getItem('account_type')
            if(accountType == 'sub_customer'){
                return true
            }
        },

        // Check for RTL layout
        get isRTL() {
            return document.documentElement.getAttribute('dir') === 'rtl' ||
                document.body.getAttribute('dir') === 'rtl'
        },

        // Check if IE
        get isIEMode() {
            return typeof document['documentMode'] === 'number'
        },

        // Check if IE10
        get isIE10Mode() {
            return this.isIEMode && document['documentMode'] === 10
        },

        // Layout navbar color
        get layoutNavbarBg() {
            return 'navbar-theme'
        },

        // Layout sidenav color
        get layoutSidenavBg() {
            return 'sidenav-theme'
        },

        // Layout footer color
        get layoutFooterBg() {
            return 'footer-theme'
        },

        // Animate scrollTop
        scrollTop(to, duration, element = document.scrollingElement || document.documentElement) {
            if (element.scrollTop === to) return
            const start = element.scrollTop
            const change = to - start
            const startDate = +new Date()

            // t = current time; b = start value; c = change in value; d = duration
            const easeInOutQuad = (t, b, c, d) => {
                t /= d / 2
                if (t < 1) return c / 2 * t * t + b
                t--
                return -c / 2 * (t * (t - 2) - 1) + b
            }

            const animateScroll = () => {
                const currentDate = +new Date()
                const currentTime = currentDate - startDate
                element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration))
                if (currentTime < duration) {
                    requestAnimationFrame(animateScroll)
                } else {
                    element.scrollTop = to
                }
            }

            animateScroll()
        },

        generateCustomFieldInput(customField){
            if(customField.field_type == 'text'){
                return `<b-form-group label="${customField.label}" class="col-lg-12">
                <b-form-input
                  v-model="customFieldData.label"
                >                  
                </b-form-input>
              </b-form-group>`
            }
        }
    }
}
