var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customFields.length > 0
    ? _c(
        "div",
        [
          _vm.showHeaderTitle
            ? _c("div", [
                _c("strong", [_vm._v(" Custom Fields")]),
                _vm._v(" "),
                _c("hr")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.customFields, function(customField) {
            return _c(
              "div",
              { key: customField.id },
              [
                customField.field_type == "text"
                  ? _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: customField.label,
                          description: customField.description
                        }
                      },
                      [
                        _c("b-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.data.custom_fields[customField.id],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.data.custom_fields,
                                customField.id,
                                $$v
                              )
                            },
                            expression: "data.custom_fields[customField.id]"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                customField.field_type == "textarea"
                  ? _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: customField.label,
                          description: customField.description
                        }
                      },
                      [
                        _c("b-form-textarea", {
                          model: {
                            value: _vm.data.custom_fields[customField.id],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.data.custom_fields,
                                customField.id,
                                $$v
                              )
                            },
                            expression: "data.custom_fields[customField.id]"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                customField.field_type == "number"
                  ? _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: customField.label,
                          description: customField.description
                        }
                      },
                      [
                        _c("b-input", {
                          attrs: { type: "number" },
                          model: {
                            value: _vm.data.custom_fields[customField.id],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.data.custom_fields,
                                customField.id,
                                $$v
                              )
                            },
                            expression: "data.custom_fields[customField.id]"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                customField.field_type == "select"
                  ? _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: customField.label,
                          description: customField.description
                        }
                      },
                      [
                        _c("b-form-select", {
                          attrs: { options: customField.field_options },
                          model: {
                            value: _vm.data.custom_fields[customField.id],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.data.custom_fields,
                                customField.id,
                                $$v
                              )
                            },
                            expression: "data.custom_fields[customField.id]"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                customField.field_type == "radio"
                  ? _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: customField.label,
                          description: customField.description
                        }
                      },
                      [
                        _c("b-form-radio-group", {
                          attrs: { options: customField.field_options },
                          model: {
                            value: _vm.data.custom_fields[customField.id],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.data.custom_fields,
                                customField.id,
                                $$v
                              )
                            },
                            expression: "data.custom_fields[customField.id]"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                customField.field_type == "checkbox"
                  ? _c(
                      "b-form-group",
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: {
                              value: "true",
                              "unchecked-value": "false"
                            },
                            model: {
                              value: _vm.data.custom_fields[customField.id],
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.data.custom_fields,
                                  customField.id,
                                  $$v
                                )
                              },
                              expression: "data.custom_fields[customField.id]"
                            }
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(customField.label) +
                                "\n      "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                customField.field_type == "datepicker"
                  ? _c(
                      "b-form-group",
                      { attrs: { label: customField.label } },
                      [
                        _c(
                          "div",
                          { staticClass: "input-group" },
                          [
                            _c("flat-pickr", {
                              attrs: { config: _vm.datePickerConfig },
                              model: {
                                value: _vm.data.custom_fields[customField.id],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.data.custom_fields,
                                    customField.id,
                                    $$v
                                  )
                                },
                                expression: "data.custom_fields[customField.id]"
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "input-group-btn" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-default",
                                  attrs: {
                                    type: "button",
                                    title: "Toggle",
                                    "data-toggle": ""
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-calendar" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "sr-only",
                                        attrs: { "aria-hidden": "true" }
                                      },
                                      [_vm._v("Toggle")]
                                    )
                                  ])
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }