var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            "no-close-on-backdrop": true,
            id: "create-new-project",
            size: "lg",
            "no-enforce-focus": "",
            title: _vm.formTitle
          },
          on: {
            shown: _vm.focusMyElement,
            show: _vm.onShow,
            hidden: _vm.onHidden
          },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit.apply(null, arguments)
                }
              }
            },
            [
              _vm.projectTypeOptions.length > 1
                ? _c(
                    "b-form-row",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-12",
                          attrs: { label: "Project Type" }
                        },
                        [
                          _c(
                            "b-form-radio-group",
                            {
                              staticClass: "project-type-radio mt-md-1",
                              attrs: {
                                buttons: "",
                                "button-variant": "outline-primary"
                              },
                              model: {
                                value: _vm.project.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.project, "type", $$v)
                                },
                                expression: "project.type"
                              }
                            },
                            [
                              _vm._l(_vm.projectTypeOptions, function(option) {
                                return [
                                  _c(
                                    "b-form-radio",
                                    {
                                      key: option.text,
                                      staticClass: "project-type-radio-item",
                                      attrs: { value: option.value }
                                    },
                                    [
                                      _c("div", [
                                        option.value == "music"
                                          ? _c("i", {
                                              staticClass:
                                                "fas fa-music project-type-radio-icon"
                                            })
                                          : option.value == "podcast"
                                          ? _c("i", {
                                              staticClass:
                                                "fas fa-microphone project-type-radio-icon"
                                            })
                                          : option.value == "audio-book"
                                          ? _c("i", {
                                              staticClass:
                                                "fas fa-book project-type-radio-icon"
                                            })
                                          : option.value == "post-production"
                                          ? _c("i", {
                                              staticClass:
                                                "fas fa-sliders-h project-type-radio-icon"
                                            })
                                          : option.value == "voiceover"
                                          ? _c("i", {
                                              staticClass:
                                                "fas fa-headset project-type-radio-icon"
                                            })
                                          : option.value == "other"
                                          ? _c("i", {
                                              staticClass:
                                                "fas fa-file-audio project-type-radio-icon"
                                            })
                                          : _vm._e()
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "project-type-radio-label"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(option.text) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isSpotify
                ? _c(
                    "b-form-row",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-12",
                          attrs: { label: "Project Code" }
                        },
                        [
                          _c("b-input", {
                            ref: "name",
                            class: {
                              "is-invalid":
                                _vm.projectSubmitted &&
                                _vm.$v.project.project_code.$error
                            },
                            model: {
                              value: _vm.project.project_code,
                              callback: function($$v) {
                                _vm.$set(_vm.project, "project_code", $$v)
                              },
                              expression: "project.project_code"
                            }
                          }),
                          _vm._v(" "),
                          _vm.projectSubmitted &&
                          !_vm.$v.project.project_code.required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n            Project Code field is required.\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.projectSubmitted &&
                          !_vm.$v.project.project_code.maxLength
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n            Project Code field cannot more than 6 characters.\n          "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-12", attrs: { label: "Project Name" } },
                    [
                      _c("b-input", {
                        ref: "name",
                        class: {
                          "is-invalid":
                            _vm.projectSubmitted && _vm.$v.project.name.$error
                        },
                        model: {
                          value: _vm.project.name,
                          callback: function($$v) {
                            _vm.$set(_vm.project, "name", $$v)
                          },
                          expression: "project.name"
                        }
                      }),
                      _vm._v(" "),
                      _vm.projectSubmitted && !_vm.$v.project.name.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n            Project Name field is required.\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-12",
                      attrs: { label: "Target Start Date" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "input-group" },
                        [
                          _c("flat-pickr", {
                            class: {
                              "is-invalid":
                                _vm.projectSubmitted &&
                                _vm.$v.project.start_date.$error
                            },
                            attrs: {
                              config: _vm.datePickerConfig,
                              placeholder: "Select Start Date",
                              name: "start_date"
                            },
                            model: {
                              value: _vm.project.start_date,
                              callback: function($$v) {
                                _vm.$set(_vm.project, "start_date", $$v)
                              },
                              expression: "project.start_date"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "input-group-btn" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-default",
                                attrs: {
                                  type: "button",
                                  title: "Toggle",
                                  "data-toggle": ""
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-calendar" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "sr-only",
                                      attrs: { "aria-hidden": "true" }
                                    },
                                    [_vm._v("Toggle")]
                                  )
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _vm.projectSubmitted &&
                          !_vm.$v.project.start_date.required
                            ? _c(
                                "div",
                                {
                                  staticClass: "invalid-feedback",
                                  staticStyle: { display: "inline-block" }
                                },
                                [
                                  _vm._v(
                                    "\n              Target Start Date field is required.\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-12",
                      attrs: { label: "Target Completion Date" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "input-group" },
                        [
                          _c("flat-pickr", {
                            class: {
                              "is-invalid":
                                _vm.projectSubmitted &&
                                _vm.$v.project.due_date.$error
                            },
                            attrs: {
                              config: _vm.datePickerConfig,
                              placeholder: "Select Target Completion Date",
                              name: "due_date"
                            },
                            model: {
                              value: _vm.project.due_date,
                              callback: function($$v) {
                                _vm.$set(_vm.project, "due_date", $$v)
                              },
                              expression: "project.due_date"
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "input-group-btn" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-default",
                                attrs: {
                                  type: "button",
                                  title: "Toggle",
                                  "data-toggle": ""
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-calendar" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "sr-only",
                                      attrs: { "aria-hidden": "true" }
                                    },
                                    [_vm._v("Toggle")]
                                  )
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _vm.projectSubmitted &&
                          !_vm.$v.project.due_date.required
                            ? _c(
                                "div",
                                {
                                  staticClass: "invalid-feedback",
                                  staticStyle: { display: "inline-block" }
                                },
                                [
                                  _vm._v(
                                    "\n              Target Completion Date is required\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-12", attrs: { label: "Stage" } },
                    [
                      _c("b-form-select", {
                        class: {
                          "is-invalid":
                            _vm.projectSubmitted &&
                            _vm.$v.project.stage_id.$error
                        },
                        attrs: { options: _vm.projectStageOptions },
                        model: {
                          value: _vm.project.stage_id,
                          callback: function($$v) {
                            _vm.$set(_vm.project, "stage_id", $$v)
                          },
                          expression: "project.stage_id"
                        }
                      }),
                      _vm._v(" "),
                      _vm.projectSubmitted && !_vm.$v.project.stage_id.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n            Stage field is required.\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-12", attrs: { label: "Genre" } },
                    [
                      _c("b-form-select", {
                        class: {
                          "is-invalid":
                            _vm.projectSubmitted &&
                            _vm.$v.project.genre_id.$error
                        },
                        attrs: { options: _vm.genre_select },
                        model: {
                          value: _vm.project.genre_id,
                          callback: function($$v) {
                            _vm.$set(_vm.project, "genre_id", $$v)
                          },
                          expression: "project.genre_id"
                        }
                      }),
                      _vm._v(" "),
                      _vm.projectSubmitted && !_vm.$v.project.genre_id.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n            Genre field is required.\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.project.type === "music"
                ? _c(
                    "b-form-row",
                    [
                      _c(
                        "b-form-group",
                        { staticClass: "col-12" },
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v(
                              "\n            Artist / Group\n            "
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "text-primary",
                                attrs: { href: "#" },
                                on: { click: _vm.createNewBandGroup }
                              },
                              [
                                _c("i", {
                                  staticClass: "ion ion-md-add-circle"
                                })
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "multiselect",
                            {
                              class: {
                                "is-invalid":
                                  _vm.$v.project.band_id.$error &&
                                  _vm.$v.project.band_id.$dirty
                              },
                              attrs: {
                                id: "band_id",
                                label: "name",
                                "track-by": "id",
                                placeholder: "Type to search",
                                "open-direction": "bottom",
                                options: _vm.bandOptions,
                                multiple: false,
                                searchable: true,
                                loading: _vm.isBandLoading,
                                "internal-search": false,
                                "clear-on-select": false,
                                "close-on-select": true,
                                "options-limit": 300,
                                "max-height": 600,
                                "show-no-results": true,
                                "hide-selected": true
                              },
                              on: { "search-change": _vm.asyncBandFind },
                              model: {
                                value: _vm.project.band_id,
                                callback: function($$v) {
                                  _vm.$set(_vm.project, "band_id", $$v)
                                },
                                expression: "project.band_id"
                              }
                            },
                            [
                              _c("template", { slot: "clear" }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noResult" },
                                  slot: "noResult"
                                },
                                [_vm._v("Band not found.")]
                              )
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.projectSubmitted &&
                          !_vm.$v.project.band_id.required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n            Band is required\n          "
                                )
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.project.type !== "music"
                ? _c(
                    "b-form-row",
                    [
                      _c(
                        "b-form-group",
                        { staticClass: "col-12" },
                        [
                          _c("template", { slot: "label" }, [
                            _vm._v("\n            Company\n            "),
                            _c(
                              "a",
                              {
                                staticClass: "text-primary",
                                attrs: { href: "#" },
                                on: { click: _vm.createNewCompany }
                              },
                              [
                                _c("i", {
                                  staticClass: "ion ion-md-add-circle"
                                })
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "multiselect",
                            {
                              class: {
                                "is-invalid":
                                  _vm.$v.project.company_id.$error &&
                                  _vm.$v.project.company_id.$dirty
                              },
                              attrs: {
                                id: "company_id",
                                label: "name",
                                "track-by": "id",
                                placeholder: "Type to search",
                                "open-direction": "bottom",
                                options: _vm.companyOptions,
                                multiple: false,
                                searchable: true,
                                loading: _vm.isCompanyLoading,
                                "internal-search": false,
                                "clear-on-select": false,
                                "close-on-select": true,
                                "options-limit": 300,
                                "max-height": 600,
                                "show-no-results": true,
                                "hide-selected": true
                              },
                              on: { "search-change": _vm.asyncCompanyFind },
                              model: {
                                value: _vm.project.company_id,
                                callback: function($$v) {
                                  _vm.$set(_vm.project, "company_id", $$v)
                                },
                                expression: "project.company_id"
                              }
                            },
                            [
                              _c("template", { slot: "clear" }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  attrs: { slot: "noResult" },
                                  slot: "noResult"
                                },
                                [_vm._v("Company not found.")]
                              )
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.projectSubmitted &&
                          !_vm.$v.project.company_id.required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n            Company field is required.\n          "
                                )
                              ])
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    { staticClass: "col-sm-12", attrs: { label: "" } },
                    [
                      _c("template", { slot: "label" }, [
                        _vm._v("\n            Primary Contact\n            "),
                        _c(
                          "a",
                          {
                            staticClass: "text-primary",
                            attrs: { href: "#" },
                            on: { click: _vm.createNewPrimaryContact }
                          },
                          [_c("i", { staticClass: "ion ion-md-add-circle" })]
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "multiselect",
                        {
                          class: {
                            "is-invalid":
                              _vm.projectSubmitted &&
                              _vm.$v.project.primary_contact_id.$error
                          },
                          attrs: {
                            id: "primary_contact_ajax",
                            label: "full_name",
                            "track-by": "id",
                            placeholder: _vm.contactTypePlaceholder,
                            "open-direction": "bottom",
                            options: _vm.primary_contact_options,
                            multiple: false,
                            searchable: true,
                            loading: _vm.primary_contact_loading,
                            "internal-search": false,
                            "clear-on-select": false,
                            "close-on-select": true,
                            "max-width": 200,
                            "max-height": 600,
                            "show-no-results": false,
                            "hide-selected": true
                          },
                          on: {
                            select: _vm.selectPrimaryContact,
                            "search-change": _vm.asyncContactFind
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "clear",
                              fn: function(props) {
                                return undefined
                              }
                            }
                          ]),
                          model: {
                            value: _vm.project.primary_contact_id,
                            callback: function($$v) {
                              _vm.$set(_vm.project, "primary_contact_id", $$v)
                            },
                            expression: "project.primary_contact_id"
                          }
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("Contact not found.")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.projectSubmitted &&
                      !_vm.$v.project.primary_contact_id.required
                        ? _c(
                            "div",
                            {
                              staticClass: "invalid-feedback",
                              staticStyle: { display: "inline-block" }
                            },
                            [
                              _vm._v(
                                "\n            Primary Contact field is required.\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.project.type == "music"
                ? _c(
                    "b-form-row",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-12",
                          attrs: { label: "Number of Songs" }
                        },
                        [
                          _c("b-input", {
                            ref: "number_of_songs",
                            class: {
                              "is-invalid":
                                _vm.projectSubmitted &&
                                _vm.$v.project.number_of_songs.$error
                            },
                            model: {
                              value: _vm.project.number_of_songs,
                              callback: function($$v) {
                                _vm.$set(_vm.project, "number_of_songs", $$v)
                              },
                              expression: "project.number_of_songs"
                            }
                          }),
                          _vm._v(" "),
                          _vm.projectSubmitted &&
                          !_vm.$v.project.number_of_songs.mustBeNumeric
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n            Number of Songs field is required.\n          "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.project.type !== "music"
                ? _c(
                    "b-form-row",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-12",
                          attrs: { label: "Number of Episodes" }
                        },
                        [
                          _c("b-input", {
                            ref: "number_of_episodes",
                            class: {
                              "is-invalid":
                                _vm.projectSubmitted &&
                                _vm.$v.project.number_of_episodes.$error
                            },
                            model: {
                              value: _vm.project.number_of_episodes,
                              callback: function($$v) {
                                _vm.$set(_vm.project, "number_of_episodes", $$v)
                              },
                              expression: "project.number_of_episodes"
                            }
                          }),
                          _vm._v(" "),
                          _vm.projectSubmitted &&
                          !_vm.$v.project.number_of_episodes.required
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  "\n            Number of Episodes field is required.\n          "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-12",
                      attrs: { label: "Project Revenue Estimate" }
                    },
                    [
                      _c("b-input", {
                        ref: "project_revenue_estimate",
                        class: {
                          "is-invalid":
                            _vm.projectSubmitted &&
                            _vm.$v.project.project_revenue_estimate.$error
                        },
                        model: {
                          value: _vm.project.project_revenue_estimate,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.project,
                              "project_revenue_estimate",
                              $$v
                            )
                          },
                          expression: "project.project_revenue_estimate"
                        }
                      }),
                      _vm._v(" "),
                      _vm.projectSubmitted &&
                      !_vm.$v.project.project_revenue_estimate
                        .mustBeNumberDecimal
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n            Project Revenue Estimate field should be a valid number.\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-12",
                      attrs: { label: "Location of Session Files" }
                    },
                    [
                      _c("b-input", {
                        ref: "location_of_session_files",
                        model: {
                          value: _vm.project.location_of_session_files,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.project,
                              "location_of_session_files",
                              $$v
                            )
                          },
                          expression: "project.location_of_session_files"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-row",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "col-12",
                      attrs: { label: "% Project Complete" }
                    },
                    [
                      _c("b-input", {
                        ref: "project_completion",
                        attrs: { type: "number" },
                        model: {
                          value: _vm.project.project_completion,
                          callback: function($$v) {
                            _vm.$set(_vm.project, "project_completion", $$v)
                          },
                          expression: "project.project_completion"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.editId == null
                ? _c(
                    "b-form-row",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-12",
                          attrs: { label: "Project Notes" }
                        },
                        [
                          _c("quill-editor", {
                            ref: "project_white_board",
                            attrs: { options: _vm.quillEditorOptions },
                            model: {
                              value: _vm.project.white_board,
                              callback: function($$v) {
                                _vm.$set(_vm.project, "white_board", $$v)
                              },
                              expression: "project.white_board"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("custom-field-form-template", {
            attrs: { data: _vm.project, object: "Project" },
            on: {
              "update:data": function($event) {
                _vm.project = $event
              }
            }
          }),
          _vm._v(" "),
          _c(
            "template",
            { slot: "modal-footer" },
            [
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.loading, variant: "default" },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("\n        Cancel\n      ")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { disabled: _vm.loading, variant: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSubmit()
                    }
                  }
                },
                [_vm._v("\n        Save\n      ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("contact-form", {
        ref: "primaryContactFormModal",
        on: { "on-save": _vm.primaryContactAdded }
      }),
      _vm._v(" "),
      _c("band-form", {
        ref: "bandFormModal",
        on: { "on-save": _vm.bandAdded }
      }),
      _vm._v(" "),
      _c("company-form", {
        ref: "companyFormModal",
        on: { "on-save": _vm.companyAdded }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }