import Layout1 from '@/layout/Layout1'

export default [{
    path: '/bookings',
    component: Layout1,
    children: [{
        name: 'bookings',
        path: '/',
        component: () => import('@/components/bookings/BookingList')
    }]
}]
