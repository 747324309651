<template>
  <div v-if="customFields.length > 0">
    <div v-if="showHeaderTitle">
      <strong> Custom Fields</strong>
      <hr>
    </div>
    <div v-for="customField in customFields" :key="customField.id">
      <!-- TEXT -->
      <b-form-group :label="customField.label" :description="customField.description" v-if="customField.field_type == 'text'">
          <b-input type="text" v-model="data.custom_fields[customField.id]"></b-input>
      </b-form-group>

      <!-- TEXT AREA -->
      <b-form-group :label="customField.label" :description="customField.description" v-if="customField.field_type == 'textarea'">
          <b-form-textarea v-model="data.custom_fields[customField.id]"></b-form-textarea>
      </b-form-group>

      <!-- Number -->
      <b-form-group :label="customField.label" :description="customField.description" v-if="customField.field_type == 'number'">
          <b-input type="number" v-model="data.custom_fields[customField.id]"></b-input>
      </b-form-group>

      <!-- Select -->
      <b-form-group :label="customField.label" :description="customField.description" v-if="customField.field_type == 'select'">
          <b-form-select v-model="data.custom_fields[customField.id]" :options="customField.field_options"></b-form-select>
      </b-form-group>

      <!-- Radio -->
      <b-form-group :label="customField.label" :description="customField.description" v-if="customField.field_type == 'radio'">
          <b-form-radio-group
            v-model="data.custom_fields[customField.id]"
            :options="customField.field_options"
          ></b-form-radio-group>
      </b-form-group>

      <!-- Checkbox -->
      <b-form-group v-if="customField.field_type == 'checkbox'">
        <b-form-checkbox
          v-model="data.custom_fields[customField.id]"
          value="true"
          unchecked-value="false"
        >
          {{ customField.label }}
        </b-form-checkbox>
      </b-form-group>

      <!-- Datepicker -->
      <b-form-group :label="customField.label" v-if="customField.field_type == 'datepicker'">
          <div class="input-group">
              <flat-pickr
                      v-model="data.custom_fields[customField.id]"
                      :config="datePickerConfig"
                      >
              </flat-pickr>
              <div class="input-group-btn">
                  <button class="btn btn-default" type="button" title="Toggle" data-toggle>
                      <i class="fa fa-calendar">
                          <span aria-hidden="true" class="sr-only">Toggle</span>
                      </i>
                  </button>
              </div>
          </div>

      </b-form-group>
    </div>
  </div>
</template>

<style src="@/vendor/libs/vue-quill-editor/typography.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-quill-editor/editor.scss" lang="scss"></style>

<!-- Page -->
<style src="@/vendor/styles/pages/users.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-form-wizard/vue-form-wizard.scss" lang="scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Vue from "vue";

export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: () => [],
      sync: true
    },
    object: {
      type: String,
      required: true
    },
    showHeaderTitle: {
      type: Boolean,
      default: true 
    }
  },
  created(){
    this.loadCustomFields(this.object)
  },
  data: () => ({
    customFields: [],
    datePickerConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: window.toFlatPickrDatetime(localStorage.getItem('php_date_format')),
        altInput: true,
        dateFormat: window.datePickerSaveFormat,
        enableTime: false,
        static: false,
        //locale: Hindi, // locale for this instance only
    },
  }),
  methods: {
    headerTitle(){
      if(this.showHeaderTitle){
        return "Custom Fields";
      }else{
        return '';
      }
    },
    loadCustomFields(object){
      var self = this;
      window.axios.get(`/api/customfield/get-custom-fields-by-object/${object}`).then(response => {
        self.customFields = response.data
        self.$emit('custom-fields-loaded', response.data);
      })
    }
  }
};
</script>
