import Layout1 from '@/layout/Layout1'

export default [{
  path: '/settings/coupons/',
  component: Layout1,
  children: [{
    name: 'coupon-list',
    path: '',
    component: () => import('@/components/coupons/CouponList')
  }]
}]
