// Polyfills
require("core-js/modules/es6.array.fill");
require("core-js/modules/es6.array.iterator");
require("core-js/modules/es6.object.assign");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.promise");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.symbol");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es7.object.entries");
require("core-js/modules/es7.promise.finally");
require("core-js/modules/es7.symbol.async-iterator");
import { Ability } from "@casl/ability";

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.ability = new Ability();
(window.permissions = [
  {
    module: "User",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: false },
    create: { checked: false, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Lead",
    read: { checked: true, disabled: false },
    update: { checked: true, disabled: false },
    create: { checked: true, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Task",
    read: { checked: true, disabled: false },
    update: { checked: true, disabled: false },
    create: { checked: true, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Event",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: false },
    create: { checked: false, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Contact",
    read: { checked: true, disabled: false },
    update: { checked: true, disabled: false },
    create: { checked: true, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Project",
    read: { checked: true, disabled: false },
    update: { checked: true, disabled: false },
    create: { checked: true, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Project-file-delivery",
    read: { checked: false, disabled: true },
    update: { checked: false, disabled: true },
    create: { checked: true, disabled: false },
    delete: { checked: false, disabled: true }
  },
  {
    module: "Project-file",
    read: { checked: true, disabled: false },
    update: { checked: true, disabled: true },
    create: { checked: true, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "File-review",
    read: { checked: true, disabled: true },
    update: { checked: true, disabled: true },
    create: { checked: true, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Company",
    read: { checked: true, disabled: false },
    update: { checked: true, disabled: false },
    create: { checked: true, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Band",
    read: { checked: true, disabled: false },
    update: { checked: true, disabled: false },
    create: { checked: true, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Room",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: false },
    create: { checked: false, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Equipment",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: false },
    create: { checked: false, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Booking",
    read: { checked: true, disabled: false },
    update: { checked: true, disabled: false },
    create: { checked: true, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Quote",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: false },
    create: { checked: false, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Invoice",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: false },
    create: { checked: false, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Report",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: true },
    create: { checked: false, disabled: true },
    delete: { checked: false, disabled: true }
  },
  {
    module: "Dashboard",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: true },
    create: { checked: false, disabled: true },
    delete: { checked: false, disabled: true }
  },
  {
    module: "Setting-whitelabel",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: false },
    create: { checked: false, disabled: true },
    delete: { checked: false, disabled: true }
  },
  {
    module: "Setting-company",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: false },
    create: { checked: false, disabled: true },
    delete: { checked: false, disabled: true }
  },
  {
    module: "Setting-picklist",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: false },
    create: { checked: false, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Setting-equipmentCategory",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: false },
    create: { checked: false, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Import",
    read: { checked: true, disabled: false },
    update: { checked: false, disabled: true },
    create: { checked: false, disabled: true },
    delete: { checked: false, disabled: true }
  },
  {
    module: "Export",
    read: { checked: true, disabled: false },
    update: { checked: false, disabled: true },
    create: { checked: false, disabled: true },
    delete: { checked: false, disabled: true }
  },
  {
    module: "Time-tracking",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: false },
    create: { checked: false, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Setting-Form",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: false },
    create: { checked: false, disabled: false },
    delete: { checked: false, disabled: false }
  },
  {
    module: "Setting-CustomField",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: false },
    create: { checked: false, disabled: false },
    delete: { checked: false, disabled: false }
  }, {
    module: "Setting-Coupon",
    read: { checked: false, disabled: false },
    update: { checked: false, disabled: false },
    create: { checked: false, disabled: false },
    delete: { checked: false, disabled: false }
  }, 
]),
  (window.roles = null);
window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["Content-Type"] = "application/json";
window.axios.defaults.headers.common["Accept"] = "application/json";

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
  window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
} else {
  console.error(
    "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
  );
}

window.toFlatPickrDatetime = function(datetime) {
  var conversions = {
    'd': 'd',
    'D': 'D',
    'j': 'j',
    'l': 'l',
    'w': 'w',
    'W': 'W',
    'F': 'F',
    'm': 'm',
    'n': 'n',
    'M': 'm',
    'Y': 'Y',
    'y': 'y',
    'H' : 'H',
    'h' : 'h',
    'i' : 'i',
    's' : 's',
    'a' : 'K'
  };
  
  return datetime.replace(/[A-Za-z]/g, function(match) {
    return conversions[match] || match;
  });
}

window.toFlatPickrTime = function(time){
  var conversions = {
    'H' : 'H',
    'h' : 'G',
    'i' : 'i',
    's' : 's',
    'a' : 'K'
  };
  
  return time.replace(/[A-Za-z]/g, function(match) {
    return conversions[match] || match;
  });
}

window.toMomentDateTime = function(datetime) {
  var conversions = {
    'd': 'DD',
    'D': 'ddd',
    'j': 'D',
    'l': 'dddd',
    'N': 'E',
    'S': 'o',
    'w': 'e',
    'z': 'DDD',
    'W': 'W',
    'F': 'MMMM',
    'm': 'MM',
    'M': 'MMM',
    'n': 'M',
    't': '',
    'L': '',
    'o': 'YYYY',
    'Y': 'YYYY',
    'y': 'YY',
    'a': 'a',
    'A': 'A',
    'B': '',
    'g': 'h',
    'G': 'H',
    'h': 'hh',
    'H': 'HH',
    'i': 'mm',
    's': 'ss',
    'u': 'SSS',
    'e': 'zz',
    'I': '',
    'O': '',
    'P': '',
    'T': '',
    'Z': '',
    'c': '',
    'r': '',
    'U': 'X',
  };

  // Perform the conversion
  var result = datetime.replace(/[a-zA-Z]/g, function(match) {
    return conversions[match] || match;
  });

  // Replace 'hh' with 'hh' and 'a' with 'a'
  result = result.replace(/hh(.*)a/, 'hh$1a');

  return result;
}


window.splitDateAndTimePhpFormat = function(dateTimePhpFormat){
  var dateFormat = 'm/d/Y';
  var timeFormat = 'h:ia';

  switch (dateTimePhpFormat) {
    case 'm/d/Y h:ia':
      dateFormat = 'm/d/Y';
      timeFormat = 'h:ia';
      break;

    case 'm/d/Y H:i': 
      dateFormat = 'm/d/Y';
      timeFormat = 'H:i';
      break;

    case 'd/m/Y h:ia':
      dateFormat = 'd/m/Y';
      timeFormat = 'h:ia';
      break;

    case 'd/m/Y H:i': 
      dateFormat = 'd/m/Y';
      timeFormat = 'H:i';
      break;

    case 'Y/m/d h:ia':
      dateFormat = 'Y/m/d';
      timeFormat = 'h:ia';
      break;

    case 'Y/m/d H:i': 
      dateFormat = 'Y/m/d';
      timeFormat = 'H:i';
      break;

    default:
      break;
  }

  return {
    'date' : dateFormat,
    'time' : timeFormat
  }
} 

window.splitDateAndTimeFormat = function(dateTimeFormat){
  var dateFormat = 'MM/DD/YYYY';
  var timeFormat = 'hh:mma';
  switch (dateTimeFormat) {
    case 'MM/DD/YYYY hh:mma':
      dateFormat = 'MM/DD/YYYY';
      timeFormat = 'hh:mma';
      break;
    case 'MM/DD/YYYY HH:mm':
      dateFormat = 'MM/DD/YYYY';
      timeFormat = 'HH:mm';
      break;
    case 'YYYY/MM/DD hh:mma':
      dateFormat = 'YYYY/MM/DD';
      timeFormat = 'hh:mma';
      break;
    case 'YYYY/MM/DD HH:mm':
      dateFormat = 'YYYY/MM/DD';
      timeFormat = 'HH:mm';
      break;
    case 'DD/MM/YYYY hh:mma':
      dateFormat = 'DD/MM/YYYY';
      timeFormat = 'hh:mma';
      break;
    case 'DD/MM/YYYY HH:mm':
      dateFormat = 'DD/MM/YYYY';
      timeFormat = 'HH:mm';
      break;
    default:
      break;
  }

  return {
    'date' : dateFormat,
    'time' : timeFormat
  }
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

window.datePickerDisplayFormat = "m/d/Y";
window.dateTimePickerDisplayFormat = "m/d/Y h:i K";
window.datePickerSaveFormat = "Y-m-d";
window.dateTimePickerSaveFormat = "Y-m-d H:i";
window.momentDateFormat = "MM/DD/YYYY";
//window.momentDateTimeFormat = 'MMM DD, YYYY h:mm a'
window.momentDateTimeFormat = "MM/DD/YYYY h:mma";
window.apiDateTimeFormat = "YYYY-MM-DD HH:mm:ss";

/**
 * Load Vue.js app
 */

require("./main.js");
