import Layout1 from '@/layout/Layout1'

export default [{
    path: '/support-access',
    component: Layout1,
    children: [{
        name: 'support-access',
        path: '',
        component: () => import('@/components/supportaccess/SupportAccess')
    }]
}]
