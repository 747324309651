import LayoutBlank from '@/layout/LayoutBlank'

export default [{
  // Blank layout
  path: '/',
  component: LayoutBlank,
  children: [{
    path: 'login',
    component: () => import('@/components/authentication/AuthenticationLoginV1')
  },{
    path: 'okta-sso',
    component: () => import('@/components/authentication/OktaSso')
  }, {
    path: 'password-reset',
    component: () => import('@/components/authentication/AuthenticationPasswordReset')
  }, {
    path : 'password-change',
    component: () => import('@/components/authentication/ChangePassword')
  }]
}]
