import Layout1 from '@/layout/Layout1'

export default [{
    path: '/tools/exports/',
    component: Layout1,
    children: [{
        name: 'data',
        path: '',
        component: () => import('@/components/exports/DataExport')
    }]
}]