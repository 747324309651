<template>
  <div class="layout-wrapper layout-1">
    <notifications
      group="notifications-default"
      :duration="8000"
      :speed="1000"
      position="top right"
    />
    <vue-snotify />

    <b-modal
      style="z-index: 9999991"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      ref="trackingReminder"
      hide-footer
      title="Time Tracking Reminder"
    >
      <div class="d-block text-center">
        <p>
          We are still tracking your time for Task:
          <span v-if="selectedTracker">
            <strong>{{ selectedTracker.task.name }}</strong>
            on Project:
            <strong>{{ selectedTracker.project.name }}</strong
            >.
          </span>
        </p>
        <p>
          If you forgot to stop your time, select
          <strong>Adjust End Time</strong> below.
        </p>
        <p>
          Otherwise, select
          <strong>Ignore</strong> for your time tracking to continue.
        </p>

        <b-button-group>
          <b-button @click="adjustTimeTracking">
            <i class="fa fa-pencil-alt"></i> &nbsp; Adjust End Time
          </b-button>
          <b-button @click="ignoreTimeTracking">
            <i class="far fa-times-circle"></i> &nbsp; Ignore
          </b-button>
        </b-button-group>
      </div>
    </b-modal>

    <time-tracking-form
      ref="timeTrackingForm"
      @on-hide="trackerAdjustHidden"
      @on-save="trackerAdjusted"
      :edit-id="adjustTrackerId"
      :type="'track'"
    />
    <notifications group="task-assignment" position="top right">
      <template slot="body" slot-scope="props">
        <div class="reminder-wrap">
          <div class="reminder-content">
            <div class="reminder-description">
              You have been assigned to a new To Do
            </div>
            <div class="reminder-title">{{ props.item.title }}</div>
            <div class="reminder-description" v-html="props.item.text"></div>
          </div>
          <div class="reminder-action">
            <b-button-group>
              <b-button
                class="btn-pdless"
                @click="
                  handleOpenTask(
                    props.item.data.task_id,
                    props.item.data.id,
                    props
                  )
                "
              >
                <i class="fa fa-search"></i> &nbsp; Open
              </b-button>
              <b-button
                class="btn-pdless"
                @click="markNotificationAsRead(props, props.item.data.id)"
              >
                <i class="far fa-times-circle"></i> &nbsp; Close
              </b-button>
              <b-button class="btn-pdless" @click="clearAllNotification">
                <i class="fa fa-ban"></i> &nbsp; Clear All
              </b-button>
            </b-button-group>
          </div>
        </div>
      </template>
    </notifications>

    <notifications
      group="booking-assignment"
      position="top right"
      style="width: 310px"
    >
      <template slot="body" slot-scope="props">
        <div class="reminder-wrap">
          <div class="reminder-content">
            <div class="reminder-description">
              <span v-if="props.item.data.is_recurring"> You have been assigned to a new recurring booking:</span>
              <span v-else> You have been assigned to a new booking:</span>
              
            </div>
            <div class="reminder-title">{{ props.item.title }}</div>
            <div class="reminder-description" v-html="props.item.text"></div>
          </div>
          <div class="reminder-action">
            <b-button-group>
              <b-button
                class="btn-pdless"
                @click="
                  openBooking(props.item.data.booking_id);
                  markNotificationAsRead(props, props.item.data.id);
                "
              >
                <i class="fa fa-search"></i> &nbsp; Open
              </b-button>
              <b-button
                class="btn-pdless"
                @click="markNotificationAsRead(props, props.item.data.id)"
              >
                <i class="far fa-times-circle"></i> &nbsp; Close
              </b-button>

              <b-button class="btn-pdless" @click="clearAllNotification">
                <i class="fa fa-ban"></i> &nbsp; Clear All
              </b-button>
            </b-button-group>
          </div>
        </div>
      </template>
    </notifications>

    <notifications group="comments" position="top right">
      <template slot="body" slot-scope="props">
        <div class="reminder-wrap">
          <div class="reminder-content">
            <div class="reminder-title">{{ props.item.title }}</div>
            <div class="reminder-description" v-html="props.item.text"></div>
          </div>
          <div class="reminder-action">
            <b-button-group>
              <b-button @click="props.close(), goToComment(props.item.data)">
                <i class="fa fa-search"></i> &nbsp; Open
              </b-button>
              <b-button @click="props.close()">
                <i class="far fa-times-circle"></i> &nbsp; Close
              </b-button>
            </b-button-group>
          </div>
        </div>
      </template>
    </notifications>

    <notifications group="reminder" position="top right">
      <template slot="body" slot-scope="props">
        <div class="reminder-wrap">
          <div class="reminder-content">
            <div class="reminder-title">{{ props.item.title }}</div>
            <div class="reminder-description" v-html="props.item.text"></div>
          </div>
          <div class="reminder-action">
            <b-button-group>
              <b-dropdown left text="Snooze">
                <template slot="button-content">
                  <i class="far fa-bell-slash"></i> Snooze
                </template>
                <b-dropdown-item
                  @click="
                    $emit('snooze', {
                      duration: '5 Minutes',
                      notification: props.item.data,
                    }),
                      props.close()
                  "
                  >5 Minutes</b-dropdown-item
                >
                <b-dropdown-item
                  @click="
                    $emit('snooze', {
                      duration: '10 Minutes',
                      notification: props.item.data,
                    }),
                      props.close()
                  "
                  >10 Minutes</b-dropdown-item
                >
                <b-dropdown-item
                  @click="
                    $emit('snooze', {
                      duration: '15 Minutes',
                      notification: props.item.data,
                    }),
                      props.close()
                  "
                  >15 Minutes</b-dropdown-item
                >
                <b-dropdown-item
                  @click="
                    $emit('snooze', {
                      duration: '30 Minutes',
                      notification: props.item.data,
                    }),
                      props.close()
                  "
                  >30 Minutes</b-dropdown-item
                >
                <b-dropdown-item
                  @click="
                    $emit('snooze', {
                      duration: '1 Hour',
                      notification: props.item.data,
                    }),
                      props.close()
                  "
                  >1 Hour</b-dropdown-item
                >
                <b-dropdown-item
                  @click="
                    $emit('snooze', {
                      duration: '2 Hours',
                      notification: props.item.data,
                    }),
                      props.close()
                  "
                  >2 Hours</b-dropdown-item
                >
                <b-dropdown-item
                  @click="
                    $emit('snooze', {
                      duration: '1 Day',
                      notification: props.item.data,
                    }),
                      props.close()
                  "
                  >1 Day</b-dropdown-item
                >
              </b-dropdown>
              <b-button
                @click="
                  handleOpen(props.item.data.reminderable_id), props.close()
                "
                v-if="props.item.data.reminderable_type == 'tasks'"
              >
                <i class="fa fa-pencil-alt"></i> &nbsp; Open
              </b-button>
              <b-button @click="props.close">
                <i class="far fa-times-circle"></i> &nbsp; Close
              </b-button>
            </b-button-group>
          </div>
        </div>
      </template>
    </notifications>

    <notifications
      group="tracker-minimized"
      position="top center"
      width="340px"
      style="top: 38px !important"
    >
      <template slot="body" slot-scope="props">
        <div class="reminder-wrap">
          <div class="reminder-content">
            <div class="row">
              <div class="col-5">
                <div class="display-4">{{ tracker }}</div>
              </div>
              <div class="col-7 d-inline">
                <b-button-group size="sm" class="float-right mt-1">
                  <b-button
                    style="
                      background: transparent;
                      border-right: 1px solid rgba(0, 0, 0, 0.1);
                    "
                    @click="props.close(), stopTracker()"
                  >
                    <i class="far fa-stop-circle"></i> &nbsp; Stop
                  </b-button>
                  <b-button
                    style="background: transparent; border-left: none"
                    @click="props.close(), toggleTimeTracker()"
                  >
                    <i class="far fa-window-maximize"></i> &nbsp; Maximize
                  </b-button>
                </b-button-group>
              </div>
            </div>
          </div>
        </div>
      </template>
    </notifications>

    <notifications group="booking-service-order-approval" position="top right">
      <template slot="body" slot-scope="props">
        <div class="reminder-wrap">
          <div class="reminder-content">
            <div class="reminder-description">
              You have a new booking request:
              <span class="float-right" @click="props.close()">
                <i class="fas fa-times"></i>
              </span>
            </div>
            <div class="reminder-title">
              {{ props.item.title }}
            </div>
            <div class="reminder-description" v-html="props.item.text"></div>
          </div>
          <div class="reminder-action">
            <b-button-group>
              <b-button
                @click="
                  acceptBookingServiceOrder(
                    props.item.data.booking_service_order_id,
                    props.item.data.id
                  ),
                    markNotificationAsRead(props, props.item.data.id)
                "
              >
                <i class="fa fa-check"></i> &nbsp; Accept
              </b-button>
              <b-button
                @click="
                  rescheduleBookingServiceOrder(props.item.data),
                    markNotificationAsRead(props, props.item.data.id)
                "
              >
                <i class="fa fa-calendar-day"></i> &nbsp; Reschedule
              </b-button>
              <b-button
                @click="
                  rejectBookingServiceOrder(
                    props.item.data.booking_service_order_id,
                    props.item.data.id
                  ),
                    markNotificationAsRead(props, props.item.data.id)
                "
              >
                <i class="fas fa-times"></i> &nbsp; Reject
              </b-button>
            </b-button-group>

            <div class="text-center mt-1 small">
              Rejecting a booking will cancel any deposit payment for this
              booking request.
            </div>
          </div>
        </div>
      </template>
    </notifications>

    <notifications group="tracker" position="top center">
      <template slot="body" slot-scope="props">
        <div class="reminder-wrap">
          <div class="reminder-content">
            <div class="reminder-title">
              {{ props.item.data.project.name }} : {{ props.item.title }}
            </div>
            <div class="reminder-description">
              <div class="mt-2">
                <small class="text-bold">Elapsed Time:</small>
                <div class="display-4">{{ tracker }}</div>
              </div>

              <div class="mt-1">
                <strong>Current Task:</strong>

                <b-form-select
                  v-model="selectedTrackerTask"
                  :options="trackerTasks"
                ></b-form-select>
              </div>
            </div>
          </div>
          <div class="reminder-action">
            <b-button-group>
              <b-button @click="props.close(), stopTracker()">
                <i class="far fa-stop-circle"></i> &nbsp; Stop
              </b-button>
              <b-button @click="props.close(), toggleTimeTracker()">
                <i class="far fa-window-minimize"></i> &nbsp; Minimize
              </b-button>
            </b-button-group>
          </div>
        </div>
      </template>
    </notifications>

    <notifications group="comments" position="top right">
      <template slot="body" slot-scope="props">
        <div class="reminder-wrap">
          <div class="reminder-content">
            <div class="reminder-title">{{ props.item.title }}</div>
            <div class="reminder-description" v-html="props.item.text"></div>
          </div>
          <div class="reminder-action">
            <b-button-group>
              <b-button @click="props.close(), goToComment(props.item.data)">
                <i class="fa fa-search"></i> &nbsp; Open
              </b-button>
              <b-button @click="props.close">
                <i class="far fa-times-circle"></i> &nbsp; Close
              </b-button>
            </b-button-group>
          </div>
        </div>
      </template>
    </notifications>

    <notifications group="reminder" position="top right">
      <template slot="body" slot-scope="props">
        <div class="reminder-wrap">
          <div class="reminder-content">
            <div class="reminder-title">{{ props.item.title }}</div>
            <div class="reminder-description" v-html="props.item.text"></div>
          </div>
          <div class="reminder-action">
            <b-button-group>
              <b-dropdown left text="Snooze">
                <template slot="button-content">
                  <i class="far fa-bell-slash"></i> Snooze
                </template>
                <b-dropdown-item
                  @click="
                    $emit('snooze', {
                      duration: '5 Minutes',
                      notification: props.item.data,
                    }),
                      props.close()
                  "
                  >5 Minutes</b-dropdown-item
                >
                <b-dropdown-item
                  @click="
                    $emit('snooze', {
                      duration: '10 Minutes',
                      notification: props.item.data,
                    }),
                      props.close()
                  "
                  >10 Minutes</b-dropdown-item
                >
                <b-dropdown-item
                  @click="
                    $emit('snooze', {
                      duration: '15 Minutes',
                      notification: props.item.data,
                    }),
                      props.close()
                  "
                  >15 Minutes</b-dropdown-item
                >
                <b-dropdown-item
                  @click="
                    $emit('snooze', {
                      duration: '30 Minutes',
                      notification: props.item.data,
                    }),
                      props.close()
                  "
                  >30 Minutes</b-dropdown-item
                >
                <b-dropdown-item
                  @click="
                    $emit('snooze', {
                      duration: '1 Hour',
                      notification: props.item.data,
                    }),
                      props.close()
                  "
                  >1 Hour</b-dropdown-item
                >
                <b-dropdown-item
                  @click="
                    $emit('snooze', {
                      duration: '2 Hours',
                      notification: props.item.data,
                    }),
                      props.close()
                  "
                  >2 Hours</b-dropdown-item
                >
                <b-dropdown-item
                  @click="
                    $emit('snooze', {
                      duration: '1 Day',
                      notification: props.item.data,
                    }),
                      props.close()
                  "
                  >1 Day</b-dropdown-item
                >
              </b-dropdown>
              <b-button
                @click="
                  handleOpen(props.item.data.reminderable_id), props.close()
                "
                v-if="props.item.data.reminderable_type == 'tasks'"
              >
                <i class="fa fa-pencil-alt"></i> &nbsp; Open
              </b-button>
              <b-button @click="props.close">
                <i class="far fa-times-circle"></i> &nbsp; Close
              </b-button>
            </b-button-group>
          </div>
        </div>
      </template>
    </notifications>

    <task-edit-modal ref="taskEditModal" :id="selectedTaskId" />

    <task-edit-modal ref="taskEditModal" :id="selectedTaskId" />

    <booking-form
      ref="bookingFormModal"
      :robin-booking-data="selectedRobinBooking"
      :booking-data="selectedBooking"
      :booking-id="selectedBookingId"
      :booking-order-id="selectedBookingOrderId"
      :notification-id="selectedNotificationId"
    ></booking-form>

    <div class="layout-inner">
      <app-layout-navbar ref="layout-navbar" />

      <div class="layout-container">
        <app-layout-sidenav />

        <div class="layout-content">
          <div
            class="router-transitions container-fluid flex-grow-1 container-p-y"
          >
            <router-view />
          </div>

          <app-layout-footer />
        </div>
      </div>
    </div>
    <div class="layout-overlay" @click="closeSidenav"></div>
  </div>
</template>

<script>
import LayoutNavbar from "./LayoutNavbar";
import LayoutSidenav from "./LayoutSidenav";
import LayoutFooter from "./LayoutFooter";
import TaskEditModal from "@/components/tasks/TaskEditModal";
import BookingForm from "@/components/bookings/BookingForm";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import moment from "moment";
import idleTimeout from "idle-timeout";
import TimeTrackingForm from "@/components/projects/TimeTrackingForm";

export default {
  name: "app-layout-1",
  components: {
    "app-layout-navbar": LayoutNavbar,
    "app-layout-sidenav": LayoutSidenav,
    "app-layout-footer": LayoutFooter,
    TaskEditModal,
    TimeTrackingForm,
    BookingForm,
  },
  watch: {
    selectedTrackerTask(newVal, oldVal) {
      console.log("selected tracker task");
      console.log("new val : ", newVal, "old val :", oldVal);
      console.log("ask ", this.askTaskConfirmation);
      if (oldVal != null && newVal && this.askTaskConfirmation) {
        this.$swal({
          title: `Are you sure you want to stop and record time for the existing task and start the timer on a new task?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "Change Task",
        }).then((result) => {
          if (result.value) {
            let self = this;
            this.askTaskConfirmation = true;

            let postedData = {
              project_id: this.selectedTracker.project_id,
              task_id: newVal,
            };
            window.axios
              .post(
                `/api/trackers/${this.selectedTracker.id}/switch`,
                postedData
              )
              .then((response) => {
                self.$root.$emit("stop-time");
                self.$snotify.success("Tracking data saved.", "Success");
                localStorage.setItem(
                  "active_tracker_id",
                  response.data.data.id
                );
                self.selectedTracker = response.data.data;
                self.triggerTimeTracker();
              });
          } else {
            this.askTaskConfirmation = false;
            this.selectedTrackerTask = oldVal;
          }
        });
      } else {
        this.askTaskConfirmation = true;
      }
    },
  },
  data: () => ({
    timeoutId: null,
    adjustTrackerId: null,
    askTaskConfirmation: true,
    isTrackerTaskLoading: false,
    isTrackerMinimized: false,
    trackerTasks: [{ value: null, text: "Choose Task" }],
    selectedTracker: null,
    selectedTrackerTask: null,
    selectedBooking: {},
    selectedBookingId: null,
    selectedBookingOrderId: null,
    selectedRobinBooking: null,
    selectedNotificationId: null,
    selectedTaskId: null,
    loading: false,
    clockStart: null,
    reminders: [],
    options: [],
    now: null,
    selected: null,
    unreadNotifications: [],
  }),
  created() {
    let self = this;
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: process.env.MIX_PUSHER_APP_KEY,
      cluster: process.env.MIX_PUSHER_APP_CLUSTER,
      encrypted: true,
      auth: {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          //'Content-Type': 'application/json',
          Accept: "application/json",
        },
      },
    });

    let settings = JSON.parse(localStorage.getItem("settings"));
    if (
      settings.company_time_tracking &&
      parseInt(settings.company_time_tracking) == 1
    ) {
      setInterval(function () {
        self.now = moment();
      }, 500);
      let activeTracker = localStorage.getItem("active_tracker_id");
      if (activeTracker) {
        window.axios
          .get(`/api/trackers/${activeTracker}`)
          .then((response) => {
            self.selectedTracker = response.data.data;
            self.triggerTimeTracker();
          })
          .catch((error) => {
            console.log("fail response");
            console.log(error);
          });
      }
    }

    let options = JSON.parse(localStorage.getItem("options"));
    this.options = options;

    this.listenForChanges();
    this.$root.$on("track-time", function (data) {
      self.selectedTracker = data;
      localStorage.setItem("active_tracker_id", data.id);
      self.selectedTrackerTask = null;
      if (self.timeoutId) {
        self.timeoutId.destroy();
      }
      self.triggerTimeTracker();
    });

    this.$on("snooze", function (data) {
      if (data.notification.reminderable_type == "tasks") {
        let snoozeData = {
          task_reminder_id: data.notification.reminder_id,
          snooze: data.duration,
        };
        window.axios
          .post(
            `/api/tasks/${data.notification.reminderable_id}/snooze`,
            snoozeData
          )
          .then((response) => {})
          .catch((error) => {
            console.log("snozze task fail response");
            console.log(error);
          });
      }
    });

    this.handleSystemNotification();
  },

  computed: {
    tracker() {
      if (this.now) {
        let hours = this.now.diff(this.clockStartAt, "hours");
        let minutes = this.now.diff(this.clockStartAt, "minutes") % 60;
        let seconds = this.now.diff(this.clockStartAt, "seconds") % 60;
        return (
          this.zeroPrefix(hours, 2) +
          ":" +
          this.zeroPrefix(minutes, 2) +
          ":" +
          this.zeroPrefix(seconds, 2)
        );
      } else {
        return "00:00:00";
      }
    },
  },

  mounted() {
    this.layoutHelpers.init();
    this.layoutHelpers.update();
    this.layoutHelpers.setAutoUpdate(true);
  },

  beforeDestroy() {
    this.layoutHelpers.destroy();
  },

  methods: {
    async acceptBookingServiceOrder(id, notificationId) {
      try {
        const result = await this.$swal({
          title: `Booking Order Approval Confirmation`,
          text: "Would you like to send a rejection notice to all other bookings during this time block and remove them from your calendar view?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#46B1C9",
          confirmButtonText: "Yes",
          showDenyButton: true,
          denyButtonText: "No",
          denyButtonColor: "#d33",
        });
        if (result.isConfirmed || result.isDenied) {
          var acceptUrl = `/api/booking-service-orders/${id}/accept`;

          const urlParams = new URLSearchParams();
          if (result.isConfirmed) {
            urlParams.append("cancel_others", 1);
          }

          if (notificationId) {
            urlParams.append("notification_id", notificationId);
          }

          acceptUrl += "?" + urlParams.toString();

          this.$swal({
            title: "Processing ...",
            html: "<div class='spinner-grow text-primary mb-2'></div> <div>Please wait while we approve the booking. </div> <div> Please don't close your browser/tab. </div>",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });

          try {
            const response = await window.axios.post(acceptUrl);
            if (response.data.status == "success") {
              this.$swal.close();
              this.$snotify.success(
                `Booking ${response.data.data.booking.title} accepted.`,
                `Success`
              );
            } else if (response.data.status == "already_accepted") {
              this.$swal.close();
              this.$snotify.success(
                `Your booking has already been accepted.`,
                `Success`
              );
            } else if (response.data.status == "already_rejected") {
              this.$swal.close();
              this.$snotify.success(
                `Your booking has already rejected.`,
                `Success`
              );
            }

            //trigger online-booking-approval event
            this.$eventHub.$emit("refresh-notification-list");
            this.$eventHub.$emit("refresh-booking-list");

            if (this.$refs["layout-navbar"]) {
              this.$refs["layout-navbar"].getLatestNotification();
            }

            if (typeof this.$parent.getCalendarBookingData === "function") {
              this.$parent.getCalendarBookingData();
            }
          } catch (error) {
            console.log(error);
            this.$swal.close();
            this.$snotify.error(
              `There was a problem when accepting the booking.`,
              "Error"
            );
          }
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);

        this.$notify({
          group: "booking-service-order-approval",
          clean: true,
        });

        this.$snotify.error(
          `There was a problem when accepting the booking.`,
          "Error"
        );
      }
    },
    async rejectBookingServiceOrder(id, notificationId) {
      try {
        const result = await this.$swal({
          title: `Are you sure you want to delete this booking?`,
          text: "Rejecting a booking will cancel any deposit payment for this booking request.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#46B1C9",
          confirmButtonText: "Yes",
        });

        if (result.isConfirmed) {
          this.$swal({
            title: "Processing ...",
            html: "<div class='spinner-grow text-primary mb-2'></div> <div>Please wait while we reject the booking. </div> <div> Please don't close your browser/tab. </div>",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });

          const urlParams = new URLSearchParams();
          if (notificationId) {
            urlParams.append("notification_id", notificationId);
          }

          var rejectUrl = `/api/booking-service-orders/${id}/reject?${urlParams.toString()}`;

          const response = await window.axios.post(rejectUrl);

          this.$notify({
            group: "booking-service-order-approval",
            clean: true,
          });

          if (response.data.status == "success") {
            this.$swal.close();
            this.$snotify.success(
              `Booking ${response.data.data.booking.title} rejected.`,
              `Success`
            );
          } else if (response.data.status == "already_accepted") {
            this.$swal.close();
            this.$snotify.success(`Booking already approved.`, `Success`);
          } else if (response.data.status == "already_rejected") {
            this.$swal.close();
            this.$snotify.success(`Booking already rejected.`, `Success`);
          }

          this.$eventHub.$emit("refresh-notification-list");
          this.$eventHub.$emit("refresh-booking-list");

          if (this.$refs["layout-navbar"]) {
            this.$refs["layout-navbar"].getLatestNotification();
          }
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);

        this.$notify({
          group: "booking-service-order-approval",
          clean: true,
        });
        this.$snotify.error(
          "There was a problem when rejecting the booking.",
          "Error"
        );
      }
    },
    rescheduleBookingServiceOrder(data) {
      this.selectedBookingId = data.booking_id;
      this.selectedBookingOrderId = data.booking_service_order_id;
      this.selectedNotificationId = data.notification_id;

      this.$refs.bookingFormModal.show();
    },
    confirmLeaving(evt) {
      if (this.selectedTracker) {
        evt.returnValue =
          "You are currently tracking time. If you log out, you will lose your tracked time progress.?";
      }
    },
    trackerAdjustHidden() {
      if (this.selectedTracker !== null) {
        this.triggerTimeTracker();
      }
    },
    trackerAdjusted() {
      localStorage.removeItem("active_tracker_id");
      this.selectedTracker = null;
      this.selectedTrackerTask = null;
      if (this.timeoutId) {
        this.timeoutId.destroy();
      }
      this.$root.$emit("stop-time");
      this.$snotify.success("Tracking data saved.", "Success");
    },
    adjustTimeTracking() {
      this.adjustTrackerId = this.selectedTracker.id;
      this.hideTracker();
      this.$refs.trackingReminder.hide();
      this.$refs.timeTrackingForm.show();
    },
    ignoreTimeTracking() {
      this.$refs.trackingReminder.hide();
      this.triggerTimeTracker();
    },
    showInactive() {
      let activeTrackerId = localStorage.getItem("active_tracker_id");
      if (activeTrackerId) {
        this.hideTracker();
        this.$refs.trackingReminder.show();
      }
    },
    hideTracker() {
      if (this.isTrackerMinimized) {
        this.$notify({
          group: "tracker-minimized",
          clean: true,
        });
      } else {
        this.$notify({
          group: "tracker",
          clean: true,
        });
      }
    },
    stopTracker() {
      let self = this;
      let activeTrackerId = localStorage.getItem("active_tracker_id");
      window.axios
        .post(`/api/trackers/${activeTrackerId}/stop`)
        .then(() => {
          localStorage.removeItem("active_tracker_id");
          self.selectedTracker = null;
          self.selectedTrackerTask = null;
          if (self.timeoutId) {
            self.timeoutId.destroy();
          }
          self.$snotify.success("Tracking data saved.", "Success");
          self.$root.$emit("stop-time");
        })
        .catch((error) => {
          console.log("fail response");
          console.log(error);
        });
    },
    zeroPrefix(num, digit) {
      let zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    async handleSystemNotification() {
      let response = await window.axios.get(`/api/notifications/unread-system`);
      console.log("resposne e");
      console.log(response.data);
      this.unreadNotifications = response.data;
      this.unreadNotifications.forEach((notification) => {
        if (
          notification.type &&
          notification.type == "App\\Notifications\\TaskAssignmentNotification"
        ) {
          let description = "";
          description += `Due Date: ${notification.data.due_date} <br>`;
          description += `Associated With: ${notification.data.associate_with} <br>`;

          let details =
            notification.data.details != null ? notification.data.details : "-";

          description += `${details}`;

          this.$notify({
            group: "task-assignment",
            title: `${notification.data.title}`,
            text: `${description}`,
            closeOnClick: false,
            duration: -1,
            data: {
              id: notification.id,
              task_id: notification.data.task_id,
            },
          });
        } else if (
          notification.type &&
          notification.type ==
            "App\\Notifications\\BookingAssignmentNotification"
        ) {
          let description = "";
          if(notification.data.is_recurring){
              let schedules = JSON.parse(notification.data.recurring_schedules);
              description += `<ul class="list-unstyled mb-0" style="font-size: small;">`;
              schedules.forEach(schedule => {
                description += `<li>${schedule}</li>`;
              });
              description += `</ul>`;
          }
          else {
              description += `${notification.data.due_date} <br>`;
          }
          description += `Room: ${notification.data.room} <br>`;
          description += `Event Type: ${notification.data.event_type} <br>`;
          description += `Associated With: ${notification.data.associate_with} <br>`;

          let details =
            notification.data.details != null ? notification.data.details : "-";

          description += `${details}`;

          this.$notify({
            group: "booking-assignment",
            title: `${notification.data.title}`,
            text: `${description}`,
            closeOnClick: false,
            duration: -1,
            data: {
              is_recurring: notification.data.is_recurring,
              id: notification.id,
              booking_id: notification.data.booking_id,
            },
          });
        }
      });
    },
    async markNotificationAsRead(props, id) {
      console.log("the props");
      console.log(props);
      console.log("the id: " + id);
      props.close();
      this.$refs["layout-navbar"].markNotificationAsRead(id);
    },
    async clearAllNotification() {
      await window.axios.get(
        "/api/notifications/mark-all-read"
      );

      this.$notify({
        group: 'task-assignment', // clean only the foo group
        clean: true
      });

      this.$notify({
        group: 'booking-assignment', // clean only the foo group
        clean: true
      });

      this.$refs["layout-navbar"].setMarkAllRead();
    },
    closeSidenav() {
      this.layoutHelpers.setCollapsed(true);
    },
    async handleOpenTask(taskId, notificationId, props) {
      this.selectedTaskId = taskId;
      this.$refs.taskEditModal.show();
      props.close();

      window.axios.get("/api/notifications/" + notificationId);
    },
    handleOpen(selectedTaskId) {
      this.selectedTaskId = selectedTaskId;
      this.$refs.taskEditModal.show();
    },
    goToComment(data) {
      this.$router.push({
        name: "file-review",
        params: {
          public_view: false,
          project_id: data.project.id,
          id: data.versions[0].project_attachment_id,
          preselect_region: data.region_data,
        },
      });
    },
    async openBooking(bookingId) {
      try {
        let response = await window.axios.get(`/api/bookings/${bookingId}`);
        let booking = response.data;

        let associate_type = null;
        if (booking.associate_type == "App\\Models\\Contact") {
          associate_type = "Contact";
        } else if (booking.associate_type == "App\\Models\\Project") {
          associate_type = "Project";
        } else if (booking.associate_type == "App\\Models\\Company") {
          associate_type = "Company";
        } else if (booking.associate_type == "App\\Models\\Band") {
          associate_type = "Band/Group";
        } else if (booking.associate_type == "App\\Models\\Lead") {
          associate_type = "Lead";
        }

        let reminder = null;
        if (booking.reminder) {
          reminder = booking.reminder.reminder;
        }

        this.selectedRobinBooking = null;
        this.selectedBooking = {
          booking_id: booking.id,
          start_at: this.$moment
            .utc(booking.start_at)
            .tz(localStorage.getItem("timezone"))
            .format("YYYY-MM-DD HH:mm:ss"),
          end_at: this.$moment
            .utc(booking.end_at)
            .tz(localStorage.getItem("timezone"))
            .format("YYYY-MM-DD HH:mm:ss"),
          title: booking.title.replace(/<[^>]*>?/gm, ""),
          all_day: booking.all_day,
          room_id: booking.room_id,
          user_id: booking.user_id,
          type: booking.type,
          description: booking.description,
          associate_id: booking.associate_id,
          associate_type: associate_type,
          reminder: reminder,
        };
        this.$refs.bookingFormModal.show();
      } catch (error) {
        console.log(error);
      }
    },
    goToTodo() {
      this.$router.push({ name: "tasks-list" });
    },

    openRobinBooking(data) {
      this.selectedBooking = null;
      let robinBookingData = data.robin_event_response;

      const timezone = localStorage.getItem("timezone");
      const startAt = this.$moment
        .utc(data.start_at)
        .tz(timezone)
        .format("YYYY-MM-DD HH:mm:ss");
      const endAt = this.$moment
        .utc(data.end_at)
        .tz(timezone)
        .format("YYYY-MM-DD HH:mm:ss");

      this.selectedRobinBooking = {
        title: robinBookingData.title,
        description: robinBookingData.description,
        start_at: startAt,
        end_at: endAt,
        all_day: robinBookingData.is_all_day,
        room_id: data.room_id,
        robin_event_id: robinBookingData.id,
      };
      this.$refs.bookingFormModal.show();
    },

    toggleTimeTracker() {
      this.isTrackerMinimized = !this.isTrackerMinimized;
      if (this.isTrackerMinimized) {
        this.$notify({
          group: "tracker",
          clean: true,
        });

        this.$notify({
          group: "tracker-minimized",
          title: `${this.selectedTracker.task.name}`,
          text: `${this.selectedTracker.task.name}`,
          closeOnClick: false,
          duration: -1,
          data: this.selectedTracker,
        });
      } else {
        this.$notify({
          group: "tracker-minimized",
          clean: true,
        });

        this.$notify({
          group: "tracker",
          title: `${this.selectedTracker.task.name}`,
          text: `${this.selectedTracker.task.name}`,
          closeOnClick: false,
          duration: -1,
          data: this.selectedTracker,
        });
      }
    },

    triggerTimeTracker() {
      let self = this;
      let timeZone = localStorage.getItem("timezone") || "UTC";
      let startAt = moment.utc(this.selectedTracker.start_at).tz(timeZone);
      this.now = moment().tz(timeZone);
      this.clockStartAt = startAt;

      this.trackerTasks.push({
        value: this.selectedTracker.task.id,
        text: this.selectedTracker.task.name,
      });
      this.askTaskConfirmation = false;
      this.selectedTrackerTask = this.selectedTracker.tracker_task_id;

      //setup the timer
      this.timeoutId = idleTimeout(this.showInactive, {
        element: document,
        //2 hours
        timeout: 1000 * 60 * 60 * 2,
        //timeout: 1000 * 2,
        loop: false,
      });

      if (this.isTrackerMinimized) {
        this.$notify({
          group: "tracker-minimized",
          clean: true,
        });

        this.$notify({
          group: "tracker-minimized",
          title: `${this.selectedTracker.task.name}`,
          text: `${this.selectedTracker.task.name}`,
          closeOnClick: false,
          duration: -1,
          data: this.selectedTracker,
        });
      } else {
        this.$notify({
          group: "tracker",
          clean: true,
        });

        this.$notify({
          group: "tracker",
          title: `${this.selectedTracker.task.name}`,
          text: `${this.selectedTracker.task.name}`,
          closeOnClick: false,
          duration: -1,
          data: this.selectedTracker,
        });
      }

      this.askTaskConfirmation = true;

      window.axios
        .get(`/api/tracker-tasks?is_active=1`)
        .then((response) => {
          let tasksData = response.data.data;
          self.trackerTasks = [];
          tasksData.forEach((task) => {
            self.trackerTasks.push({
              text: task.name,
              value: task.id,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listenForChanges() {
      const websiteUUid = localStorage.getItem("website_uuid");
      const userId = localStorage.getItem("user_id");
      let self = this;
      window.Echo.private(`App.User.${websiteUUid}.${userId}`).notification(
        (notification) => {
          self.$refs["layout-navbar"].getLatestNotification();

          if (
            notification.type &&
            notification.type == "App\\Notifications\\ReviewCommentNotification"
          ) {
            self.$refs["layout-navbar"].getLatestNotification();
          } else if (
            notification.type &&
            notification.type ==
              "App\\Notifications\\BookingAssignmentNotification"
          ) {
            let description = "";
            if(notification.is_recurring){
              let schedules = JSON.parse(notification.recurring_schedules);
              description += `<ul class="list-unstyled mb-0" style="font-size: small;">`;
              schedules.forEach(schedule => {
                description += `<li>${schedule}</li>`;
              });
              description += `</ul>`;
            }
            else {
                description += `${notification.due_date} <br>`;
            }
            description += `Room: ${notification.room} <br>`;
            description += `Event Type: ${notification.event_type} <br>`;
            description += `Associated With: ${notification.associate_with} <br>`;

            let details =
              notification.details != null ? notification.details : "-";

            description += `${details}`;

            let notify = self.$notify({
              group: "booking-assignment",
              title: `${notification.title}`,
              text: `${description}`,
              closeOnClick: false,
              duration: -1,
              data: {
                is_recurring: notification.is_recurring,
                id: notification.id,
                booking_id: notification.booking_id,
              },
            });
          } else if (
            notification.type &&
            notification.type ==
              "App\\Notifications\\BookingServicePendingOrderNotification"
          ) {
            let description = "";
            description += `${notification.due_date} <br>`;
            description += `Room: ${notification.room} <br>`;
            description += `Event Type: ${notification.event_type} <br>`;
            description += `Associated With: ${notification.associate_with} <br>`;

            self.$notify({
              group: "booking-service-order-approval",
              title: notification.title,
              text: description,
              closeOnClick: false,
              duration: -1,
              data: {
                booking_service_order_id: notification.booking_service_order_id,
                booking_id: notification.booking_id,
                id: notification.id,
              },
            });
          } else if (
            notification.type &&
            notification.type ==
              "App\\Notifications\\TaskAssignmentNotification"
          ) {
            let description = "";
            description += `Due Date: ${notification.due_date} <br>`;
            description += `Associated With: ${notification.associate_with} <br>`;

            let details =
              notification.details != null ? notification.details : "-";

            description += `${details}`;

            let notify = self.$notify({
              group: "task-assignment",
              title: `${notification.title}`,
              text: `${description}`,
              closeOnClick: false,
              duration: -1,
              data: {
                id: notification.id,
                task_id: notification.task_id,
              },
            });
          } else if (notification.notification_type == "message") {
            return;
          } else if (
            notification.type &&
            notification.type ==
              "App\\Notifications\\RobinBookingAddedNotification"
          ) {
            self.$notify({
              group: "robin-booking-notification",
              title: notification.title,
              text: "",
              closeOnClick: false,
              duration: -1,
              data: notification,
            });
          } else if (
            notification.type &&
            notification.type == "App\\Notifications\\TaskReminderNotification"
          ) {
            let title = notification.title
              .substr(0, 50)
              .concat(notification.title.length > 50 ? "..." : "");
            let description =
              notification.description != null
                ? notification.description
                    .substr(0, 130)
                    .concat(notification.description.length > 130 ? "..." : "")
                : "-";

            let notify = self.$notify({
              group: "reminder",
              title: `${title}`,
              text: `${description}`,
              closeOnClick: false,
              duration: -1,
              data: notification,
            });

            let soundReminder = this.options.find(function (option) {
              return option.option_name == "sound_reminder";
            });

            if (
              soundReminder &&
              soundReminder.hasOwnProperty("option_value") &&
              soundReminder.option_value === "on"
            ) {
              let audio = new Audio("/audio/knock_brush.ogg");
              audio.play();
            }
          } else if (
            notification.type &&
            notification.type ==
              "App\\Notifications\\BookingReminderNotification"
          ) {
            let title = notification.title
              .substr(0, 50)
              .concat(notification.title.length > 50 ? "..." : "");
            let description =
              notification.description != null
                ? notification.description
                    .substr(0, 130)
                    .concat(notification.description.length > 130 ? "..." : "")
                : "-";

            let notify = self.$notify({
              group: "reminder",
              title: `${title}`,
              text: `${description}`,
              closeOnClick: false,
              duration: -1,
              data: notification,
            });

            let soundReminder = this.options.find(function (option) {
              return option.option_name == "sound_reminder";
            });

            if (
              soundReminder &&
              soundReminder.hasOwnProperty("option_value") &&
              soundReminder.option_value === "on"
            ) {
              let audio = new Audio("/audio/knock_brush.ogg");
              audio.play();
            }
          } else if (notification.type && notification.type == "App\\Notifications\\StripePaymentIntentReaderNotification") {
            this.$nextTick(() => {
              this.$snotify.success("Payment Completed.", "Success");
            });
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.btn-pdless {
  padding: 0.3rem 0.8rem !important;
}
</style>