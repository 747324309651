import { render, staticRenderFns } from "./LayoutBlank.vue?vue&type=template&id=0eddc054&"
import script from "./LayoutBlank.vue?vue&type=script&lang=js&"
export * from "./LayoutBlank.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/code/nathan/Sonido_SaaS/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0eddc054')) {
      api.createRecord('0eddc054', component.options)
    } else {
      api.reload('0eddc054', component.options)
    }
    module.hot.accept("./LayoutBlank.vue?vue&type=template&id=0eddc054&", function () {
      api.rerender('0eddc054', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/src/layout/LayoutBlank.vue"
export default component.exports