import Layout1 from '@/layout/Layout1'

export default [{
  path: '/settings/custom-field',
  component: Layout1,
  children: [{
    name: 'custom-field-list',
    path: '',
    component: () => import('@/components/customField/CustomFieldList')
  }]
}]
