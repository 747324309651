import LayoutBlank from '@/layout/LayoutBlank'

export default [{
  path: '/review-portal-revoked',
  component: LayoutBlank,
  children: [{
    name: 'review-portal-revoked',
    path: '',
    component: () => import('@/components/reviewer/RevokedPage')
  }]
}]
