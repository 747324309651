<template>
    <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">
        <!-- Brand -->
        <b-navbar-brand to="/"><img :src="logo" height="40"></b-navbar-brand>

        <!-- Sidenav toggle -->
        <b-navbar-nav class="align-items-lg-center mr-auto mr-lg-4" v-if="sidenavToggle">
            <a class="nav-item nav-link px-0 ml-2 ml-lg-0" href="javascript:void(0)" @click="toggleSidenav">
                <i class="ion ion-md-menu text-large align-middle"/>
            </a>
        </b-navbar-nav>

        <!-- Navbar toggle -->
        <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

        <b-collapse is-nav id="app-layout-navbar">
            <!-- Divider -->
            <hr class="d-lg-none w-100 my-2">

            <!--<b-navbar-nav class="align-items-lg-center">
                &lt;!&ndash; Search &ndash;&gt;
                <label class="nav-item navbar-text navbar-search-box p-0 active">
                    <i class="ion ion-ios-search navbar-icon align-middle"></i>
                    <span class="navbar-search-input pl-2">
            <input type="text" class="form-control navbar-text mx-2" placeholder="Search..." style="width:200px">
          </span>
                </label>
            </b-navbar-nav>-->

            <b-navbar-nav class="align-items-lg-center ml-auto">
        <!-- <b-badge variant="success" class="mr-5">{{ getPackageName() }}</b-badge> -->
                <b-nav-item-dropdown no-caret :right="!isRTL" class="demo-navbar-notifications mr-lg-3">
                    <template slot="button-content" class="item">
                        <i class="ion ion-md-notifications-outline navbar-icon align-middle"></i>
                        <!-- <span class="badge badge-primary badge-dot indicator" v-if="unreadNotification > 0"></span> -->
                        <span class="notify-badge" v-if="unreadNotification > 0">{{ unreadNotification }}</span>
                        <span class="d-lg-none align-middle">&nbsp; Notifications</span>
                    </template>

                    <div class="bg-primary text-center text-white font-weight-bold p-3">
                        {{ unreadNotification }} New Notifications
                    </div>
                    <b-list-group flush>
                        <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center bg-"
                                           :class="{'bg-lighter': notification.read_at == null}"
                                           v-for="notification in notifications" :key="notification.id">
                            <div class="ui-icon ui-icon-sm ion ion ion-md-megaphone bg-success border-0 text-white"></div>

                            <div v-if="notification.type == 'App\\Notifications\\ReviewCommentNotification'" class="media-body line-height-condenced ml-3">
                                <div @click="showMessage(notification)">
                                    <div class="text-dark font-weight-bold">
                                        {{notification.data.type}}
                                    </div>
                                    <div class="text-dark">
                                        {{notification.data.description}}
                                    </div>
                                    <div class="text-light small">{{ formatTimeZoneFromNow(notification.created_at) }} </div>
                                </div>
                            </div>

                            <div v-else-if="notification.type == 'App\\Notifications\\ClientUploadNewFileNotification'" class="media-body line-height-condenced ml-3">
                                <div @click="showMessage(notification)">
                                    <div class="text-dark font-weight-bold">
                                        New client files have been uploaded.
                                    </div>
                                    <div class="text-dark">
                                        New client files have been uploaded to {{ notification.data.project_name }}.
                                    </div>
                                    <div class="text-light small">{{ formatTimeZoneFromNow(notification.created_at) }} </div>
                                </div>
                            </div>

                            <div v-else-if="notification.type == 'App\\Notifications\\BookingServicePendingOrderNotification'" class="media-body line-height-condenced ml-3">
                                <div @click="showMessage(notification)">
                                    <div class="text-dark font-weight-bold">
                                        Booking Request - {{notification.data.title}}
                                    </div>
                                    <div class="text-light small">{{ formatTimeZoneFromNow(notification.created_at) }}</div>
                                </div>
                            </div>

                            <div v-else class="media-body line-height-condenced ml-3">
                                <div @click="showMessage(notification)">
                                    <div class="text-dark font-weight-bold">
                                        {{notification.data.title}}
                                    </div>
                                    <div class="text-light small">{{ formatTimeZoneFromNow(notification.created_at) }}</div>
                                </div>
                            </div>
                            
                        </b-list-group-item>
                    </b-list-group>

                    <a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1" @click.prevent="markAllRead()">Show all
                        notifications</a>
                </b-nav-item-dropdown>

                <!-- Divider -->
                <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">
                    |
                </div>

                <b-nav-item-dropdown :right="!isRTL" class="demo-navbar-user">
                    <template slot="button-content">
                        <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                        <span v-if="photo_path == null">
                            <i class="fa fa-user-circle fa-2x"></i>
                        </span>
                        <img v-else :src="photo_path" alt class="d-block ui-w-30 rounded-circle">
                        <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ `${first_name} ${last_name}` }}</span>
                        </span>
                    </template>

                    <b-dd-item>
                        <router-link to="/profile"><i class="ion ion-ios-person text-lightest"></i> &nbsp;<span class="text-primary">My Profile</span>
                        </router-link>
                    </b-dd-item>
                    <!--<b-dd-item><i class="ion ion-ios-mail text-lightest"></i> &nbsp; Messages</b-dd-item>-->
                    <!--<b-dd-item><i class="ion ion-md-settings text-lightest"></i> &nbsp; Account settings</b-dd-item>-->
                    <b-dd-divider/>
                    <b-dd-item @click.prevent="logout"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Log Out
                    </b-dd-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>

        <!-- Notification Pop Up -->
        <b-modal
            centered
            id="notification-modal"
            ref="notification-modal"
            header-text-variant="dark"
            body-text-variant="dark"
            :title="notif.title"
            hide-footer
            >
            <div v-html="notif.description" class="text-dark"></div>
        </b-modal>
    </b-navbar>
</template>

<script>

    import moment from 'moment';

    export default {
        name: 'app-layout-navbar',

        props: {
            sidenavToggle: {
                type: Boolean,
                default: true
            }
        },

  data: function () {
    return {
      oktaUrls: {
        okta_base_url: '',
        okta_client_id: '',
        okta_issuer_url: ''
      },
      packageId: localStorage.getItem("website_p"),
      first_name: localStorage.getItem("first_name"),
      last_name: localStorage.getItem("last_name"),
      notifications: [],
      notif: {
        id: null,
        title: null,
        description: null,
        createdAt: null,
      },
      unreadNotification: 0,
    };
  },

        created() {
            this.getLatestNotification()
        },

        computed: {
            userTimezone: function () {
                return localStorage.getItem('timezone')
            },
            logo: function () {
                let logo = localStorage.getItem('logo_path')
                if(logo.includes('http')){
                    return logo;
                }
                else {
                    return '/media/'+logo
                }
            },
            photo_path: function(){
                let photoPath = localStorage.getItem('photo_path')
                if(photoPath == 'null'){
                    return null;
                }
                else {
                    return '/media/'+photoPath
                }
            }
        },

        watch: {
            notifications: function (val) {
                this.unreadNotification = 0
                this.notifications.forEach(notification => {
                    if (notification.read_at === null) {
                        this.unreadNotification += 1
                    }
                })

            }
        },

        filters: {
            moment: function (mydate) {
                var date = new Date(mydate + ' UTC');
                return moment(date.toString()).fromNow();
            }
        },
        methods: {

    getPackageName(){
      return `${localStorage.getItem('package_name')} PLAN`.toUpperCase()
      
    },
    
            formatTimeZoneFromNow(dateTime){
                return this.$moment.utc(dateTime).tz(this.userTimezone).fromNow();
            },
            secondsToHms(d) {
                d = Number(d);
                var h = Math.floor(d / 3600);
                var m = Math.floor(d % 3600 / 60);
                var s = Math.floor(d % 3600 % 60);

                var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
                var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
                var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
                return hDisplay + mDisplay + sDisplay;
            },
            moment: function (mydate) {
                return moment(mydate);
            },

            toggleSidenav() {
                this.layoutHelpers.toggleCollapsed()
            },

            getLayoutNavbarBg() {
                return this.layoutNavbarBg
            },

            logout() {
                console.log(this.$parent.selectedTracker)

                if(this.$parent.selectedTracker){
                    let self = this;
                    let selectedTracker = this.$parent.selectedTracker
                    this.$swal({
                        title: `You are currently tracking time. If you log out, you will lose your tracked time progress.`,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#d33",
                        confirmButtonText: "Continue",
                        cancelButtonText: "End Timer"

                    }).then(result => {

                        if (result.value) {
                            let selectedTracker = self.$parent.selectedTracker;

                            window.axios.delete(`/api/trackers/${selectedTracker.id}`)
                            .then(response => {
                                window.axios.post('/api/logout').then(logoutResponse => {
                                    window.ability.update([])
                                    localStorage.clear()
                                    self.$router.push('/login')
                                })    
                            }).catch(error => {
                                location.reload()
                            })

                    
                        } else {
                            window.axios.post(`/api/trackers/${selectedTracker.id}/stop`)
                            .then(response => {
                                window.axios.post('/api/logout').then(logoutResponse => {
                                    window.ability.update([])
                                    localStorage.clear()
                                    self.$router.push('/login')
                                })    
                            }).catch(error => {
                                location.reload()
                            })
                        }
                    });
                }
                else{
                    let logoutResponse = axios.post('/api/logout').then(response => {
                        window.ability.update([])
                        localStorage.clear()
                        this.$router.push('/login')
                    }).catch(error => {
                        location.reload()

                    })
                }

               
            },

            showMessage(n){
                let self = this

                if(n.type == 'App\\Notifications\\ReviewCommentNotification'){
                    self.$router.push({name: 'file-review', 
                        params: {
                            public_view: false,
                            project_id: n.data.project.id, 
                            id: n.data.versions[0].project_attachment_id,
                            preselect_region: n.data.region_data,
                            notification_id: n.id
                        }
                    })
                }

                else if(n.type == 'App\\Notifications\\ClientUploadNewFileNotification'){
                    self.$router.push({name: 'projects-view', 
                        params: {
                            id: n.data.project_id, 
                        }
                    })
                }

                 else if(n.type == 'App\\Notifications\\BookingServicePendingOrderNotification'){
                    this.markNotificationAsRead(n.id)

                    let description = '';
                    description += `${n.data.due_date} <br>`;
                    description += `Room: ${n.data.room} <br>`;
                    description += `Event Type: ${n.data.event_type} <br>`;
                    description += `Associated With: ${n.data.associate_with} <br>`;

                    this.$notify({
                        group: 'booking-service-order-approval',
                        title: n.data.title,
                        text: description,
                        closeOnClick: false,
                        duration: -1,
                        data: {
                            booking_service_order_id: n.data.booking_service_order_id,
                            booking_id: n.data.booking_id,
                            id: n.id 
                        }
                    });
                    
                }

                else if(n.type == 'App\\Notifications\\TaskAssignmentNotification'){

                    this.markNotificationAsRead(n.id)
                    this.$parent.handleOpen(n.data.id)
                }

                else if(n.type == 'App\\Notifications\\BookingAssignmentNotification'){
                    this.markNotificationAsRead(n.id)
                    this.$parent.goToBooking()
                }

                else if(n.type == 'App\\Notifications\\RobinBookingAddedNotification'){
                    this.markNotificationAsRead(n.id);
                    this.$parent.openRobinBooking(n.data);
                }

                else {
                    this.notif.id = n.id;
                    this.notif.title = n.data.title;
                    this.notif.description = n.data.description;
                    this.notif.createdAt = n.created_at;
                    this.$refs['notification-modal'].show();

                    window.axios.get('/api/notifications/' + this.notif.id).then(response => {
                        self.unreadNotification -= 1
                        let i = self.notifications.map(notification => notification.id).indexOf(n.id)
                        this.$delete(self.notifications, i)
                    }).
                    catch(error => {
                        console.log(error)
                    });
                }

                
            },

            getLatestNotification() {
                let self = this
                window.axios.get('/api/notifications/take/5').then(response => {
                    self.notifications = response.data
                }).catch(error => {
                    console.log(error)
                });
            },

            setMarkAllRead(){
                this.unreadNotification = 0;
                this.notifications = [];
            },

            markAllRead() {
                this.unreadNotification = 0;
                this.notifications = [];
                this.$router.push({name: 'notifications'});
            },
            markNotificationAsRead(id){
                let self = this
                window.axios.get('/api/notifications/' + id).then(response => {
                    self.unreadNotification -= 1
                    let i = self.notifications.map(notification => notification.id).indexOf(id)
                    this.$delete(self.notifications, i)
                }).
                catch(error => {
                    console.log(error)
                });
            }
        }
    }
</script>

<style>
.item {
    position:relative;
    text-align:center;
    vertical-align:middle;
}
.notify-badge{
    position: absolute;
    right:-20%;
    top:-1%;
    background:red;
    text-align: center;
    border-radius: 50%;
    color:white;
    padding:0px 6px;
    border:1px solid;
    font-size: 12px;
}
</style>