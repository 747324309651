<template>
    <div id="app">
        <BlockUI message="" :html="html" v-if="loading"/>
        <shared-primary-contact-form v-if="accessToken != null" ref="sharedPrimaryContactForm" />
        <shared-member-contact-form v-if="accessToken != null" ref="sharedMemberContactForm" />
        <shared-band-form v-if="accessToken != null" ref="sharedBandForm" />
        <shared-company-form v-if="accessToken != null" ref="sharedCompanyForm" />
        <router-view @showLoader="showLoader" @hideLoader="hideLoader" />

    </div>
</template>

<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-corporate.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="@/vendor/libs/spinkit/spinkit.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-notification/vue-notification.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>
<style src="./demo.css" lang="css"></style>
<style>
@import '~@fortawesome/fontawesome-free/css/all.min.css';
</style>

<script>

    import moment from 'moment';
    import _ from 'lodash';

    export default {
        name: 'app',
        metaInfo: {
            title: 'Index',
            titleTemplate: 'Sonido - %s'
        },
        data: () => ({
            accessToken: localStorage.getItem('accessToken'),
            timeoutId: null,
            selectedTaskId : null,
            loading: false,
            html: `
                <div class="sk-folding-cube sk-primary">
                    <div class="sk-cube1 sk-cube"></div>
                    <div class="sk-cube2 sk-cube"></div>
                    <div class="sk-cube4 sk-cube"></div>
                    <div class="sk-cube3 sk-cube"></div>
                 </div>
                <h5 class="text-center mb-0">LOADING...</h5>
            `,
            reminders: [],
            options : []
        }),

        methods: {
            showLoader(message){
                
                if(message != undefined){
                    this.html = `
                        <div style="background: rgba(255,255,255,0.9);">
                            <div class="sk-folding-cube sk-primary">
                                <div class="sk-cube1 sk-cube"></div>
                                <div class="sk-cube2 sk-cube"></div>
                                <div class="sk-cube4 sk-cube"></div>
                                <div class="sk-cube3 sk-cube"></div>
                            </div>
                            <h5 class="text-center mb-0"> ${message} </h5>
                        </div>
                    `
                }
                else {
                    this.html = `
                       <div style="background: rgba(255,255,255,0.9);">
                         <div class="sk-folding-cube sk-primary">
                            <div class="sk-cube1 sk-cube"></div>
                            <div class="sk-cube2 sk-cube"></div>
                            <div class="sk-cube4 sk-cube"></div>
                            <div class="sk-cube3 sk-cube"></div>
                         </div>
                         <h5 class="text-center mb-0">LOADING...</h5>
                       </div>
                    `
                }
                this.loading = true
            },
            hideLoader(){
                this.loading = false
            }
        },
        created() {
            if(process.env.NODE_ENV == 'production') {
                (function (h, o, t, j, a, r) {
                    h.hj = h.hj || function () {
                        (h.hj.q = h.hj.q || []).push(arguments)
                    };
                    h._hjSettings = {hjid: 1425381, hjsv: 6};
                    a = o.getElementsByTagName('head')[0];
                    r = o.createElement('script');
                    r.async = 1;
                    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
            }
        },

    }


</script>
