<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      id="create-new-contact"
      size="lg"
      no-enforce-focus
      :title="formTitle"
      v-model="show_modal"
      @shown="focusMyElement"
      @show="onShow()"
      ref="modal"
    >
      <b-form-row>
        <b-form-group label="Contact Status" class="col-sm-12">
          <label class="switcher switcher-primary">
            <input
              type="checkbox"
              class="switcher-input"
              v-model="data.is_active"
            />
            <span class="switcher-indicator">
              <span class="switcher-yes text-primary"></span>
              <span class="switcher-no"></span>
            </span>
            <span class="switcher-label" v-if="!data.is_active">Inactive</span>
            <span class="switcher-label" v-if="data.is_active">Active</span>
          </label>
        </b-form-group>
      </b-form-row>



      <b-form-row v-if="displayCompanyField()">
        <b-form-group label="Company" class="col-12">
          <multiselect
            v-model="data.company_id"
            id="company_id"
            label="name"
            track-by="id"
            placeholder="Type to search"
            open-direction="bottom"
            :options="companyOptions"
            :multiple="false"
            :searchable="true"
            :loading="isCompanyLoading"
            :internal-search="false"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="true"
            @search-change="asyncCompanyFind"
          >
            <template slot="clear" slot-scope="props"></template>
            <span slot="noResult">Company not found.</span>
          </multiselect>
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Contact Type" class="col-sm-12">
          <b-form-select
            v-model="data.contact_type_id"
            :options="contact_type_options"
          ></b-form-select>
        </b-form-group>
      </b-form-row>

      <b-form-row v-if="isSpotifySite">
       <b-form-group label="Department" class="col-sm-12">
          <b-form-select
            v-model="data.department_id"
            :options="departmentOptions"
          ></b-form-select>
       </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group
          label="First Name"
          :invalid-feedback="getErrorLabel($v.data.first_name)"
          class="col-sm-6"
        >
          <b-input
            ref="first_name"
            v-model="data.first_name"
            class="mb-1"
            :state="$v.data.first_name.$error ? 'invalid' : null"
          />
        </b-form-group>
        <b-form-group
          label="Last Name"
          :invalid-feedback="getErrorLabel($v.data.last_name)"
          class="col-sm-6"
        >
          <b-input
            v-model="data.last_name"
            :state="$v.data.last_name.$error ? 'invalid' : null"
            class="mb-1"
          />
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group
          label="Email Address"
          :invalid-feedback="getErrorLabel($v.data.email)"
          class="col-sm-12"
        >
          <b-input
            v-model="data.email"
            :state="$v.data.email.$error ? 'invalid' : null"
            class="mb-1"
          />
        </b-form-group>
      </b-form-row>

      <!-- <b-form-row>
        <b-form-group label="Gender" class="col-sm-12">
          <b-form-select v-model="data.gender" :options="genderOption"></b-form-select>
        </b-form-group>
      </b-form-row> -->

      <b-form-row>
        <b-form-group
          label="Phone Number"
          :invalid-feedback="getErrorLabel($v.data.phone_number)"
          class="col-sm-6"
        >
          <b-input
            v-model="data.phone_number"
            :state="$v.data.phone_number.$error ? 'invalid' : null"
            class="mb-1"
          />
        </b-form-group>
        <b-form-group label="Primary Instrument(s)" class="col-sm-6">
          <b-input v-model="data.primary_instruments" class="mb-1" />
        </b-form-group>
      </b-form-row>

      <b-form-row>
        <b-form-group label="Country" class="col-lg-12">
          <b-form-select
            v-model="data.country"
            :options="countries_options"
          ></b-form-select>
        </b-form-group>
      </b-form-row>

      <div class="row">
        <b-form-group label="Address" class="col-lg-12">
          <b-input v-model="data.street"></b-input>
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group label="City" class="col-lg-12">
          <b-input v-model="data.city"></b-input>
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group label="State" class="col-lg-12">
          <b-spinner small class="ml-2 mt-3" v-if="loading_states" />
          <b-form-select
            v-model="data.state"
            :options="states_options"
            :disabled="loading_states"
          >
            <template slot="first">
              <option :value="null" disabled>-- Please select state --</option>
            </template>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group label="Zip" class="col-lg-6">
          <b-input v-model="data.zip_code"></b-input>
        </b-form-group>
      </div>
      
      <custom-field-form-template v-show="isCustomFieldsAvailable" :data.sync="data" object="Contact" @custom-fields-loaded="handleCustomFieldsLoaded"/>

      <template slot="modal-footer">
        <b-button variant="default" @click="cancel()">Cancel</b-button>
        <b-button :disabled="loading" variant="primary" @click="save()"
          >Save</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<style src="@/vendor/libs/vue-quill-editor/typography.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-quill-editor/editor.scss" lang="scss"></style>

<!-- Page -->
<style src="@/vendor/styles/pages/users.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-form-wizard/vue-form-wizard.scss" lang="scss"></style>

<script>
import Vue from "vue";
import Toasted from "vue-toasted";
import LaddaBtn from "@/vendor/libs/ladda/Ladda";
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import _ from "lodash";
import CustomFieldFormTemplate from "../customField/CustomFieldFormTemplate.vue";

import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from "vue-multiselect";

Vue.use(Vuelidate);
Vue.use(Toasted);
Vue.use(VueMask);

const initData = {
  is_active: true,
  contact_type_id: "",
  email: "",
  first_name: "",
  last_name: "",
  // gender: "Male",
  phone_number: "",
  street: "",
  city: "",
  state: null,
  zip_code: "",
  country: "",
  favorite_drink_or_snack: "",
  primary_instruments: "",
  notes: "",
  company_id: "",
  department_id: "",
  custom_fields: {}
};
export default {
  props: {
    editId: {
      type: Number,
      default: null,
    },
    email: {
      type: String,
      default: null,
    },
    first_name: {
      type: String,
      default: null,
    },
    last_name: {
      type: String,
      default: null,
    },
    createSource: {
      type: String,
      default: null,
    },
  },
  components: {
    Multiselect,
    CustomFieldFormTemplate,
    LaddaBtn,
    quillEditor: () =>
      import("vue-quill-editor/dist/vue-quill-editor")
        .then((m) => m.quillEditor)
        .catch(() => {}),
  },
  created() {
    this.detectSpotify();

    window.axios.get(`/api/customfield/get-custom-fields-by-object/Contact`).then(response => {
      this.customFields = response.data
    })
  },
  computed: {
    formTitle() {
      if (this.editId !== null) {
        return `Edit Contact ${this.data.first_name} ${this.data.last_name}`;
      } else {
        return "Create New Contact";
      }
    },
  },
  watch: {
    "data.country"(newVal, oldVal) {
      if (newVal) {
        let self = this;
        if (newVal !== null) {
          this.loading_states = true;
          window.axios
            .get(`/api/countries/get-states/${newVal}`)
            .then((response) => {
              self.states_options = [];
              Object.keys(response.data).forEach((key) => {
                self.states_options.push({
                  text: response.data[key],
                  value: response.data[key],
                });
              });
              self.loading_states = false;
            });
        }
      }
    },
  },
  validations: {
    data: {
      email: {
        required,
        email,
      },
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      phone_number: {
        //required,
      },
    },
    form: ["data"],
  },
  data: () => ({
    
    isSpotifySite: false,
    contact_type_options: [],
    editorOptions: {
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }, { size: [] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ direction: "rtl" }, { align: [] }],
          ["link", "image", "video"],
          ["clean"],
        ],
      },
    },
    breadcrumbItems: [
      {
        text: "Contacts",
        to: { name: "contacts-list" },
      },
      {
        text: "Create New Contact",
        active: true,
      },
    ],
    step: 0,
    loading: false,
    loading_states: false,
    show_modal: false,
    errors: [],
    band_member_error: false,
    social_media_error: false,
    social_medias: [],
    music_media_error: false,
    music_medias: [],
    data: { ...initData },
    states_options: [],
    countries_options: [],
    errorLabels: {
      required: "This field is required.",
      email: "Invalid email format",
    },
    isCompanyLoading: false,
    companyOptions: [],
    departmentOptions: [],
    isCustomFieldsAvailable: false,
  }),
  methods: {
    detectSpotify() {
      const baseUrl = window.location.host.toString();
      if (baseUrl.includes("spotify")) {
        this.isSpotifySite = true;
      }
    },
    displayCompanyField(){
      if(this.createSource == 'lead-form'){
        return false;
      }
      else if(this.createSource == 'company-form'){
        return false;
      }
      return true;
    },
    async loadContactTypeOptions() {
      const contactResponse = await window.axios.get(
        "/api/picklists/Contact Types"
      );
      this.contact_type_options = contactResponse.data.data.options.map(
        (contactOption) => {
          return {
            text: contactOption.name,
            value: contactOption.id,
          };
        }
      );
      this.data.contact_type_id = this.contact_type_options[0].value;
    },
    asyncCompanyFind: _.debounce(function (query) {
      let self = this;
      self.companyOptions = [];
      self.isCompanyLoading = true;
      window.axios
        .post("/api/companies/search-select", {
          search_string: query,
        })
        .then((response) => {
          self.companyOptions = response.data;
          self.isCompanyLoading = false;
        });
    }, 500),

    async loadCountryOptions() {
      try {
        const response = await window.axios.get("/api/countries/get-all-name");
        Object.keys(response.data).forEach((key) => {
          this.countries_options.push({
            text: response.data[key],
            value: response.data[key],
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    loadCompanyOptions() {
      let self = this;
      window.axios
        .post("/api/companies/search-select", {
          search_string: "",
        })
        .then((response) => {
          self.companyOptions = response.data;
          self.isCompanyLoading = false;
        });
    },

    getErrorLabel(validation) {
      return Object.keys(validation)
        .filter((key) => !key.startsWith("$"))
        .reduce((prev, curr) => {
          if (!validation[curr]) {
            prev = this.errorLabels[curr];
          }

          return prev;
        }, "");
    },

    async save(event, createAnother = false) {
      let self = this;

      this.$v.form.$touch();
      var isValid = !this.$v.form.$invalid;
      if (isValid) {
        this.loading = true;
        let postedData = { ...this.data };
        if (postedData.company_id && postedData.company_id.id) {
          postedData.company_id = postedData.company_id.id;
        }

        if (this.editId === null) {
          try {
            window.axios.post(`/api/contacts`, postedData).then((response) => {
              this.$store.state.primaryContact = response.data;
              this.clearForm();
              this.hide();
              this.$snotify.success("New Contact Created", "Success");
              this.$emit("on-save", response.data);
              this.loading = false;
            });
          } catch (error) {
            console.log(error);
            this.loading = false;
            this.errors = error.response.data.errors;
            this.$snotify.error(
              "There was a problem creating Contact.",
              "Error"
            );
          }
        } else {
          try {
            const response = window.axios.put(
              `/api/contacts/${this.editId}`,
              postedData
            ).then(response => {
              this.clearForm();
              this.hide();
              this.$snotify.success("Contact Updated", "Success");
              this.$emit("on-save", response.data);
              this.loading = false;
            });
            
          } catch (error) {
            this.loading = false;
            this.errors = error.response.data.errors;
            this.$snotify.error(
              "There was a problem updating Contact.",
              "Error"
            );
            console.log(error);
          }
        }
      }
    },
    clearForm() {
      this.data = Object.assign({}, initData);
      this.step = 0;
      this.errors = [];
      this.$v.$reset();
    },
    show() {
      this.loadCompanyOptions();
      this.loadCountryOptions();
      this.show_modal = true;
    },
    hide() {
      this.show_modal = false;
    },
    cancel() {
      this.hide();
    },
    focusMyElement(e) {
      this.$refs.first_name.focus();
    },

     async loadDepartmentPicklistOptions() {
      const picklistResponse = await window.axios.get(
        "/api/picklists/Department"
      );

      if (
        picklistResponse.data.data.options &&
        picklistResponse.data.data.options.length > 0
      ) {
        this.departmentOptions = picklistResponse.data.data.options.map(
          (option) => {
            return {
              value: option.id,
              text: option.name,
            };
          }
        );
        this.departmentOptions.unshift({
          value: "",
          text: "Choose Content Team",
        });
      }
    },

    async onShow() {
      this.loadContactTypeOptions();
      this.loadDepartmentPicklistOptions();
      if (this.editId !== null) {
        const response = await window.axios.get(`/api/contacts/${this.editId}`);
        this.data = response.data;
        if (this.data.company_bind.length > 0) {
          this.data.company_id = {
            id: this.data.company_bind[0].id,
            name: this.data.company_bind[0].name,
          };
        }
      } else {
        this.data = { ...initData };
        this.data.country = "United States";

        if (this.email) {
          this.data.email = this.email;
        }

        if (this.first_name) {
          this.data.first_name = this.first_name;
        }

        if (this.last_name) {
          this.data.last_name = this.last_name;
        }
      }
    },

     handleCustomFieldsLoaded(data){
      this.isCustomFieldsAvailable = data.length > 0 ? true : false;
    }
  },
};
</script>
