import Layout1 from '@/layout/Layout1'

export default [{
  path: '/equipment',
  component: Layout1,
    // beforeEnter: (to, from, next) => {
    //     if(localStorage.getItem('website_p') == 3) {
    //         next()
    //     } else {
    //         next({ path: 'dashboard' })
    //     }
    // },
  children: [{
    name: 'equipment-list',
    path: '',
    component: () => import('@/components/equipments/EquipmentList')
  }, {
    name: 'equipment-view',
    path: ':id',
    props: true,
    component: () => import('@/components/equipments/EquipmentView')
  }]
}]
