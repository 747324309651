import LayoutWithoutSideNav from '@/components/reviewer/LayoutWithoutSidenav'

export default [{
  path: '/subscription-sales/',
  component: LayoutWithoutSideNav,
  children: [{
    name: 'subscription-sales',
    path: '',
    component: () => import('@/components/SubscriptionSales')
  },
  {
    name: 'subscription-account-create-success',
    path: 'success',
    component: () => import('@/components/SubscriptionAccountCreateSuccess')
  }]
}]
